export const TOGGLE_VIEW = 'TOGGLE_VIEW'
export const RESET_VIEW = 'RESET_VIEW'

export const setView = view => ({
  type: TOGGLE_VIEW,
  payload: {
    VIEW: view,
  },
})

export const resetViewState = () => ({
  type: RESET_VIEW,
})
