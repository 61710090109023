import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Container } from '@jsluna/grid'
import { Display1 } from '@jsluna/typography'
import {
  selectFormRef,
  selectFormContactEmail,
} from '../../redux/selectors/ideaForm'
import { name } from '../../data/volunteeringFormData'
import { PageHeader } from '../../components/PageHeader'
import sendImage from '../../assets/send.svg'
import { Text, Paragraph, Paragraph2 } from '../../styles'
import routes from '../../routes'
import { Content } from './styles'

const VolunteeringSuccess: React.FC = () => {
  const contactEmail = useSelector(selectFormContactEmail(name))
  const ref = useSelector(selectFormRef(name))

  return (
    <>
      <PageHeader
        title="Thanks for being awesome!"
        blurb="Your idea has been submitted"
      />
      <Container size="xs" soft>
        <Content centered>
          <figure>
            <img src={sendImage} alt="Sent" />
          </figure>
          <Display1 element="p">
            We will look over your idea and get back to you as soon as possible
          </Display1>
          {ref && (
            <Paragraph2>
              <strong>
                Ref:
                <Text orange>{ref}</Text>
              </strong>
            </Paragraph2>
          )}
          <Paragraph>
            Correspondence will be sent to: <strong>{contactEmail}</strong>.
          </Paragraph>
          <Paragraph>
            <Text>
              Remember that you can check the status of your ideas in the{' '}
              <Link to={routes.userIdeas}>My ideas</Link> page, where you will
              be able to edit your idea in case it is declined.
            </Text>
          </Paragraph>
          <Paragraph>
            In the meantime, you can check the{' '}
            <Link to={routes.faqs}>FAQs</Link> section, look over{' '}
            <Link to={routes.caseStudies}>Case Studies</Link> or{' '}
            <a href="mailto:Community.Affairs@sainsburys.co.uk">Get in touch</a>{' '}
            if you have any further comments or questions.
          </Paragraph>
          <Paragraph>
            We‘ll be in touch soon!
            <br />
            <strong>Thank you.</strong>
          </Paragraph>
        </Content>
      </Container>
    </>
  )
}

export default VolunteeringSuccess
