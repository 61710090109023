import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import UserIdeas from './UserIdeas'
import {
  doFetchUserIdeas,
  doUpdateUserSort,
} from '../../redux/dispatchers/userIdeas'
import {
  getLoading,
  getErrorMessage,
  getSortedIdeas,
} from '../../redux/selectors/userIdeas'

const mapStateToProps = (state, ownProps) => ({
  loading: getLoading(state),
  errorMessage: getErrorMessage(state),
  ideas: getSortedIdeas(state),
  campaign: ownProps.campaign,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doFetchUserIdeas,
      doUpdateUserSort,
    },
    dispatch
  )

const IdeasContainer = connect(mapStateToProps, mapDispatchToProps)(UserIdeas)

export default IdeasContainer
