import Cookie from 'js-cookie'
import { api, post } from '../../httpClient/httpClient'
import { login, logout, refresh } from '../actions/auth'
import {
  getToken,
  setTokenInLocalStorage,
  unsetTokenInLocalStorage,
} from '../../util/TokenUtil'
import { reportError } from '../../util/errorReporting'

const doLogin = () => dispatch => {
  const token = getToken()
  if (token) {
    setTokenInLocalStorage(token)
    dispatch(login(token))
  }
}

const doLogout = () => dispatch => {
  unsetTokenInLocalStorage()
  dispatch(logout())
  window.location.href = window.ENV.URL
}

const doRefresh = () => async dispatch => {
  try {
    const { accessToken } = await post(api('/token'), null, null, 'include')
    dispatch(refresh(accessToken))
    setTokenInLocalStorage(accessToken)
  } catch (error) {
    const traceId = Cookie.get('trace_id')
    reportError(error, {
      traceId,
    })
    dispatch(doLogout())
  }
}

export { doLogin, doLogout, doRefresh }
