import React from 'react'
import Layout from '../layouts/Layout/Layout'

import NewIdeaErrorContainer from '../components/NewIdea/NewIdeaErrorContainer'

const NewIdeaError = () => {
  return (
    <Layout pageId="page-new-idea-error">
      <NewIdeaErrorContainer />
    </Layout>
  )
}

export default NewIdeaError
