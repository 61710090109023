import React, { useState } from 'react'
import 'react-dates/initialize'

import moment from 'moment'
import { DateRangePicker } from 'luna-react'

moment.locale('en-GB')

const DateRangeFilter = ({
  disabled = false,
  onChange,
  start = null,
  end = null,
}) => {
  const [focusedInput, setFocusedInput] = useState(null)
  const [startDate, setStartDate] = useState(start && moment(start))
  const [endDate, setEndDate] = useState(end && moment(end))

  return (
    <div style={{ marginTop: 10 }}>
      <DateRangePicker
        disabled={disabled}
        startDate={startDate}
        startDateId="drp-start-date"
        endDate={endDate}
        endDateId="drp-end-date"
        displayFormat="DD MMM YYYY"
        noBorder={false}
        showClearDates={true}
        showDefaultInputIcon
        onDatesChange={({ startDate, endDate }) => {
          if (startDate && endDate) {
            onChange({
              start: startDate.format('YYYY-MM-DD'),
              end: endDate.format('YYYY-MM-DD'),
            })
          } else {
            onChange({})
          }
          setStartDate(startDate)
          setEndDate(endDate)
        }}
        isOutsideRange={day => day.isAfter(moment())}
        numberOfMonths={1}
        focusedInput={disabled ? null : focusedInput}
        onFocusChange={focusedInput => {
          setFocusedInput(focusedInput)
        }}
      />
    </div>
  )
}

export default DateRangeFilter
