import { createSelector } from 'reselect'
import { isFormValid } from '../../util/localHeroesFormValidations'

export const getLocalHeroesFormState = state => state.localHeroes

export const getFormValues = createSelector(
  [getLocalHeroesFormState],
  localHeroesForm => localHeroesForm.formValues
)

export const getIsValid = createSelector([getFormValues], formValues =>
  isFormValid(formValues)
)

export const getErrorMessage = createSelector(
  [getLocalHeroesFormState],
  localHeroesForm => localHeroesForm.errorMessage
)

export const getSending = createSelector(
  [getLocalHeroesFormState],
  localHeroesForm => localHeroesForm.sending
)

export const getColleagueEmail = createSelector(
  [getLocalHeroesFormState],
  localHeroesForm => localHeroesForm.colleague_email
)

export const getRef = createSelector(
  [getLocalHeroesFormState],
  localHeroesForm => localHeroesForm.ref
)
