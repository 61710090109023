import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  setSearchTerm,
  showMoreRecords,
} from '../../redux/actions/opportunitiesSearch'
import { doSearchOpportunities } from '../../redux/dispatchers/opportunitiesSearch'

import OpportunitiesSearchResults from './OpportunitiesSearchResults'
import {
  getIsSearching,
  getSearchTerm,
  getErrorMessage,
  getPaginatedRecordCount,
  getResults,
  getHasMoreResults,
  getUnseenOpportunitiesCount,
} from '../../redux/selectors/opportunitiesSearch'
import { getView } from '../../redux/selectors/mapList'

const mapStateToProps = state => {
  return {
    isSearching: getIsSearching(state),
    searchTerm: getSearchTerm(state),
    display: getPaginatedRecordCount(state),
    errorMessage: getErrorMessage(state),
    results: getResults(state),
    hasMore: getHasMoreResults(state),
    unseenOpportunitiesCount: getUnseenOpportunitiesCount(state),
    view: getView(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSearchTerm,
      showMoreRecords,
      doSearchOpportunities,
    },
    dispatch
  )

const OpportunitiesSearchResultsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OpportunitiesSearchResults)

export default OpportunitiesSearchResultsContainer
