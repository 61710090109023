import { connect } from 'react-redux'

import { getRef, getContactEmail } from '../../redux/selectors/newIdea'
import NewIdeaSuccess from './NewIdeaSuccess'

const mapStateToProps = state => {
  return {
    idea_ref: getRef(state),
    contact_email: getContactEmail(state),
  }
}

export default connect(mapStateToProps, null)(NewIdeaSuccess)
