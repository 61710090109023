import React from 'react'
import styled from 'styled-components'

// TODO - Extract ListText to its own component file
interface ListTextInterface {
  fontWeight?: number
}
interface ListTextWrapperInterface {
  columns?: boolean
  marginBottom?: string
}
const ListTextWrapper = styled.div<ListTextWrapperInterface>`
  display: flex;
  flex-direction: ${({ columns }) => (columns ? 'row' : 'column')};
  margin-bottom: ${({ marginBottom }) => marginBottom || '1.33rem'};
`
const ListText = styled.li<ListTextInterface>`
  font-weight: ${props => props.fontWeight || 500};
  font-size: 1rem;
  list-style-type: none;
  line-height: 1.5;
`

interface CaptionInterface {
  title: string | number | null
  text: string | number | null
}
interface CaptionListTextInterface {
  columns?: boolean
  marginBottom?: string
  items: CaptionInterface[]
}

const CaptionListText = (props: CaptionListTextInterface) => {
  const { items, columns, marginBottom } = props
  const content = items.map((element: CaptionInterface, index: number) => {
    return (
      <ListTextWrapper
        columns={columns}
        marginBottom={marginBottom}
        key={`__listTextWrapper${element.title}${index}`}
      >
        <ListText key={`__listTextWrapper${element.title}${index}`}>
          {element.title}
        </ListText>
        <ListText
          key={`__listTextValue${element.title}${index}`}
          fontWeight={800}
        >
          {element.text}
        </ListText>
      </ListTextWrapper>
    )
  })
  return <>{content}</>
}

export default CaptionListText
