import React from 'react'
import Layout from '../layouts/Layout/Layout'
import { PageHeader } from '../components/PageHeader'
import { Container, Content } from '../styles'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import routes from '../routes'

const TermsList = styled.ol`
  max-width: 960px;
`
const TermsListItem = styled.li`
  margin: 20px 0;
  font-size: 16px;
`

const TermsAndConditions = () => (
  <Layout pageId="page-terms-and-conditions">
    <PageHeader title="Our Community Terms and Conditions" />
    <Content>
      <Container.Padded>
        <TermsList>
          <TermsListItem>
            We’re asking all Sainsbury’s Group colleagues to get involved in our
            150 days of Community this year, our 150th anniversary.
          </TermsListItem>
          <TermsListItem>
            The Charity of the Year programme will not exist in 2019 and is
            replaced by the 150 days of Community. The Local Hero programme is
            still operational.
          </TermsListItem>
          <TermsListItem>
            The 150 days of Community gives each colleague the opportunity to
            volunteer. It’s not compulsory to volunteer, but we’d like everyone
            to get involved.
          </TermsListItem>
          <TermsListItem>
            To sign up, each store / division / work location / department (each
            a “Location”) should nominate a lead colleague (volunteering
            co-ordinator) to take responsibility for organising the activity and
            one colleague (with a personal email address) who will take
            responsibility for submitting their team idea.
          </TermsListItem>
          <TermsListItem>
            Each Location must choose a charity or community project to work
            with. For inspiration, Our Community Hub, hosted on OurSainsburys,
            contains all the information you will need to join the 150 days of
            Community program. It contains links to ideas for volunteering and
            case studies. It hosts information about charities and suggestions
            about where to go to get further help.
          </TermsListItem>
          <TermsListItem>
            The Community Hub may offer links to third party websites or
            resources. Sainsbury’s has no control over such websites and
            resources and you agree that links are provided for your convenience
            and that Sainsbury’s is not responsible for the content of these
            sites. If you decide to click through to any third party website you
            do so at your own risk. The Third party website is solely
            responsible for ensuring that any material on their website complies
            with all relevant laws and codes of practice
          </TermsListItem>
          <TermsListItem>
            The Hub contains an option to insert a postcode to locate charities
            Sainsbury’s already works with at a local level. This function can
            also be used to locate other charities in your area.
          </TermsListItem>
          <TermsListItem>
            Before starting to work with their chosen charity or community
            project, each Location must submit their volunteering idea on the
            Portal by the deadline of 15 April 2019.
          </TermsListItem>
          <TermsListItem>
            Each idea should include which charity or community project the
            Location wishes to volunteer with and why.{' '}
          </TermsListItem>
          <TermsListItem>
            Community Affairs will examine all submissions and will let each
            Location know, as soon as possible, whether their idea is agreed and
            signed off.
          </TermsListItem>
          <TermsListItem>
            Once agreed each Location is free to approach their chosen charity
            and begin to work with them. If Community Affairs are unable to
            agree an idea they will let you know why they declined it.
          </TermsListItem>
          <TermsListItem>
            A risk assessment form will be shared with colleagues to ensure the
            volunteering idea is suitable for colleagues who wish to join in.{' '}
          </TermsListItem>
          <TermsListItem>
            Sainsbury’s exclude all liability, so far as is permitted by law.
            This does not include or limit in any way our liability for death or
            personal injury caused by our negligence.The 150 days of Community
            will run from end of May to October 2019 inclusive.
          </TermsListItem>
          <TermsListItem>
            All Sainsbury’s Group colleagues will get the opportunity to
            volunteer away from their usual work location to volunteer. All
            Volunteer Hours are subject to availability and may be taken only at
            the discretion of the Location Manager. Whilst we’d like all
            colleagues to work with their Location’s choice of charity or
            community project, we accept that some colleagues may prefer to
            volunteer at an alternative location. Individual requests must be
            submitted to the Location Manager and may be accepted or refused at
            their sole discretion.{' '}
          </TermsListItem>
          <TermsListItem>
            Store and logistics labour hours for the 150 days of Community may
            be recouped by following the instructions issued in the briefing
            pack. Labour hours for central locations should be absorbed by
            Locations on the same basis as a Store Working Day.
          </TermsListItem>
          <TermsListItem>
            Each Volunteer hours allocation can be used only for volunteering.
            There is no paid leave alternative for colleagues who do not wish to
            participate in the 150 days of Community.
          </TermsListItem>
          <TermsListItem>
            There is no central budget available if protective clothing is
            required. Colleagues should provide their own clothing suitable for
            the 150 days of Community.
          </TermsListItem>
          <TermsListItem>
            Once Locations have completed their 150 days of Community, Community
            Affairs will ask for feedback to find out how things have gone. All
            submissions will be maintained subject to our{' '}
            <Link to={routes.privacyPolicy}>Privacy Policy</Link> and the
            following terms.
          </TermsListItem>
          <TermsListItem>
            Locations may upload photos and commentary to Our Community Hub to
            share with the business and other Locations. If Locations or
            individuals wish to share photos please only upload photos you’re
            comfortable sharing. As a general rule, the person who took the
            photo and all those who appear in it should give permission before
            uploading.{' '}
          </TermsListItem>
          <TermsListItem>
            By commenting or submitting content or photos Locations confirm on
            behalf of individual colleagues that the work is not copied, is
            their own original work and grants to Sainsbury’s a worldwide,
            royalty-free irrevocable licence, including the right to
            sub-licence, to Sainsbury’s to use, publish, adapt, distribute and
            reproduce the content, including any part of it in any marketing,
            blog, social media, comment, or website in whole or in part without
            notice or payment and without any liability. Locations acknowledge
            that content, including any comment or photo submitted on the
            Volunteering Portal may be amended, substituted, withdrawn or
            deleted by us at any time and without notice.
          </TermsListItem>
          <TermsListItem>
            The content of the volunteer portal and third party websites and
            resources are protected by copyright, trademarks, database and other
            intellectual property rights. You must not reproduce, modify, copy
            or distribute any of the materials or content without written
            permission from the owner
          </TermsListItem>
          <TermsListItem>
            Locations submitting content acknowledge the Hub may not be
            monitored and agree content should be respectful and that the spirit
            of all posts should be constructive, supportive and of general
            benefit. Sainsbury’s reserves the right to remove any comment and
            our decision to do so is final.
          </TermsListItem>
          <TermsListItem>
            Sainsbury’s reserves the right to change, suspend or discontinue Our
            Community Hub or the 150 days of Community, or any part of it,
            including without limitation, Location volunteering ideas at any
            time, without notice should it be necessary to do so for operational
            reasons or reasons outside its reasonable control.
          </TermsListItem>
          <TermsListItem>
            Promoter: Sainsbury’s Supermarkets Ltd, 33 Holborn, London EC1N 2HT.
          </TermsListItem>
        </TermsList>
      </Container.Padded>
    </Content>
  </Layout>
)

export default TermsAndConditions
