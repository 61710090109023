// 1. Actions
import {
  CONTENTFUL_OPPORTUNITY_DETAIL_GET_REQUEST,
  CONTENTFUL_OPPORTUNITY_DETAIL_GET_SUCCESS,
  CONTENTFUL_OPPORTUNITY_DETAIL_GET_FAILURE,
  OpportunityDetailActionTypes,
} from '../actions'

// 2. Initial state
const initialState: {} = {}

// 3. Reducer
export function opportunityDetailReducer(
  state = initialState,
  action: OpportunityDetailActionTypes
) {
  switch (action.type) {
    // GET :: Request
    case CONTENTFUL_OPPORTUNITY_DETAIL_GET_REQUEST:
      return { ...state, ...action.payload }
    // GET :: Success
    case CONTENTFUL_OPPORTUNITY_DETAIL_GET_SUCCESS:
      return { ...action.payload }
    // GET :: Fail
    case CONTENTFUL_OPPORTUNITY_DETAIL_GET_FAILURE:
      return { ...state, ...action.payload }
    // Default
    default:
      return state
  }
}
