import React from 'react'
import { ImageContainer, Image } from './ContentfulImage.styles'

interface ContentfulImageProps {
  content: any
}

const ContentfulImage: React.FC<ContentfulImageProps> = ({ content }) => {
  if (!content || !content.fields || !content.fields.file) {
    return null
  }

  const { description, file } = content.fields

  return (
    <ImageContainer>
      <Image src={file.url} alt={description} />
    </ImageContainer>
  )
}

export default ContentfulImage
