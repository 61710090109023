import { withRouter } from 'react-router'
import { Dispatch, AnyAction } from 'redux'
import { connect } from 'react-redux'
import FiltersSearch from './FiltersSearch.component'
import { State } from '../../redux/store'
import {
  selectConfigData,
  selectSearchValue,
  selectIsTextAreaDisabled,
  selectSearchType,
} from '../../redux/selectors/filters'
import {
  SearchOptionType,
  changeSearchTypeAction,
  updateSearchAction,
} from '../../redux/actions/filters'

const mapStateToProps = (state: State, ownProps: any): any => {
  return {
    configData: selectConfigData(ownProps.location.pathname),
    searchValue: selectSearchValue(state),
    disabled: selectIsTextAreaDisabled(state),
    searchType: selectSearchType(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction, State>) => {
  return {
    onChange: (key: string, value: string) => {
      dispatch(updateSearchAction(key, value))
    },
    onSearchTypeChange: (searchOptions: SearchOptionType[], value: string) =>
      dispatch(changeSearchTypeAction(searchOptions, value)),
  }
}

const FiltersSearchContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FiltersSearch)
)

export default FiltersSearchContainer

FiltersSearchContainer.displayName = 'FiltersSearchContainer'
