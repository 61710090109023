import React from 'react'
import routes from '../../routes'
import {
  Callout,
  Container,
  Content,
  Panel,
  CenteredLoadingIndicator,
} from '../../styles'
import { Accordion, AccordionItem } from 'luna-react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { history } from '../../createHistory'
import { BackHeader } from '../PageHeader'

class LocalHeroes extends React.Component {
  componentDidMount() {
    this.props.doFetchLocalHeroes()
  }

  renderContent = () => {
    const {
      title,
      subtitle,
      paragraphItems,
      quoteText,
      image,
      accordionItems,
      buttonText,
    } = this.props.localHeroes

    return (
      <Content>
        <BackHeader
          title={title}
          blurb={subtitle}
          textTo="Our Community Hub"
          to={routes.about}
        />
        <Content>
          <Container.FullBleed>
            <Panel.Wrapper>
              <Panel.Head bg={image.file.url}>
                <Container.Padded>
                  {paragraphItems &&
                    paragraphItems.map(index => (
                      <Panel.Container white key={index}>
                        <Panel.Title>{index.paragraphHeader}</Panel.Title>
                        <Panel.Blurb>{index.paragraphSubheader}</Panel.Blurb>
                      </Panel.Container>
                    ))}
                </Container.Padded>
              </Panel.Head>
              <Container.Padded>
                <Panel.Container noPaddingTop white>
                  {paragraphItems &&
                    paragraphItems.map(index => (
                      <Panel.ContainerWithQuote white key={index}>
                        <div style={{ flex: '1 1 0px' }}>
                          {index.paragraph.content.map(
                            (nestedContent, index) => (
                              <Panel.P key={index}>
                                {nestedContent.content[0].value}
                              </Panel.P>
                            )
                          )}
                        </div>
                        <Panel.Quote>{quoteText}</Panel.Quote>
                      </Panel.ContainerWithQuote>
                    ))}
                </Panel.Container>
              </Container.Padded>
            </Panel.Wrapper>
          </Container.FullBleed>
          <Content grey>
            <Container.Padded>
              <Accordion multipleOpen titleElement="h3">
                {accordionItems &&
                  accordionItems.map((item, index) => (
                    <AccordionItem title={item.accordionHeader} key={index}>
                      {item.accordionText}
                    </AccordionItem>
                  ))}
              </Accordion>
            </Container.Padded>
          </Content>
          <Content>
            <Callout.Wrapper white>
              <Callout.Button
                data-id="local-heroes-form-button"
                onClick={() => history.push(routes.localHeroes)}
              >
                {buttonText}
              </Callout.Button>
            </Callout.Wrapper>
          </Content>
        </Content>
      </Content>
    )
  }

  render() {
    const Wrapper = styled.div`
      padding: 300px 50px;
    `
    const { loading, localHeroes, errorMessage } = this.props
    return (
      <>
        {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        {loading && (
          <Wrapper>
            <Container.Padded style={{ overflow: 'auto' }}>
              <CenteredLoadingIndicator message="Fetching content..." />
            </Container.Padded>
          </Wrapper>
        )}
        {localHeroes && this.renderContent()}
      </>
    )
  }
}

LocalHeroes.propTypes = {
  doFetchLocalHeroes: PropTypes.func,
  localHeroes: PropTypes.object,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default LocalHeroes
