import styled from 'styled-components'

const ButtonGroupWrapper = styled.div.attrs(() => ({
  className: 'ln-c-button-group',
}))`
  display: flex;
  flex-direction: column;

  @media (min-width: 480px) {
    flex-direction: row;
  }
`

const ButtonGroupPrimary = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 1rem;
  }

  @media (min-width: 480px) {
    flex-direction: row;

    button {
      margin-bottom: 0;

      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
`

const ButtonGroupSecondary = styled(ButtonGroupPrimary)`
  @media (min-width: 480px) {
    margin-left: auto;
  }
`

export { ButtonGroupWrapper, ButtonGroupPrimary, ButtonGroupSecondary }
