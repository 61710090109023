import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import AuthContainer from './components/Auth/AuthContainer'
import Theme from './providers/Theme'
import { initialiseAnalytics } from './util/analytics'
import { initialiseTraceId } from './util/traceId'
import { initialiseErrorReporting } from './util/errorReporting'

class App extends Component {
  componentDidMount() {
    if (window.ENV && window.ENV.analytics && window.ENV.analytics.tealiumTag) {
      initialiseAnalytics(window.ENV.analytics.tealiumTag)
    }
    initialiseTraceId()
    initialiseErrorReporting()
  }
  render() {
    return (
      <Provider store={store}>
        <Theme>
          <AuthContainer />
        </Theme>
      </Provider>
    )
  }
}

export default App
