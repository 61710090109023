import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom'
import { Container as LunaContainer } from '@jsluna/grid'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'
import {
  FilledButton,
  OutlinedButton,
  ButtonGroupWrapper,
  ButtonGroupSecondary,
} from '@jsluna/button'
import {
  selectFormValues,
  selectFormIsSending,
  selectFormIsValid,
} from '../../redux/selectors/ideaForm'
import { doFormPost, doFormEdit } from '../../redux/dispatchers/ideaForm'
import { PageHeader } from '../../components/PageHeader'
import IdeaReview from '../../components/IdeaReview/IdeaReview'
import { ideaFormRoutes } from '../../data/ideaFormRoutes'
import { name, path, fields } from '../../data/volunteeringFormData'
import { Container } from '../../styles'

interface MatchProps {
  id: string
}

const VolunteeringConfirm: React.FC<RouteComponentProps<MatchProps>> = ({
  match,
  history,
  location,
}) => {
  const id = match.params.id
  const dispatch = useDispatch()
  const isSending = useSelector(selectFormIsSending(name))
  const isValid = useSelector(selectFormIsValid(name))
  const values = useSelector(selectFormValues(name))
  const onEdit = () => {
    history.goBack()
  }
  const onConfirm = () => {
    if (!isSending) {
      if (id) {
        dispatch(doFormEdit(name, id))
      } else {
        dispatch(doFormPost(name))
      }
    }
  }

  if (!isValid) {
    return <Redirect to={`${path}${ideaFormRoutes.form}`} />
  }

  return (
    <>
      <PageHeader
        title="Review form content"
        blurb="Please confirm the information entered is correct."
      />
      <Container.Wrapper>
        <LunaContainer soft>
          <div className="ln-u-soft-ends">
            <IdeaReview
              items={[].concat(
                ...fields.map((section: any) => {
                  return section.items.map((item: any) => ({
                    label: item.label,
                    value: values[item.name],
                  }))
                })
              )}
            />
            <ButtonGroupWrapper actionBar>
              <ButtonGroupSecondary>
                <ProgressIndicator
                  loading={isSending}
                  indicator={<ProgressSpinner size="small" color="light" />}
                >
                  {({ indicator }: { indicator: ProgressSpinner }) => (
                    <FilledButton
                      style={{ minWidth: '8rem' }}
                      onClick={onConfirm}
                    >
                      {isSending ? indicator : 'Submit'}
                    </FilledButton>
                  )}
                </ProgressIndicator>
                <OutlinedButton style={{ minWidth: '8rem' }} onClick={onEdit}>
                  Edit
                </OutlinedButton>
              </ButtonGroupSecondary>
            </ButtonGroupWrapper>
          </div>
        </LunaContainer>
      </Container.Wrapper>
    </>
  )
}

export default withRouter(VolunteeringConfirm)
