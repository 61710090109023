import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import DeclineIdeaForm from './DeclineIdeaForm'
import { hideDeclineForm } from '../../redux/actions/idea'
import { doDeclineIdea } from '../../redux/dispatchers/idea'

const mapStateToProps = (state, ownProps) => {
  return {
    idea_id: ownProps.idea_id,
    submitted_by: ownProps.submitted_by,
    isDeclineFormVisible: state.ideaReducer.isDeclineFormVisible,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideDeclineForm,
      doDeclineIdea,
    },
    dispatch
  )

const IdeaContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeclineIdeaForm)

export default IdeaContainer
