import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import Router from './Router'
import { doFetchOpportunities } from '../../redux/dispatchers/opportunitiesList'

const mapStateToProps = (state, ownProps) => {
  return {
    match: ownProps.match,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doFetchOpportunities,
    },
    dispatch
  )

const RouterContainer = connect(mapStateToProps, mapDispatchToProps)(Router)

export { RouterContainer }

export default withRouter(RouterContainer)
