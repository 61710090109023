import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import LocalHeroes from './LocalHeroes'
import { getLocalHeroes } from '../../redux/selectors/localHeroes'
import { doFetchLocalHeroes } from '../../redux/dispatchers/localHeroes'

const mapStateToProps = state => {
  return {
    loading: state.localHeroesReducer.loading,
    errorMessage: state.localHeroesReducer.errorMessage,
    localHeroes: getLocalHeroes(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doFetchLocalHeroes,
    },
    dispatch
  )
const LocalHeroesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalHeroes)

export default LocalHeroesContainer
