import React from 'react'
import Layout from '../layouts/Layout/Layout'
import AboutContainer from '../components/About/AboutContainer'

const About = () => {
  return (
    <Layout pageId="page-about">
      <AboutContainer />
    </Layout>
  )
}

export default About
