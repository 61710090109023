const features = (window && window.ENV && window.ENV.FEATURES) || {}

export const shouldReportErrors = () => features.REPORT_ERRORS

export const showMapView = () => features.OPP_MAP

export const isNewAdmin = () => features.NEW_ADMIN

export const isNewFilters = () => features.NEW_FILTERS

export const isProgrammePage = () => features.PROGRAM_PAGE

export const isVolunteering = () => features.VOLUNTEERING

export const is150DaysSection = () => features.DAYS150_SECTION

export const isLocalCharity = () => features.LOCAL_CHARITY

export default features
