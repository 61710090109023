export const VALID_TEAM_SIZE = [
  { label: '< 5 people', value: '< 5 people' },
  { label: 'Between 6 and 10', value: 'Between 6 and 10' },
  { label: 'Between 11 and 15', value: 'Between 11 and 15' },
  { label: '> 15', value: '> 15' },
]

export const VALID_BUSINESSES = [
  { label: 'Sainsbury’s Asia', value: 'Sainsbury’s Asia' },
  { label: 'Sainsbury’s Bank', value: 'Sainsbury’s Bank' },
  { label: 'Sainsbury’s SSC', value: 'Sainsbury’s SSC' },
  { label: 'Sainsbury’s Store', value: 'Sainsbury’s Store' },
  { label: 'Sainsbury’s Logistics', value: 'Sainsbury’s Logistics' },
  { label: 'Sainsbury’s Argos', value: 'Sainsbury’s Argos' },
  { label: 'Argos Store', value: 'Argos Store' },
  { label: 'Argos Logistics', value: 'Argos Logistics' },
  { label: 'Sainsbury’s and Argos CMC', value: 'Sainsbury’s and Argos CMC' },
  { label: 'Habitat', value: 'Habitat' },
  { label: 'Nectar', value: 'Nectar' },
  { label: 'AFS', value: 'AFS' },
  { label: 'Other', value: 'Other' },
]
export const VALID_CATEGORIES = [
  { label: 'Poverty', value: 'Poverty' },
  { label: 'Homelessness', value: 'Homelessness' },
  { label: 'Disability', value: 'Disability' },
  { label: 'Health', value: 'Health' },
  { label: 'Mental health', value: 'Mental health' },
  { label: 'Schools/Young people', value: 'Schools/Young people' },
  { label: 'Older people', value: 'Older people' },
  { label: 'Community care', value: 'Community care' },
  { label: 'Environment', value: 'Environment' },
  { label: 'Other', value: 'Other' },
]

export const MAX_SHORT_TEXT_LENGTH = 255
export const MAX_LONG_TEXT_LENGTH = 4000

const CAN_BE_BOOKMARKED = {
  PENDING: true,
  UPDATED: true,
}

export const canBeBookmarked = idea => {
  const { idea_status = '' } = idea
  if (!idea_status) {
    return false
  }
  return CAN_BE_BOOKMARKED[idea_status]
}
