import styled from 'styled-components'
import { TextAreaField, OutlinedButton, FilledButton } from 'luna-react'

import {
  Section,
  SectionHeader,
  RequiredSectionMarker,
} from '../Form/FormSection/styles'
import { FormLabel } from '../Form/FormItem/styles'

const CancelButton = styled(OutlinedButton)`
  margin-right: 20px;
`

const DeclineSection = styled(Section)`
  margin: 40px 0 10px;
`

const Note = styled.p`
  margin: 0 0 20px;
  font-size: 13px;
  line-height: 21px;
`

export {
  SectionHeader,
  RequiredSectionMarker,
  FormLabel,
  TextAreaField,
  FilledButton,
  CancelButton,
  DeclineSection,
  Note,
}
