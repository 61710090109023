import { AdminIdeasStatusTypes } from './adminIdeas'

export enum AdminIdeaActionTypes {
  ADMIN_IDEA_SET_SOURCE = 'ADMIN_IDEA_SET_SOURCE',
}

export type AdminIdeaDataType =
  | AdminIdeaData150DaysType
  | AdminIdeaDataHeroesType

// Domain types
export type AdminIdeaDataHeroesType = {
  business: string
  charityAccountNumber: string
  charityAddress: string
  charityCategory: string[]
  charityContact: string
  charityEmailAddress: string
  charityName: string
  charityNumber: string
  charityPhoneNumber: string
  charitySortCode: string
  colleagueEmail: string
  colleagueName: string
  createdDate: string
  employeeNumber: string
  gender: string
  hoursClaimed: string
  id: string
  lastModified: string
  ref: string
  storeName: string
  submittedBy: string
  title: string
  volunteeringDetails: string
}

export type AdminIdeaData150DaysType = {
  business: string[]
  category: string
  colleagueName: string
  contactEmail: string
  createdDate: string
  date: string
  id: string
  ideaStatus: AdminIdeasStatusTypes
  lastModified: string
  organisationName: string
  organisationWebsite: string
  ref: string
  region: string
  stardustMoments: string
  store: string
  storeNumber: string
  submittedBy: string
  teamSize: string
  volunteeringIdea: string
}

// Service types
export interface AdminIdeaData150Days {
  business: string[]
  category: string
  colleague_name: string
  contact_email: string
  created_date: string
  date: string
  id: string
  idea_status: string
  last_modified: string
  organisation_name: string
  organisation_website: string
  ref: string
  region: string
  stardust_moments: string
  store: string
  store_number: string
  submitted_by: string
  team_size: string
  volunteering_idea: string
}

export interface AdminIdeaDataHeroes {
  business: string
  charity_account_number: string
  charity_address: string
  charity_category: string[]
  charity_contact: string
  charity_email_address: string
  charity_name: string
  charity_number: string
  charity_phone_number: string
  charity_sort_code: string
  colleague_email: string
  colleague_name: string
  created_date: string
  employee_number: string
  gender: string
  hours_claimed: string
  id: string
  last_modified: string
  ref: string
  store_name: string
  submitted_by: string
  title: string
  volunteering_details: string
}
