import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const homeGradient =
  'linear-gradient(to bottom,#ffffff 0%,#ffffff 50%, #efefef 100%)'

const Wrapper = styled.div`
  background: ${({ background, theme }) =>
    background || theme.color.lighterGrey};
  padding-bottom: ${({ nopaddingbottom }) => (nopaddingbottom ? '0' : '20px')};
  padding-top: calc(40px + ${({ theme }) => theme.header.height});
`

const Title = styled.h1`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  padding: 0 ${({ hard }) => (hard ? 0 : '15px')};
  ${({ small }) => (small ? 'font-size: 1.625rem;' : null)}
`

const Blurb = styled.p`
  font-family: ${props => props.theme.fontFamily};
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  padding: 0 15px;
`

const Back = styled(Link)`
  display: inline-block;
  font-size: 24px;
  color: ${({ theme }) => theme.color.darkGrey};
  width: 80px;
  text-align: center;
`
const BackText = styled(Link)`
  padding: 0 15px;
  font-family: ${props => props.theme.fontFamily};
  font-size: 22px;
  color: ${({ theme }) => theme.color.orange};
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: bold;
`

const BackSmall = styled.span`
  font-size: 12px;
  width: 30px;
  display: inline-block;
  color: ${({ theme }) => theme.color.darkGrey};
  text-align: center;
`

export { Wrapper, Title, Blurb, Back, BackText, BackSmall }
