import PropTypes from 'prop-types'
import { isAnyAdmin } from '../../util/roles'

const AdminOnly = props => {
  const { user, children } = props
  if (isAnyAdmin(user)) {
    return children
  }
  return null
}

AdminOnly.propTypes = {
  user: PropTypes.object,
}

export default AdminOnly
