import { actionTypes } from '../actions/editIdea'

const {
  GET_EDIT_IDEA,
  GET_EDIT_IDEA_SUCCESS,
  GET_EDIT_IDEA_FAILURE,
  SAVE_EDIT_IDEA_ID,
  REMOVE_EDIT_IDEA_ID,
} = actionTypes

const initialState = {
  errorMessage: null,
  loading: false,
  id: null,
}

const editIdeaReducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case GET_EDIT_IDEA:
      return {
        ...state,
        loading: true,
      }
    case GET_EDIT_IDEA_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case GET_EDIT_IDEA_FAILURE:
      const { errorMessage } = payload
      return {
        ...state,
        loading: false,
        errorMessage,
      }
    case SAVE_EDIT_IDEA_ID:
      const { id } = payload
      return {
        ...state,
        id,
      }
    case REMOVE_EDIT_IDEA_ID:
      return {
        ...state,
        id: null,
      }
    default:
      return state
  }
}

export { initialState, editIdeaReducer }
