import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { ProgressSpinner } from '@jsluna/progress'
import IdeasDataRowHeroes from '../../components/IdeaSummary/IdeasDataRowHeroes'
import { AdminIdeaDataType } from '../../types'
import { FilledButtonControl } from './AdminIdeas.styles'
import { isNewFilters } from '../../features'
import FiltersContainer from '../../components/Filters/Filters.container'
import { NoIdeaFound } from './AdminList150Days.style'

interface AdminIdeasListHeroesProps extends RouteComponentProps {
  data: any
  loading: boolean
  downloadLocalHeroes: () => void
  fetchData: (paramsQuery: string) => void
}

const AdminIdeasListHeroes: React.FC<AdminIdeasListHeroesProps> = ({
  data,
  loading,
  downloadLocalHeroes,
  fetchData,
  location,
}) => {
  useEffect(() => {
    const search = location.search
    const paramsQuery = search.length > 1 ? search.substr(1, search.length) : ''
    fetchData(paramsQuery)
  }, [fetchData, location])

  const clickHandlerDownload = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    downloadLocalHeroes()
  }

  return (
    <div className="ln-u-soft-ends">
      {loading ? (
        <ProgressSpinner />
      ) : (
        <>
          {isNewFilters() && <FiltersContainer />}
          {data && data.length > 0 ? (
            <>
              <FilledButtonControl onClick={clickHandlerDownload}>
                Export Local Heroes (.csv)
              </FilledButtonControl>
              {data.map((idea: AdminIdeaDataType, index: number) => (
                <IdeasDataRowHeroes
                  idea={idea}
                  currentIndex={index}
                  key={index}
                />
              ))}
            </>
          ) : (
            <NoIdeaFound>
              No Local Heroes found matching the current filter
            </NoIdeaFound>
          )}
        </>
      )}
    </div>
  )
}

export default AdminIdeasListHeroes
