import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ToggleMapList from './ToggleMapList'
import { getView } from '../../redux/selectors/mapList'
import { setView } from '../../redux/actions/mapList'

const mapStateToProps = state => {
  return {
    VIEW: getView(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setView,
    },
    dispatch
  )

const ToggleMapListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ToggleMapList)

export default ToggleMapListContainer
