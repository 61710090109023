import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { IconButton } from '@jsluna/button'
import IdeaActionModal from '../IdeaActionModal'
import { doUpdateIdeaStatus } from '../../redux/dispatchers/idea'
import { idea_statuses } from '../../constants'
import { canBeBookmarked } from '../../data/ideasDomain'
import { ReactComponent as BookmarkIcon } from '../../assets/bookmark-outline.svg'
import { IdeaType } from '../../types'

interface IdeaBookmarkProps {
  idea: IdeaType
}

const IdeaBookmark: React.FC<IdeaBookmarkProps> = ({ idea }) => {
  const dispatch = useDispatch()
  const [isVisible, setVisible] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const onConfirm = () => {
    setLoading(true)
    dispatch(
      doUpdateIdeaStatus(idea.id, idea.campaign, idea_statuses.BOOKMARKED)
    ).then(() => {
      setLoading(false)
      setVisible(false)
    })
  }

  return canBeBookmarked(idea) ? (
    <>
      <IconButton
        className="ln-u-bg-color-transparent"
        onClick={() => setVisible(true)}
        data-id="idea-bookmark"
        title="Bookmark idea"
        label="Bookmark idea"
      >
        <BookmarkIcon />
      </IconButton>
      <IdeaActionModal
        isLoading={isLoading}
        isOpen={isVisible}
        title={`You're about to bookmark idea ${idea.ref}`}
        message={`Are you sure you want to do this?`}
        cancelLabel="Cancel"
        confirmLabel="Yes"
        onConfirm={() => onConfirm()}
        onCancel={() => setVisible(false)}
      />
    </>
  ) : null
}

export default IdeaBookmark
