import React from 'react'
import Layout from '../layouts/Layout'
import AdminIdea from '../sections/AdminIdea'

const AdminVolunteeringIdeaPage = () => {
  return (
    <Layout pageId="page-admin-volunteering-detail">
      <AdminIdea campaign="volunteering" />
    </Layout>
  )
}

export default AdminVolunteeringIdeaPage
