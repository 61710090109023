import React from 'react'
import PropTypes from 'prop-types'
import { LoadingIndicator, FilledButton } from 'luna-react'
import { showMapView } from '../../features'

import ErrorMessage from '../ErrorMessage/ErrorMessage'
import OpportunitySummary from '../../components/OpportunitySummary/OpportunitySummary'
import { OpportunitiesSearch } from './../../styles'
import ToogleMaplist from '../ToggleMapList'
import OppsMap from '../OppsMap/'
import { VIEW } from '../../constants'
import { TwoColContainer } from './styles.js'

class OpportunitiesSearchResults extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.isSearching !== nextProps.isSearching ||
      this.props.errorMessage !== nextProps.errorMessage ||
      this.props.results !== nextProps.results ||
      this.props.view !== nextProps.view
    )
  }

  renderResultsList(results) {
    const resultsList = results.map(result => {
      return (
        <OpportunitySummary
          key={result.id}
          data={result}
          organisationSlug={result.organisation_slug}
        />
      )
    })
    return (
      <div data-id="opportunities-results-list">
        <TwoColContainer>{resultsList}</TwoColContainer>
      </div>
    )
  }

  render() {
    const {
      searchTerm,
      isSearching,
      errorMessage,
      showMoreRecords,
      results,
      hasMore,
      unseenOpportunitiesCount,
      view,
    } = this.props

    if (isSearching) {
      return <LoadingIndicator message="Searching for ideas…" />
    }
    if (errorMessage) {
      return <ErrorMessage errorMessage={errorMessage} />
    }
    if (!isSearching && searchTerm && results && results.length === 0) {
      return (
        <>
          <OpportunitiesSearch.Heading hard>
            No volunteering opportunities found near &nbsp;
            <OpportunitiesSearch.SearchTerm>
              {searchTerm}
            </OpportunitiesSearch.SearchTerm>
          </OpportunitiesSearch.Heading>
          <p>Please refine your search criteria</p>
        </>
      )
    }
    if (results && results.length > 0) {
      return (
        <div>
          <OpportunitiesSearch.Heading
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <span>
              Volunteering List closest to{' '}
              <OpportunitiesSearch.SearchTerm>
                {searchTerm}
              </OpportunitiesSearch.SearchTerm>
            </span>
            {showMapView() && <ToogleMaplist />}
          </OpportunitiesSearch.Heading>

          {view === VIEW.MAP && showMapView() ? (
            <OppsMap />
          ) : (
            <>
              <div>{this.renderResultsList(results)}</div>
              {hasMore && (
                <FilledButton
                  onClick={e => {
                    e.preventDefault()
                    showMoreRecords(6)
                  }}
                  style={{ margin: '0 auto', display: 'block' }}
                  data-id="show-more-button"
                >
                  Show {Math.min(6, unseenOpportunitiesCount)} more{' '}
                  {unseenOpportunitiesCount === 1
                    ? 'Opportunity'
                    : 'Opportunities'}
                </FilledButton>
              )}
            </>
          )}
        </div>
      )
    }
    return null
  }
}

OpportunitiesSearchResults.propTypes = {
  searchTerm: PropTypes.string,
  isSearching: PropTypes.bool,
  errorMessage: PropTypes.string,
  results: PropTypes.array,
  display: PropTypes.number.isRequired,
  showMoreRecords: PropTypes.func.isRequired,
}

export default OpportunitiesSearchResults
