import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { OutlinedButton } from 'luna-react'
import { Org } from '../../styles'
import Address from '../Address/Address'
import TeamSize from '../TeamSize/TeamSize'

const renderDistance = (anywhere, distance) =>
  anywhere ? (
    <Org.Distance>UKWide</Org.Distance>
  ) : (
    <Org.Distance>{`${distance} mi`}</Org.Distance>
  )

const OrgOpportunity = ({
  data,
  anywhere,
  index,
  isPadded = false,
  nomargin = true,
}) => {
  const {
    project_title,
    project_slug,
    organisation_name,
    project_description,
    address_line_1,
    address_line_2,
    address_line_3,
    postal_code,
    geolocation: { distance },
    volunteers_required_max,
    volunteers_required_min,
  } = data

  const address = {
    address_line_1,
    address_line_2,
    address_line_3,
    postal_code,
  }

  return (
    <Org.Wrapper white padded={isPadded} nomargin={nomargin}>
      <div>{renderDistance(anywhere, distance)}</div>
      <Org.Header>{project_title}</Org.Header>
      <Org.Meta>
        <Org.MetaItem row>
          <Org.MetaItemLabel>Organisation: </Org.MetaItemLabel>
          <Org.MetaItemHighlight>{organisation_name}</Org.MetaItemHighlight>
        </Org.MetaItem>
        <Org.MetaItem row>
          <Org.MetaItemLabel>Location:</Org.MetaItemLabel>
          <Org.MetaItemValue>
            {anywhere ? 'UK Wide' : <Address address={address} />}
          </Org.MetaItemValue>
        </Org.MetaItem>
        {volunteers_required_min && volunteers_required_max ? (
          <Org.MetaItem row>
            <Org.MetaItemLabel>Team size:</Org.MetaItemLabel>
            <TeamSize
              min={volunteers_required_min}
              max={volunteers_required_max}
            />
          </Org.MetaItem>
        ) : null}
      </Org.Meta>
      <Org.Description>{project_description}</Org.Description>
      <Link to={`/opportunities/opportunity/${project_slug}`}>
        <OutlinedButton data-id={`opportunity-${index}`}>
          {project_title}
        </OutlinedButton>
      </Link>
    </Org.Wrapper>
  )
}

OrgOpportunity.propTypes = {
  data: PropTypes.object,
  organisationSlug: PropTypes.string,
  anywhere: PropTypes.bool,
  index: PropTypes.number,
  isPadded: PropTypes.bool,
}

export { OrgOpportunity, TeamSize }

export default withRouter(OrgOpportunity)
