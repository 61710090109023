import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import IdeaActionModal from '../IdeaActionModal'
import IdeaActionButton from './IdeaActionButton'
import { doUpdateIdeaStatus } from '../../redux/dispatchers/idea'
import { idea_statuses } from '../../constants'
import { IdeaType } from '../../types'

interface IdeaAcceptProps {
  idea: IdeaType
}

const IdeaAccept: React.FC<IdeaAcceptProps> = ({ idea }) => {
  const dispatch = useDispatch()
  const [isVisible, setVisible] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const onConfirm = () => {
    setLoading(true)
    dispatch(
      doUpdateIdeaStatus(idea.id, idea.campaign, idea_statuses.ACCEPTED)
    ).then(() => {
      setLoading(false)
      setVisible(false)
    })
  }

  return (
    <>
      <IdeaActionButton
        status={idea_statuses.ACCEPTED}
        disabled={idea.idea_status === idea_statuses.ACCEPTED}
        onClick={() => setVisible(true)}
        data-id="idea-accept"
        title="Accept idea"
        label="Accept idea"
      />
      <IdeaActionModal
        isLoading={isLoading}
        isOpen={isVisible}
        title={`You're about to accept idea ${idea.ref}`}
        message={`Are you sure that you want to accept the idea? An email will be sent to ${idea.submitted_by}.`}
        cancelLabel="Cancel"
        confirmLabel="Yes"
        onConfirm={() => onConfirm()}
        onCancel={() => setVisible(false)}
      />
    </>
  )
}

export default IdeaAccept
