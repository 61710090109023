import { createSelector } from 'reselect'

const getLocalHeroesState = state => state.localHeroesReducer

const getLoading = createSelector(
  [getLocalHeroesState],
  localHeroesReducer => localHeroesReducer.loading
)

const getErrorMessage = createSelector(
  [getLocalHeroesState],
  localHeroesReducer => localHeroesReducer.errorMessage
)

const getLocalHeroes = createSelector(
  [getLocalHeroesState],
  localHeroesReducer => localHeroesReducer.localHeroes
)

export { getLocalHeroes, getLoading, getErrorMessage }
