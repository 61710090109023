import styled from 'styled-components'

const Image = styled.img`
  width: 100%;
  display: block;
  border: none;
`

const ImageContainer = styled.div`
  margin-bottom: 1.5rem;
`

const UnnorderedList = styled.ul`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 3rem;
`

const OrderedList = styled.ol`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 3rem;
`

const ListItem = styled.li`
  margin-left: 0.5rem;
  margin-bottom: 1rem;
`

const ListItemP = styled.p`
  margin-bottom: 0.5rem;
`

export {
  OrderedList,
  UnnorderedList,
  ListItem,
  ListItemP,
  Image,
  ImageContainer,
}
