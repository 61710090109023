import React from 'react'
import Layout from '../layouts/Layout/Layout'

import LocalHeroesSuccessContainer from '../components/LocalHeroes/LocalHeroesSuccessContainer'

const LocalHeroesSuccess = () => {
  return (
    <Layout pageId="page-local-heroes-success">
      <LocalHeroesSuccessContainer />
    </Layout>
  )
}

export default LocalHeroesSuccess
