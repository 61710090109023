import { SearchOptionType } from '../redux/actions/filters'

const filterUnselectedFields = (obj: any) => {
  Object.keys(obj).forEach(key => {
    if (!obj[key]) delete obj[key]
  })

  return obj
}

export const isFilterDisabled = (
  searchOptions: SearchOptionType[],
  searchType: string
): boolean | undefined => {
  const searchTypeCheck = searchOptions.filter(
    (searchOption: SearchOptionType, index: number) => {
      return index.toString() === searchType
    }
  )
  return !!(searchTypeCheck.length > 0 && searchTypeCheck[0].enableSearchOnly)
}

export const serialise = (fields = {}) =>
  Object.keys(fields).reduce((acc, field) => {
    const filteredFields = filterUnselectedFields(fields[field])

    const fieldArray = Object.keys(filteredFields)
    if (fieldArray.length) {
      acc[field] = fieldArray.join(',')
    }
    return acc
  }, {})

export const extractFields = (fields: any = {}) => {
  const { search, values } = fields
  const searchCategory = Object.keys(search)[0]
  const params = {}
  if (searchCategory && search[searchCategory]) {
    params[searchCategory] = search[searchCategory]
  }
  return {
    ...params,
    ...serialise(values),
  }
}

export const parseDateRange = (dateMap = {}) => {
  const dateRange = Object.keys(dateMap)
    .filter(date => dateMap[date])
    .sort()

  if (!dateRange.length) {
    return {}
  }
  const [start, end] = dateRange
  return { start, end }
}
