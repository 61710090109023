import { Dispatch, AnyAction } from 'redux'
import {
  AdminIdeasData150Days,
  AdminIdeasDataHeroes,
  AdminIdeasData150DaysType,
  AdminIdeasDataHeroesType,
  AdminIdeasStatusTypes,
  AdminIdeasDataVolunteering,
  AdminIdeasDataVolunteeringType,
  paginatedRecordCount,
  PaginationActionTypes,
} from '../../types'
import { State } from '../store'
import {
  getDataAction,
  dataFailureAction,
  dataSuccessAction,
} from './dataStore'
import { getAuthToken } from '../selectors/auth'
import { api, get, getFile } from '../../httpClient/httpClient'
import errorHandler from '../dispatchers/errorHandler'

const paginationSetControlsAction = (activePage: number, lastPage: number) => {
  return {
    type: PaginationActionTypes.PAGINATION_CHANGE_PAGE,
    payload: { pageActive: activePage, pageLast: lastPage },
  }
}

/**
 * pagination data action
 * @param data any - can be any type of data from datastore
 */
const paginationChangePageDataAction = (data: any) => {
  return {
    type: PaginationActionTypes.PAGINATION_SET_PAGE_DATA,
    payload: { data: data },
  }
}

const paginationSetSourceAction = (source: string) => {
  return {
    type: PaginationActionTypes.PAGINATION_SET_SOURCE,
    payload: { source: source },
  }
}

const map150DaysData = (
  data: AdminIdeasData150Days[]
): AdminIdeasData150DaysType[] => {
  const mappedData = data.map((item: AdminIdeasData150Days, index: number) => {
    return {
      colleagueName: item.colleague_name || '',
      contactEmail: item.contact_email || '',
      organisationName: item.organisation_name || '',
      createdDate: item.created_date || '',
      ideaStatus: item.idea_status
        ? AdminIdeasStatusTypes[item.idea_status.toUpperCase()]
        : '',
      category: item.category || '',
      id: item.id || '',
      stores: item.business || [],
      ref: item.ref || '',
    }
  })
  return mappedData.sort(
    (a: AdminIdeasData150DaysType, b: AdminIdeasData150DaysType): number => {
      const aDate = new Date(a.createdDate)
      const bDate = new Date(b.createdDate)
      return aDate > bDate ? -1 : aDate < bDate ? 1 : 0
    }
  )
}

const mapLocalHeroesData = (
  data: AdminIdeasDataHeroes[]
): AdminIdeasDataHeroesType[] => {
  const mappedData = data.map((item: AdminIdeasDataHeroes, index: number) => {
    return {
      colleagueName: item.colleague_name || '',
      createdDate: item.created_date || '',
      employeeNumber: item.employee_number || '',
      charityCategory: item.charity_category || '',
      id: item.id || '',
      storeName: item.store_name || '',
      ref: item.ref || '',
    }
  })
  return mappedData.sort(
    (a: AdminIdeasDataHeroesType, b: AdminIdeasDataHeroesType): number => {
      const aDate = new Date(a.createdDate)
      const bDate = new Date(b.createdDate)
      return aDate > bDate ? -1 : aDate < bDate ? 1 : 0
    }
  )
}

const mapVolunteeringData = (
  data: AdminIdeasDataVolunteering[]
): AdminIdeasDataVolunteeringType[] => {
  const mappedData = data.map((item: AdminIdeasDataVolunteering) => {
    return {
      authorEmail: item.author_email || '',
      createdDate: item.created_date || '',
      volunteeringDate: item.volunteering_date || '',
      organisationName: item.organisation_name || '',
      id: item.id || '',
      storeName: item.store_name || '',
      ref: item.ref || '',
      ideaStatus: item.idea_status
        ? AdminIdeasStatusTypes[item.idea_status.toUpperCase()]
        : '',
    }
  })
  return mappedData.sort(
    (
      a: AdminIdeasDataVolunteeringType,
      b: AdminIdeasDataVolunteeringType
    ): number => {
      const aDate = new Date(a.createdDate)
      const bDate = new Date(b.createdDate)
      return aDate > bDate ? -1 : aDate < bDate ? 1 : 0
    }
  )
}

const fetch150DaysActionCreator = (queryString: string) => {
  return async (
    dispatch: Dispatch<AnyAction, State>,
    getState: () => State
  ) => {
    try {
      dispatch(getDataAction())
      const endPoint = '/admin/ideas'
      const token = getAuthToken(getState())
      // const params = `${queryString}&keys=id,business,colleague_name,contact_email,idea_status,created_date,category,organisation_name`
      const data = await get<AdminIdeasData150Days[]>(
        api(endPoint, queryString),
        token
      )
      const result: AdminIdeasData150DaysType[] = await map150DaysData(data)
      dispatch(dataSuccessAction(result, endPoint))
      dispatch(paginationSetSourceAction(endPoint))
      dispatch(paginationChangePageViewActionCreator(1))
    } catch (error) {
      errorHandler(dispatch, error, dataFailureAction)
    }
  }
}

const fetchLocalHerosActionCreator = (queryString: string) => {
  return async (
    dispatch: Dispatch<AnyAction, State>,
    getState: () => State
  ) => {
    try {
      dispatch(getDataAction())
      const endPoint = '/admin/localHeroes'
      const token = getAuthToken(getState())
      const data = await get<AdminIdeasDataHeroes[]>(
        api(endPoint, queryString),
        token
      )
      const result: AdminIdeasDataHeroesType[] = await mapLocalHeroesData(data)
      dispatch(dataSuccessAction(result, endPoint))
      dispatch(paginationSetSourceAction(endPoint))
      dispatch(paginationChangePageViewActionCreator(1))
    } catch (error) {
      errorHandler(dispatch, error, dataFailureAction)
    }
  }
}

const fetchVolunteeringActionCreator = (queryString: string) => {
  return async (
    dispatch: Dispatch<AnyAction, State>,
    getState: () => State
  ) => {
    try {
      dispatch(getDataAction())
      const endPoint = '/admin/volunteers'
      const token = getAuthToken(getState())
      const data = await get<AdminIdeasDataVolunteering[]>(
        api(endPoint, queryString),
        token
      )
      const result: AdminIdeasDataVolunteeringType[] = await mapVolunteeringData(
        data
      )
      dispatch(dataSuccessAction(result, endPoint))
      dispatch(paginationSetSourceAction(endPoint))
      dispatch(paginationChangePageViewActionCreator(1))
    } catch (error) {
      errorHandler(dispatch, error, dataFailureAction)
    }
  }
}

const paginationChangePageViewActionCreator = (pageNumber: number) => async (
  dispatch: Dispatch<AnyAction, State>,
  getState: () => State
) => {
  try {
    // always an unkknown data source type returned to 'result'
    const adminIdeas = getState().adminIdeas
    const index = adminIdeas.source ? adminIdeas.source : undefined
    const dataStoreData = getState().dataStore.data
    const result = dataStoreData && index ? dataStoreData[index] : undefined

    if (result) {
      // get subset of data
      const recordsFromIndex = (pageNumber - 1) * paginatedRecordCount

      const subSetResult = result.slice(
        recordsFromIndex,
        paginatedRecordCount + recordsFromIndex
      )

      dispatch(paginationChangePageDataAction(subSetResult))
      dispatch(
        paginationSetControlsAction(
          pageNumber,
          Math.ceil(result.length / paginatedRecordCount)
        )
      )
    }
  } catch (error) {
    errorHandler(dispatch, error, dataFailureAction)
  }
}

const downloadIdeas = () => async (
  dispatch: Dispatch<AnyAction, State>,
  getState: () => State
) => {
  const urlParams = window.location.search
  const token = getAuthToken(getState())
  getFile(api(`/admin/ideas${urlParams}`), token)
}

const downloadLocalHeroes = () => async (
  dispatch: Dispatch<AnyAction, State>,
  getState: () => State
) => {
  const urlParams = window.location.search
  const token = getAuthToken(getState())
  getFile(api(`/admin/localHeroes${urlParams}`), token)
}

const downloadVolunteering = () => async (
  dispatch: Dispatch<AnyAction, State>,
  getState: () => State
) => {
  const urlParams = window.location.search
  const token = getAuthToken(getState())
  getFile(api(`/admin/volunteers${urlParams}`), token)
}

export {
  downloadIdeas,
  downloadLocalHeroes,
  downloadVolunteering,
  map150DaysData,
  mapLocalHeroesData,
  fetch150DaysActionCreator,
  fetchLocalHerosActionCreator,
  fetchVolunteeringActionCreator,
  paginationChangePageViewActionCreator,
  paginationChangePageDataAction,
  paginationSetSourceAction,
  paginationSetControlsAction,
}
