import styled from 'styled-components'
import { Select, TextInput } from 'luna-react'
import filterImage from '../../assets/filter.svg'

export const SearchLabel = styled.h5`
  flex: 1 1 100%;
  margin-bottom: 10px;
  font-size: 18px;
`
export const SearchSelect = styled(Select)`
  flex: 0 0 250px;
  margin: 0 10px 0 auto;
`
export const SearchTextInput = styled(TextInput)`
  flex: 1 0 300px;
  margin: 0 auto 0 10px;
`
export const SearchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const SearchPanelWrapper = styled.div`
  padding-bottom: 30px;
  transition: flex 0.3s ease-in-out;
  height: auto;
  flex: ${({ isOpen }) => (!!isOpen ? '1' : '0.001')};
`

export const StatusFilterWrapper = styled.div`
  flex: 0 1 20%;
  line-height: 38px;
`
export const StoreFilterWrappper = styled.div`
  flex: 1 1 20%;
  line-height: 38px;
`
export const BusinessFilterWrapper = styled.div`
  flex: 0 1 20%;
  line-height: 38px;
`
export const CategoryFilterWrapper = styled.div`
  flex: 0 1 20%;
  line-height: 38px;
`
export const DateRangeFilterWrapper = styled.div`
  flex: 1 1 40%;
  height: 400px;
  .DateRangePickerInput {
    background-color: rgba(255, 255, 255, 0);
  }
  .DateInput {
    width: 160px;
    margin: 0 5px;
  }

  .DateRangePickerInput_clearDates {
    right: auto;
  }
`
export const IdeasFilterWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`
export const FilterHeader = styled.h6`
  font-size: 18px;
  margin-bottom: 0;
`

export const Filter = styled.div`
  padding: 0 5px;
`
export const Wrapper = styled.div``

export const FilterButton = styled.div`
  cursor: pointer;
  padding-left: 30px;
  background: transparent url(${filterImage}) left center/20px no-repeat;
`
