import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../layouts/Layout/Layout'
import { PageHeader } from '../components/PageHeader'
import routes from '../routes'
import { Container, Content } from '../styles'

const NotFound = () => (
  <Layout pageId="page-404">
    <PageHeader title="404" blurb="Page not found" />
    <Content>
      <Container.Padded>
        <p>You have navigated to a page that doesn’t exist.</p>
        <p>
          <Link to={routes.home}>Go to the homepage</Link>
        </p>
      </Container.Padded>
    </Content>
  </Layout>
)

export default NotFound
