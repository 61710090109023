import { api, get } from '../../httpClient/httpClient'
import {
  getUserIdeas,
  getUserIdeasSuccess,
  getUserIdeasFailure,
  updateUserSortBy,
} from '../actions/userIdeas'
import { getAuthToken } from '../selectors/auth'
import errorHandler from './errorHandler'

const doFetchUserIdeas = campaign => {
  return async (dispatch, getState) => {
    dispatch(getUserIdeas())
    try {
      const endpoint = campaign === 'volunteering' ? '/volunteers' : '/ideas'
      const token = getAuthToken(getState())
      const data = await get(api(endpoint), token)
      dispatch(getUserIdeasSuccess(data))
    } catch (error) {
      errorHandler(dispatch, error, getUserIdeasFailure)
    }
  }
}

const doUpdateUserSort = by => dispatch => {
  dispatch(updateUserSortBy(by))
}

export { doFetchUserIdeas, doUpdateUserSort }
