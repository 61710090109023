import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Heading6 from 'luna-react/components/typography/Heading6'

export const ResourcesContainer = styled.div`
  background-color: ${({ theme }) => theme.color.lighterGrey};
  width: 100%;
  padding: 24px;
  border-radius: 2px;
  margin-bottom: 1.5rem;
`

export const ResourcesList = styled.ul`
  margin: 0;
  padding: 0;
`

export const ResourcesListItem = styled.li`
  list-style-type: none;
  margin-bottom: 1rem;
`

export const ResourcesItemLink = styled(Link)`
  display: flex;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const ResourcesItemIconCol = styled.span<{ right?: boolean }>`
  display: block;
  flex: 0 0 ${({ right }) => (right ? '24px' : '26px')};
  padding: ${({ right }) => (right ? '0 0 0 4px' : '4px 12px 0 0')};
`

export const ResourcesItemContentCol = styled.span`
  display: block;
  flex: 1 1 auto;
`

export const ResourcesItemParagraph = styled.p<{ bold?: boolean }>`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`

export const ResourcesItemHeading = styled(Heading6)`
  font-size: 16px;
  line-height: 1.25;
  margin-bottom: 0.5rem;
`
