import styled from 'styled-components'

const NoIdeaFound = styled.h5`
  margin-top: 50px;
  text-align: center;
`

NoIdeaFound.displayName = 'NoIdeaFound'

export { NoIdeaFound }
