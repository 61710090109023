import { actionTypes } from '../actions/about'

const {
  GET_CONTENT_ABOUT,
  GET_CONTENT_ABOUT_SUCCESS,
  GET_CONTENT_ABOUT_FAILURE,
} = actionTypes

const initialState = {
  about: null,
  loading: false,
  errorMessage: null,
}

const aboutReducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case GET_CONTENT_ABOUT:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      }
    case GET_CONTENT_ABOUT_SUCCESS:
      return {
        ...state,
        about: payload.about,
        loading: false,
      }
    case GET_CONTENT_ABOUT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
      }
    default:
      return state
  }
}

export { initialState, aboutReducer }
