import React from 'react'
import Heading5 from 'luna-react/components/typography/Heading5'
import ResourcesItem from './ResourcesItem'
import { ResourcesContainer, ResourcesList } from './Resources.styles'

interface ResourcesProps {
  items: any[]
  title: string
  withIcon?: boolean
}

const Resources: React.FC<ResourcesProps> = ({ items, title, withIcon }) => {
  if (!items || !items.length) {
    return null
  }

  return (
    <ResourcesContainer>
      {title && <Heading5>{title}</Heading5>}
      <ResourcesList>
        {items.map((item: any, index: number) => {
          if (item.fields) {
            return (
              <ResourcesItem
                key={index}
                url={item.fields.url}
                title={item.fields.title}
                description={item.fields.description}
                hasIcon={withIcon}
              />
            )
          }

          return null
        })}
      </ResourcesList>
    </ResourcesContainer>
  )
}

export default Resources
