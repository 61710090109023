import React from 'react'
import Layout from '../layouts/Layout/Layout'

import NewIdeaFormContainer from '../components/NewIdea/NewIdeaFormContainer'

const NewIdeaForm = () => {
  return (
    <Layout pageId="page-new-idea">
      <NewIdeaFormContainer />
    </Layout>
  )
}

export default NewIdeaForm
