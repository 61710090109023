import React from 'react'
import Layout from '../layouts/Layout/Layout'
import Study from '../components/Study/StudyContainer'

const CaseStudy = props => {
  const { study_id } = props.match.params
  return (
    <Layout pageId="page-case-study">
      <Study titleSlug={study_id} />
    </Layout>
  )
}

export default CaseStudy
