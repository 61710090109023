import { history } from '../../createHistory'
import { Heading6 } from 'luna-react'
import React from 'react'
import styled from 'styled-components'
import routes from '../../routes'
import { Container, Content } from '../../styles'
import { PageHeader } from '../PageHeader'
import sendImage from '../../assets/send.svg'

const SuccessContent = styled.div`
  padding: 0 68px;
  text-align: center;
`

const SuccessHeader = styled(Heading6)`
  margin-top: 1.5rem;
`

const FakeLink = styled.a`
  color: inherit;
  cursor: pointer;
  text-decoration: underline;
`

class EditIdeaSuccess extends React.Component {
  go(e, route) {
    e.preventDefault()
    history.replace(route)
  }

  render() {
    return (
      <>
        <PageHeader
          title="Thanks for being awesome!"
          blurb="Your idea has been edited"
        />

        <Content data-id="success">
          <Container.Padded>
            <SuccessContent>
              <img src={sendImage} alt="Send Icon" />
              <SuccessHeader>
                We will look over your idea and get back to you as soon as
                possible
              </SuccessHeader>
              <p>
                In the meantime, you can check the{' '}
                <FakeLink onClick={e => this.go(e, routes.faqs)}>FAQs</FakeLink>{' '}
                section, look over{' '}
                <FakeLink onClick={e => this.go(e, routes.caseStudies)}>
                  Case Studies
                </FakeLink>{' '}
                or{' '}
                <a
                  href="mailto:Community.Affairs@sainsburys.co.uk
                "
                >
                  Get in touch
                </a>{' '}
                if you have any further comments or questions.
              </p>
              <p>
                We‘ll be in touch soon!
                <br />
                <strong>Thank you.</strong>
              </p>
            </SuccessContent>
          </Container.Padded>
        </Content>
      </>
    )
  }
}

export default EditIdeaSuccess
