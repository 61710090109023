import React from 'react'
import { Text } from '@jsluna/typography'
import CaptionListText from '../../components/CaptionListText/CaptionListText'

interface OpportunitySidebarInterface {
  availableFrom?: string | null
  availableUntil?: string | null
  email?: string | null
  minimumCommitment?: string | null
  phone?: string | null
  organisation_name?: string | null
  skills?: string | null
  teamSize?: string | null | number
  training?: string | null
  website?: string | null
}

function OpportunitySidebar(props: OpportunitySidebarInterface) {
  const {
    availableFrom,
    availableUntil,
    email,
    minimumCommitment,
    phone,
    skills,
    teamSize,
    training,
    website,
  } = props
  const fields = {
    specs: [
      {
        name: 'Team size',
        value: teamSize,
      },
      {
        name: 'Skills required',
        value: skills,
      },
      {
        name: 'Training will be offered (where required)',
        value: training,
      },
      {
        name: 'Minimum commitment time',
        value: minimumCommitment,
      },
      {
        name: 'Available from',
        value: availableFrom,
      },
      {
        name: 'Available until',
        value: availableUntil,
      },
    ],
    contact: [
      {
        name: 'E: ',
        value: email,
        link: `mailto:${email}`,
      },
      {
        name: 'W: ',
        value: website,
        link: website,
      },
      {
        name: 'T: ',
        value: phone,
      },
    ],
  }
  // Opportunity specification content
  const contentSpecs = fields.specs.map((specification, index) => (
    <div key={`specification_${specification.name}${index}`}>
      <CaptionListText
        items={[
          {
            title: specification.name,
            text: specification.value || '',
          },
        ]}
      />
    </div>
  ))
  // Opportunity contact content
  const contentContact = fields.contact.map((contact, index) => {
    const contentValue = contact.link ? (
      <Text key={`oppcontact_textLink_${contact.link}${index}`}>
        {contact.value}
      </Text>
    ) : (
      <a
        key={`oppcontact_anchor_${contact.link}${index}`}
        href={contact.link || ''}
      >
        <Text>{contact.value}</Text>
      </a>
    )
    if (!contact.value) {
      return <React.Fragment key={index} />
    }
    return (
      <div key={`oppcontact_div_${contact.name}${index}`}>
        <Text key={`oppcontact_text_${contact.link}${index}`}>
          {contact.name}
        </Text>
        {contentValue}
      </div>
    )
  })
  // Render
  return (
    <div>
      {contentSpecs}
      {contentContact}
    </div>
  )
}

export default OpportunitySidebar
