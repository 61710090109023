import React from 'react'
import { useSelector } from 'react-redux'
import { Heading5 } from '@jsluna/typography'
import { selectFormErrorMessage } from '../../redux/selectors/ideaForm'
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentHeaderBlurb,
  Content,
  Container,
} from '../../styles'
import { name } from '../../data/volunteeringFormData'

const LocalCharityError: React.FC = () => {
  const errorMessage = useSelector(selectFormErrorMessage(name))

  return (
    <>
      <ContentHeader grey>
        <Container.FullBleed>
          <ContentHeaderTitle>Sorry</ContentHeaderTitle>
          <ContentHeaderBlurb>
            Something has gone wrong with your submission.
          </ContentHeaderBlurb>
        </Container.FullBleed>
      </ContentHeader>
      <Content>
        <Container.Padded>
          <Heading5>
            {errorMessage
              ? errorMessage
              : 'An error occurred while trying to submit your Volunteering Project. The team has been notified. Please try again.'}
          </Heading5>
        </Container.Padded>
      </Content>
    </>
  )
}

export default LocalCharityError
