import { AnyAction } from 'redux'
import { AdminIdeaState } from '../store'
import { AdminIdeaActionTypes } from '../../types'

const adminIdeaInitialState = {
  source: '',
}

const adminIdeaReducer = (
  state: AdminIdeaState = adminIdeaInitialState,
  action: AnyAction
): AdminIdeaState => {
  const { type, payload } = action as AnyAction
  switch (type) {
    case AdminIdeaActionTypes.ADMIN_IDEA_SET_SOURCE: {
      return {
        ...state,
        source: payload.source,
      }
    }
    default:
      return state
  }
}

export { adminIdeaInitialState, adminIdeaReducer }
