import React from 'react'
import { Modal, ModalHeading } from '@jsluna/modal'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'
import {
  FilledButton,
  TextButton,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
} from '@jsluna/button'

interface IdeaActionModalProps {
  title: string
  message: string
  isOpen: boolean
  isLoading: boolean
  confirmLabel: string
  cancelLabel: string
  onConfirm: Function
  onCancel: Function
}

const IdeaActionModal: React.FC<IdeaActionModalProps> = ({
  title,
  message,
  isOpen,
  isLoading,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal small fullScreen alert restrictClose open={isOpen}>
      <ModalHeading element="h4">{title}</ModalHeading>
      <p>{message}</p>
      <ButtonGroupWrapper actionBar>
        <ButtonGroupPrimary>
          <ProgressIndicator
            loading={isLoading}
            indicator={<ProgressSpinner size="small" color="light" />}
          >
            {({ indicator }: { indicator: React.Component<any, any> }) => (
              <FilledButton
                onClick={!isLoading ? onConfirm : null}
                style={{ minWidth: '6rem' }}
              >
                {isLoading ? indicator : confirmLabel}
              </FilledButton>
            )}
          </ProgressIndicator>
        </ButtonGroupPrimary>
        <ButtonGroupSecondary>
          <TextButton onClick={onCancel}>{cancelLabel}</TextButton>
        </ButtonGroupSecondary>
      </ButtonGroupWrapper>
    </Modal>
  )
}

export default IdeaActionModal
