import React from 'react'
import { PropTypes } from 'prop-types'
import { animateScroll, scroller, Element } from 'react-scroll'
import { Sticky } from 'react-sticky'
import { Accordion, AccordionItem, LoadingIndicator } from 'luna-react'
import { ArrowUp } from 'luna-icons'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
  AccordionSectionWrapper,
  AccordionSectionHeader,
  BackToTop,
  FlexSidebarWrapper,
  FaqWrapper,
  FaqSectionLink,
  FaqAccordionWrapper,
} from './styles'
import ErrorMessage from '../ErrorMessage/ErrorMessage'

const SCROLL_DURATION = 250

export const FaqSidebarLinkList = ({ faqs }) => {
  return (
    <>
      {faqs.map((section, sectionIndex) => (
        <FaqSectionLink
          onClick={() => {
            scroller.scrollTo(`faq-${sectionIndex}`, {
              smooth: 'easeOutCubic',
              duration: (sectionIndex + 1) * SCROLL_DURATION,
            })
          }}
          key={`faq-${sectionIndex}`}
        >
          {section.heading}
        </FaqSectionLink>
      ))}
    </>
  )
}

export const FaqSidebar = ({ faqs }) => {
  return (
    <FlexSidebarWrapper>
      <Sticky>
        {({ style }) => (
          <div style={{ ...style, paddingTop: 30 }}>
            <FaqSidebarLinkList faqs={faqs} />
          </div>
        )}
      </Sticky>
    </FlexSidebarWrapper>
  )
}
class Faqs extends React.Component {
  componentDidMount() {
    this.props.doFetchFaqs()
  }

  render() {
    const { faqs = [], loading, errorMessage } = this.props

    if (loading) {
      return <LoadingIndicator message="Loading faqs…" />
    }

    if (!loading && errorMessage) {
      return <ErrorMessage errorMessage={errorMessage} />
    }

    return (
      <FaqWrapper>
        <FaqSidebar faqs={faqs} />
        <FaqAccordionWrapper>
          {faqs.map((section, sectionIndex) => {
            return (
              <Element key={`faq-${sectionIndex}`} name={`faq-${sectionIndex}`}>
                <AccordionSectionWrapper id={`faq-${sectionIndex}`}>
                  <AccordionSectionHeader>
                    {section.heading}
                  </AccordionSectionHeader>
                  <Accordion multipleOpen titleElement="h3">
                    {section.items.map((faq, index) => (
                      <AccordionItem
                        id={index.toString()}
                        title={faq.question}
                        key={index}
                      >
                        {documentToReactComponents(faq.answer)}
                      </AccordionItem>
                    ))}
                  </Accordion>
                  <BackToTop
                    onClick={() =>
                      animateScroll.scrollToTop({
                        smooth: 'easeOutCubic',
                        duration: (sectionIndex + 1) * SCROLL_DURATION,
                      })
                    }
                  >
                    Back to top <ArrowUp style={{ color: '#000' }} />
                  </BackToTop>
                </AccordionSectionWrapper>
              </Element>
            )
          })}
        </FaqAccordionWrapper>
      </FaqWrapper>
    )
  }
}

Faqs.propTypes = {
  doFetchFaqs: PropTypes.func.isRequired,
  ideas: PropTypes.object,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default Faqs
