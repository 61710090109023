import React from 'react'
import PropTypes from 'prop-types'

const FormattedDate = ({ date }) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const d = new Date(date)
  const day = ('0' + d.getDate()).slice(-2)
  const month = monthNames[d.getMonth()]
  const year = d.getFullYear()
  const hours = ('0' + d.getHours()).slice(-2)
  const minutes = ('0' + d.getMinutes()).slice(-2)
  return <>{`${day} ${month} ${year}, ${hours}:${minutes}`}</>
}

FormattedDate.propTypes = {
  date: PropTypes.string.isRequired,
}

export default FormattedDate
