import React from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  Switch,
  Route,
  withRouter,
} from 'react-router-dom'
import { Container } from '@jsluna/grid'
import { State } from '../../redux/store'
import IdeasListControls from './AdminIdeasNavigation.component'
import {
  selectDataIsLoading,
  selectDataIsError,
  selectDataErrorMessage,
} from '../../redux/selectors/dataStore'
import { AdminIdeasPaginationContainer } from './AdminIdeasPagination.container'
import routes from '../../routes'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import AdminIdeasList150DaysContainer from './AdminIdeasList150Days.container'
import AdminIdeasListHeroesContainer from './AdminIdeasListHeroes.container'
import AdminIdeasListVolunteeringContainer from './AdminIdeasListVolunteering.container'

interface IdeasListProps extends RouteComponentProps {
  loading: boolean
  error: boolean
  errorMessage: string
}

const AdminIdeasListWrapper: React.FC<IdeasListProps> = ({
  loading,
  error,
  errorMessage,
}) => {
  return (
    <Container fluid soft="sm">
      {error && <ErrorMessage errorMessage={errorMessage} />}
      {!error && (
        <>
          <IdeasListControls />
          <Switch>
            <Route
              path={routes.admin150Days}
              exact
              render={() => <AdminIdeasList150DaysContainer />}
            />
            <Route
              path={routes.adminLocalHeroes}
              exact
              render={() => <AdminIdeasListHeroesContainer />}
            />
            <Route
              path={routes.adminVolunteering}
              exact
              render={() => <AdminIdeasListVolunteeringContainer />}
            />
          </Switch>
          {!loading && <AdminIdeasPaginationContainer />}
        </>
      )}
    </Container>
  )
}

const mapStateToProps = (state: State): any => {
  return {
    loading: selectDataIsLoading(state),
    error: selectDataIsError(state),
    errorMessage: selectDataErrorMessage(state),
  }
}

export const AdminIdeasContainer = withRouter(
  connect(mapStateToProps)(AdminIdeasListWrapper)
)

export default AdminIdeasListWrapper
