const campaigns = Object.freeze({
  volunteering: {
    name: 'volunteering',
    adminRoute: '/admin/volunteering',
    ideasRoute: '/ideas/volunteering',
    editRoute: '/volunteering/edit/',
    adminEndpoint: '/volunteers',
    ideasEndpoint: '/volunteers',
    fieldLabels: {
      author_email: 'Email address',
      business: 'Business area',
      store_name: 'Store/site name or division',
      store_number: 'Store number',
      region: 'Region',
      organisation_name: 'Organisation name',
      organisation_website: 'Organisation website',
      category: 'Category',
      volunteering_colleagues: 'Number of colleagues',
      volunteering_hours: 'Hours per colleague',
      volunteering_date: 'Estimated date of project',
      volunteering_impact_detail: 'Impact measurement',
      volunteering_project_detail: 'Volunteering project detail',
      activity: 'Activity',
      activity_other: 'Other activity details',
      volunteering_unavoidable_costs: 'Unavoidable associated costs',
      volunteering_unavoidable_costs_details:
        'Unavoidable associated costs details',
      stardust_moments: 'Stardust moments',
    },
  },
  localheroes: {
    name: 'localheroes',
    adminRoute: '/admin/localheroes',
    ideasRoute: '/ideas/localheroes',
    editRoute: '/edit-idea/',
    adminEndpoint: '/admin/localHeroes',
    ideasEndpoint: null,
    fieldLabels: {
      colleague_name: 'Team Leader',
      contact_email: 'Email address',
      business: 'Associated store or team',
      store: 'Store name, or store-support-centre team name',
      store_number: 'Store number, or store-support-centre team number',
      region: 'Region',
      team_size: 'Team Size',
      organisation_name: 'Organisation name',
      organisation_website: 'Organisation website',
      category: 'Category',
      volunteering_idea: 'Volunteering idea',
      stardust_moments: 'Stardust moment',
      date: 'Volunteering date',
    },
  },
  '150days': {
    name: '150days',
    adminRoute: '/admin/150days',
    ideasRoute: '/ideas/150days',
    editRoute: '/edit-idea/',
    adminEndpoint: '/admin/ideas',
    ideasEndpoint: '/ideas',
    fieldLabels: {
      colleague_name: 'Team Leader',
      contact_email: 'Email address',
      business: 'Associated store or team',
      store: 'Store name, or store-support-centre team name',
      store_number: 'Store number, or store-support-centre team number',
      region: 'Region',
      team_size: 'Team Size',
      organisation_name: 'Organisation name',
      organisation_website: 'Organisation website',
      category: 'Category',
      volunteering_idea: 'Volunteering idea',
      stardust_moments: 'Stardust moment',
    },
  },
})

export default campaigns
