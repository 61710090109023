import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import Menu from '../Menu/Menu'
import { Mobile } from '../../util/MediaQuery'
import { Container } from '../../styles'

const ContainerComponent = props => <Container.FullBleed {...props} />

const CardContainer = styled(ContainerComponent)`
  position: absolute;
  z-index: 100;
  padding: 0 15px;
  top: calc(${({ theme }) => theme.header.height} + 15px);
`

const Card = styled.div`
  width: 100%;
  background: ${props => props.theme.color.white};
  box-shadow: 0 1px 1px 1px ${({ theme }) => theme.color.lightGrey};
`

export const MobileMenu = ({ isOpen, toggleOpen }) => {
  return (
    <Mobile>
      {isOpen ? (
        <CardContainer>
          <Card>
            <Menu isOpen={isOpen} toggleOpen={toggleOpen} />
          </Card>
        </CardContainer>
      ) : (
        ''
      )}
    </Mobile>
  )
}

MobileMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
}
