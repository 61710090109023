import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { doLogin } from '../../redux/dispatchers/auth'
import Auth from './Auth'

const mapStateToProps = state => {
  const stateProps = {
    user: state.auth.user,
  }
  return stateProps
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLogin,
    },
    dispatch
  )

const AuthContainer = connect(mapStateToProps, mapDispatchToProps)(Auth)

export default AuthContainer
