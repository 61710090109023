import { actionTypes } from '../actions/studies'

const {
  GET_CONTENT_STUDIES,
  GET_CONTENT_STUDIES_SUCCESS,
  GET_CONTENT_STUDIES_FAILURE,
} = actionTypes

const initialState = {
  studies: [],
  loading: false,
  errorMessage: null,
}

const studiesReducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case GET_CONTENT_STUDIES:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      }
    case GET_CONTENT_STUDIES_SUCCESS:
      return {
        ...state,
        studies: payload.studies,
        loading: false,
      }
    case GET_CONTENT_STUDIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
      }
    default:
      return state
  }
}

export { initialState, studiesReducer }
