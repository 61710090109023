import React, { useState } from 'react'
import moment, { Moment } from 'moment'
import 'react-dates/initialize'
import { SingleDatePicker } from '@jsluna/date-picker'
import { FormGroup } from '@jsluna/form'
import { filterProps } from '@jsluna/utils'

moment.locale('en-GB')

interface DatePickerProps {
  name: string
  defaultValue: string
  onChange: Function
}

const DatePicker = ({ name, defaultValue, onChange }: DatePickerProps) => {
  const [focused, setFocused] = useState(false)

  return (
    <FormGroup name={name}>
      {({ name, ...inputProps }: any) => (
        <SingleDatePicker
          id={name}
          {...filterProps(inputProps, ['aria-describedby', 'invalid'])}
          date={moment(defaultValue)}
          focused={focused}
          displayFormat="DD/MM/YYYY"
          onDateChange={(date: Moment) => {
            if (date) {
              onChange({
                target: {
                  name,
                  value: date.format('YYYY-MM-DD'),
                },
              })
            }
          }}
          onFocusChange={({ focused }: any) => {
            setFocused(focused)
          }}
        />
      )}
    </FormGroup>
  )
}

export default DatePicker
