import React from 'react'
import { BackHeader } from '../PageHeader/BackHeader'
import { Wrapper, Label, Reference } from './styles'
import Head from '../Head/Head'

const RefIdeaHeader = ({ visible, idea = {}, to }) => {
  const ref = idea && idea.ref ? idea.ref : ''
  return (
    <Wrapper visible={visible}>
      <Head title={ref ? `Idea ref: ${ref}` : 'Fetching idea...'} />
      <BackHeader
        title={
          <>
            <Label>Ref:&nbsp;</Label>
            <Reference>{ref}</Reference>
          </>
        }
        to={to}
      />
    </Wrapper>
  )
}

export default RefIdeaHeader
