export const Filters150Days = {
  searchMaxShortTextLength: 0,
  searchMaxLongTextLength: 0,
  dateRangeVisible: true,
  searchOptions: [
    {
      label: 'Filter by Ref ID',
      value: 'ref',
      placeholder: 'e.g. A1B2C3',
      enableSearchOnly: true,
    },
    {
      label: 'Filter by email',
      value: 'submitted_by',
      placeholder: 'e.g. sam.smith@sainsburys.co.uk',
    },
    {
      label: 'Filter by organisation',
      value: 'organisation_name',
      placeholder: 'e.g. The Woodland Trust',
    },
  ],
  filterOptions: [
    {
      heading: 'Status',
      key: 'idea_status',
      values: [
        { label: 'Pending', value: 'PENDING' },
        { label: 'Accepted', value: 'ACCEPTED' },
        { label: 'Updated', value: 'UPDATED' },
        { label: 'Declined', value: 'DECLINED' },
        { label: 'Bookmarked', value: 'BOOKMARKED' },
      ],
    },
    {
      heading: 'Store/Team',
      key: 'business',
      values: [
        { label: 'Sainsbury’s Asia', value: 'Sainsbury’s Asia' },
        { label: 'Sainsbury’s Bank', value: 'Sainsbury’s Bank' },
        { label: 'Sainsbury’s SSC', value: 'Sainsbury’s SSC' },
        { label: 'Sainsbury’s Store', value: 'Sainsbury’s Store' },
        { label: 'Sainsbury’s Logistics', value: 'Sainsbury’s Logistics' },
        { label: 'Sainsbury’s Argos', value: 'Sainsbury’s Argos' },
        { label: 'Argos Store', value: 'Argos Store' },
        { label: 'Argos Logistics', value: 'Argos Logistics' },
        {
          label: 'Sainsbury’s and Argos CMC',
          value: 'Sainsbury’s and Argos CMC',
        },
        { label: 'Habitat', value: 'Habitat' },
        { label: 'Nectar', value: 'Nectar' },
        { label: 'AFS', value: 'AFS' },
        { label: 'Other', value: 'Other' },
      ],
    },
    {
      heading: 'Category',
      key: 'category',
      values: [
        { label: 'Poverty', value: 'Poverty' },
        { label: 'Homelessness', value: 'Homelessness' },
        { label: 'Disability', value: 'Disability' },
        { label: 'Health', value: 'Health' },
        { label: 'Mental health', value: 'Mental health' },
        { label: 'Schools/Young people', value: 'Schools/Young people' },
        { label: 'Older people', value: 'Older people' },
        { label: 'Community care', value: 'Community care' },
        { label: 'Environment', value: 'Environment' },
        { label: 'Other', value: 'Other' },
      ],
    },
  ],
}
