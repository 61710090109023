import styled from 'styled-components'
import { FilledButton, SelectField, TextInputField } from 'luna-react'

export const FormItem = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 10px;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    flex-wrap: no-wrap;
  }
`

export const StyledTextInputField = styled(TextInputField)`
  flex: 1 0 100%;
  margin: 10px 10px 10px 0;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    flex: 1 0 auto;
  }
`

export const StyledButton = styled(FilledButton)`
  flex: 1 0 100%;
  margin: 10px 0;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    flex: 0 0 140px;
  }
  @media (max-width: 792px) {
    flex: 0 0 100%;
  }
`
export const StyledSelectField = styled(SelectField)`
  flex: 1 0 180px;
  margin: 10px 0;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    flex: 0 0 180px;
    margin: 10px;
  }
`

export const SeperatorText = styled.p`
  display: none;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    display: block;
    margin-bottom: 18px;
    font-family: MaryAnn;
    font-size: 20px;
    font-weight: 800;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
  }
`
