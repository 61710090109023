import React from 'react'
import { withRouter } from 'react-router-dom'
import { Wrapper, Block, Heading, P, Button, ButtonAlt } from './styles'

export const argosYammer =
  'https://www.yammer.com/homeretailgroup.com/#/threads/inGroup?type=in_group&feedId=17846041&view=all'
export const sainsburysYammer =
  'https://www.yammer.com/mysainsburys.co.uk/#/threads/inGroup?type=in_group&feedId=17232855'

const UserFeedback = ({ email }) => {
  const isArgos = email.includes('@argos')

  return (
    <Wrapper>
      <Block>
        <Heading>Share your volunteering stories with us</Heading>
        <P>
          We’d love you to share your team’s volunteering story and images on
          Yammer and email the Community Affairs team if you’d like to be
          included in our Case Studies.
        </P>
        <ButtonAlt
          id="yammer-button"
          element="a"
          href={isArgos ? argosYammer : sainsburysYammer}
        >
          Yammer
        </ButtonAlt>
        <Button element="a" href="mailto:Community.Affairs@sainsburys.co.uk">
          Community Affairs
        </Button>
      </Block>
    </Wrapper>
  )
}

export { UserFeedback }

export default withRouter(UserFeedback)
