import React from 'react'
import styled from 'styled-components'
import { Heading } from '@jsluna/typography'

const FlexBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: ${props => props['justifyContent'] || 'space-between'};
  flex: 1;
`
const FlexRow = styled(FlexBox)`
  flex-basis: 100%;
`
const PrimaryHeaderWrapper = styled(FlexBox)`
  background: #f6f6f6;
  margin: 0 auto;
  min-height: 120px;
`
const PrimaryHeaderContent = styled(FlexBox)`
  align-items: center;
  background: white;
  border-bottom: 0.5px solid #efefef;
  margin: 0 auto;
  max-width: 1260px;
  min-height: 120px;
  padding: 24px;
`
const LoadingCenterDiv = styled.div`
  display: ${(props: { loading: number }) => (props.loading ? 'flex' : '')};
  margin: ${(props: { loading: number }) => (props.loading ? 'auto' : '')};
`
const HeaderLogoWrapper = styled.div`
  cursor: pointer;
  justify-content: flex-end;
  max-width: 120px;
`
interface PrimaryHeaderInterface {
  title: string
  children?: any
  image?: string | null
  imageAlt?: string
  loading?: boolean
}

function PrimaryHeader(props: PrimaryHeaderInterface) {
  const { title, children, image, imageAlt, loading } = props
  return (
    <PrimaryHeaderWrapper>
      <PrimaryHeaderContent>
        <FlexRow>
          <LoadingCenterDiv loading={loading ? 1 : 0}>
            <Heading level={2}>{title}</Heading>
            {children}
          </LoadingCenterDiv>
          <HeaderLogoWrapper>
            {image && <img alt={imageAlt} src={image} />}
          </HeaderLogoWrapper>
        </FlexRow>
      </PrimaryHeaderContent>
    </PrimaryHeaderWrapper>
  )
}

export default PrimaryHeader
