import { AnyAction } from 'redux'
import { ProgrammeAction } from '../actions/programmeDetail'

export interface ProgrammeState {
  data: object | undefined
  programmeKey: string | null
  errorMessage: string | null
  isLoading: boolean
}

export const programmeInitialState: ProgrammeState = {
  programmeKey: null,
  data: undefined,
  isLoading: false,
  errorMessage: null,
}

export function programmeReducer(
  state: ProgrammeState = programmeInitialState,
  action: AnyAction
): ProgrammeState {
  switch (action.type) {
    case ProgrammeAction.REQUEST:
      return {
        ...state,
        isLoading: true,
        programmeKey: action.programmeKey,
        errorMessage: null,
      }

    case ProgrammeAction.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload[0],
        errorMessage: null,
      }

    case ProgrammeAction.FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.errorMessage,
      }

    default:
      return state
  }
}
