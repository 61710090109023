import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { ProgressSpinner } from '@jsluna/progress'
import IdeasDataRowVolunteering from '../../components/IdeaSummary/IdeasDataRowVolunteering'
import { AdminIdeaDataType, StatisticsType } from '../../types'
import { FilledButtonControl } from './AdminIdeas.styles'
import { isNewFilters } from '../../features'
import { Statistics } from './AdminIdeasStatistics.component'
import FiltersContainer from '../../components/Filters/Filters.container'
import { NoIdeaFound } from './AdminList150Days.style'

interface AdminIdeasListVolunteeringProps extends RouteComponentProps {
  data: any
  loading: boolean
  stats: StatisticsType
  downloadVolunteering: () => void
  fetchData: (paramsQuery: string) => void
}

const AdminIdeasListVolunteering: React.FC<AdminIdeasListVolunteeringProps> = ({
  data,
  loading,
  stats,
  downloadVolunteering,
  fetchData,
  location,
}) => {
  useEffect(() => {
    const search = location.search
    const paramsQuery = search.length > 1 ? search.substr(1, search.length) : ''
    fetchData(paramsQuery)
  }, [fetchData, location])

  const clickHandlerDownload = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    downloadVolunteering()
  }

  return (
    <div className="ln-u-soft-ends">
      {loading ? (
        <ProgressSpinner />
      ) : (
        <>
          {isNewFilters() && <FiltersContainer />}
          {data && data.length > 0 ? (
            <>
              <FilledButtonControl onClick={clickHandlerDownload}>
                Export ideas (.csv)
              </FilledButtonControl>
              {stats && <Statistics stats={stats} />}
              {data.map((idea: AdminIdeaDataType, index: number) => (
                <IdeasDataRowVolunteering
                  idea={idea}
                  currentIndex={index}
                  key={index}
                />
              ))}
            </>
          ) : (
            <NoIdeaFound>
              No Volunteering Projects found matching the current filter
            </NoIdeaFound>
          )}
        </>
      )}
    </div>
  )
}

export default AdminIdeasListVolunteering
