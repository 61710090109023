import React from 'react'
import Layout from '../Layout/Layout'
import { Container, Content } from '../../styles'
import { WithChildren } from '../../types'
import { Wrapper, SubTitle, Title } from './ProgrammesLayout.styles'
import Head from '../../components/Head/Head'

interface ProgrammesLayoutProps {
  children: WithChildren
  subHeader: string
  title: string
}

const ProgrammesLayout: React.FC<ProgrammesLayoutProps> = ({
  children,
  subHeader,
  title,
}) => {
  const isTitle = title && title.length > 1
  return (
    <Layout pageId="page-programmes-home">
      <Wrapper minHeight={'310px'}>
        {isTitle && (
          <>
            <Head
              title="Local and National programmes"
              description="Local and National programmes content"
            />
            <Container.FullBleed>
              <Title data-id="programmes-title">{title}</Title>
              {subHeader && <SubTitle>{subHeader}</SubTitle>}
            </Container.FullBleed>
          </>
        )}
      </Wrapper>
      <Content>
        <Container.FalseBleed>{children}</Container.FalseBleed>
      </Content>
    </Layout>
  )
}

export default ProgrammesLayout
