import { connect } from 'react-redux'
import { updateFilters, updateDateRange } from '../../redux/actions/ideaFilter'
import IdeaFilters from './IdeaFilters'
import { isFilterDisabled } from '../../redux/selectors/ideaFilter'

const mapStateToProps = state => {
  const { value } = state.ideaFilter.filter
  return {
    fields: value,
    disabled: isFilterDisabled(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onChange: (key, filter) => dispatch(updateFilters(key, filter)),
    onRangeChange: range => dispatch(updateDateRange(range)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(IdeaFilters)
