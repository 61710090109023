import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PrimaryButton } from 'luna-react'
import IdeaSearch from './IdeaSearchContainer'
import IdeaFilters from './IdeaFiltersContainer'
import { SearchPanelWrapper, Wrapper, FilterButton } from './styles'
import { stringify } from 'query-string'
import routes from '../../routes'
import { history } from '../../createHistory'
import { extractFields } from '../../util/ideaFilterUtils'

const IdeaFilterPanel = ({ onApply, fields }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const params = extractFields(fields)
    onApply(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = () => {
    setIsOpen(false)
    const params = extractFields(fields)
    const url = stringify(params, { encode: false })
    history.replace(`${routes.adminIdeas}?${url}`)
    if (onApply) {
      onApply(params)
    }
  }

  return (
    <Wrapper>
      <FilterButton onClick={() => setIsOpen(!isOpen)}>
        <h5>Filter</h5>
      </FilterButton>
      {isOpen && (
        <SearchPanelWrapper>
          <form onSubmit={handleSubmit}>
            <IdeaSearch />
            <IdeaFilters />
            <PrimaryButton onClick={handleSubmit}>Apply</PrimaryButton>
          </form>
        </SearchPanelWrapper>
      )}
    </Wrapper>
  )
}

IdeaFilterPanel.propTypes = {
  onApply: PropTypes.func,
}

export default IdeaFilterPanel
