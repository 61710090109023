import React, { useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const ModalBackground = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9000;
`
const Modal = ({ children }) => {
  const body = document.querySelector('body')
  const modalRoot = document.getElementById('modal-root')
  const element = document.createElement('div')
  useLayoutEffect(() => {
    modalRoot.appendChild(element)
    body.style.overflow = 'hidden'
    return () => {
      modalRoot.removeChild(element)
      body.style.removeProperty('overflow')
    }
  }, [children, body.style, modalRoot, element])
  const content = <ModalBackground>{children}</ModalBackground>
  return ReactDOM.createPortal(content, element)
}

export default Modal
