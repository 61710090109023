import styled from 'styled-components'
import theme from '../../styles/theme'

const StatisticsColours = Object.freeze({
  pending: theme.color.yellow,
  accepted: theme.color.green,
  declined: theme.color.red,
  updated: theme.color.aqua,
  bookmarked: theme.color.black,
  total: theme.color.grey,
})

const StatisticsIcon = styled.div`
  color: ${({ status }) => (status === 'pending' ? 'black' : 'white')};
  height: 18px;
  width: 36px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
  text-align: center;
  background: ${({ status }) => StatisticsColours[status]};
`

const StatisticsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`

export { StatisticsIcon, StatisticsWrapper }
