import React from 'react'
import Faqs from '../components/Faqs'
import Layout from '../layouts/Layout/Layout'
import { PageHeader } from '../components/PageHeader'
import { Callout, Container, Content } from '../styles'

const FaqPage = () => (
  <Layout pageId="page-faqs">
    <PageHeader
      title="Frequently Asked Questions"
      blurb="We’ve compiled a list of answers to some frequently asked questions, which we hope will clear up any confusion."
    />
    <Content>
      <Container.Padded>
        <Faqs />
      </Container.Padded>
      <Container.FalseBleed>
        <Callout.Wrapper verticalSpacing={12}>
          <Callout.Header level={4}>
            Still can’t find what you’re looking for?
          </Callout.Header>
          <Callout.Body>
            If you’ve read the above and still can’t find what you’re looking
            for, please email the Community Affairs team.
          </Callout.Body>
          <Callout.Button
            element="a"
            href="mailto:community.affairs@sainsburys.co.uk"
          >
            Community Affairs
          </Callout.Button>
        </Callout.Wrapper>
      </Container.FalseBleed>
    </Content>
  </Layout>
)

export default FaqPage
