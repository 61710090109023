import { actionTypes } from '../actions/menu'
const { TOGGLE } = actionTypes

const initialState = {
  isOpen: false,
}

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
      }
    default:
      return state
  }
}

export { initialState, menuReducer }
