import React from 'react'
import PropTypes from 'prop-types'

import {
  SectionHeader,
  RequiredSectionMarker,
  FormLabel,
  TextAreaField,
  FilledButton,
  CancelButton,
  DeclineSection,
  Note,
} from './styles'

class DeclineIdeaForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      decline_reason: '',
    }
    this.submitHandler = this.submitHandler.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.cancelHandler = this.cancelHandler.bind(this)
    this.isValid = this.isValid.bind(this)
  }

  submitHandler(e) {
    e.preventDefault()
    if (this.isValid()) {
      if (
        window.confirm(
          `Are you sure that you want to decline this idea? An email will be sent to the ${this.props.submitted_by}.`
        )
      ) {
        this.props.doDeclineIdea(this.props.idea_id, this.state.decline_reason)
      }
    }
  }

  changeHandler(e) {
    this.setState({
      decline_reason: e.target.value,
    })
  }

  cancelHandler() {
    this.props.hideDeclineForm()
    this.setState({
      decline_reason: '',
    })
  }

  isValid() {
    return this.state.decline_reason && this.state.decline_reason.length > 0
  }

  renderForm(submitted_by) {
    return (
      <div>
        <form onSubmit={this.submitHandler} data-id="idea-decline-form">
          <DeclineSection>
            <SectionHeader data-id="idea-form-section-header">
              Reason for declining this idea{' '}
              <RequiredSectionMarker>*</RequiredSectionMarker>
            </SectionHeader>
            <FormLabel>
              <label id="decline_reason">
                Please give as much detail as possible as to why you have
                declined this idea.
              </label>
            </FormLabel>
            <TextAreaField
              onChange={this.changeHandler}
              name="decline_reason"
              maxLength={1000}
              data-id="decline_reason_field"
            />
          </DeclineSection>
          <div>
            <Note>
              <strong>Note:</strong> An automated email template with the above
              content will be sent to: <strong>{submitted_by}</strong>
            </Note>
          </div>
          <div>
            <CancelButton
              data-id="decline_reason_cancel"
              onClick={this.cancelHandler}
            >
              Cancel
            </CancelButton>
            <FilledButton
              data-id="decline_reason_submit"
              disabled={!this.isValid()}
              type="submit"
            >
              Submit
            </FilledButton>
          </div>
        </form>
      </div>
    )
  }

  render() {
    const { isDeclineFormVisible, submitted_by } = this.props
    return isDeclineFormVisible ? this.renderForm(submitted_by) : null
  }
}

DeclineIdeaForm.propTypes = {
  hideDeclineForm: PropTypes.func.isRequired,
  doDeclineIdea: PropTypes.func.isRequired,
  idea_id: PropTypes.string.isRequired,
  submitted_by: PropTypes.string.isRequired,
  isDeclineFormVisible: PropTypes.bool,
}

export default DeclineIdeaForm
