import React from 'react'
import styled from 'styled-components'
import { ErrorCircle } from '@jsluna/icons'
import { Text, Heading3 } from '@jsluna/typography'
import theme from '../../styles/theme'

interface ErrorMessageProps {
  icon?: boolean | null
  title?: string
  errorMessage: string | null
  children?: any
}
const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1047px;
  padding-top: 1rem;
`
const ErrorMessageRow = styled.div`
  align-content: center;
  display: flex;
  flex: 1;
  flex-basis: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1.6rem;
`
const ErrorMessageContent = styled.div`
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
`
const ErrorMessageHeader = styled(Heading3)`
  padding: 0 1rem;
`
const ErrorMessageIcon = styled(ErrorCircle)`
  height: 2rem;
  width: 2rem;
  @media (max-width: ${theme.mq.xs}) {
    margin-bottom: 0.6rem;
  }
`
const ErrorMessageTemplate = (props: ErrorMessageProps) => {
  return (
    <ErrorMessageWrapper>
      <ErrorMessageRow>
        <ErrorMessageContent>
          {props.icon && <ErrorMessageIcon className="ln-u-color-orange" />}
          <ErrorMessageHeader element="div" className="ln-u-color-orange">
            {props.title ? props.title : 'Error'}
          </ErrorMessageHeader>
        </ErrorMessageContent>
      </ErrorMessageRow>
      <ErrorMessageRow>
        <ErrorMessageContent>
          {
            <Text typeStyle="body-2">
              {props.children ? props.children : props.errorMessage}
            </Text>
          }
        </ErrorMessageContent>
      </ErrorMessageRow>
    </ErrorMessageWrapper>
  )
}

const ErrorMessage = (props: ErrorMessageProps) => {
  // Page not found error
  if (props.errorMessage === 'not_found') {
    return (
      <ErrorMessageTemplate
        icon={true}
        title="Page not found"
        errorMessage="You just hit a route that doesn't exist"
      />
    )
  }
  // Other errors
  return (
    <ErrorMessageTemplate
      icon={props.icon}
      title={props.title}
      errorMessage={props.errorMessage}
    >
      {props.children}
    </ErrorMessageTemplate>
  )
}

export default ErrorMessage
