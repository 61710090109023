import { actionTypes } from '../actions/userIdeas'
import { updateSortByAndDirection } from './ideas'

const {
  GET_USER_IDEAS,
  GET_USER_IDEAS_SUCCESS,
  GET_USER_IDEAS_FAILURE,
  UPDATE_USER_SORT_BY,
} = actionTypes

const initialState = {
  ideas: [],
  loading: false,
  errorMessage: null,
  sort: {
    by: 'created_date',
    direction: 'desc',
  },
}

const userIdeasReducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case GET_USER_IDEAS:
      return {
        ...state,
        loading: true,
      }
    case GET_USER_IDEAS_SUCCESS:
      return {
        ...state,
        ideas: payload.ideas,
        loading: false,
      }
    case GET_USER_IDEAS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
      }
    case UPDATE_USER_SORT_BY:
      return {
        ...state,
        sort: updateSortByAndDirection(state, payload.by),
      }
    default:
      return state
  }
}

export { initialState, userIdeasReducer }
