import {
  OpportunityActionTypes,
  ActionTypes,
} from '../actions/opportunitiesList'
import { normalizeOpportunities } from '../../util/opportunitiesUtils'

const initialState = {
  distance: null,
  searchTerm: '',
  isSearching: false,
  errorMessage: null,
  opportunities: [],
  opportunitiesById: {},
  paginateRecordCount: 6,
  teamSize: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // TODO: take out of redux? - Distance,TeamSize,SearchTerm
    case OpportunityActionTypes.SET_DISTANCE:
      return {
        ...state,
        distance: action.payload.distance,
      }
    case OpportunityActionTypes.SET_TEAM_SIZE:
      return {
        ...state,
        teamSize: action.payload.teamSize,
      }
    case OpportunityActionTypes.SET_OPPORTUNITY_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload.searchTerm,
      }

    case OpportunityActionTypes.SEARCH_OPPORTUNITIES:
      return {
        ...state,
        isSearching: true,
        errorMessage: null,
      }
    case OpportunityActionTypes.SEARCH_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        opportunities: action.payload.results,
        opportunitiesById: normalizeOpportunities(action.payload.results),
        isSearching: false,
      }
    case OpportunityActionTypes.SEARCH_OPPORTUNITIES_FAILURE:
      return {
        ...state,
        isSearching: false,
        errorMessage: action.payload.errorMessage,
      }
    case ActionTypes.SHOW_MORE_RECORDS:
      return {
        ...state,
        paginateRecordCount: state.paginateRecordCount + action.payload.number,
      }
    case OpportunityActionTypes.DELETE_SEARCH_STATE:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export { initialState, reducer }
