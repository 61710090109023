import React from 'react'
import { Container } from '../../styles'
import {
  Footer as FooterContainer,
  Wrapper,
  SubWrapper,
  List,
  ListItem,
  ListLink,
  ListLinkInternal,
  P,
} from './styles'
import footer from '../../data/footer'
import logo from '../../assets/sainsburys-150.svg'
import { WithChildren } from '../../types'

const Footer = () => {
  const { links } = footer
  return (
    <FooterLanding>
      <List data-id="footer-links">
        {links.map(link => (
          <ListItem key={link.id}>
            {link.internal ? (
              <ListLinkInternal
                to={link.href}
                data-id={link.dataId ? link.dataId : ''}
              >
                {link.title}
              </ListLinkInternal>
            ) : (
              <ListLink href={link.href}>{link.title}</ListLink>
            )}
          </ListItem>
        ))}
      </List>
    </FooterLanding>
  )
}

const FooterLanding = ({ children }: WithChildren) => {
  const { disclaimer, copyright } = footer
  return (
    <FooterContainer>
      <Wrapper>
        <Container.FalseBleed>
          {children ? children : null}
          <P>{disclaimer}</P>
          <P>{copyright}</P>
        </Container.FalseBleed>
      </Wrapper>
      <SubWrapper>
        <img src={logo} alt="150 Years Logo" />
      </SubWrapper>
    </FooterContainer>
  )
}

export { FooterLanding }

export default Footer
