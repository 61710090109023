import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: ${({ nomargin }) => (nomargin ? 0 : '0 0 20px 0')};
  background: ${({ theme, grey, white }) =>
    grey ? theme.color.lighterGrey : white ? theme.color.white : 'transparent'};
  padding: ${({ padded }) => (padded ? '20px' : '0 20px 26px 0')};
  position: relative;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    width: 48%;
  }
  @media (max-width: ${({ theme }) => theme.mq.sm}) {
    padding-bottom: 40px;
  }
`

const OneColumnWrapper = styled.div`
  margin: ${({ nomargin }) => (nomargin ? 0 : '0 0 20px 0')};
  background: ${({ theme, grey, white }) =>
    grey ? theme.color.lighterGrey : white ? theme.color.white : 'transparent'};
  padding: ${({ padded }) => (padded ? '20px' : '0 20px 26px 0')};
  position: relative;
  width: 100%;
`

const Panel = styled.div`
  background-color: ${props => props.theme.color.lighterGrey};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    flex-direction: row;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Column = styled.div`
  padding: 20px;
  min-width: auto;
`

const MainColumn = styled(Column)`
  flex: 2 1 0px;
  width: 100%;
`

const SecondaryColumn = styled(Column)`
  flex: 1 1 0px;
  width: 100%;
`

const Header = styled.h3`
  margin: 0 0 10px;
  font-size: 32px;
  width: 85%;
`

const HeaderLink = styled(Link)`
  text-decoration: none;
`

const HeaderContainer = styled.div`
  width: 100%;
`

const TitleOrg = styled.h1`
  font-size: 40px;
  color: ${({ theme, accented }) =>
    accented ? theme.color.orange : theme.color.darkGrey};
  margin-bottom: 10px;
`

const Meta = styled.div`
  margin: 0 0 20px;

  &:empty {
    margin: 0;
  }
`

const MetaItem = styled.p`
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  &:not(:last-child) {
    margin-bottom: ${({ row }) => (row ? 0 : '10px')};
  }
`

const MetaItemLabel = styled.span`
  margin-right: 6px;
`

const MetaItemValue = styled.span`
  font-weight: bold;
`

const MetaItemHighlight = styled.span`
  color: ${({ theme }) => theme.color.orange};
  font-weight: bold;
`

const Distance = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.color.orange};
  position: absolute;
  top: 20px;
  right: 20px;
`

const ContactInfo = styled.span`
  text-transform: none;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`

const Description = styled.p`
  margin: 20px 0;
  font-size: ${({ big }) => (big ? '22px' : '16px')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`

const Logo = styled.img`
  max-width: 100px;
  padding: 0 20px 20px 0;
  width: 100%;
`

const Accent = styled.span`
  color: ${({ theme }) => theme.color.orange};
`

const ListHeading = styled(Description)`
  font-family: ${({ theme }) => theme.fontFamily};
`

const TeamIcon = styled.img`
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.color.lightestGrey};
  &:not(:last-child) {
    margin-right: 10px;
  }
`

export {
  Wrapper,
  Panel,
  Flex,
  MainColumn,
  SecondaryColumn,
  Header,
  HeaderContainer,
  TitleOrg,
  HeaderLink,
  Meta,
  MetaItem,
  MetaItemLabel,
  MetaItemValue,
  MetaItemHighlight,
  ContactInfo,
  Description,
  Logo,
  Distance,
  Accent,
  ListHeading,
  TeamIcon,
  OneColumnWrapper,
}
