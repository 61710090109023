import React, { useEffect } from 'react'
import { Container, Content } from '../../styles'
import routes from '../../routes'
import { PageHeader } from '../PageHeader'
import ImageBackground from '../ImageBackground/ImageBackground'
import { Card, LoadingIndicator } from 'luna-react'
import SideCardAsd from '../SideCard/SideCard'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import styled from 'styled-components'
import { is150DaysSection } from '../../features'

const Wrapper = styled.div`
  padding: 300px 50px;
`

const About = ({ about, loading, errorMessage, doFetchAbout }) => {
  useEffect(() => {
    doFetchAbout()
  }, [doFetchAbout])

  if (loading) {
    return (
      <Wrapper>
        <Container.Padded style={{ overflow: 'auto' }}>
          <LoadingIndicator
            style={{ color: 'black', width: '300px', margin: '0 auto' }}
            message="Fetching content..."
          />
        </Container.Padded>
      </Wrapper>
    )
  }

  if (errorMessage) {
    return <ErrorMessage errorMessage={errorMessage} />
  }

  if (about) {
    return (
      <>
        <PageHeader title={about.title} blurb={about.subtitle} />
        <ImageBackground
          padding={{ md: '140px' }}
          image={about.imgBg.fields.file.url}
        >
          <Container.Padded style={{ overflow: 'auto' }}>
            <Card>{documentToReactComponents(about.portalText)[0]}</Card>
          </Container.Padded>
        </ImageBackground>
        <Content>
          {is150DaysSection() && (
            <Container.FullBleed data-id="about-150-days-wrapper">
              <SideCardAsd
                img={`${about.img150.fields.file.url}?w=1046`}
                title={about.title150}
                description={about.text150}
                button={about.button150}
                link={routes.about150}
              />
            </Container.FullBleed>
          )}
          <Container.FullBleed data-id="about-local-heroes-wrapper">
            <SideCardAsd
              reverse
              img={`${about.imgLocalHeroes.fields.file.url}?w=1046`}
              title={about.titleLocalHeroes}
              description={about.textLocalHeroes}
              button={about.buttonLocalHeroes}
              link={routes.aboutLocalHeroes}
            />
          </Container.FullBleed>
        </Content>
      </>
    )
  }

  return null
}

export { About }
