import { connect } from 'react-redux'

import OppsMap from './OppsMap'
import { getAllResults } from '../../redux/selectors/opportunitiesSearch'

const mapStateToProps = state => {
  return {
    results: getAllResults(state),
  }
}

const OppsMapContainer = connect(mapStateToProps)(OppsMap)

export default OppsMapContainer
