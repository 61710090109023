export const actionTypes = {
  GET_CONTENT_FAQS: 'GET_CONTENT_FAQS',
  GET_CONTENT_FAQS_SUCCESS: 'GET_CONTENT_FAQS_SUCCESS',
  GET_CONTENT_FAQS_FAILURE: 'GET_CONTENT_FAQS_FAILURE',
}

const {
  GET_CONTENT_FAQS,
  GET_CONTENT_FAQS_SUCCESS,
  GET_CONTENT_FAQS_FAILURE,
} = actionTypes

export const getContentFaqs = () => ({
  type: GET_CONTENT_FAQS,
})

export const getContentFaqsSuccess = faqs => ({
  type: GET_CONTENT_FAQS_SUCCESS,
  payload: { faqs },
})

export const getContentFaqsFailure = errorMessage => ({
  type: GET_CONTENT_FAQS_FAILURE,
  payload: {
    errorMessage,
  },
})
