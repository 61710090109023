export const actionTypes = {
  GET_USER_IDEAS: 'GET_USER_IDEAS',
  GET_USER_IDEAS_SUCCESS: 'GET_USER_IDEAS_SUCCESS',
  GET_USER_IDEAS_FAILURE: 'GET_USER_IDEAS_FAILURE',
  UPDATE_USER_SORT_BY: 'UPDATE_USER_SORT_BY',
}

const {
  GET_USER_IDEAS,
  GET_USER_IDEAS_SUCCESS,
  GET_USER_IDEAS_FAILURE,
  UPDATE_USER_SORT_BY,
} = actionTypes

export const getUserIdeas = () => ({
  type: GET_USER_IDEAS,
})

export const getUserIdeasSuccess = ideas => ({
  type: GET_USER_IDEAS_SUCCESS,
  payload: {
    ideas,
  },
})

export const getUserIdeasFailure = errorMessage => ({
  type: GET_USER_IDEAS_FAILURE,
  payload: {
    errorMessage,
  },
})

export const updateUserSortBy = by => ({
  type: UPDATE_USER_SORT_BY,
  payload: {
    by,
  },
})
