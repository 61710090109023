import React, { useEffect } from 'react'
import { Dispatch, AnyAction } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { State } from '../../redux/store'
import AdminIdeaDetailHero from './AdminIdeaDetailHero.component'
import { fetchLocalHerosDetailActionCreator } from '../../redux/actions/adminIdea'
import { selectAdminIdeaSource } from '../../redux/selectors/adminIdea'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import {
  selectData,
  selectDataErrorMessage,
  selectDataIsError,
  selectDataIsLoading,
} from '../../redux/selectors/dataStore'
import { LoadingIndicator } from 'luna-react'
import routes from '../../routes'
import AdminIdeaLayout from '../../pages/AdminIdea'
import { AdminIdeaDataHeroesType } from '../../types'

export interface AdminIdeaDetailHeroWrapperProps {
  fetchIdeaData: (ideaId: string) => void
  ideaId: string
  data: AdminIdeaDataHeroesType
  error: boolean
  errorMessage: string
  loading: boolean
}

const AdminIdeaDetailHeroWrapper: React.FC<AdminIdeaDetailHeroWrapperProps> = ({
  fetchIdeaData,
  ideaId,
  data,
  error,
  errorMessage,
  loading,
}) => {
  useEffect(() => {
    fetchIdeaData(ideaId)
  }, [fetchIdeaData, ideaId])

  return (
    <AdminIdeaLayout
      route={routes.adminLocalHeroes}
      refVisible={!loading}
      isAdmin
    >
      {loading && <LoadingIndicator message="Loading idea…" />}
      {error && <ErrorMessage errorMessage={errorMessage} />}
      {!error && !loading && data && <AdminIdeaDetailHero data={data} />}
    </AdminIdeaLayout>
  )
}

const mapStateToProps = (state: State, ownProps: any): any => {
  return {
    ideaId: ownProps.match.params.idea_id,
    data: selectData(state, selectAdminIdeaSource(state)),
    loading: selectDataIsLoading(state),
    error: selectDataIsError(state),
    errorMessage: selectDataErrorMessage(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction, State>) => {
  return {
    fetchIdeaData: (ideaId: string) =>
      dispatch(fetchLocalHerosDetailActionCreator(ideaId)),
  }
}

export const AdminIdeaDetailHeroContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminIdeaDetailHeroWrapper)
)

export default AdminIdeaDetailHeroWrapper
