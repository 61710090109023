import { createSelector } from 'reselect'

export const getEditIdeaState = state => state.editIdeaReducer

export const getErrorMessage = createSelector(
  [getEditIdeaState],
  ({ errorMessage }) => errorMessage
)

export const getLoading = createSelector(
  [getEditIdeaState],
  ({ loading }) => loading
)

export const getEditIdeaId = createSelector([getEditIdeaState], ({ id }) => id)
