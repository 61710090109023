import React from 'react'
import routes from '../../routes'
import FormattedDate from '../FormattedDate/FormattedDate'
import { TableCard } from '../../styles'

/** @note 'any' type changed to a structured type when 'myidea' files are
    refactored to typescript **/
export interface IdeasDataRowHeroesProps {
  idea: any
  currentIndex: number
}

const IdeasDataRowHeroes: React.FC<IdeasDataRowHeroesProps> = ({
  idea,
  currentIndex,
}) => {
  const {
    id,
    colleagueName,
    employeeNumber,
    storeName,
    charityCategory,
    createdDate,
    ref,
  } = idea
  return (
    <TableCard.Wrapper
      to={`${routes.adminLocalHeroes}/${id}`}
      data-id={`idea-${currentIndex}`}
      data-ref={`${ref}`}
      data-status={`${id}`}
      status={id}
    >
      <TableCard.Block>
        <TableCard.Legend>Reference</TableCard.Legend>
        <TableCard.Label>{ref}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend> Date Submitted</TableCard.Legend>
        <TableCard.Label>
          <FormattedDate date={createdDate} />
        </TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Colleague Name</TableCard.Legend>
        <TableCard.Label> {colleagueName}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Employee Number</TableCard.Legend>
        <TableCard.Label> {employeeNumber}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Store or team</TableCard.Legend>
        <TableCard.Label>{storeName}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Categories</TableCard.Legend>
        <TableCard.Label> {charityCategory}</TableCard.Label>
      </TableCard.Block>
    </TableCard.Wrapper>
  )
}

export default IdeasDataRowHeroes
