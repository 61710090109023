import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import HeaderContainer from '../../components/Header/HeaderContainer'
import Theme from '../../providers/Theme'
import Footer from '../../components/Footer'
import FeedbackButton from '../../components/Feedback/FeedbackButton'

const Wrapper = styled.main``

const Layout = ({ children, pageId }) => (
  <Theme>
    <HeaderContainer siteTitle="CCH" />
    <Wrapper data-id={pageId}>{children}</Wrapper>
    <FeedbackButton />
    <Footer />
  </Theme>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
