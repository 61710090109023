import React from 'react'
import Helmet from 'react-helmet'

export const DEFAULT_DESCRIPTION = `Our community`
export const DEFAULT_TITLE = `Our Community`

export const isValidTitle = title => title && typeof title === 'string'

const Head = ({ title, description }) => (
  <Helmet
    titleTemplate="%s | Our Community"
    title={isValidTitle(title) ? title : DEFAULT_TITLE}
    meta={[
      {
        name: 'description',
        content: description || DEFAULT_DESCRIPTION,
      },
    ]}
  />
)

export default Head
