import { createSelector } from 'reselect'
import { isFormValid } from '../../util/newIdeaValidations'
import { MY_SAINSBURYS_EMAIL_REGEXP } from '../../constants'

export const currentUser = state => state.auth.user

export const canSubmitNewIdea = createSelector([currentUser], currentUser => {
  if (!currentUser || !currentUser.email) {
    return false
  }
  return !currentUser.email.trim().match(MY_SAINSBURYS_EMAIL_REGEXP)
})

export const getNewIdeaState = state => state.newIdea

export const getFormValues = createSelector(
  [getNewIdeaState],
  newIdea => newIdea.formValues
)

export const getIsValid = createSelector([getFormValues], formValues =>
  isFormValid(formValues)
)

export const getErrorMessage = createSelector(
  [getNewIdeaState],
  newIdea => newIdea.errorMessage
)

export const getRef = createSelector([getNewIdeaState], newIdea => newIdea.ref)

export const getContactEmail = createSelector(
  [getNewIdeaState],
  newIdea => newIdea.contact_email
)

export const getSending = createSelector(
  [getNewIdeaState],
  newIdea => newIdea.sending
)
