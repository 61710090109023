import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { doPostIdea } from '../../redux/dispatchers/editIdea'
import EditIdeaConfirmation from './EditIdeaConfirmation'
import {
  getFormValues,
  getSending,
  getIsValid,
} from '../../redux/selectors/newIdea'

const mapStateToProps = state => {
  return {
    formValues: getFormValues(state),
    sending: getSending(state),
    isValid: getIsValid(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doPostIdea,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditIdeaConfirmation)
