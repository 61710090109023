import { RouteComponentProps } from 'react-router'
import React, { useEffect } from 'react'
import ProgrammesLayout from '../../layouts/ProgrammesLayout/ProgrammesLayout'
import { Container, SelectField, LoadingIndicator } from 'luna-react'
import MediaQuery from 'react-responsive'
import {
  ProgrammesContainer,
  TabsStyled,
  TabsLinkStyled,
  NoProgrammeMessage,
} from './Programmes.styles'
import ProgrammesTabPanel from './ProgramnmesTabPanel.component'
import { ProgrammeType } from '../../redux/actions/programmeList'
import routes from '../../routes'

type ProgrammesTabType = {
  label: string
  value: string
}

interface ProgrammesProps extends RouteComponentProps {
  subHeader: string
  title: string
  tabs: ProgrammesTabType[]
  programmeName: string
  loading: boolean
  error: boolean
  tiles: ProgrammeType[]
  fetchProgrammeHeaderDetail: () => void
  fetchProgrammeTiles: (tab: string) => void
}

const Programmes: React.FC<ProgrammesProps> = ({
  subHeader,
  title,
  tabs,
  programmeName,
  loading,
  error,
  tiles,
  fetchProgrammeHeaderDetail,
  fetchProgrammeTiles,
  history,
}) => {
  const defaultProgramme = tabs[1].value

  useEffect(() => {
    fetchProgrammeHeaderDetail()
  }, [fetchProgrammeHeaderDetail])

  useEffect(() => {
    fetchProgrammeTiles(programmeName)
  }, [fetchProgrammeTiles, programmeName])

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    e.preventDefault()
    const programmeName = !e.currentTarget.value
      ? defaultProgramme
      : e.currentTarget.value
    history.push(`?tab=${programmeName}`)
  }

  const getLinkTo = (title: string) => {
    const encodedTitle = encodeURIComponent(title) || ''
    return `${routes.programmes}/${encodedTitle}`
  }

  return (
    <ProgrammesLayout subHeader={subHeader} title={title}>
      <Container fluid>
        <ProgrammesContainer>
          <MediaQuery query={`(min-width: 480px)`}>
            <TabsStyled deep element="div">
              {tabs.map((tab, index) => (
                <TabsLinkStyled
                  key={tab.value}
                  isActive={() => programmeName === tab.value}
                  activeClassName="is-active"
                  to={`${routes.programmes}?tab=${tab.value}`}
                  data-id={`tab-${index}`}
                >
                  {tab.label}
                </TabsLinkStyled>
              ))}
            </TabsStyled>
          </MediaQuery>
          <MediaQuery query={`(max-width: 480px)`}>
            <SelectField
              name="tab-data-select"
              options={tabs}
              onChange={(e: React.FormEvent<HTMLSelectElement>) =>
                handleChange(e)
              }
              defaultValue={programmeName}
              // value={programmeName}
            />
          </MediaQuery>
          {((!loading && !tiles) || error) && (
            <NoProgrammeMessage>No programmes found</NoProgrammeMessage>
          )}
          {loading && !error && (
            <LoadingIndicator
              style={{ color: 'black', width: '300px', margin: '100px auto' }}
              message={`Loading ${programmeName} programmes….`}
            />
          )}
          {!loading && !error && (
            <ProgrammesTabPanel tiles={tiles} getLinkTo={getLinkTo} />
          )}
        </ProgrammesContainer>
      </Container>
    </ProgrammesLayout>
  )
}

export default Programmes
