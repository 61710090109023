import { AnyAction } from 'redux'
import { AdminIdeasState } from '../store'
import { PaginationActionTypes } from '../../types'
import { FilterActionTypes } from '../../redux/actions/filters'

const filtersInitialState = {
  filters: {
    search: {},
    values: {},
    type: '',
  },
}

const adminIdeasInitialState = {
  data: [],
  source: '',
  ...filtersInitialState,
}

const adminIdeasReducer = (
  state: AdminIdeasState = adminIdeasInitialState,
  action: AnyAction
): AdminIdeasState => {
  const { type, payload } = action as AnyAction
  switch (type) {
    case PaginationActionTypes.PAGINATION_SET_PAGE_DATA:
      return {
        ...state,
        data: payload.data,
      }
    case PaginationActionTypes.PAGINATION_SET_SOURCE:
      return {
        ...state,
        source: payload.source,
      }
    case PaginationActionTypes.PAGINATION_CHANGE_PAGE:
      return {
        ...state,
        controls: {
          ...state.controls,
          pageActive: payload.pageActive,
          pageLast: payload.pageLast,
        },
      }
    case FilterActionTypes.SEARCH_TYPE_CHANGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: {},
          type: payload.searchType,
        },
      }
    case FilterActionTypes.SEARCH_UPDATE_FIELD:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: {
            [payload.key]: payload.value,
          },
        },
      }
    case FilterActionTypes.FILTER_UPDATE_DATE_RANGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          values: {
            ...state.filters.values,
            created_date: payload,
          },
        },
      }
    case FilterActionTypes.FILTER_UPDATE_FIELD:
      const stateFilters = state.filters
      return {
        ...state,
        filters: {
          ...stateFilters,
          values: {
            ...stateFilters.values,
            [payload.key]: {
              ...stateFilters.values[payload.key],
              [payload.value]: payload.selected,
            },
          },
        },
      }
    case FilterActionTypes.FILTERS_RESET:
      return {
        ...state,
        ...filtersInitialState,
      }
    default:
      return state
  }
}

export { adminIdeasInitialState, adminIdeasReducer }
