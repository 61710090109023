import { Dispatch, AnyAction } from 'redux'
import { State } from '../store'
import routes from '../../routes'
import { extractFields, isFilterDisabled } from '../../util/filtersUtils'
import { stringify } from 'querystring'
import { history } from '../../createHistory'

export type FiltersType = {
  search: object
  values: object
  type: string
}

export type FilterValuesType = {
  value: string
  label: string
}

export type SearchOptionType = {
  label: string
  value: string
  placeholder: string
  enableSearchOnly?: boolean
}

export enum FilterActionTypes {
  SEARCH_UPDATE_FIELD = 'SEARCH_UPDATE_FIELD',
  SEARCH_TYPE_CHANGE = 'SEARCH_TYPE_CHANGE',
  FILTER_UPDATE_FIELD = 'FILTER_UPDATE_FIELD',
  FILTER_UPDATE_DATE_RANGE = 'FILTER_UPDATE_DATE_RANGE',
  FILTERS_RESET = 'FILTERS_RESET',
}

const applyFiltersActionCreator = (currentPath: string) => {
  return async (
    dispatch: Dispatch<AnyAction, State>,
    getState: () => State
  ) => {
    const filterState = getState().adminIdeas.filters
    const params = filterState.values ? extractFields(filterState) : ''
    const routeKey = Object.keys(routes).find(
      key => routes[key] === currentPath
    )
    const route = routeKey ? routes[routeKey] : routes.adminIdeas
    history.push(`${route}?${stringify(params)}`)
  }
}

const changeSearchTypeAction = (
  searchOptions: SearchOptionType[],
  searchType: string
) => (dispatch: Dispatch<AnyAction, State>) => {
  if (isFilterDisabled(searchOptions, searchType)) {
    dispatch({
      type: FilterActionTypes.FILTERS_RESET,
    })
  }
  dispatch({
    type: FilterActionTypes.SEARCH_TYPE_CHANGE,
    payload: { searchType },
  })
}

const updateDateRange = (value: object) => ({
  type: FilterActionTypes.FILTER_UPDATE_DATE_RANGE,
  payload: value,
})

const updateFilters = (key: string, value: string, selected: boolean) => {
  return {
    type: FilterActionTypes.FILTER_UPDATE_FIELD,
    payload: { key, value, selected },
  }
}

const updateSearchAction = (key: string, value: string) => ({
  type: FilterActionTypes.SEARCH_UPDATE_FIELD,
  payload: { key, value },
})

const resetFilters = () => ({
  type: FilterActionTypes.FILTERS_RESET,
})

export {
  applyFiltersActionCreator,
  changeSearchTypeAction,
  updateSearchAction,
  updateFilters,
  updateDateRange,
  resetFilters,
}
