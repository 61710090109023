import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    flex-direction: row;
  }
  margin-bottom: 70px;
`

export const Block = styled.div`
  background: ${({ theme }) => theme.color.lighterGrey};
  padding: 46px 39px;
  flex: 1;
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  color: ${props =>
    props.selected
      ? ({ theme }) => theme.color.orange
      : ({ theme }) => theme.color.black};
  border-bottom: 4px solid
    ${props =>
      props.selected ? ({ theme }) => theme.color.orange : 'transparent'};
`

export const StyledLink = styled(Link)`
  line-height: 1.23;
  font-family: MaryAnn;
  font-size: 26px;
  font-weight: 800;
  text-decoration: none;
`

export const Divider = styled.div`
  margin: 20px;
  width: 100%;
  max-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    min-width: auto;
  }
`

export const InnerDivider = styled.span`
  font-family: MaryAnn, 'Trebuchet MS', sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  position: relative;
  padding: 10px;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: ${({ theme }) => theme.color.lightGrey};
    width: 60%;
    height: 1px;
    top: 50%;
    @media (min-width: ${({ theme }) => theme.mq.sm}) {
      top: auto;
      width: 1px;
      height: 60%;
      left: 50%;
    }
  }
  &:before {
    right: 100%;
    @media (min-width: ${({ theme }) => theme.mq.sm}) {
      right: auto;
      bottom: 100%;
    }
  }
  &:after {
    left: 100%;
    @media (min-width: ${({ theme }) => theme.mq.sm}) {
      left: 50%;
      top: 100%;
    }
  }
`

export const TextInfoWrapper = styled.div`
  text-align: center;
`

export const TextInfoHeader = styled.h3`
  color: ${({ theme }) => theme.color.orange};
  font-size: 1.6rem;
`
