import { createSelector } from 'reselect'
import { MY_SAINSBURYS_EMAIL_REGEXP } from '../../constants'
import { State } from '../store'

export const selectForms = (state: State) => state.forms

export const selectForm = (formName: string) => {
  return createSelector(selectForms, forms => forms[formName])
}

export const selectFormPath = (formName: string) => {
  return createSelector(selectForm(formName), form => form.path)
}

export const selectFormEndpoint = (formName: string) => {
  return createSelector(selectForm(formName), form => form.endpoint)
}

export const selectFormSections = (formName: string) => {
  return createSelector(selectForm(formName), form => form.fields)
}

export const selectFormFields = (formName: string) => {
  return createSelector(selectFormSections(formName), sections => {
    const fields = {}

    sections.forEach((section: any) => {
      section.items.forEach((item: any) => {
        fields[item.name] = item
      })
    })

    return fields
  })
}

export const selectFormField = (formName: string, key: string) => {
  return createSelector(
    selectFormFields(formName),
    fields => fields[key] || null
  )
}

export const selectFormValidations = (formName: string) => {
  return createSelector(selectFormFields(formName), fields => {
    const validations = {}

    Object.keys(fields).forEach((key: string) => {
      validations[key] = fields[key].validation
    })

    return validations
  })
}

export const selectFormValues = (formName: string) => {
  return createSelector(selectForm(formName), form => form.formValues)
}

export const selectFormFieldValue = (formName: string, key: string) => {
  return createSelector(selectFormValues(formName), values => values[key])
}

export const selectFormFieldIsDirty = (formName: string, key: string) => {
  return createSelector(selectFormValues(formName), values =>
    values.hasOwnProperty(key)
  )
}

export const selectFormErrors = (formName: string) => {
  return createSelector(selectForm(formName), form => form.formErrors)
}

export const selectFormErrorMessage = (formName: string) => {
  return createSelector(selectForm(formName), form => form.errorMessage)
}

export const selectFormAuthorEmail = (formName: string) => {
  return createSelector(selectForm(formName), form => form.author_email)
}

export const selectFormContactEmail = (formName: string) => {
  return createSelector(
    [
      selectFormAuthorEmail(formName),
      selectFormFieldValue(formName, 'alternate_email'),
    ],
    (author_email, alternate_email) =>
      alternate_email ? alternate_email : author_email
  )
}

export const selectFormRef = (formName: string) => {
  return createSelector(selectForm(formName), form => form.ref)
}

export const selectFormIsSending = (formName: string) => {
  return createSelector(selectForm(formName), form => form.sending)
}

export const selectFormIsLoading = (formName: string) => {
  return createSelector(selectForm(formName), form => form.loading)
}

export const selectFormFieldErrorMessage = (formName: string, key: string) => {
  return createSelector(
    [selectFormFieldIsDirty(formName, key), selectFormErrors(formName)],
    (isDirty, formErrors) => {
      let errorMessage = null

      if (isDirty && formErrors && formErrors.inner) {
        formErrors.inner.forEach((error: any) => {
          if (error.path === key) {
            errorMessage = error.message
          }
        })
      }

      return errorMessage
    }
  )
}

export const selectFormFieldState = (formName: string, key: string) => {
  return createSelector(
    [
      selectFormField(formName, key),
      selectFormFieldErrorMessage(formName, key),
      selectFormValues(formName),
    ],
    (field, error, values) => {
      const value = !values[key] && field.type === 'text' ? '' : values[key]

      let visible = true
      if (field.visibility) {
        Object.keys(field.visibility).forEach((k: string) => {
          visible = values[k] && values[k].includes(field.visibility[k])
        })
      }

      return {
        visible,
        error,
        value,
      }
    }
  )
}

export const selectFormIsValid = (formName: string) => {
  return createSelector(selectForm(formName), form => form.isValid)
}

export const selectCurrentUser = (state: State) => state.auth.user

export const selectIsAuthorized = createSelector(selectCurrentUser, user => {
  if (!user || !user['email']) {
    return false
  }

  return !user['email'].trim().match(MY_SAINSBURYS_EMAIL_REGEXP)
})
