import { AnyAction } from 'redux'
import { ProgrammesState } from '../store'
import { ProgrammesActionTypes } from '../actions/programmeList'

const programmesInitialState = {
  title: '',
  subHeader: '',
}

const programmesReducer = (
  state: ProgrammesState = programmesInitialState,
  action: AnyAction
): ProgrammesState => {
  const { type, payload } = action as AnyAction
  switch (type) {
    case ProgrammesActionTypes.PROGRAMME_HEADER_DETAIL_SET: {
      return {
        ...state,
        title: payload.title,
        subHeader: payload.subHeader,
      }
    }
    case ProgrammesActionTypes.PROGRAMME_TILES_SET: {
      return {
        ...state,
        tiles: payload.tiles,
      }
    }
    default:
      return state
  }
}

export { programmesInitialState, programmesReducer }
