import styled from 'styled-components'
import { Callout } from '../styles'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.lighterGrey};
  text-align: left;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
  }
  margin: 20px 0 40px;
  ${Callout.Wrapper} {
    align-items: flex-start;
  }
`

const ImageWrapper = styled.div`
  line-height: 0;
  width: 100%;
  background: transparent url(${props => props.src}) center center/cover
    no-repeat;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    width: 70%;
    border-left: 10px solid #fff;
  }
  & > div {
    width: 100%;
    padding-bottom: 100%;
  }
`

const TextWrapper = styled.div`
  text-align: left;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    width: 50%;
    border-right: 10px solid #fff;
  }
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    padding: 40px 15px;
  }

  @media (min-width: ${({ theme }) => theme.mq.md}) {
    padding: 40px;
  }
`
const Header = styled.h5`
  margin-bottom: 10px;
  color: ${props => props.theme.color.orange};
  font-size: 22px;
`

export { Wrapper, ImageWrapper, TextWrapper, Header }
