import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Dispatch, AnyAction } from 'redux'
import Filters from './Filters.component'
import { State } from '../../redux/store'
import { selectFilters } from '../../redux/selectors/filters'
import { applyFiltersActionCreator } from '../../redux/actions/filters'

const mapStateToProps = (state: State) => {
  return {
    fields: selectFilters(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction, State>) => {
  return {
    applyFilters: (pathname: string) => {
      dispatch(applyFiltersActionCreator(pathname))
    },
  }
}

const FiltersContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Filters)
)

export default FiltersContainer
