import React from 'react'
import Layout from '../../layouts/Layout/Layout'
import { WithChildren } from '../../types'
import { BackHeader } from '../../components/PageHeader'
import routes from '../../routes'

interface ProgrammeLayoutProps {
  children: WithChildren
}

const ProgrammeLayout: React.FC<ProgrammeLayoutProps> = ({ children }) => {
  return (
    <Layout pageId="page-programmes">
      <BackHeader
        title=""
        textTo="Programmes"
        to={`${routes.programmes}/?tab=all`}
      />
      {children}
    </Layout>
  )
}

export default ProgrammeLayout
