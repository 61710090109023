import styled from 'styled-components'
import { SelectField, TextInput } from 'luna-react'
import filterImage from '../../assets/filter.svg'

export const SearchLabel = styled.h5`
  flex: 1 1 100%;
  margin-bottom: 10px;
  font-size: 18px;
`
SearchLabel.displayName = 'SearchLabel'

export const SearchSelect = styled(SelectField)`
  flex: 0 0 250px;
  margin: 0 10px 0 auto;
`
SearchSelect.displayName = 'SearchSelect'

export const SearchTextInput = styled(TextInput)`
  flex: 1 0 300px;
  margin: 0 auto 0 10px;
`
SearchTextInput.displayName = 'SearchTextInput'

export const SearchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
SearchWrapper.displayName = 'SearchWrapper'

export const SearchPanelWrapper = styled.div<{ isOpen: boolean }>`
  padding-bottom: 30px;
  height: auto;
  display: ${props => (props.isOpen ? 'none' : 'flex')};
`
SearchPanelWrapper.displayName = 'SearchPanelWrapper'

export const FilterWrapper = styled.div`
  flex: 0 1 26%;
  line-height: 38px;
`
FilterWrapper.displayName = 'FilterWrapper'

export const DateRangeFilterWrapper = styled.div`
  flex: 1 1 40%;
  height: 400px;
  .DateRangePickerInput {
    background-color: rgba(255, 255, 255, 0);
  }
  .DateInput {
    width: 160px;
    margin: 0 5px;
  }

  .DateRangePickerInput_clearDates {
    right: auto;
  }
`
DateRangeFilterWrapper.displayName = 'DateRangeFilterWrapper'

export const IdeasFilterWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`
IdeasFilterWrapper.displayName = 'IdeasFilterWrapper'

export const FilterHeader = styled.h6`
  font-size: 18px;
  margin-bottom: 0;
`
FilterHeader.displayName = 'FilterHeader'

export const FormControl = styled.form`
  width: 100%;
`
FormControl.displayName = 'FormControl'

export const Filter = styled.div`
  padding: 0 5px;
`
Filter.displayName = 'Filter'

export const Wrapper = styled.div``
Wrapper.displayName = 'Wrapper'

export const FilterButton = styled.div`
  cursor: pointer;
  padding-left: 30px;
  background: transparent url(${filterImage}) left center/20px no-repeat;
`
FilterButton.displayName = 'FilterButton'
