import { Dispatch, AnyAction } from 'redux'
import { object } from 'yup'
import { api, post, get, patch } from '../../httpClient/httpClient'
import { getAuthToken } from '../selectors/auth'
import errorHandler from './errorHandler'
import { history } from '../../createHistory'
import { State } from '../store'
import { ideaFormRoutes } from '../../data/ideaFormRoutes'
import {
  formValuesUpdate,
  formGetRequest,
  formGetSuccess,
  formGetFailure,
  formPostRequest,
  formPostSuccess,
  formPostFailure,
} from '../actions/ideaForm'
import {
  selectFormEndpoint,
  selectFormValues,
  selectFormPath,
  selectFormValidations,
} from '../selectors/ideaForm'
import { FormValue } from '../../types'

export function doFormUpdate(formName: string, key: string, value: FormValue) {
  return (dispatch: Dispatch<AnyAction, State>, getState: () => State) => {
    const state: State = getState()
    const values = selectFormValues(formName)(state)
    const validations = selectFormValidations(formName)(state)

    object()
      .shape(validations)
      .validate(
        {
          ...values,
          [key]: value,
        },
        { abortEarly: false }
      )
      .then(data => {
        dispatch(formValuesUpdate(formName, key, value, null))
      })
      .catch(errors => {
        dispatch(formValuesUpdate(formName, key, value, errors))
      })
  }
}

export function doFormConfirm(formName: string) {
  return (dispatch: Dispatch<AnyAction, State>, getState: () => State) => {
    const path = selectFormPath(formName)(getState())
    history.push(`${path}${ideaFormRoutes.confirm}`)
  }
}

export function doFormPost(formName: string) {
  return async (
    dispatch: Dispatch<AnyAction, State>,
    getState: () => State
  ) => {
    dispatch(formPostRequest(formName))
    try {
      const state = getState()
      const token = getAuthToken(state)
      const endpoint = selectFormEndpoint(formName)(state)
      const formValues = selectFormValues(formName)(state)
      const path = selectFormPath(formName)(state)
      const data = await post(api(endpoint), token, formValues)
      const { author_email, ref } = data
      dispatch(formPostSuccess(formName, { author_email, ref }))
      history.replace(`${path}${ideaFormRoutes.success}`)
    } catch (error) {
      errorHandler(dispatch, error, onFormPostFailure, formName)
    }
  }
}

export function doFormEdit(formName: string, id: string) {
  return async (
    dispatch: Dispatch<AnyAction, State>,
    getState: () => State
  ) => {
    dispatch(formPostRequest(formName))
    try {
      const state = getState()
      const token = getAuthToken(state)
      const endpoint = selectFormEndpoint(formName)(state)
      const formValues = selectFormValues(formName)(state)
      const path = selectFormPath(formName)(state)
      await patch(api(`${endpoint}/${id}`), token, formValues)
      history.replace(`${path}${ideaFormRoutes.success}`)
    } catch (error) {
      errorHandler(dispatch, error, onFormPostFailure, formName)
    }
  }
}

export function onFormPostFailure(errorMessage: string, formName: string) {
  return (dispatch: Dispatch<AnyAction, State>, getState: () => State) => {
    const path = selectFormPath(formName)(getState())
    dispatch(formPostFailure(formName, errorMessage))
    history.push(`${path}${ideaFormRoutes.error}`)
  }
}

export function doFetchFormValues(formName: string, id: string) {
  return async (
    dispatch: Dispatch<AnyAction, State>,
    getState: () => State
  ) => {
    dispatch(formGetRequest(formName))
    try {
      const state = getState()
      const token = getAuthToken(state)
      const endpoint = selectFormEndpoint(formName)(state)
      const data = await get(api(`${endpoint}/${id}`), token)

      dispatch(formGetSuccess(formName, data))
    } catch (error) {
      errorHandler(dispatch, error, onFormGetFailure, formName)
    }
  }
}

export function onFormGetFailure(errorMessage: string, formName: string) {
  return (dispatch: Dispatch<AnyAction, State>, getState: () => State) => {
    dispatch(formGetFailure(formName, errorMessage))
  }
}
