import styled from 'styled-components'

const getPadding = (padding, breakpoint) => {
  if (typeof padding === 'object' && padding !== null) {
    return padding[breakpoint]
  }
  return padding
}

const Container = styled.div`
  background: ${props =>
    props.white ? props.theme.color.white : 'transparent'};
  margin: 0 auto;
  max-width: 1260px;
`

const Wrapper = styled(Container)``

const Padded = styled(Container)`
  width: 100%;
  padding: 0 15px;

  @media (min-width: ${({ theme }) => theme.mq.md}) {
    padding: 0 ${({ padding }) => (padding ? getPadding(padding, 'md') : '')};
    max-width: 1200px;
  }
`

const FullBleed = styled(Container)`
  width: 100%;
`

const FalseBleed = styled(Container)`
  width: 100%;
  padding: 0 15px;

  @media (min-width: ${({ theme }) => theme.mq.md}) {
    padding: ${({ padded }) => (padded ? '0 20px' : '0')};
  }
`

export { Padded, FullBleed, FalseBleed, Wrapper }
