const distanceOptions = [
  { label: 'Within 2 miles', value: 'a', distance: 2 },
  { label: 'Within 5 miles', value: 'b', distance: 5 },
  { label: 'Within 10 miles', value: 'c', distance: 10 },
  { label: 'Within 15 miles', value: 'd', distance: 15 },
  { label: 'Within 25 miles', value: 'e', distance: 25 },
  { label: 'within 50 miles', value: 'f', distance: 50 },
]

const idea_statuses = Object.freeze({
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  UPDATED: 'UPDATED',
  BOOKMARKED: 'BOOKMARKED',
  COMPLETED: 'COMPLETED',
})

const ideaLabels = Object.freeze({
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
  UPDATED: 'Updated',
  BOOKMARKED: 'Bookmarked',
  COMPLETED: 'Completed',
})

const ROLE = Object.freeze({
  EXTERNAL_ADMIN: 'ROLE_EXTERNAL_ADMIN',
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
})

const DATASTORE_ENDPOINTS = Object.freeze({
  ONEFIFTYDAYS: '/content/about/about150Days',
  STATS: '/content/homepage/stats',
})

const VIEW = Object.freeze({
  MAP: 'MAP_VIEW',
  LIST: 'LIST_VIEW',
})

const ACCESS_TOKEN = 'access_token'

const HERO_FORM = Object.freeze({
  SAINSBURYS:
    'https://jsainsbury.sharepoint.com/sites/OurSainsburys/assets/forms/Lists/DataLocalHeroes/NewForm.aspx',
  ARGOS: '#',
})

const MY_SAINSBURYS_EMAIL_REGEXP = new RegExp('@mysainsburys.co.uk$', 'gi')

const VOLUNTEER_FEEDBACK_LINK = `https://forms.office.com/Pages/ResponsePage.aspx?id=NNYf4bUm9EeLjJCORm6b3zzxk9l2AjxPpjGSz5zqP0ZUMEU3VzE4NDhFRktFUUVXQ05MR1VHMFVLSy4u`
const COORDINATOR_FEEDBACK_LINK = `https://forms.office.com/Pages/ResponsePage.aspx?id=NNYf4bUm9EeLjJCORm6b3zzxk9l2AjxPpjGSz5zqP0ZUMzhPT1pIVFNHR1lKTERWMkFRUjRVTk9DUC4u`

export {
  distanceOptions,
  idea_statuses,
  ideaLabels,
  ROLE,
  ACCESS_TOKEN,
  HERO_FORM,
  VIEW,
  MY_SAINSBURYS_EMAIL_REGEXP,
  VOLUNTEER_FEEDBACK_LINK,
  COORDINATOR_FEEDBACK_LINK,
  DATASTORE_ENDPOINTS,
}
