import React from 'react'
import styled from 'styled-components'

const Center = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 90vh;
  padding: 20px;
  text-align: center;
`

const Sunrise = () => {
  return (
    <Center data-id="page-login">
      <h1>Our Community Hub</h1>
      <h2>Coming soon…</h2>
    </Center>
  )
}

export default Sunrise
