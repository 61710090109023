import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import formSections from '../../data/ideasFormData'
import NewIdeaForm from './NewIdeaForm'
import { getIsValid, canSubmitNewIdea } from '../../redux/selectors/newIdea'
import { doSubmitForm, doResetForm } from '../../redux/dispatchers/newIdea'
import { getEditIdeaId } from '../../redux/selectors/editIdea'

const mapStateToProps = state => {
  return {
    isValid: getIsValid(state),
    formSections,
    canSubmitIdeaForm: canSubmitNewIdea(state),
    isEditIdea: getEditIdeaId(state) ? true : false,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doSubmitForm,
      doResetForm,
    },
    dispatch
  )

const NewIdeaFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewIdeaForm)

export { NewIdeaFormContainer }

export default NewIdeaFormContainer
