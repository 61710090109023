import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import Layout from '../layouts/Layout/Layout'
import { Container, Content } from '../styles'
import RefIdeaHeader from '../components/RefIdeaHeader'
import { WithChildren } from '../types'

interface AdminIdeaProps extends RouteComponentProps {
  route?: string
  isAdmin?: boolean
  refVisible?: boolean
}

const AdminIdea: React.FunctionComponent<AdminIdeaProps & WithChildren> = ({
  children,
  route,
  refVisible,
  isAdmin,
}) => {
  return (
    <Layout pageId="page-admin-idea">
      {route && <RefIdeaHeader to={route} refVisible isAdmin={isAdmin} />}
      <Content>
        <Container.Padded>{children}</Container.Padded>
      </Content>
    </Layout>
  )
}

const AdminIdeaLayout = withRouter(AdminIdea)

export default AdminIdeaLayout
