import { connect } from 'react-redux'

import { getErrorMessage } from '../../redux/selectors/newIdea'

import EditIdeaError from './EditIdeaError'

const mapStateToProps = state => {
  return {
    errorMessage: getErrorMessage(state),
  }
}

export default connect(mapStateToProps)(EditIdeaError)
