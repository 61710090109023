import { ArrowLeft } from 'luna-icons'
import PropTypes from 'prop-types'
import React from 'react'
import { Container } from '../../styles'
import { Back, Blurb, Title, Wrapper, BackText, BackSmall } from './styles'

const BackHeader = ({ title, to, textTo, blurb = null }) => (
  <Wrapper>
    <Container.FullBleed>
      {textTo && to && (
        <BackText to={to}>
          <BackSmall>
            <ArrowLeft />
          </BackSmall>
          <span>{textTo}</span>
        </BackText>
      )}
      <Title>
        {!textTo && (
          <Back to={to}>
            <ArrowLeft />
          </Back>
        )}
        {title}
      </Title>
      {blurb && <Blurb>{blurb}</Blurb>}
    </Container.FullBleed>
  </Wrapper>
)

BackHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  to: PropTypes.string.isRequired,
  textTo: PropTypes.string,
  blurb: PropTypes.string,
}
export { BackHeader }
