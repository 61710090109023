import styled from 'styled-components'
import { OutlinedButton } from 'luna-react'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-end;
  margin: 40px 0;

  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
    overflow: hidden;
    height: 530px;
    padding: 0;
    padding-bottom: 40px;
    overflow: hidden;
  }
`

const Img = styled.img`
  margin-bottom: -40px;

  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${({ reverse }) => (reverse ? 'auto' : '0')};
    left: ${({ reverse }) => (reverse ? '0' : 'auto')};
  }
`

const Card = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: 20px;
  margin: 0 20px;

  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    margin: 0;
    padding: 40px;
    z-index: 2;
    width: 50%;
    min-width: 620px;
  }
`

const Title = styled.h3`
  font-size: 26px;
  margin-bottom: 14px;
  color: ${({ theme }) => theme.color.darkGrey};
`

const Description = styled.p`
  margin-bottom: 16px;
`

const Button = styled(OutlinedButton)``

export { Wrapper, Img, Card, Title, Description, Button }
