import { dataStoreActionTypes } from '../../types'

export const getDataAction = () => ({
  type: dataStoreActionTypes.GET_DATA,
})

export const dataSuccessAction = (data: object, section?: string) => ({
  type: dataStoreActionTypes.GET_DATA_SUCCESS,
  payload: { data, section },
})

export const dataFailureAction = (errorMessage: string) => ({
  type: dataStoreActionTypes.GET_DATA_FAILURE,
  payload: {
    errorMessage,
  },
})
