import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import formSections from '../../data/localHerosFormData'
import LocalHeroesForm from './LocalHeroesForm'
import { getIsValid } from '../../redux/selectors/localHeroesForm'
import { doSubmitForm, doResetForm } from '../../redux/dispatchers/localHeroes'
import { getEditIdeaId } from '../../redux/selectors/editIdea'

const mapStateToProps = state => {
  return {
    isValid: getIsValid(state),
    formSections,
    isEditIdea: getEditIdeaId(state) ? true : false,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doSubmitForm,
      doResetForm,
    },
    dispatch
  )

const NewLocalHeroesFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalHeroesForm)

export { NewLocalHeroesFormContainer }

export default NewLocalHeroesFormContainer
