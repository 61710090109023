import styled from 'styled-components'
import { Link as LinkC } from 'react-router-dom'

const Wrapper = styled.div`
  margin: 0 0 50px;
`

const Head = styled.div`
  padding-top: ${({ bg }) => (bg ? '400px' : '0')};
  background: ${({ bg }) => (bg ? `url(${bg})` : 'transparent')};
  background-size: cover;
  background-position: center top;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Container = styled.div`
  overflow: auto;
  background: ${({ theme, white }) =>
    white ? theme.color.white : theme.color.lighterGrey};
  padding: 20px;
  padding-top: ${({ noPaddingTop }) => (noPaddingTop ? '0' : '20px')};
  padding-bottom: ${({ noPaddingBottom }) => (noPaddingBottom ? '0' : '20px')};
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    padding: 30px;
    padding-top: ${({ noPaddingTop }) => (noPaddingTop ? '0' : '30px')};
    padding-bottom: ${({ noPaddingBottom }) =>
      noPaddingBottom ? '0' : '30px'};
  }

  @media (min-width: ${({ theme }) => theme.mq.md}) {
    padding: 40px;
    padding-top: ${({ noPaddingTop }) => (noPaddingTop ? '0' : '30px')};
    padding-bottom: ${({ noPaddingBottom }) =>
      noPaddingBottom ? '0' : '30px'};
  }
`

const ContainerWithQuote = styled.div`
  overflow: auto;
  background: ${({ theme, white }) =>
    white ? theme.color.white : theme.color.lighterGrey};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.mq.md}) {
    flex-direction: row;
  }
`

const Quote = styled.span`
  margin: 0;
  max-width: 300px;
  width: 100%;
  border: 0px solid ${({ theme }) => theme.color.orange};
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  font-family: ${({ theme }) => theme.fontFamily};

  @media (max-width: ${({ theme }) => theme.mq.md}) {
    border-top-width: 5px;
    padding-top: 20px;
    margin-top: 20px;
  }

  @media (min-width: ${({ theme }) => theme.mq.md}) {
    border-left-width: 5px;
    padding-left: 20px;
    margin-left: 20px;
  }
`

const Title = styled.h2`
  font-size: ${({ big }) => (big ? '32px' : '22px')};
  line-height: 26px;
  color: ${({ theme, accented }) =>
    accented ? theme.color.orange : 'inherit'};
  margin-bottom: 10px;
`

const Blurb = styled.p`
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 18px;
  margin: 0;
`

const B = styled.p`
  font-size: 16px;

  &:first-of-type {
    font-weight: bold;
    font-size: 18px;
    font-family: ${({ theme }) => theme.fontFamily};
  }
`

const P = styled.p`
  font-size: 16px;
  padding-left: ${({ logo }) => (logo ? '100px' : '0')};

  &:last-child {
    margin: 0;
  }
`
const Blockquote = styled.blockquote`
  font-size: 16px;
  margin-bottom: 24px;
  border-left: 5px solid ${({ theme }) => theme.color.orange};
  padding-left: 20px;
  display: block;
  font-style: italic;
  > ${B} {
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
  }
  &:after,
  &:before {
    display: none;
  }
`

const Link = styled(LinkC)``

export {
  Wrapper,
  Head,
  Container,
  Title,
  Blurb,
  B,
  P,
  Link,
  ContainerWithQuote,
  Quote,
  Blockquote,
}
