import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { About } from './About'
import { doFetchAbout } from '../../redux/dispatchers/about'

// TODO: Move this file to sections

const mapStateToProps = state => {
  return {
    about: state.aboutReducer.about,
    loading: state.aboutReducer.loading,
    errorMessage: state.aboutReducer.errorMessage,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doFetchAbout,
    },
    dispatch
  )
const AboutContainer = connect(mapStateToProps, mapDispatchToProps)(About)

export default AboutContainer
