import { PropTypes } from 'prop-types'
import React from 'react'
import { FormFields } from '../../FormFields/legacy/FormFieldContainer'
import { FormLabel } from '../styles'

class FormItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.item !== nextProps.item) {
      return true
    }
    return false
  }

  render() {
    const {
      type = '',
      label = '',
      placeholder,
      name,
      options,
      maxLength,
      disabled,
    } = this.props.item
    const { formType = 'local-heroes' } = this.props
    const FormField = FormFields[type]

    if (!FormField) {
      return null
    }
    return (
      <div data-id="idea-form-item">
        <FormLabel>{label}</FormLabel>
        <FormField
          type={type}
          name={name}
          options={options}
          maxLength={maxLength}
          placeholder={placeholder}
          disabled={disabled ? true : null}
          form={formType}
        />
      </div>
    )
  }
}

FormItem.propTypes = {
  item: PropTypes.object,
}

export default FormItem
