import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  TextInputField,
  FilledButton,
  LoadingIcon,
  SelectField,
} from 'luna-react'

import { Section } from '../Form/FormSection/styles'
import { distanceOptions } from '../../constants'

const FormItem = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 10px;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    flex-wrap: no-wrap;
  }
`

const StyledTextInputField = styled(TextInputField)`
  flex: 1 0 100%;
  margin: 10px 10px 10px 0;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    flex: 1 0 auto;
  }
`

const StyledButton = styled(FilledButton)`
  flex: 1 0 100%;
  margin: 10px 0;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    flex: 0 0 140px;
  }
  @media (max-width: 859px) {
    flex: 0 0 100%;
  }
`
const StyledSelectField = styled(SelectField)`
  flex: 1 0 180px;
  margin: 10px 0;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    flex: 0 0 180px;
    margin: 10px;
  }
`

const TeamSizeInput = styled(TextInputField)`
  flex: 1 0 120px;
  margin: 10px 0 10px 5px;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    margin: 10px;
    flex: 0 0 120px;
  }
  @media (max-width: 769px) {
    margin-right: 0;
    flex: 0 0 120px;
  }
  img.ln-c-input-group__icon {
    width: ${({ iconSize }) => `${iconSize || 32}px`};
    height: ${({ iconSize }) => `${iconSize || 32}px`};
    margin-left: -7px;
  }
`

class OpportunitiesSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: props.searchTerm || '',
    }
    props.doResetSearch()
  }

  componentDidMount() {
    if (!this.props.searching) {
      if (this.props.distance !== '') {
        this.props.setDistance(this.props.distance)
      }

      if (this.props.teamSize !== '') {
        this.props.setTeamSize(this.props.teamSize)
      }
      if (this.state.searchTerm !== '') {
        this.props.setSearchTerm(this.props.searchTerm)
        this.props.doSearchOpportunities()
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    if (!this.props.searching && this.state.searchTerm !== '') {
      this.props.setSearchTerm(this.state.searchTerm)
      this.props.doSearchOpportunities()
    }
  }

  handleSearchTermChange = e => {
    e.preventDefault()
    this.setState({
      searchTerm: e.target.value,
    })
    this.props.setSearchTerm(e.target.value)
  }

  handleDistanceChange = e => {
    e.preventDefault()
    this.props.setDistance(e.target.value)
  }

  handleTeamSizeChange = e => {
    e.preventDefault()
    this.props.setTeamSize(e.target.value)
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Section>
          <FormItem>
            <StyledTextInputField
              label="Post code"
              onChange={this.handleSearchTermChange}
              defaultValue={this.props.searchTerm}
              maxLength={20}
              placeholder="e.g. E20 6PQ"
              name="postcode"
              data-id="opportunity-search-input"
            />
            <StyledSelectField
              label="Distance"
              onChange={this.handleDistanceChange}
              data-id="opportunity-distance-select"
              options={distanceOptions}
              defaultValue={String(this.props.distance)}
              name="distance"
            />
            <TeamSizeInput
              data-id="opportunity-team-size-input"
              min={1}
              max={100}
              label="Team size"
              type="number"
              defaultValue={this.props.teamSize || ''}
              placeholder="Any"
              name="team_size"
              onChange={this.handleTeamSizeChange}
            />
            <StyledButton
              data-id="opportunity-search-button"
              type="submit"
              disabled={this.state.searchTerm === ''}
            >
              {this.props.searching ? (
                <LoadingIcon style={{ display: 'inline-block' }} inverted />
              ) : (
                <span>Submit</span>
              )}
            </StyledButton>
          </FormItem>
        </Section>
      </form>
    )
  }
}

OpportunitiesSearch.propTypes = {
  searchTerm: PropTypes.string,
  searching: PropTypes.bool,
  errorMessage: PropTypes.string,
  setSearchTerm: PropTypes.func.isRequired,
  doSearchOpportunities: PropTypes.func.isRequired,
}

export default OpportunitiesSearch
