import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Layout from '../layouts/Layout'
import VolunteeringForm from '../sections/Volunteering/VolunteeringForm'
import VolunteeringConfirm from '../sections/Volunteering/VolunteeringConfirm'
import VolunteeringSuccess from '../sections/Volunteering/VolunteeringSuccess'
import VolunteeringError from '../sections/Volunteering/VolunteeringError'
import VolunteeringEdit from '../sections/Volunteering/VolunteeringEdit'
import { name, path } from '../data/volunteeringFormData'
import { ideaFormRoutes } from '../data/ideaFormRoutes'

const Volunteering: React.FC = () => {
  return (
    <Layout pageId={`page-${name}`}>
      <Switch>
        <Route
          exact
          key="form"
          path={`${path}${ideaFormRoutes.form}`}
          component={VolunteeringForm}
        />
        <Route
          exact
          key="confirm"
          path={[
            `${path}${ideaFormRoutes.confirm}`,
            `${path}${ideaFormRoutes.confirm}/:id`,
          ]}
          component={VolunteeringConfirm}
        />
        <Route
          exact
          key="success"
          path={`${path}${ideaFormRoutes.success}`}
          component={VolunteeringSuccess}
        />
        <Route
          exact
          key="error"
          path={`${path}${ideaFormRoutes.error}`}
          component={VolunteeringError}
        />
        <Route
          exact
          key="edit"
          path={`${path}${ideaFormRoutes.edit}/:id`}
          component={VolunteeringEdit}
        />
      </Switch>
    </Layout>
  )
}

export default Volunteering
