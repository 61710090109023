import styled from 'styled-components'
import { OutlinedButton, PrimaryButton } from 'luna-react'

const Form = styled.form`
  margin-top: 40px;
`

const FormSubmitButton = styled(PrimaryButton)`
  margin: 0 20px;
  padding: 0.5rem 2rem;
`
const FormCancelButton = styled(OutlinedButton)`
  margin: 0 20px;
  padding: 0.5rem 2rem;
`

const FormButtons = styled.div`
  padding-top: 60px;
`

const EditButton = styled(OutlinedButton)`
  margin-right: 20px;
`

const ConfirmButton = styled(PrimaryButton)`
  min-width: 100px;
`
export {
  Form,
  FormSubmitButton,
  FormButtons,
  EditButton,
  ConfirmButton,
  FormCancelButton,
}
