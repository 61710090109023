import React from 'react'
import {
  SearchLabel,
  SearchSelect,
  SearchTextInput,
  SearchWrapper,
} from './styles'
import { OPTIONS } from '../../util/ideaFilterUtils'

export const getPlaceholder = index => {
  const option = OPTIONS[index] || {}
  return option.placeholder || ''
}

const IdeaSearch = ({
  onChange,
  onSearchTypeChange,
  searchValue = '',
  disabled,
  filterType,
}) => (
  <SearchWrapper>
    <SearchLabel>Search</SearchLabel>
    <SearchSelect
      name="idea_search_select"
      options={OPTIONS.map((currentOption, index) => ({
        ...currentOption,
        value: index,
      }))}
      value={filterType}
      onChange={event => onSearchTypeChange(event.target.value)}
    />
    <SearchTextInput
      disabled={disabled}
      name="idea_search_input"
      onChange={e =>
        onChange(
          OPTIONS[filterType] && OPTIONS[filterType].value,
          e.target.value
        )
      }
      value={searchValue}
      placeholder={getPlaceholder(filterType)}
    />
  </SearchWrapper>
)

export default IdeaSearch
