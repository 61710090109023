import React from 'react'
import { PropTypes } from 'prop-types'

import { IdeaTitle, IdeaBody } from '../../styles'

const LocalHeroesContent = ({ content }) => {
  const {
    business,
    colleague_name,
    colleague_email,
    gender,
    employee_number,
    charity_contact,
    charity_name,
    charity_number,
    charity_address,
    charity_email_address,
    charity_phone_number,
    charity_account_number,
    charity_sort_code,
    charity_category,
    hours_claimed,
    store_name,
    title,
    volunteering_details,
  } = content
  return (
    <>
      <div id="lh_colleague_name">
        <IdeaTitle>Colleague name</IdeaTitle>
        <IdeaBody>{colleague_name}</IdeaBody>
      </div>
      <div id="lh_colleague_email">
        <IdeaTitle>Colleague or store email address</IdeaTitle>
        <IdeaBody>{colleague_email}</IdeaBody>
      </div>
      <div id="lh_gender">
        <IdeaTitle>Gender</IdeaTitle>
        <IdeaBody>{gender}</IdeaBody>
      </div>
      <div id="lh_employee_number">
        <IdeaTitle>Employee number</IdeaTitle>
        <IdeaBody>{employee_number}</IdeaBody>
      </div>
      <div id="idea_business">
        <IdeaTitle>Associated store or team</IdeaTitle>
        <IdeaBody>
          {Array.isArray(business) ? business.sort().join(', ') : business}
        </IdeaBody>
      </div>
      <div id="lh_store_name">
        <IdeaTitle>
          Store name and number, depot name or divisional team name
        </IdeaTitle>
        <IdeaBody>
          <IdeaBody>{store_name}</IdeaBody>
        </IdeaBody>
      </div>
      <div id="lh_charity_name">
        <IdeaTitle>Name of charity / community group</IdeaTitle>
        <IdeaBody>
          <IdeaBody>{charity_name}</IdeaBody>
        </IdeaBody>
      </div>
      <div id="lh_charity_number">
        <IdeaTitle>Registered charity number</IdeaTitle>
        <IdeaBody>
          <IdeaBody>{charity_number}</IdeaBody>
        </IdeaBody>
      </div>
      <div id="lh_charity_contact">
        <IdeaTitle>Contact name at charity / community group</IdeaTitle>
        <IdeaBody>
          <IdeaBody>{charity_contact}</IdeaBody>
        </IdeaBody>
      </div>
      <div id="lh_charity_address">
        <IdeaTitle>Charity / community group address</IdeaTitle>
        <IdeaBody>
          <IdeaBody>{charity_address}</IdeaBody>
        </IdeaBody>
      </div>
      <div id="lh_charity_email_address">
        <IdeaTitle>Charity / community email address</IdeaTitle>
        <IdeaBody>
          <IdeaBody>{charity_email_address}</IdeaBody>
        </IdeaBody>
      </div>
      <div id="lh_charity_phone_number">
        <IdeaTitle>Charity / community group telephone number</IdeaTitle>
        <IdeaBody>
          <IdeaBody>{charity_phone_number}</IdeaBody>
        </IdeaBody>
      </div>
      <div id="lh_charity_account_number">
        <IdeaTitle>Charity / community group bank account number</IdeaTitle>
        <IdeaBody>
          <IdeaBody>{charity_account_number}</IdeaBody>
        </IdeaBody>
      </div>
      <div id="lh_charity_sort_code">
        <IdeaTitle>Charity / community group sort code</IdeaTitle>
        <IdeaBody>
          <IdeaBody>{charity_sort_code}</IdeaBody>
        </IdeaBody>
      </div>
      <div id="lh_charity_phone_number">
        <IdeaTitle>Number of hours claimed</IdeaTitle>
        <IdeaBody>
          <IdeaBody>{hours_claimed}</IdeaBody>
        </IdeaBody>
      </div>
      <div id="idea_volunteering_idea">
        <IdeaTitle>What you did to volunteer</IdeaTitle>
        <IdeaBody>{volunteering_details}</IdeaBody>
      </div>
      <div id="lh_category">
        <IdeaTitle>Charity category</IdeaTitle>
        <IdeaBody>
          {Array.isArray(charity_category)
            ? charity_category.sort().join(', ')
            : charity_category}
        </IdeaBody>
      </div>
      <div id="lh_title">
        <IdeaTitle>Title</IdeaTitle>
        <IdeaBody>
          <IdeaBody>{title}</IdeaBody>
        </IdeaBody>
      </div>
    </>
  )
}

LocalHeroesContent.propTypes = {
  content: PropTypes.shape({
    business: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    colleague_name: PropTypes.string,
    colleague_email: PropTypes.string,
    gender: PropTypes.string,
    employee_number: PropTypes.string,
    store_name: PropTypes.string,
    charity_name: PropTypes.string,
    charity_number: PropTypes.string,
    charity_address: PropTypes.string,
    charity_phone_number: PropTypes.string,
    charity_account_number: PropTypes.string,
    charity_sort_code: PropTypes.string,
    charity_category: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    hours_claimed: PropTypes.string,
    title: PropTypes.string,
    volunteering_details: PropTypes.string,
  }),
}

export default LocalHeroesContent
