import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  getFormValues,
  getSending,
  getIsValid,
} from '../../redux/selectors/localHeroesForm'
import { doPostLocalHeroes } from '../../redux/dispatchers/localHeroes'
import LocalHeroesConfirmation from './LocalHeroesConfirmation'

const mapStateToProps = state => {
  return {
    formValues: getFormValues(state),
    sending: getSending(state),
    isValid: getIsValid(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doPostLocalHeroes,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalHeroesConfirmation)
