import styled from 'styled-components'
import { FilledButton, GridItem } from 'luna-react'

const GridItemControl = styled(GridItem)`
  padding-bottom: 1rem;
`

const FilledButtonControl = styled(FilledButton)`
  margin: 0 0 15px auto;
  float: right;
  width: 265px;
`
FilledButtonControl.displayName = 'FilledButtonControl'

export { GridItemControl, FilledButtonControl }
