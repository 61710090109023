import React from 'react'
import Layout from '../layouts/Layout/Layout'

import LocalHeroesError from '../components/LocalHeroes/LocalHeroesError'

const LocalHeroesErrorContainer = () => {
  return (
    <Layout pageId="page-lh-error">
      <LocalHeroesError />
    </Layout>
  )
}

export default LocalHeroesErrorContainer
