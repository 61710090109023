import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import About150 from './About150'
import { selectData } from '../../redux/selectors/dataStore'
import { doFetchDataStoreData } from '../../redux/dispatchers/dataStore'
import { DATASTORE_ENDPOINTS } from '../../constants'

const mapStateToProps = state => {
  return {
    loading: state.dataStore.loading,
    errorMessage: state.dataStore.errorMessage,
    about150: selectData(state, DATASTORE_ENDPOINTS.ONEFIFTYDAYS),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doFetchDataStoreData: () =>
        doFetchDataStoreData(DATASTORE_ENDPOINTS.ONEFIFTYDAYS),
    },
    dispatch
  )
const About150Container = connect(mapStateToProps, mapDispatchToProps)(About150)

export default About150Container
