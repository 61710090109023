import { FormActionTypes, FormValue } from '../../types'

const FORM_VALUES_RESET = 'form/values/reset'
const FORM_VALUES_UPDATE = 'form/values/update'
const FORM_GET_REQUEST = 'form/get/request'
const FORM_GET_SUCCESS = 'form/get/success'
const FORM_GET_FAILURE = 'form/get/failure'
const FORM_POST_REQUEST = 'form/post/request'
const FORM_POST_SUCCESS = 'form/post/success'
const FORM_POST_FAILURE = 'form/post/failure'

function formValuesReset(formName: string): FormActionTypes {
  return {
    type: FORM_VALUES_RESET,
    formName,
  }
}

function formValuesUpdate(
  formName: string,
  key: string,
  value: FormValue,
  errors: object | null
): FormActionTypes {
  return {
    type: FORM_VALUES_UPDATE,
    formName,
    key,
    value,
    errors,
  }
}

function formGetRequest(formName: string): FormActionTypes {
  return {
    type: FORM_GET_REQUEST,
    formName,
  }
}

function formGetFailure(
  formName: string,
  errorMessage: string
): FormActionTypes {
  return {
    type: FORM_GET_FAILURE,
    formName,
    errorMessage,
  }
}

interface FormPostSuccessPayload {
  author_email: string
  ref: string
}

function formGetSuccess(formName: string, payload: any): FormActionTypes {
  return {
    type: FORM_GET_SUCCESS,
    formName,
    payload,
  }
}

function formPostRequest(formName: string): FormActionTypes {
  return {
    type: FORM_POST_REQUEST,
    formName,
  }
}

function formPostFailure(
  formName: string,
  errorMessage: string
): FormActionTypes {
  return {
    type: FORM_POST_FAILURE,
    formName,
    errorMessage,
  }
}

interface FormPostSuccessPayload {
  author_email: string
  ref: string
}

function formPostSuccess(
  formName: string,
  { author_email, ref }: FormPostSuccessPayload
): FormActionTypes {
  return {
    type: FORM_POST_SUCCESS,
    formName,
    payload: {
      author_email,
      ref,
    },
  }
}

export {
  FORM_VALUES_RESET,
  FORM_VALUES_UPDATE,
  FORM_GET_REQUEST,
  FORM_GET_SUCCESS,
  FORM_GET_FAILURE,
  FORM_POST_REQUEST,
  FORM_POST_SUCCESS,
  FORM_POST_FAILURE,
  formValuesReset,
  formValuesUpdate,
  formGetRequest,
  formGetSuccess,
  formGetFailure,
  formPostRequest,
  formPostSuccess,
  formPostFailure,
}
