import { api, get, getFile } from '../../httpClient/httpClient'
import {
  getIdeas,
  getIdeasSuccess,
  getIdeasFailure,
  updateSortBy,
  clearIdeas,
} from '../actions/ideas'
import { getAuthToken } from '../selectors/auth'
import errorHandler from './errorHandler'
import { stringify } from 'query-string'

const doFetchIdeas = (params = {}) => async (dispatch, getState) => {
  dispatch(getIdeas())
  try {
    const token = getAuthToken(getState())

    const searchParams = Object.keys(params).length
      ? `${stringify(params, { encode: false })}&`
      : ''
    // this should take an exported list from IdeaSummary.jsx or some constant that both will use
    const queryParams = `${searchParams}keys=id,business,colleague_name,contact_email,idea_status,created_date,category,organisation_name`

    const data = await get(api('/admin/ideas', queryParams), token)
    dispatch(getIdeasSuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, getIdeasFailure)
  }
}

const doUpdateSort = by => dispatch => {
  dispatch(updateSortBy(by))
}

const doClearIdeas = () => dispatch => dispatch(clearIdeas())

const doDownloadIdeas = () => async (dispatch, getState) => {
  const urlParams = window.location.search
  const token = getAuthToken(getState())
  getFile(api(`/admin/ideas${urlParams}`), token)
}

export { doFetchIdeas, doUpdateSort, doDownloadIdeas, doClearIdeas }
