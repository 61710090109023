import React from 'react'
import { FilledButton } from 'luna-react'
import Modal from '../Modal/Modal'
import { HomepageHeading } from '../../styles/Home'
import teamImage from '../../assets/team.svg'
import individualImage from '../../assets/individual.svg'
import {
  VOLUNTEER_FEEDBACK_LINK,
  COORDINATOR_FEEDBACK_LINK,
} from '../../constants'
import PropTypes from 'prop-types'

import {
  FeedbackWrapper,
  FeedbackStepWrapper,
  FeedbackStep,
  CloseButton,
  FeedbackDisclaimer,
} from './styles'

const FeedbackModal = ({ onDismiss }) => {
  return (
    <Modal>
      <FeedbackWrapper data-id="survey-feedback-wrapper">
        <HomepageHeading align="left" accented size={26}>
          Help us measure your impact!
        </HomepageHeading>
        <p>
          Hi, we are looking to measure the wider impact of the 150 Days of
          Community initiative, for both the beneficiaries and the Sainsbury’s
          colleagues who took part. Please complete this survey to help us
          understand your team’s volunteering experience.
        </p>
        <FeedbackStepWrapper>
          <FeedbackStep>
            <img src={teamImage} alt="I'm a volunteering coordinator" />
            <HomepageHeading accented size={20}>
              I’m a Volunteering Coordinator
            </HomepageHeading>
            <p>
              This survey is only for Volunteer Coordinators, if you didn’t
              organise your team’s volunteering, please select ‘Volunteer’ to
              share your experience.
            </p>
            <a
              href={COORDINATOR_FEEDBACK_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FilledButton>Share your experience</FilledButton>
            </a>
          </FeedbackStep>
          <FeedbackStep>
            <img src={individualImage} alt="I'm a volunteer" />
            <HomepageHeading accented size={20}>
              I’m a Volunteer
            </HomepageHeading>
            <p>
              This survey is only for Volunteers, if you organised your team’s
              volunteering, please follow select ‘Volunteering Coordinator’ to
              share your experience.
            </p>
            <a
              href={VOLUNTEER_FEEDBACK_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FilledButton>Share your experience</FilledButton>
            </a>
          </FeedbackStep>
        </FeedbackStepWrapper>
        <FeedbackDisclaimer>
          Please note that the responses will be kept anonymous, and data
          aggregated.
        </FeedbackDisclaimer>
        <CloseButton data-id="survey-modal-close" onClick={onDismiss}>
          Close
        </CloseButton>
      </FeedbackWrapper>
    </Modal>
  )
}

FeedbackModal.propTypes = {
  onDismiss: PropTypes.func,
}

FeedbackModal.defaultProps = {
  onDismiss: () => null,
}
export default FeedbackModal
