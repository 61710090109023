import React from 'react'
import { Button } from '@jsluna/button'
import { List, ListItem } from '@jsluna/list'

export interface TabProps {
  id: string
  label: string
  onClick: Function
}

interface TabGroupProps {
  activeTab?: string
  tabs: TabProps[]
}

const TabGroup: React.FC<TabGroupProps> = ({ tabs, activeTab }) => {
  return (
    <List type="bare" className="ln-u-display-flex">
      {tabs.map(tab => (
        <ListItem key={tab.id}>
          <Button
            variant={tab.id === activeTab ? 'filled' : 'outlined'}
            data-id={tab.id}
            aria-label={`${tab.label} list, selected ${tab.id === activeTab}`}
            onClick={tab.onClick}
          >
            {tab.label}
          </Button>
        </ListItem>
      ))}
    </List>
  )
}

export default TabGroup
