import { connect } from 'react-redux'
import { stringify } from 'query-string'
import RefIdeaHeader from './RefIdeaHeader'
import { extractFields } from '../../util/ideaFilterUtils'

const mapStateToProps = (
  { dataStore = {}, adminIdea = {}, ideaReducer = {}, ideaFilter },
  { to, isAdmin = false, refVisible = false, ...props }
) => {
  const fields = extractFields(ideaFilter)
  const params = stringify(fields, { encode: false }) || ''
  const searchParams = !!params.length ? `?${params}` : ''
  const adminIdeaData =
    dataStore && dataStore.data ? dataStore.data[adminIdea.source] : {}
  return {
    visible: !refVisible && !isAdmin ? !ideaReducer.loading : refVisible,
    idea: isAdmin ? adminIdeaData : ideaReducer.idea,
    to: `${to}${searchParams}`,
    ...props,
  }
}

export default connect(mapStateToProps)(RefIdeaHeader)
