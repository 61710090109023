import React, { useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Container } from '@jsluna/grid'
import routes from '../../routes'
import { isVolunteering } from '../../features'
import UserIdeasContainer from '../../components/UserIdeas'
import TabGroup from '../../components/TabGroup'

interface MatchParams {
  campaign?: string
}

const UserIdeas: React.FC<RouteComponentProps<MatchParams>> = ({
  history,
  match,
}) => {
  const defaultTab = isVolunteering() ? 'volunteering' : '150days'
  const [activeTab, setActiveTab] = useState<string>(
    match.params.campaign || defaultTab
  )

  return (
    <Container fluid soft="sm">
      {isVolunteering() && (
        <div className="ln-u-soft-bottom">
          <TabGroup
            activeTab={activeTab}
            tabs={[
              {
                id: 'volunteering',
                label: 'Volunteering',
                onClick: () => {
                  history.push(`${routes.userIdeas}/volunteering`)
                  setActiveTab('volunteering')
                },
              },
              {
                id: '150days',
                label: '150 Days',
                onClick: () => {
                  history.push(`${routes.userIdeas}/150days`)
                  setActiveTab('150days')
                },
              },
            ]}
          />
        </div>
      )}
      <UserIdeasContainer campaign={activeTab} />
    </Container>
  )
}

export default withRouter(UserIdeas)
