import React from 'react'
import Layout from '../layouts/Layout/Layout'
import AboutLocalHeroesContainer from '../components/LocalHeroes/LocalHeroesContainer'

class AboutLocalHeroes extends React.Component {
  render() {
    return (
      <Layout pageId="page-about-local-heroes">
        <AboutLocalHeroesContainer />
      </Layout>
    )
  }
}

export default AboutLocalHeroes
