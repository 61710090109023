import styled from 'styled-components'

const InfoWrapper = styled.div`
  padding: 10px;
`

const InfoHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`

const InfoTitle = styled.h2`
  margin-bottom: 0;
  width: 100%;
`

const InfoDistance = styled.span`
  color: ${({ theme }) => theme.color.orange};
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: arial;
`

const InfoTag = styled.span`
  display: block;
  font-family: arial;
  font-size: 13px;
  line-height: 20px;
`

const InfoTagBold = styled.b`
  letter-spacing: 0.53px;
  font-weight: bold;
`

export {
  InfoWrapper,
  InfoHeader,
  InfoTitle,
  InfoDistance,
  InfoTag,
  InfoTagBold,
}
