import { getEmailFromToken } from '../../util/TokenUtil'
import { FormActionTypes, FormState, FormValues } from '../../types'
import {
  FORM_VALUES_RESET,
  FORM_VALUES_UPDATE,
  FORM_GET_REQUEST,
  FORM_GET_SUCCESS,
  FORM_GET_FAILURE,
  FORM_POST_REQUEST,
  FORM_POST_SUCCESS,
  FORM_POST_FAILURE,
} from '../actions/ideaForm'

const formInitialState: FormState = {
  formValues: {
    author_email: null,
  },
  isValid: false,
  path: null,
  endpoint: null,
  fields: [],
  loading: false,
  sending: false,
  errorMessage: null,
  ref: null,
  author_email: null,
  formErrors: null,
}

const createFormReducer = (
  formName: string,
  path: string,
  endpoint: string,
  fields: any,
  formValues: FormValues
) => {
  const author_email: string = getEmailFromToken()
  const initialState = {
    ...formInitialState,
    path,
    endpoint,
    fields,
    author_email,
    formValues: {
      ...formValues,
      author_email,
    },
  }

  return (state: FormState = initialState, action: FormActionTypes) => {
    if (!action.formName || action.formName !== formName) {
      return state
    }

    switch (action.type) {
      case FORM_VALUES_RESET:
        return initialState

      case FORM_VALUES_UPDATE:
        return {
          ...state,
          formValues: {
            ...state.formValues,
            [action.key]: action.value,
          },
          isValid: action.errors === null,
          formErrors: action.errors,
        }

      case FORM_GET_REQUEST:
        return {
          ...state,
          loading: true,
        }

      case FORM_GET_SUCCESS:
        const formValues = {}
        state.fields.forEach((section: any) => {
          section.items.forEach((field: any) => {
            if (action.payload[field.name]) {
              formValues[field.name] = action.payload[field.name]
            }
          })
        })

        return {
          ...initialState,
          loading: false,
          isValid: true,
          formValues,
        }

      case FORM_GET_FAILURE:
        return {
          ...state,
          loading: false,
          errorMessage: action.errorMessage,
        }

      case FORM_POST_REQUEST:
        return {
          ...state,
          sending: true,
          ref: null,
          author_email: null,
        }

      case FORM_POST_SUCCESS:
        return {
          ...initialState,
          formErrors: {},
          sending: false,
          author_email: action.payload.author_email,
          ref: action.payload.ref,
        }

      case FORM_POST_FAILURE:
        return {
          ...state,
          sending: false,
          errorMessage: action.errorMessage,
        }

      default:
        return state
    }
  }
}

export { formInitialState, createFormReducer }
