import React from 'react'
import NewWindow from 'luna-react/icons/NewWindow'
import { Document } from '../../assets/svg'
import {
  ResourcesListItem,
  ResourcesItemLink,
  ResourcesItemContentCol,
  ResourcesItemIconCol,
  ResourcesItemParagraph,
  ResourcesItemHeading,
} from './Resources.styles'

interface ResourcesItemProps {
  url: string
  title: string
  description?: string
  hasIcon?: boolean
}

const ResourcesItem: React.SFC<ResourcesItemProps> = ({
  url,
  title,
  description,
  hasIcon,
}) => {
  if (!url || !title) {
    return null
  }

  return (
    <ResourcesListItem>
      <ResourcesItemLink to={url} target="_blank">
        {hasIcon && (
          <ResourcesItemIconCol>
            <Document />
          </ResourcesItemIconCol>
        )}
        <ResourcesItemContentCol>
          <ResourcesItemHeading>{title}</ResourcesItemHeading>
          {description && (
            <ResourcesItemParagraph>{description}</ResourcesItemParagraph>
          )}
        </ResourcesItemContentCol>
        <ResourcesItemIconCol right>
          <NewWindow />
        </ResourcesItemIconCol>
      </ResourcesItemLink>
    </ResourcesListItem>
  )
}

export default ResourcesItem
