import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { parse } from 'query-string'
import { Container as LunaContainer } from '@jsluna/grid'
import {
  OutlinedButton,
  ButtonGroupWrapper,
  ButtonGroupSecondary,
} from '@jsluna/button'
import { formValuesReset } from '../../redux/actions/ideaForm'
import { doFetchFormValues } from '../../redux/dispatchers/ideaForm'
import { selectFormIsValid } from '../../redux/selectors/ideaForm'
import { PageHeader } from '../../components/PageHeader'
import FormSection from '../../components/Form/FormSection'
import { ideaFormRoutes } from '../../data/ideaFormRoutes'
import { name, path, fields } from '../../data/volunteeringFormData'
import { Paragraph, HeadingSmall, Container } from '../../styles'
import { Form } from './styles'

const VolunteeringForm: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  let id: any = null
  if (location && location.search) {
    id = parse(location.search).id
  }

  const confirmPath = `${path}${ideaFormRoutes.confirm}`
  const dispatch = useDispatch()
  const isValid = useSelector(selectFormIsValid(name))
  const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    if (isValid) {
      history.push(confirmPath)
    }
  }

  useEffect(() => {
    if (id) {
      dispatch(doFetchFormValues(name, id))
    }

    return () => {
      if (history.location.pathname !== confirmPath) {
        dispatch(formValuesReset(name))
      }
    }
  }, [id, history.location.pathname, confirmPath, dispatch])

  return (
    <>
      <PageHeader
        title="Volunteering Project Submission Page"
        blurb="This page is for colleagues to get involved in the Volunteering project."
      />
      <Container.Wrapper>
        <LunaContainer soft>
          <div className="ln-u-soft-top">
            <HeadingSmall>
              In the form below, please provide detail of your volunteering
              project, including a project overview, with as much detail as
              possible. Please give particular thought to how you intend to
              measure the impact of your project.
            </HeadingSmall>
            <Paragraph>
              By completing this form, you are confirming that this project has
              been agreed upon by your colleagues.
            </Paragraph>
            <Paragraph>
              Your submission will be reviewed by the Community Affairs team.
              Please wait for approval before finalising the partnership and
              undertaking your activity.
            </Paragraph>
            <Paragraph>
              You can only submit your volunteering project if you have a
              personal Sainsbury’s Group email inbox. If you do not have a
              personal inbox, please ask your store or senior manager to submit
              the details on your behalf.
            </Paragraph>
          </div>
          <Form onSubmit={onSubmit}>
            {fields.map((section: object, index: number) => (
              <FormSection formType={name} key={index} section={section} />
            ))}
            <ButtonGroupWrapper actionBar>
              <ButtonGroupSecondary>
                <OutlinedButton
                  type="submit"
                  disabled={!isValid}
                  style={{ minWidth: '8rem' }}
                >
                  Review details
                </OutlinedButton>
              </ButtonGroupSecondary>
            </ButtonGroupWrapper>
          </Form>
        </LunaContainer>
      </Container.Wrapper>
    </>
  )
}

export default withRouter(VolunteeringForm)
