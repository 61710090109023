import React from 'react'
import { PropTypes } from 'prop-types'
import FormItem from '../FormItem'
import { Section, SectionHeader } from './styles'

class FormSection extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.section !== nextProps.section) {
      return true
    }
    return false
  }
  render() {
    const { formType = {} } = this.props
    const { items = [], header } = this.props.section

    return (
      <Section>
        <SectionHeader data-id="idea-form-section-header">
          {header}
        </SectionHeader>
        {items.map(item => (
          <FormItem key={item.name} formType={formType} item={item} />
        ))}
      </Section>
    )
  }
}

FormItem.propTypes = {
  section: PropTypes.object,
}

export default FormSection
