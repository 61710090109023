import React from 'react'
import Layout from '../layouts/Layout/Layout'

import { PageHeader } from '../components/PageHeader'
import { Content, Container } from '../styles'
import OpportunitiesControls from '../components/OpportunitiesControls/OpportunitiesControls'
import OrganisationsSearchContainer from '../components/OrganisationsSearch/OrganisationsSearchContainer'
import { getParamValueFromLocation } from '../util/getParamValue'
import OrganisationsSearchResultsContainer from '../components/OrganisationsSearchResults/OrganisationsSearchResultsContainer'

const OrganisationsView = ({ location }) => {
  return (
    <Layout pageId="page-opportunities">
      <PageHeader
        title="Volunteering Opportunities"
        blurb="We know that sometimes the hardest part about setting up
              volunteering activity, is simply knowing where to look."
      />
      <Content>
        <Container.FalseBleed padded>
          <OpportunitiesControls selectOrg />
          <OrganisationsSearchContainer
            searchTerm={getParamValueFromLocation(location, 'orgname')}
            category={getParamValueFromLocation(location, 'category')}
          />
          <OrganisationsSearchResultsContainer
            searchTerm={getParamValueFromLocation(location, 'orgname')}
          />
        </Container.FalseBleed>
      </Content>
    </Layout>
  )
}

export default OrganisationsView

OrganisationsView.displayName = 'OrganisationsSearch'
