const initialiseAnalytics = (scriptLocation = '') => {
  window.digitalData = { version: '0.0.1', event: [] }
  window.utag_cfg_ovrd = { noview: true }
  ;(function(a, b, c, d) {
    a = scriptLocation
    b = document
    c = 'script'
    d = b.createElement(c)
    d.src = a
    d.type = 'text/java' + c
    d.async = true
    a = b.getElementsByTagName(c)[0]
    a.parentNode.insertBefore(d, a)
  })()
}

const sendAnalytics = params => {
  if (window.utag && typeof window.utag.view === 'function') {
    window.utag.view(params)
  }
}

const registerPageView = params => {
  const analyticsParams = {
    ...params,
  }
  sendAnalytics(analyticsParams)
}
export { initialiseAnalytics, registerPageView }
