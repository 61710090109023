import { actionTypes } from '../actions/newIdea'
import { getEmailFromToken } from '../../util/TokenUtil'

const {
  SAVE_FORM_VALUES,
  UPDATE_FORM,
  SET_FIELD_ERROR,
  SEND_IDEA,
  SEND_IDEA_SUCCESS,
  SEND_IDEA_FAILURE,
  RESET_FORM,
} = actionTypes

const initialState = {
  formValues: {
    contact_email: getEmailFromToken(),
  },
  formErrors: {},
  sending: false,
  errorMessage: null,
  ref: null,
  contact_email: null,
}

const newIdeaReducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case SAVE_FORM_VALUES:
      const { formValues } = payload
      return {
        ...state,
        formValues,
      }
    case UPDATE_FORM:
      const { key, value } = payload
      const { formValues: currentFormValues } = state

      return {
        ...state,
        formValues: {
          ...currentFormValues,
          [key]: value,
        },
      }
    case SET_FIELD_ERROR:
      const { key: errorKey, errorMessage } = payload
      const { formErrors: currentFormErrors } = state
      return {
        ...state,
        formErrors: {
          ...currentFormErrors,
          [errorKey]: errorMessage,
        },
      }
    case SEND_IDEA:
      return {
        ...state,
        sending: true,
        ref: null,
        contact_email: null,
      }
    case SEND_IDEA_SUCCESS:
      const { contact_email, ref } = payload
      return {
        ...initialState,
        formErrors: {},
        sending: false,
        contact_email,
        ref,
      }
    case SEND_IDEA_FAILURE:
      return {
        ...state,
        sending: false,
        errorMessage: payload.errorMessage,
      }
    case RESET_FORM:
      return initialState
    default:
      return state
  }
}

export { initialState, newIdeaReducer }
