import React from 'react'
import { PropTypes } from 'prop-types'
import { LoadingIndicator, FilledButton } from 'luna-react'
import IdeaSummary2 from '../IdeaSummary/IdeaSummary'
import { Divider } from '../../styles'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { Statistics } from '../Statistics/Statistics'
import IdeaFilterPanel from '../IdeaFilter/IdeaFilterPanelContainer'
import styled from 'styled-components'
import routes from '../../routes'
import { isNewFilters } from '../../features'
import FiltersContainer from '../Filters/Filters.container'

const NoIdeaFound = styled.h5`
  margin-top: 50px;
  text-align: center;
`

class Ideas extends React.Component {
  componentWillUnmount() {
    this.props.doClearIdeas()
  }

  clickHandlerDownload = e => {
    e.preventDefault()
    this.props.doDownloadIdeas()
  }

  render() {
    const { doFetchIdeas } = this.props
    const { ideas = [], stats, loading, errorMessage } = this.props
    return (
      <>
        {isNewFilters() ? (
          <FiltersContainer />
        ) : (
          <IdeaFilterPanel onApply={doFetchIdeas} />
        )}
        {ideas.length ? (
          <FilledButton
            onClick={this.clickHandlerDownload}
            style={{
              margin: '0 0 15px auto',
              float: 'right',
              width: '265px',
            }}
          >
            Export ideas (.csv)
          </FilledButton>
        ) : null}
        {!loading && stats ? <Statistics stats={stats} /> : null}
        {!loading && errorMessage ? (
          <ErrorMessage errorMessage={errorMessage} />
        ) : null}
        {loading ? <LoadingIndicator message="Loading ideas…" /> : null}
        {!loading && !errorMessage && ideas.length ? (
          <>
            <Divider margin={'0 0 15px'} style={{ clear: 'both' }} />
            {ideas.map((idea, i) => (
              <IdeaSummary2
                idea={idea}
                key={i}
                currentIndex={i}
                routeTo={routes.adminIdea}
              />
            ))}
          </>
        ) : null}
        {!loading && !errorMessage && !ideas.length ? (
          <NoIdeaFound>No ideas found matching the current filter</NoIdeaFound>
        ) : null}
      </>
    )
  }
}

Ideas.propTypes = {
  doFetchIdeas: PropTypes.func.isRequired,
  doUpdateSort: PropTypes.func.isRequired,
  doClearIdeas: PropTypes.func.isRequired,
  ideas: PropTypes.array,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default Ideas
