import { history } from '../../createHistory'
import { Heading6 } from 'luna-react'
import React from 'react'
import styled from 'styled-components'
import routes from '../../routes'
import { Container, Content } from '../../styles'
import { PageHeader } from '../PageHeader'
import sendImage from '../../assets/send.svg'

const SuccessContent = styled.div`
  padding: 0 68px;
  text-align: center;
`

const SuccessHeader = styled(Heading6)`
  margin-top: 1.5rem;
`

const SuccessRef = styled.p`
  font-size: 18px;
  font-weight: bold;
`

const Ref = styled.span`
  color: ${({ theme }) => theme.color.orange};
`

const FakeLink = styled.a`
  color: inherit;
  cursor: pointer;
  text-decoration: underline;
`

class LocalHeroesSuccess extends React.Component {
  go(e, route) {
    e.preventDefault()
    history.replace(route)
  }

  render() {
    const { colleague_email, local_heroes_ref } = this.props
    return (
      <>
        <PageHeader
          title="Thank you for submitting your Local Heroes application form."
          blurb="Your Local Heroes application has been submitted"
        />

        <Content data-id="success">
          <Container.Padded>
            <SuccessContent>
              <img src={sendImage} alt="Send Icon" />
              <SuccessHeader>
                <p>
                  Hi there, thank you for submitting your Local Heroes
                  application form.
                </p>
                <p>
                  The Community Affairs team will now review your application
                  and reply in the coming weeks.
                </p>
              </SuccessHeader>
              <SuccessRef>
                Ref: <Ref>{local_heroes_ref}</Ref>
              </SuccessRef>
              <p>
                Correspondence will be sent to: <b>{colleague_email}</b>
              </p>
              <p>
                In the meantime, you can check the{' '}
                <FakeLink onClick={e => this.go(e, routes.faqs)}>FAQs</FakeLink>{' '}
                section, look over{' '}
                <FakeLink onClick={e => this.go(e, routes.caseStudies)}>
                  Case Studies
                </FakeLink>{' '}
                or{' '}
                <a
                  href="mailto:Community.Affairs@sainsburys.co.uk
                "
                >
                  Get in touch
                </a>{' '}
                if you have any further comments or questions.
              </p>
              <p>
                We‘ll be in touch soon!
                <br />
                <strong>Thank you.</strong>
              </p>
            </SuccessContent>
          </Container.Padded>
        </Content>
      </>
    )
  }
}

export default LocalHeroesSuccess
