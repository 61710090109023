import React, { useState } from 'react'
import {
  RadioButtonField,
  TextAreaField,
  CheckboxField,
  LoadingIndicator,
} from 'luna-react'
import { ErrorCircle } from 'luna-icons'

import { api, post } from '../../httpClient/httpClient'
import { getToken } from '../../util/TokenUtil'
import {
  FormSection,
  FormLabel,
  SubmitButton,
  CloseButton,
  Heading,
  P,
  ErrorMessage,
} from './styles'

export const SuccessHeading = ({ doClose }) => (
  <>
    <Heading data-testid="success_header">
      Thank you for submitting your feedback
    </Heading>
    <P>
      Thank you for having taken your time to provide us with your valuable
      feedback.
    </P>
    <CloseButton onClick={() => doClose()}>Close</CloseButton>
  </>
)

const FeedbackForm = ({ doClose }) => {
  const [values, setValues] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMessage, setError] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const { pathname } = document.location

  const token = getToken()

  const setValue = (name, value) => {
    setValues({
      ...values,
      [name]: value,
      page: pathname,
    })
  }

  const validate = () => (!values.comments && !values.experience ? true : false)

  const submitHandler = async e => {
    e.preventDefault()

    try {
      setLoading(true)
      setError(false)
      await post(api('/feedback'), token, values)
      setLoading(false)
      setSubmitted(true)
    } catch (error) {
      setLoading(false)
      setError(true)

      console.log({ error })
    }
  }

  const inputHandler = e => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      return setValue(name, checked)
    }
    setValue(name, value)
  }

  if (submitted) {
    return <SuccessHeading doClose={() => doClose()} />
  }

  return (
    <>
      <Heading>We value your feedback</Heading>
      <P>
        We would love to hear your thoughts, concerns or problems with anything
        so we can improve!
      </P>
      {loading ? (
        <LoadingIndicator message="Sending feedback" />
      ) : (
        <form onSubmit={e => submitHandler(e)}>
          <FormSection>
            <FormLabel>Comments or suggestions</FormLabel>
            <TextAreaField
              name="comments"
              data-testid="comment_field"
              maxLength={1000}
              onChange={e => inputHandler(e)}
            />
          </FormSection>
          <FormSection>
            <FormLabel>How would you rate your overall experience?</FormLabel>
            <RadioButtonField
              type="radio"
              name="experience"
              fullWidth
              options={[
                { value: 'HAPPY', label: '😀 Happy' },
                { value: 'AVERAGE', label: '😐 Average' },
                { value: 'POOR', label: '🙁 Poor' },
              ]}
              onChange={e => inputHandler(e)}
            />
          </FormSection>
          <CheckboxField
            name="contacted"
            fullWidth
            options={[
              {
                label: 'Can we contact you directly regarding your feedback?',
              },
            ]}
            onChange={e => inputHandler(e)}
          />
          {errorMessage && (
            <ErrorMessage data-testid="error_message">
              <ErrorCircle />
              <span> An error ocurred! Please try again.</span>
            </ErrorMessage>
          )}
          <CloseButton onClick={() => doClose()}>Close</CloseButton>
          <SubmitButton
            data-testid="submit"
            type="submit"
            disabled={validate()}
          >
            Submit
          </SubmitButton>
        </form>
      )}
    </>
  )
}

export default FeedbackForm
