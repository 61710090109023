import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { Menu as MenuIcon, Cancel } from 'luna-icons'
import { Mobile } from '../../util/MediaQuery'

const ToggleButton = styled.button`
  background-color: transparent;
  font-size: 24px;
  display: flex;
`

export const MenuToggle = ({ isOpen, toggleOpen }) => {
  const [isMenuOpen, setMenuState] = React.useState(isOpen)
  function handleChange(newValue) {
    const newState = newValue
    setMenuState(newState)
  }

  return (
    <Mobile>
      <ToggleButton
        onClick={() => {
          handleChange(!isMenuOpen)
          toggleOpen()
        }}
        data-id="toggle-menu"
      >
        {isMenuOpen ? <Cancel /> : <MenuIcon />}
      </ToggleButton>
    </Mobile>
  )
}

MenuToggle.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
}
