import React from 'react'
import menu from '../../data/menu'
import routes from '../../routes'
import AdminOnly from '../AdminOnly'
import {
  Wrapper,
  List,
  ListItem,
  ListLink,
  ButtonLink,
  ButtonList,
  ButtonListItem,
} from './styles'
import {
  is150DaysSection,
  isLocalCharity,
  isVolunteering,
} from '../../features'

const Menu = ({ isOpen = null, toggleOpen = null }) => {
  const menuResult = menu()
  const adminPath = isVolunteering()
    ? routes.adminVolunteering
    : is150DaysSection()
    ? routes.admin150Days
    : routes.adminLocalHeroes

  return (
    <Wrapper>
      <List data-id="menu">
        {Object.values(menuResult).map(menuItem => (
          <ListItem key={menuItem.title}>
            <ListLink
              data-id={menuItem.id}
              to={menuItem.path}
              title={menuItem.title}
              onClick={isOpen ? toggleOpen : null}
            >
              {menuItem.title}
            </ListLink>
          </ListItem>
        ))}
      </List>
      <ButtonList>
        {isLocalCharity() && (
          <ButtonListItem>
            <ButtonLink
              data-id="localCharity"
              to={routes.localCharity}
              onClick={isOpen ? toggleOpen : null}
            >
              Local charity of the year
            </ButtonLink>
          </ButtonListItem>
        )}
        {isVolunteering() && (
          <ButtonListItem>
            <ButtonLink
              data-id="volunteeringProject"
              to={routes.volunteeringProject}
              onClick={isOpen ? toggleOpen : null}
            >
              Volunteering project
            </ButtonLink>
          </ButtonListItem>
        )}
        <ButtonListItem>
          <ButtonLink
            data-id="myIdeas"
            to={routes.userIdeas}
            onClick={isOpen ? toggleOpen : null}
          >
            My ideas
          </ButtonLink>
        </ButtonListItem>
        {is150DaysSection() && (
          <ButtonListItem>
            <ButtonLink
              data-id="newIdea"
              to={routes.newIdea}
              onClick={isOpen ? toggleOpen : null}
            >
              Submit your idea
            </ButtonLink>
          </ButtonListItem>
        )}
        <AdminOnly>
          <ButtonListItem>
            <ButtonLink
              data-id="admin-link"
              to={adminPath}
              onClick={isOpen ? toggleOpen : null}
            >
              Admin
            </ButtonLink>
          </ButtonListItem>
        </AdminOnly>
      </ButtonList>
    </Wrapper>
  )
}

export default Menu
