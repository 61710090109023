import styled from 'styled-components'
import { Link } from 'react-router-dom'
const OuterWrapper = styled.header`
  position: absolute;
  width: 100%;
`

const InnerWrapper = styled.div`
  background: ${props => props.theme.color.white};
  box-shadow: 0 1px 0 0 ${props => props.theme.color.lightGrey};
  height: ${({ theme }) => theme.header.height};
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.color.black};
  line-height: 0;
`

const Logo = styled.img`
  max-width: 140px;
  margin-right: 15px;
`

const UserInfo = styled.div`
  text-align: right;
  padding: 5px 10px;
  font-size: 13px;
  @media (min-width: ${({ theme }) => theme.mq.md}) {
    padding: 5px 0;
  }
`
export { OuterWrapper, InnerWrapper, StyledLink, Logo, UserInfo }
