export const OpportunityActionTypes = {
  SET_DISTANCE: 'SET_DISTANCE',
  SET_OPPORTUNITY_SEARCH_TERM: 'SET_OPPORTUNITY_SEARCH_TERM',
  SET_TEAM_SIZE: 'SET_TEAM_SIZE',
  SEARCH_OPPORTUNITIES: 'SEARCH_OPPORTUNITIES',
  SEARCH_OPPORTUNITIES_SUCCESS: 'SEARCH_OPPORTUNITIES_SUCCESS',
  SEARCH_OPPORTUNITIES_FAILURE: 'SEARCH_OPPORTUNITIES_FAILURE',
  GET_OPPORTUNITIES: 'GET_OPPORTUNITIES',
  GET_OPPORTUNITIES_SUCCESS: 'GET_OPPORTUNITIES_SUCCESS',
  GET_OPPORTUNITIES_FAILURE: 'GET_OPPORTUNITIES_FAILURE',
  SHOW_MORE_OPPORTUNITIES: 'SHOW_MORE_OPPORTUNITIES',
  DELETE_SEARCH_STATE: 'DELETE_SEARCH_STATE',
}

export const OrganisationActionTypes = {
  SET_DISTANCE: 'SET_DISTANCE',
  SET_ORGANISATION_SEARCH_TERM: 'SET_ORGANISATION_SEARCH_TERM',
  SET_TEAM_SIZE: 'SET_TEAM_SIZE',
  SET_CATEGORY: 'SET_CATEGORY',
  GET_CATEGORIES: 'GET_CATEGORIES',
  SEARCH_ORGANISATIONS: 'SEARCH_ORGANISATIONS',
  SEARCH_ORGANISATIONS_SUCCESS: 'SEARCH_ORGANISATIONS_SUCCESS',
  SEARCH_ORGANISATIONS_FAILURE: 'SEARCH_ORGANISATIONS_FAILURE',
  SET_ORGANISATION: 'SET_ORGANISATION',
  SHOW_MORE_ORGANISATIONS: 'SHOW_MORE_ORGANISATIONS',
}

export const ActionTypes = {
  SHOW_MORE_RECORDS: 'SHOW_MORE_RECORDS',
}

const setOrganisation = organisation_slug => ({
  type: OrganisationActionTypes.SET_ORGANISATION,
  payload: { selectedOrganisation: organisation_slug },
})

const getOpportunities = () => ({
  type: OpportunityActionTypes.GET_OPPORTUNITIES,
})

const getOpportunitiesSuccess = opportunities => ({
  type: OpportunityActionTypes.GET_OPPORTUNITIES_SUCCESS,
  payload: { opportunities },
})

const getOpportunitiesFailure = errorMessage => ({
  type: OpportunityActionTypes.GET_OPPORTUNITIES_FAILURE,
  payload: { errorMessage },
})

export {
  setOrganisation,
  getOpportunities,
  getOpportunitiesFailure,
  getOpportunitiesSuccess,
}
