import styled from 'styled-components'

const Heading = styled.h3`
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: ${props => (props.hard ? '5px' : '66px')};
`

const SearchTerm = styled.span`
  color: ${props => props.theme.color.orange};
`

export { Heading, SearchTerm }
