import { createSelector } from 'reselect'
import { State } from '../store'
import { responseStatus } from '../../types'

const getDataStoreReducer = (state: State) => state.dataStore

const selectDataIsLoading = createSelector(
  [getDataStoreReducer],
  dataStore => dataStore.responseStatus === responseStatus.PENDING
)

const selectDataErrorMessage = createSelector(
  [getDataStoreReducer],
  dataStore => dataStore.errorMessage
)

const selectDataIsError = createSelector(
  [getDataStoreReducer],
  dataStore => !!dataStore.errorMessage
)

const selectData = (state: State, section: string) =>
  createSelector([getDataStoreReducer], dataStore =>
    dataStore && dataStore.data ? dataStore.data[section] : undefined
  )(state)

export {
  selectDataIsLoading,
  selectDataErrorMessage,
  selectDataIsError,
  selectData,
}
