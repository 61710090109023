import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import routes from '../../routes'
import AdminIdeasLayout from '../../pages/AdminIdeas'
import AdminIdeas from '../../pages/AdminIdeas'
import AdminIdea from '../../pages/AdminIdea'
import PrivateRoute from '../../components/PrivateRoute'
import { isNewAdmin } from '../../features'
import IdeasContainer from '../../components/Ideas'
import IdeaContainer from '../../components/Idea/IdeaContainer'
import AdminVolunteeringIdea from '../../pages/AdminVolunteeringIdea'
import * as IdeasNew from './AdminIdeas.container'
import { AdminIdeaDetailHeroContainer } from '../AdminIdea/AdminIdeaDetailHero.container'

const AdminRouter: React.ComponentType<RouteComponentProps> = () => {
  return (
    <>
      <PrivateRoute
        path={[
          routes.admin150Days,
          routes.adminIdeas,
          routes.adminLocalHeroes,
          routes.adminVolunteering,
        ]}
        exact
        render={() => (
          <AdminIdeasLayout>
            <IdeasNew.AdminIdeasContainer />
          </AdminIdeasLayout>
        )}
      />
      <PrivateRoute
        exact
        path={`${routes.admin150Days}/:idea_id`}
        render={() => (
          <AdminIdea route={routes.admin150Days}>
            <IdeaContainer />
          </AdminIdea>
        )}
      />
      <PrivateRoute
        exact
        path={`${routes.adminLocalHeroes}/:idea_id`}
        render={() => <AdminIdeaDetailHeroContainer />}
      />
      <PrivateRoute
        exact
        path={`${routes.adminVolunteering}/:idea_id`}
        render={() => <AdminVolunteeringIdea />}
      />
    </>
  )
}

const AdminRouterOld: React.ComponentType<RouteComponentProps> = () => {
  return (
    <>
      <PrivateRoute
        exact
        path={routes.adminIdeas}
        render={() => (
          <AdminIdeas>
            <IdeasContainer />
          </AdminIdeas>
        )}
      />
      <PrivateRoute
        exact
        path={`${routes.adminIdea}/:idea_id`}
        render={() => (
          <AdminIdea route={routes.adminIdeas}>
            <IdeaContainer />
          </AdminIdea>
        )}
      />
    </>
  )
}

AdminRouter.displayName = 'AdminRouter'

export const AdminRouterContainer = withRouter(
  isNewAdmin() ? AdminRouter : AdminRouterOld
)
