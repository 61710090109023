import React from 'react'
import Layout from '../layouts/Layout/Layout'
import EditIdeaConfirmationContainer from '../components/EditIdea/EditIdeaConfirmationContainer'

const EditIdeaConfirmation = () => {
  return (
    <Layout pageId="page-edit-idea-confirm">
      <EditIdeaConfirmationContainer />
    </Layout>
  )
}

export default EditIdeaConfirmation
