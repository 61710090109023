import { api, get } from '../../httpClient/httpClient'
import {
  getContentFaqs,
  getContentFaqsSuccess,
  getContentFaqsFailure,
} from '../actions/faqs'
import { getAuthToken } from '../selectors/auth'
import errorHandler from './errorHandler'
import { is150DaysSection } from '../../features'

const filterOut150DaysFetchedFaqs = data =>
  data.filter(
    (item, index) => !data[index].heading.toLowerCase().includes('150 days')
  )

const doFetchFaqs = () => async (dispatch, getState) => {
  dispatch(getContentFaqs())
  try {
    const token = getAuthToken(getState())
    const data = await get(api('/content/faqs'), token)
    const dataResult = is150DaysSection()
      ? data
      : filterOut150DaysFetchedFaqs(data)
    dispatch(getContentFaqsSuccess(dataResult))
  } catch (error) {
    errorHandler(dispatch, error, getContentFaqsFailure)
  }
}

export { doFetchFaqs }
