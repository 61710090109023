import React from 'react'
import { withTheme } from 'styled-components'
import MediaQuery from 'react-responsive'

const MobileQ = props => {
  return (
    <MediaQuery query={`(max-width: ${props.theme.mq.md})`}>
      {props.children}
    </MediaQuery>
  )
}
const Mobile = withTheme(MobileQ)
const DesktopQ = props => {
  return (
    <MediaQuery query={`(min-width: ${props.theme.mq.md})`}>
      {props.children}
    </MediaQuery>
  )
}
const Desktop = withTheme(DesktopQ)
export { Mobile, Desktop }
