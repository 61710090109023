import { State } from '../store'
import { createSelector } from 'reselect'
import { StatisticsType } from '../../types'

const adminIdeasReducer = (state: State) => state.adminIdeas
const adminDataStoreReducer = (state: State) => state.dataStore

const selectAdminIdeasData = createSelector(
  adminIdeasReducer,
  reducer => reducer.data
)

const selectAdminIdeaCount = createSelector(adminIdeasReducer, reducer =>
  reducer && reducer.data ? reducer.data.length : 0
)

const selectPageLast = createSelector(adminIdeasReducer, reducer =>
  reducer.controls && reducer.controls.pageLast ? reducer.controls.pageLast : 1
)

const selectPageActive = createSelector(adminIdeasReducer, reducer =>
  reducer.controls && reducer.controls.pageActive
    ? reducer.controls.pageActive
    : 1
)

const selectAdminIdeasStats = createSelector(
  [adminIdeasReducer, adminDataStoreReducer],
  (adminIdeasReducer, dataStoreReducer) => {
    const data =
      adminIdeasReducer.source && dataStoreReducer.data
        ? dataStoreReducer.data[adminIdeasReducer.source]
        : undefined
    if (data) {
      return data.reduce(
        (stats: StatisticsType, { ideaStatus }: any) => {
          if (stats && ideaStatus) {
            stats[ideaStatus.toLowerCase()]++
            stats.total++
          }
          return stats
        },
        {
          total: 0,
          bookmarked: 0,
          updated: 0,
          pending: 0,
          accepted: 0,
          declined: 0,
        }
      )
    }
  }
)

export {
  selectAdminIdeasData,
  selectAdminIdeaCount,
  selectPageLast,
  selectPageActive,
  selectAdminIdeasStats,
}
