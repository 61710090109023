import React from 'react'
import Layout from '../layouts/Layout/Layout'
import { PageHeader } from '../components/PageHeader'
import StudiesContainer from '../components/Studies/StudiesContainer'
import { Container, Content, VideoWrapper } from '../styles'

const CaseStudiesPage = () => (
  <Layout pageId="page-case-studies">
    <PageHeader
      title="Case Studies"
      blurb="Read case studies on how colleagues have helped organisations by volunteering within their local communities."
    />
    <Content>
      <Container.FalseBleed>
        <VideoWrapper>
          <iframe
            title="Our Community case studies video"
            src="https://www.youtube-nocookie.com/embed/3yT6vPQf974?controls=0"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </VideoWrapper>
        <StudiesContainer />
      </Container.FalseBleed>
    </Content>
  </Layout>
)

export default CaseStudiesPage
