import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { IconButton } from '@jsluna/button'
import { Delete } from '@jsluna/icons'
import IdeaActionModal from '../IdeaActionModal'
import { doDeleteIdeaNew } from '../../redux/dispatchers/idea'
import { idea_statuses } from '../../constants'
import campaignFields from '../../data/campaigns'
import { history } from '../../createHistory'
import { IdeaType } from '../../types'

interface IdeaDeleteProps {
  idea: IdeaType
}

const IdeaDelete: React.FC<IdeaDeleteProps> = ({ idea }) => {
  const dispatch = useDispatch()
  const [isVisible, setVisible] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const onConfirm = () => {
    setLoading(true)
    dispatch(doDeleteIdeaNew(idea.id, idea.campaign)).then(() => {
      history.replace(campaignFields[idea.campaign].adminRoute)
    })
  }

  return idea.idea_status === idea_statuses.DECLINED ? (
    <>
      <IconButton
        className="ln-u-color-red ln-u-bg-color-transparent"
        status={idea_statuses.ACCEPTED}
        onClick={() => setVisible(true)}
        data-id="idea-delete"
        title="Delete idea"
        label="Delete idea"
      >
        <Delete />
      </IconButton>
      <IdeaActionModal
        isLoading={isLoading}
        isOpen={isVisible}
        title={`You're about to delete idea ${idea.ref}`}
        message={`Are you sure you want to do this?`}
        cancelLabel="Cancel"
        confirmLabel="Yes"
        onConfirm={() => onConfirm()}
        onCancel={() => setVisible(false)}
      />
    </>
  ) : null
}

export default IdeaDelete
