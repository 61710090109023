import React from 'react'
import Layout from '../layouts/Layout/Layout'
import { PageHeader } from '../components/PageHeader'
import UserIdeas from '../sections/UserIdeas'
import { Content, Container } from '../styles'

const UserIdeasPage = () => {
  return (
    <Layout pageId="page-my-ideas">
      <PageHeader title="My ideas" />
      <Content>
        <Container.Wrapper>
          <UserIdeas />
        </Container.Wrapper>
      </Content>
    </Layout>
  )
}

export default UserIdeasPage
