import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.menu`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.mq.md}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const List = styled.ul`
  margin: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.mq.md}) {
    flex-direction: row;
    width: auto;
  }
`

const ListItem = styled.li`
  list-style: none;
  margin: 20px;

  @media (min-width: ${({ theme }) => theme.mq.md}) {
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

const ListLink = styled(NavLink)`
  text-decoration: none;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fontFamily};
  line-height: 24px;
  font-weight: bold;
  padding: 5px;
  width: 100%;
  display: block;
  text-align: center;
  transition: all 0.3s ease-in-out;
  color: ${({ theme }) => theme.color.black};
  &.active {
    color: ${({ theme }) => theme.color.orange};
  }
  &:hover {
    background: white;
  }
  @media (min-width: ${({ theme }) => theme.mq.md}) {
    display: inline-block;
  }
`

const ButtonList = styled.ul`
  display: inline-block;
  margin: 0;
  padding: 0;
  @media (max-width: ${({ theme }) => theme.mq.md}) {
    display: block;
    width: 100%;
  }
`

const ButtonListItem = styled.li`
  display: inline-block;
  margin: 8px;
  @media (max-width: ${({ theme }) => theme.mq.md}) {
    display: block;
  }
`

const ButtonLink = styled(Link).attrs({
  className: `ln-c-button ln-c-button--outlined ln-c-button--full`,
})`
  min-height: 40px;
`

export {
  Wrapper,
  List,
  ListItem,
  ListLink,
  ButtonList,
  ButtonListItem,
  ButtonLink,
}
