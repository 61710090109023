import { createSelector } from 'reselect'
import { State } from '../store'
import { Filters150Days } from '../../data/filters150Days'
import { FiltersHeroes } from '../../data/filtersHeroes'
import { FiltersVolunteering } from '../../data/filtersVolunteering'
import routes from '../../routes'
import { isFilterDisabled } from '../../util/filtersUtils'
import { SearchOptionType } from '../actions/filters'
import { is150DaysSection } from '../../features'

const getFiltersState = (state: State) => state.adminIdeas.filters

const selectFilters = createSelector([getFiltersState], filters => filters)

function selectConfigData(pathname: string): any {
  switch (pathname) {
    case routes.admin150Days:
      return Filters150Days
    case routes.adminVolunteering:
      return FiltersVolunteering
    case routes.adminIdeas:
      return is150DaysSection() ? Filters150Days : FiltersHeroes
    default:
      return FiltersHeroes
  }
}

const selectSearchValue = createSelector([getFiltersState], filterState => {
  return filterState.search[Object.keys(filterState.search)[0]] || ''
})

const selectSearchType = createSelector(
  [getFiltersState],
  filterState => filterState.type
)

const selectIsTextAreaDisabled = createSelector(
  [selectSearchType],
  searchType => searchType === ''
)

const selectFilterValues = createSelector(
  [getFiltersState],
  filterState => filterState.values
)

function selectIsFilterDisabled(
  state: State,
  pathname: string
): boolean | undefined {
  return createSelector([selectSearchType], searchType => {
    const configData = selectConfigData(pathname)
    const searchOptions: SearchOptionType[] = configData
      ? configData.searchOptions
      : []
    return isFilterDisabled(searchOptions, searchType)
  })(state)
}

export {
  selectFilters,
  selectConfigData,
  selectSearchValue,
  selectIsTextAreaDisabled,
  selectSearchType,
  selectFilterValues,
  selectIsFilterDisabled,
}
