import styled from 'styled-components'
import { Heading6 } from '@jsluna/typography'

const FormSection = styled.div.attrs(() => ({
  className: 'ln-u-soft-sides ln-u-soft-ends-sm ln-u-margin-bottom*2',
}))`
  background-color: ${props => props.theme.color.lighterGrey};
`

const FormHeading = styled(Heading6).attrs(() => ({
  className: 'ln-u-display-1-fixed ln-u-margin-bottom*1/2',
}))``

const FormLabel = styled.p.attrs(() => ({
  className: 'ln-u-margin-bottom',
}))`
  line-height: 1.25;
`

export { FormSection, FormHeading, FormLabel }
