import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { PrimaryButton } from 'luna-react'
import {
  Wrapper,
  FilterButton,
  SearchPanelWrapper,
  FormControl,
} from './Filters.styles'
import FiltersControlsContainer from './FiltersControls.container'
import FiltersSearchContainer from './FiltersSearch.container'

interface FiltersProps extends RouteComponentProps {
  applyFilters: (pathname: string) => void
}

const Filters: React.FC<FiltersProps> = ({ applyFilters, location }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleSubmit = () => {
    setIsOpen(false)
    applyFilters(location ? location.pathname : '')
  }

  return (
    <Wrapper>
      <FilterButton
        onClick={() => setIsOpen(!isOpen)}
        data-test-id="filter-button"
      >
        <h5>Filter</h5>
      </FilterButton>
      <SearchPanelWrapper isOpen={!isOpen}>
        <FormControl onSubmit={handleSubmit}>
          <FiltersSearchContainer />
          <FiltersControlsContainer />
          <PrimaryButton
            onClick={handleSubmit}
            data-test-id="filter-apply-button"
          >
            Apply
          </PrimaryButton>
        </FormControl>
      </SearchPanelWrapper>
    </Wrapper>
  )
}

export default Filters
