import { Dispatch, AnyAction } from 'redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { State } from '../../redux/store'
import ProgrammeComponent from './Programme.component'
import { doFetchProgramme } from '../../redux/dispatchers/programmeDetail'
import {
  selectProgrammeData,
  selectProgrammeKey,
  selectLoading,
  selectErrorMessage,
} from '../../redux/selectors/programmeDetail'

function mapStateToProps(state: State, ownProps: any): any {
  return {
    programmeKey: selectProgrammeKey(state),
    programmeData: selectProgrammeData(state),
    loading: selectLoading(state),
    errorMessage: selectErrorMessage(state),
  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction, State>) {
  return bindActionCreators(
    {
      getProgrammeData: (programmeKey: string) =>
        doFetchProgramme(programmeKey),
    },
    dispatch
  )
}

const ProgrammeContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProgrammeComponent)
)

export default ProgrammeContainer
