import { connect } from 'react-redux'
import IdeaFilterPanel from './IdeaFilterPanel'

const mapStateToProps = ({ ideaFilter }, { onApply = () => null }) => {
  return {
    fields: ideaFilter,
    onApply,
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(IdeaFilterPanel)
