import styled from 'styled-components'

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.lighterGrey};
  min-height: 100vh;
`

const Body = styled.main`
  padding: 100px 0 100px;
`

const Title = styled.h1`
  font-size: 26px;
  line-height: 32px;
  margin: 0;
  color: ${({ theme }) => theme.color.orange};
`
const Intro = styled.h2`
  font-size: 22px;
  margin-bottom: 16px;
`

const Logo = styled.img`
  margin-bottom: 45px;
`

const ButtonGroup = styled.div`
  display: flex;
  margin: 0 -5px;
  flex-wrap: wrap;
`

const ButtonWrapper = styled.div`
  padding: 5px;
  width: 100%;
  max-width: calc(100% / 2);

  @media (min-width: ${({ theme }) => theme.mq.ss}) {
    min-width: 200px;
    max-width: calc(100% / 3);
  }

  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    max-width: calc(100% / 5);
  }
`
const Button = styled.a`
  display: block;
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }
`
const ImgWrapper = styled.img`
  width: 100%;
`

export {
  Wrapper,
  Body,
  Title,
  Intro,
  Logo,
  ButtonGroup,
  ButtonWrapper,
  Button,
  ImgWrapper,
}
