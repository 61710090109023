import * as Sentry from '@sentry/browser'
import { shouldReportErrors } from '../features'
import { getTraceId } from './traceId'

const SENTRY_DSN = window && window.ENV && window.ENV.SENTRY_DSN

const initialiseErrorReporting = () => {
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
    })
    Sentry.configureScope(scope => {
      scope.setTag('trace_id', getTraceId())
    })
  }
}

const reportError = (error, extra) => {
  try {
    if (shouldReportErrors() && SENTRY_DSN) {
      Sentry.captureException(error, { extra })
    }
  } catch (error) {
    console.log({ error })
  }
}

export { reportError, initialiseErrorReporting }
