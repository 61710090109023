import { actionTypes } from '../actions/localHeroes'

const {
  GET_LOCAL_HEROES,
  GET_LOCAL_HEROES_SUCCESS,
  GET_LOCAL_HEROES_FAILURE,
} = actionTypes

const initialState = {
  localHeroes: undefined,
  loading: false,
  errorMessage: null,
}

const localHeroesReducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case GET_LOCAL_HEROES:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      }
    case GET_LOCAL_HEROES_SUCCESS:
      return {
        ...state,
        localHeroes: payload.localHeroes,
        loading: false,
      }
    case GET_LOCAL_HEROES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
      }
    default:
      return state
  }
}

export { initialState, localHeroesReducer }
