import { Dispatch, AnyAction } from 'redux'
import { State } from '../store'
import { api, get } from '../../httpClient/httpClient'
import { getAuthToken } from '../selectors/auth'
import errorHandler from './errorHandler'
import {
  getProgrammeRequest,
  getProgrammeSuccess,
  getProgrammeFailure,
} from '../actions/programmeDetail'

export function doFetchProgramme(programmeKey: string) {
  const programmeKeyEncoded = encodeURIComponent(programmeKey)
  const endpoint = '/content/programme?title='
  const path = `${endpoint}${programmeKeyEncoded}`

  return async (
    dispatch: Dispatch<AnyAction, State>,
    getState: () => State
  ) => {
    dispatch(getProgrammeRequest(programmeKey))

    try {
      const token = getAuthToken(getState())
      const data = await get(api(path), token)

      dispatch(getProgrammeSuccess(data))
    } catch (error) {
      errorHandler(dispatch, error, getProgrammeFailure)
    }
  }
}
