import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Faqs from './Faqs'
import { doFetchFaqs } from '../../redux/dispatchers/faqs'
import {
  getLoading,
  getErrorMessage,
  getFaqs,
} from '../../redux/selectors/faqs'

const mapStateToProps = state => ({
  loading: getLoading(state),
  errorMessage: getErrorMessage(state),
  faqs: getFaqs(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doFetchFaqs,
    },
    dispatch
  )

const FaqsContainer = connect(mapStateToProps, mapDispatchToProps)(Faqs)

export default FaqsContainer
