import React from 'react'
import FormattedDate from '../FormattedDate/FormattedDate'
import { TableCard } from '../../styles'

export interface IdeaSummaryProps {
  idea: any
  currentIndex: number
  key: number
  routeTo: string
}

const IdeaSummary: React.FC<IdeaSummaryProps> = ({
  idea,
  currentIndex,
  routeTo,
}) => {
  const {
    id,
    business,
    colleague_name,
    contact_email,
    idea_status = '',
    created_date,
    ref,
    category,
  } = idea
  return (
    <TableCard.Wrapper
      to={`${routeTo}/${id}`}
      data-id={`idea-${currentIndex}`}
      data-ref={`${ref}`}
      data-status={`${idea_status}`}
      status={idea_status}
    >
      <TableCard.Block>
        <TableCard.Legend>Reference</TableCard.Legend>
        <TableCard.Label>{ref}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend> Date Submitted</TableCard.Legend>
        <TableCard.Label>
          <FormattedDate date={created_date} />
        </TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Team Leader</TableCard.Legend>
        <TableCard.Label> {colleague_name}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Email</TableCard.Legend>
        <TableCard.Label> {contact_email}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Store/team</TableCard.Legend>
        <TableCard.Label>
          {Array.isArray(business) ? business.join(', ') : business}
        </TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Category</TableCard.Legend>
        <TableCard.Label> {category}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block data-id="ideas_status">
        <TableCard.Legend>Status</TableCard.Legend>
        <TableCard.Label status={idea_status}>
          {idea_status.toLowerCase()}
        </TableCard.Label>
      </TableCard.Block>
    </TableCard.Wrapper>
  )
}

export default IdeaSummary
