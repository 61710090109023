import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import routes from '../../routes'
import Organisation from '../../pages/Organisation'
import OpportunitiesView from '../../pages/OpportunitiesView'
import OrganisationsView from '../../pages/OrganisationsView'

class Router extends React.Component {
  componentDidMount() {
    this.props.doFetchOpportunities()
  }
  render() {
    return (
      <Route
        path={routes.opportunities}
        render={({ match }) => {
          return (
            <Switch>
              <Route
                exact
                path={`${match.url}/`}
                component={OpportunitiesView}
              />
              <Route
                exact
                path={`${match.url}/search`}
                component={OpportunitiesView}
              />
              <Route
                exact
                path={`${match.url}/org/`}
                component={OrganisationsView}
              />
              <Route
                exact
                path={`${match.url}/org/search`}
                component={OrganisationsView}
              />
              <Route
                exact
                path={`${match.url}/org/:organisation_slug/:organisation_id`}
                component={Organisation}
              />
              <Route
                exact
                path={`${match.url}/:organisation_slug/:opportunity_id`}
                component={Organisation}
              />
            </Switch>
          )
        }}
      />
    )
  }
}

Router.propTypes = {
  doFetchOpportunities: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
}

export default Router
