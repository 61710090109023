import React from 'react'
import styled from 'styled-components'
import { FooterLanding } from '../components/Footer'
import {
  Wrapper,
  Body,
  Title,
  Logo,
  ButtonGroup,
  ButtonWrapper,
  Button,
  ImgWrapper,
  Intro,
} from '../styles/Landing'
import logo from '../assets/logo.svg'
import { Container, Divider } from '../styles'

import sainsburys from '../assets/logos/sainsburys.png'
import sainsburysbank from '../assets/logos/sainsburysbank.png'
import argos from '../assets/logos/argos.png'
import habitat from '../assets/logos/habitat.png'
import nectar from '../assets/logos/nectar.png'

const HelpMessage = styled.h6`
  display: block;
  margin: 1.5rem auto;
  text-align: center;
  font-size: 18px;
  color: ${({ theme }) => theme.color.orange};
`
const Landing = () => {
  const SSO = (window && window.ENV && window.ENV.SSO) || ''
  return (
    <Wrapper data-id="page-landing">
      <Body>
        <Container.Padded>
          <Logo src={logo} alt="Together we are Sainsbury’s" />
          <Title>Our Community</Title>
          <Divider margin={'10px 0 20px'} />
          <Intro>
            Welcome to Sainsbury’s Group’s Our Community, this website is for
            Sainsbury’s Group colleagues only
          </Intro>
          <p>
            Select one of the logos below to be redirected to your
            organisation’s personalised login page:
          </p>
          <ButtonGroup>
            <ButtonWrapper>
              <Button
                data-id="login-btn-sainsburys"
                element="a"
                href={SSO.js || ''}
              >
                <ImgWrapper img src={sainsburys} alt="Sainsbury’s Logo" />
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button data-id="login-btn-bank" element="a" href={SSO.js || ''}>
                <ImgWrapper
                  img
                  src={sainsburysbank}
                  alt="Sainsbury’s Bank Logo"
                />
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                data-id="login-btn-argos"
                element="a"
                href={SSO.argos || ''}
              >
                <ImgWrapper img src={argos} alt="Argos Logo" />
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                data-id="login-btn-habitat"
                element="a"
                href={SSO.argos || ''}
              >
                <ImgWrapper img src={habitat} alt="Habitat" />
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                data-id="login-btn-nectar"
                element="a"
                href={SSO.js || ''}
              >
                <ImgWrapper img src={nectar} alt="Nectar" />
              </Button>
            </ButtonWrapper>
          </ButtonGroup>
        </Container.Padded>
        <HelpMessage>
          If you have problems accessing the platform, please use Chrome
        </HelpMessage>
      </Body>
      <FooterLanding />
    </Wrapper>
  )
}

export default Landing
