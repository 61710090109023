import React from 'react'
import { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'
import theme from '../styles/theme'

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>{children}</>
  </ThemeProvider>
)

export default Theme

Theme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
