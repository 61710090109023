import { actionTypes } from '../actions/ideas'

const {
  GET_IDEAS,
  GET_IDEAS_SUCCESS,
  GET_IDEAS_FAILURE,
  UPDATE_SORT_BY,
  CLEAR_IDEAS,
} = actionTypes

const initialState = {
  ideas: [],
  loading: false,
  errorMessage: null,
  sort: {
    by: 'created_date',
    direction: 'desc',
  },
  stats: null,
}

const updateSortByAndDirection = (state, by) => {
  let direction
  if (by === state.sort.by) {
    if (state.sort.direction === 'desc') {
      direction = 'asc'
    } else {
      direction = 'desc'
    }
  } else {
    direction = 'desc'
  }
  return { by, direction }
}

const ideasReducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case GET_IDEAS:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      }
    case GET_IDEAS_SUCCESS:
      return {
        ...state,
        ideas: payload.ideas,
        loading: false,
      }
    case GET_IDEAS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
      }
    case UPDATE_SORT_BY:
      return {
        ...state,
        sort: updateSortByAndDirection(state, payload.by),
      }
    case CLEAR_IDEAS:
      return initialState
    default:
      return state
  }
}

export { initialState, ideasReducer, updateSortByAndDirection }
