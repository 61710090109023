import styled from 'styled-components'
import { Heading6 } from '@jsluna/typography'

const Section = styled.div`
  background-color: ${props => props.theme.color.lighterGrey};
  padding: 10px 20px 10px;
  width: 100%;
  margin: 0 auto 20px auto;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    width: 88%;
  }
`

const SectionHeader = styled(Heading6)`
  font-size: 18px;
  margin-bottom: 0.5rem;
`

const RequiredSectionMarker = styled.span`
  color: ${props => props.theme.color.red};
  padding-left: 3px;
`

export { Section, SectionHeader, RequiredSectionMarker }
