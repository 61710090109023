import React from 'react'
import routes from '../../routes'
import FormattedDate from '../FormattedDate/FormattedDate'
import { TableCard } from '../../styles'

export interface IdeasDataRowVolunteeringProps {
  idea: any
  currentIndex: number
}

const IdeasDataRowVolunteering: React.FC<IdeasDataRowVolunteeringProps> = ({
  idea,
  currentIndex,
}) => {
  const {
    id,
    storeName,
    ideaStatus,
    authorEmail,
    createdDate,
    organisationName,
    volunteeringDate,
    ref,
  } = idea

  return (
    <TableCard.Wrapper
      to={`${routes.adminVolunteering}/${id}`}
      data-id={`idea-${currentIndex}`}
      data-ref={`${ref}`}
      data-status={`${ideaStatus}`}
      status={ideaStatus}
    >
      <TableCard.Block>
        <TableCard.Legend>Reference</TableCard.Legend>
        <TableCard.Label>{ref}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Date Submitted</TableCard.Legend>
        <TableCard.Label>
          <FormattedDate date={createdDate} />
        </TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Email</TableCard.Legend>
        <TableCard.Label> {authorEmail}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Store or team</TableCard.Legend>
        <TableCard.Label>{storeName}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Project Date</TableCard.Legend>
        <TableCard.Label> {volunteeringDate}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Organisation</TableCard.Legend>
        <TableCard.Label> {organisationName}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block data-id="ideas_status">
        <TableCard.Legend>Status</TableCard.Legend>
        <TableCard.Label status={ideaStatus}>
          {ideaStatus.toLowerCase()}
        </TableCard.Label>
      </TableCard.Block>
    </TableCard.Wrapper>
  )
}

export default IdeasDataRowVolunteering
