import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  fontFamily: "MaryAnn, 'Trebuchet MS', sans-serif",
  color: {
    orange: '#F06C00',
    darkOrange: '#E55000',
    black: '#0C0C0C',
    grey: '#8E8E8E',
    darkGrey: '#4C4C4C',
    lightGrey: '#D7D7D7',
    lightestGrey: '#EDEDED',
    lighterGrey: '#F6F6F6',
    white: '#FFF',
    plum: '#7F0442',
    green: '#218234',
    yellow: '#FFC911',
    red: '#D70000',
    darkRed: '#e30613',
    aqua: '#00A9CF',
  },
  mq: {
    xs: '480px',
    ss: '600px',
    sm: '720px',
    md: '1200px',
    xl: '1400px',
    xxl: '1800px',
  },
  header: {
    height: '75px',
  },
  status: {
    DEFAULT: '#8E8E8E',
    ACCEPTED: '#218234',
    UPDATED: '#00A9CF',
    DECLINED: '#D70000',
    PENDING: '#FFC911',
  },
}

export default theme
