import styled from 'styled-components'

export const TwoColContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 40px;
`
