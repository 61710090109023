import {
  SelectField,
  TextAreaField as LunaTextAreaField,
  TextInputField,
  RadioButtonField,
} from 'luna-react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import FormItemCheckbox from './FormItemCheckbox'
import { doUpdateForm as doUpdateIdeaForm } from '../../../../redux/dispatchers/newIdea'
import { doUpdateForm as doUpdateLocalHeroesForm } from '../../../../redux/dispatchers/localHeroes'

const TextAreaField = styled(LunaTextAreaField)`
  display: flex;
`

const getFormUpdateAction = formName => {
  switch (formName) {
    case 'local-heroes':
      return doUpdateLocalHeroesForm
    default:
      return doUpdateIdeaForm
  }
}

const getFormReducer = (state = {}, formName) => {
  switch (formName) {
    case 'local-heroes':
      return state.localHeroes
    default:
      return state.newIdea
  }
}

export const getValueKey = (type = '') => {
  switch (type) {
    case 'checkbox':
    case 'radio':
    case 'textarea':
      return 'value'
    default:
      return 'defaultValue'
  }
}

const mapStateToProps = (state, { name, type, form, ...rest }) => {
  const formReducer = getFormReducer(state, form)
  return {
    error: formReducer.formErrors[name],
    [getValueKey(type)]: formReducer.formValues[name] || '',
    name,
    ...rest,
  }
}

const mapDispatchToProps = (dispatch, { form = 'new-idea' }) => {
  const updateAction = getFormUpdateAction(form)
  const onChange = evt => {
    const { name, value } = evt.target
    dispatch(updateAction(name, value))
  }
  const onBlur = evt => {
    const { name, value } = evt.target
    dispatch(updateAction(name, value))
  }
  return {
    onChange,
    onBlur,
  }
}

const wrapper = Field => connect(mapStateToProps, mapDispatchToProps)(Field)

export const FormFields = {
  text: wrapper(TextInputField),
  radio: wrapper(RadioButtonField),
  textarea: wrapper(TextAreaField),
  email: wrapper(TextInputField),
  date: wrapper(TextInputField),
  number: wrapper(TextInputField),
  select: wrapper(SelectField),
  checkbox: wrapper(FormItemCheckbox),
}
