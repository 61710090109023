import React, { Component } from 'react'
import routes from '../../routes'
import FormattedDate from '../FormattedDate/FormattedDate'
import { TableCard } from '../../styles'

class UserIdeaSummary extends Component {
  render() {
    const {
      id,
      volunteering_idea,
      organisation_name,
      idea_status,
      created_date,
      ref,
    } = this.props.idea

    return (
      <TableCard.Wrapper
        to={`${routes.userIdeas}/${this.props.campaign}/${id}`}
        data-id={`idea-${this.props.count}`}
        data-ref={ref}
        data-status={`${idea_status}`}
        status={idea_status}
      >
        <TableCard.Block data-id="ref" data-ref={ref}>
          <TableCard.Legend>Reference</TableCard.Legend>
          <TableCard.Label>{ref}</TableCard.Label>
        </TableCard.Block>
        <TableCard.Block>
          <TableCard.Legend> Date Submitted</TableCard.Legend>
          <TableCard.Label>
            <FormattedDate date={created_date} />
          </TableCard.Label>
        </TableCard.Block>
        <TableCard.Block>
          <TableCard.Legend>Organisation</TableCard.Legend>
          <TableCard.Label>{organisation_name}</TableCard.Label>
        </TableCard.Block>
        <TableCard.Block size={3}>
          <TableCard.Legend>Idea</TableCard.Legend>
          <TableCard.Label>{volunteering_idea}</TableCard.Label>
        </TableCard.Block>
        <TableCard.Block data-id="ideas_status">
          <TableCard.Legend>Status</TableCard.Legend>
          <TableCard.Label status={idea_status}>{idea_status}</TableCard.Label>
        </TableCard.Block>
      </TableCard.Wrapper>
    )
  }
}

export default UserIdeaSummary
