import { createSelector } from 'reselect'
import { isIdTypeSelected } from '../actions/ideaFilter'

const getIdeaFilterState = state => state.ideaFilter

const getIdeaFilterType = createSelector(
  [getIdeaFilterState],
  ideaFilter => ideaFilter.type
)

const getIdeaFilterValue = createSelector(
  [getIdeaFilterState],
  ideaFilter => ideaFilter.filter.value
)

const isSearchDisabled = createSelector(
  [getIdeaFilterType, getIdeaFilterValue],
  (type, filterValues) =>
    !type ||
    (isIdTypeSelected(type) &&
      Object.values(filterValues).reduce(
        (acc, value) =>
          acc ||
          !!Object.values(value).filter(filterValue => filterValue).length,
        false
      ))
)

const isFilterDisabled = createSelector(
  [getIdeaFilterType],
  type => !!type && isIdTypeSelected(type)
)

export { isSearchDisabled, isFilterDisabled }
