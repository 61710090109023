import React from 'react'
import { Tick, Cancel, AwardWinning } from '@jsluna/icons'
import solidBookmark from '../../assets/bookmark-solid.svg'
import styled from 'styled-components'

const BookmarkIcon = styled.div`
  background: transparent url(${solidBookmark}) center center / 37.5% no-repeat;
  width: inherit;
  height: inherit;
`
const Pending = () => <span>!</span>
const Updated = () => <b>↻</b>

const Icons = Object.freeze({
  PENDING: Pending,
  ACCEPTED: Tick,
  COMPLETED: AwardWinning,
  DECLINED: Cancel,
  UPDATED: Updated, // TODO change this to an SVG
  BOOKMARKED: BookmarkIcon,
})

export default Icons
