import { parse } from 'query-string'

const isValidTeamSize = teamSize => !Number.isNaN(teamSize) && teamSize > 0

const getTeamSizeFromLocation = location => {
  let teamSize
  try {
    if (location && location.search) {
      teamSize = parse(location.search).team_size
    }
  } catch (e) {
    teamSize = null
  }
  if (isValidTeamSize(teamSize)) {
    return teamSize
  }
  return null
}

export { getTeamSizeFromLocation, isValidTeamSize }
