import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { Panel } from '../../styles'
import {
  OrderedList,
  UnnorderedList,
  ListItem,
  ListItemP,
} from './ContentfulText.styles'

interface ContentfulTextProps {
  content: any
}

const ListParagraph = (node: any, children: any) => (
  <ListItemP>{children}</ListItemP>
)

const listItemOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: ListParagraph,
  },
}

const OlList = (node: any, children: any) => (
  <OrderedList>{children}</OrderedList>
)
const UlList = (node: any, children: any) => (
  <UnnorderedList>{children}</UnnorderedList>
)
const ItemList = (node: any, children: any) => (
  <ListItem>{documentToReactComponents(node, listItemOptions)}</ListItem>
)
const Paragraph = (node: any, children: any) => <Panel.B>{children}</Panel.B>
const Quote = (node: any, children: any) => (
  <Panel.Blockquote>{children}</Panel.Blockquote>
)

const options = {
  renderNode: {
    [BLOCKS.OL_LIST]: OlList,
    [BLOCKS.UL_LIST]: UlList,
    [BLOCKS.LIST_ITEM]: ItemList,
    [BLOCKS.PARAGRAPH]: Paragraph,
    [BLOCKS.QUOTE]: Quote,
  },
}

const ContentfulText: React.FC<ContentfulTextProps> = ({ content }) => {
  if (!content) {
    return null
  }

  return <>{documentToReactComponents(content, options)}</>
}

export default ContentfulText
