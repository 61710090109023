import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Ideas2 from './Ideas'
import {
  doFetchIdeas,
  doUpdateSort,
  doDownloadIdeas,
  doClearIdeas,
} from '../../redux/dispatchers/ideas'
import {
  getLoading,
  getErrorMessage,
  getSortedIdeas,
  getStats,
} from '../../redux/selectors/ideas'

const mapStateToProps = state => ({
  loading: getLoading(state),
  errorMessage: getErrorMessage(state),
  ideas: getSortedIdeas(state),
  stats: getStats(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doFetchIdeas,
      doUpdateSort,
      doDownloadIdeas,
      doClearIdeas,
    },
    dispatch
  )

const Ideas = Ideas2

const IdeasContainer = connect(mapStateToProps, mapDispatchToProps)(Ideas)

export default IdeasContainer
