import React, { useState } from 'react'
import { ToggleButtonGroup, ToggleButton } from 'luna-react'
import { StoreLocation, ListView } from 'luna-icons'
import { VIEW as cVIEW } from '../../constants'

const ToogleMaplist = ({ VIEW, setView }) => {
  const [state, setState] = useState(VIEW)
  const buttons = [
    { label: 'List', value: cVIEW.LIST, icon: ListView },
    { label: 'Map', value: cVIEW.MAP, icon: StoreLocation },
  ]

  const handleToggle = (value, event) => {
    event.preventDefault()
    if (value === state) return
    setState(value)
    setView(value)
  }

  return (
    <ToggleButtonGroup
      inline
      label="Results view"
      value={state}
      onChange={(event, value) => handleToggle(value, event)}
    >
      {buttons.map(({ label, value, icon: Icon }) => (
        <ToggleButton key={value} value={value} id={value}>
          <Icon className="ln-c-icon ln-c-icon--small" size="Small" />
          <span style={{ fontSize: '16px', paddingLeft: '10px' }}>{label}</span>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default ToogleMaplist
