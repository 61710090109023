import React, { useEffect } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { CardImage } from '../../styles'
import routes from '../../routes'
import {
  CaseStudyCard,
  CaseStudyLink,
  CaseStudyText,
  CaseStudyTitle,
} from '../../styles/Home'

const HomeCaseStudies = ({ studies, doFetchStudies }) => {
  useEffect(() => {
    if (studies.length === 0) {
      doFetchStudies()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {studies.map((study, index) => (
        <CaseStudyCard hard key={study.id}>
          <CaseStudyLink
            data-id={`case-study-${index + 1}`}
            to={`${routes.caseStudies}/${study.titleSlug}`}
          >
            {study.image && <CardImage src={study.image.fields.file.url} />}
            <CaseStudyText align="left" size={16}>
              {documentToReactComponents(study.detail)[0]}
            </CaseStudyText>
            <CaseStudyTitle element="div" level={5}>
              {study.title}
            </CaseStudyTitle>
          </CaseStudyLink>
        </CaseStudyCard>
      ))}
    </>
  )
}

export default HomeCaseStudies
