import { createSelector } from 'reselect'

const getStudiesState = state => state.studiesReducer

const getLoading = createSelector(
  [getStudiesState],
  studiesReducer => studiesReducer.loading
)

const getErrorMessage = createSelector(
  [getStudiesState],
  studiesReducer => studiesReducer.errorMessage
)

const getStudies = createSelector(
  [getStudiesState],
  studiesReducer => studiesReducer.studies
)

const getHomeStudies = createSelector([getStudies], studies =>
  studies.filter(caseStudy => caseStudy.viewInHomepage).slice(0, 2)
)

export { getStudies, getHomeStudies, getLoading, getErrorMessage }
