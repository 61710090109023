import React from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { doFormUpdate } from '../../../redux/dispatchers/ideaForm'
import { selectFormFieldState } from '../../../redux/selectors/ideaForm'
import { FormFields } from '../FormFields'
import { FormLabel } from './styles'

class FormItem extends React.Component {
  render() {
    const { field, value, error, visible, onChange } = this.props
    const FormField = FormFields[field.type]

    if (!FormFields[field.type] || !visible) {
      return null
    }

    return (
      <div data-id="idea-form-item">
        <FormLabel>
          {field.label}
          {field.required && <span className="ln-u-color-red"> *</span>}
        </FormLabel>
        <FormField
          type={field.type}
          name={field.name}
          options={field.options}
          maxLength={field.maxLength}
          placeholder={field.placeholder}
          disabled={field.disabled ? true : null}
          onChange={onChange}
          onBlur={onChange}
          error={error}
          value={value}
        />
      </div>
    )
  }
}

FormItem.propTypes = {
  formType: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  error: PropTypes.string,
  visible: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const field = ownProps.item
  const fieldState = selectFormFieldState(ownProps.formType, field.name)(state)

  return {
    field,
    ...fieldState,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const onChange = evt => {
    const { name, value } = evt.target
    dispatch(doFormUpdate(ownProps.formType, name, value))
  }

  return {
    onChange,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormItem)
