import { date, array, string } from 'yup'
import {
  MAX_SHORT_TEXT_LENGTH,
  MAX_LONG_TEXT_LENGTH,
  BUSINESS_AREAS,
  CATEGORIES,
  ACTIVITIES,
  VOLUNTEERING_COLLEAGUES,
  VOLUNTEERING_HOURS,
  mapOptions,
} from './volunteeringFormOptions'

const name = 'volunteering'
const path = '/volunteering'
const endpoint = '/volunteers'
const fields = [
  {
    header: 'Email address',
    items: [
      {
        name: 'author_email',
        label: 'Correspondence will be sent to this email address.',
        placeholder: 'e.g. firstname.surname@sainsburys.co.uk',
        type: 'email',
        disabled: true,
      },
    ],
  },
  {
    header: 'Do you have an inbox?',
    items: [
      {
        name: 'inbox',
        label: 'Are you able to pick up emails using the email address above?',
        type: 'radio',
        options: [
          {
            label: 'Yes',
            value: 'Yes',
          },
          {
            label: 'No',
            value: 'No',
          },
        ],
        validation: string()
          .oneOf(['Yes', 'No'])
          .required(),
      },
      {
        name: 'alternate_email',
        label:
          'Please provide an alternate email address where you are able to receive and read emails.',
        placeholder: 'e.g. name@gmail.com',
        type: 'email',
        required: true,
        validation: string().when('inbox', function(
          value: string[],
          schema: any
        ) {
          return value && value.includes('No')
            ? schema
                .email('Must be a valid email address')
                .required('Alternate email address is required')
            : schema
        }),
        visibility: { inbox: 'No' },
      },
    ],
  },
  {
    header: 'Business',
    items: [
      {
        name: 'business',
        label: 'Business area',
        type: 'checkbox',
        required: true,
        options: mapOptions(BUSINESS_AREAS),
        validation: array()
          .min(1, 'Business area is required')
          .required(),
      },
      {
        name: 'store_name',
        label: 'Store/site name or division',
        placeholder: 'e.g. Holborn Circus Sainsbury’s Store',
        type: 'text',
        required: true,
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: string()
          .max(MAX_SHORT_TEXT_LENGTH, 'This is too long')
          .required('Store/site name or division is required'),
      },
      {
        name: 'store_number',
        label: 'Store number (if applicable)',
        placeholder: 'e.g. 2345',
        type: 'text',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: string().max(MAX_SHORT_TEXT_LENGTH, 'This is too long'),
      },
      {
        name: 'region',
        label: 'Region',
        placeholder: 'e.g. West London',
        type: 'text',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: string().max(MAX_SHORT_TEXT_LENGTH, 'This is too long'),
      },
    ],
  },
  {
    header: 'Organisation',
    items: [
      {
        name: 'organisation_name',
        label: 'Organisation name',
        placeholder: 'e.g. Amber Communities Trust',
        type: 'text',
        required: true,
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: string()
          .max(MAX_SHORT_TEXT_LENGTH, 'This is too long')
          .required('Organisation name is required'),
      },
      {
        name: 'organisation_website',
        label: 'Organisation website',
        placeholder: 'e.g. https://www.website.com',
        type: 'text',
        required: true,
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: string()
          .max(MAX_SHORT_TEXT_LENGTH, 'This is too long')
          .required('Organisation website is required'),
      },
      {
        name: 'category',
        label: 'Category',
        type: 'checkbox',
        options: mapOptions(CATEGORIES),
        required: true,
        validation: array()
          .min(1, 'You need to select at least one')
          .required(),
      },
    ],
  },
  {
    header: 'Volunteering project',
    items: [
      {
        name: 'volunteering_colleagues',
        label: 'How many colleagues will take part in the activity?',
        type: 'select',
        options: VOLUNTEERING_COLLEAGUES,
        required: true,
        validation: string()
          .required('Number of colleagues required')
          .oneOf(
            VOLUNTEERING_COLLEAGUES.map(({ value }) => value),
            'Number of colleagues required'
          ),
      },
      {
        name: 'volunteering_hours',
        label:
          'How many hours do you need per colleague to complete this project?',
        type: 'select',
        options: VOLUNTEERING_HOURS,
        required: true,
        validation: string()
          .required('Number of hours required')
          .oneOf(
            VOLUNTEERING_HOURS.map(({ value }) => value),
            'Number of hours required'
          ),
      },
      {
        name: 'volunteering_date',
        label: 'Estimated date of project',
        type: 'date',
        required: true,
        validation: date().required(),
      },
      {
        name: 'volunteering_impact_detail',
        label: 'How do you plan to measure the impact of your project?',
        type: 'textarea',
        maxLength: MAX_LONG_TEXT_LENGTH,
        required: true,
        validation: string()
          .max(MAX_LONG_TEXT_LENGTH, 'This is too long')
          .required(
            'Please tell us how you’d like to measure the impact of your project?'
          ),
      },
      {
        name: 'volunteering_project_detail',
        label: 'Volunteering project detail',
        type: 'textarea',
        maxLength: MAX_LONG_TEXT_LENGTH,
        required: true,
        validation: string()
          .max(MAX_LONG_TEXT_LENGTH, 'This is too long')
          .required('Please provide some details'),
      },
      {
        name: 'activity',
        label: 'Activity',
        type: 'checkbox',
        options: mapOptions(ACTIVITIES),
        required: true,
        validation: array()
          .min(1, 'You need to select at least one')
          .required(),
      },
      {
        name: 'activity_other',
        label: 'Please provide some details',
        type: 'text',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        required: true,
        validation: string().when('activity', function(
          value: string,
          schema: any
        ) {
          return value && value.includes('Other')
            ? schema
                .max(MAX_SHORT_TEXT_LENGTH, 'This is too long')
                .required('Details are required')
            : schema
        }),
        visibility: { activity: 'Other' },
      },
      {
        name: 'volunteering_unavoidable_costs',
        label: 'Does this project have any unavoidable associated costs?',
        type: 'radio',
        value: 'Yes',
        options: [
          {
            value: 'No',
            label: 'No',
          },
          {
            value: 'Yes',
            label: 'Yes',
          },
        ],
        required: true,
        validation: string().required(),
      },
      {
        name: 'volunteering_unavoidable_costs_details',
        label: 'Please provide some details',
        type: 'textarea',
        maxLength: MAX_LONG_TEXT_LENGTH,
        required: true,
        validation: string().when('volunteering_unavoidable_costs', function(
          value: string,
          schema: any
        ) {
          return value === 'Yes'
            ? schema.required('Details are required')
            : schema
        }),
        visibility: { volunteering_unavoidable_costs: 'Yes' },
      },
    ],
  },
  {
    header: 'Stardust moments',
    items: [
      {
        name: 'stardust_moments',
        label:
          'Please provide any ideas on how we could help enhance the impact of your project',
        type: 'textarea',
        maxLength: MAX_LONG_TEXT_LENGTH,
        validation: string().max(MAX_LONG_TEXT_LENGTH, 'This is too long'),
      },
    ],
  },
]

export { name, path, endpoint, fields }
