import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import HomeCaseStudies from './HomeCaseStudies'
import { getHomeStudies } from '../../redux/selectors/studies'
import { doFetchStudies } from '../../redux/dispatchers/studies'

const mapStateToProps = state => {
  return {
    studies: getHomeStudies(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doFetchStudies,
    },
    dispatch
  )
const HomeCaseStudiesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeCaseStudies)

export default HomeCaseStudiesContainer
