import React from 'react'

import { CheckboxField } from 'luna-react'
import { VALID_CATEGORIES, VALID_BUSINESSES } from '../../data/ideasDomain'
import DateRangeFilter from '../DateRangeFilter/DateRangeFilter'
import {
  BusinessFilterWrapper,
  StatusFilterWrapper,
  CategoryFilterWrapper,
  IdeasFilterWrapper,
  FilterHeader,
  DateRangeFilterWrapper,
  Filter,
} from './styles'
import { parseDateRange } from '../../util/ideaFilterUtils'

const VALID_STATUS = [
  { label: 'Pending', value: 'PENDING' },
  { label: 'Accepted', value: 'ACCEPTED' },
  { label: 'Updated', value: 'UPDATED' },
  { label: 'Declined', value: 'DECLINED' },
  { label: 'Bookmarked', value: 'BOOKMARKED' },
]

const IdeaFilters = ({ onChange, onRangeChange, disabled, fields }) => {
  const handleCheckboxSelect = key => e => {
    const value = { value: e.target.value, selected: e.target.checked }
    onChange(key, value)
  }

  const handleDateRangeChange = ({ start, end }) => {
    if (start && end) {
      onRangeChange({
        [start]: true,
        [end]: true,
      })
    } else {
      onRangeChange({})
    }
  }
  const { start = null, end = null } = parseDateRange(fields['created_date'])
  return (
    <IdeasFilterWrapper>
      <StatusFilterWrapper>
        <FilterHeader>Status</FilterHeader>
        <Filter>
          <CheckboxField
            disabled={disabled}
            name="status_filter"
            options={VALID_STATUS.map(status => ({
              ...status,
              checked: fields['idea_status'][status.value] || false,
            }))}
            onChange={handleCheckboxSelect('idea_status')}
          />
        </Filter>
      </StatusFilterWrapper>
      <BusinessFilterWrapper>
        <FilterHeader>Store/Team</FilterHeader>
        <Filter>
          <CheckboxField
            disabled={disabled}
            name="status_filter"
            options={VALID_BUSINESSES.map(business => ({
              ...business,
              checked: fields['business'][business.value] || false,
            }))}
            onChange={handleCheckboxSelect('business')}
          />
        </Filter>
      </BusinessFilterWrapper>
      <CategoryFilterWrapper>
        <FilterHeader>Category</FilterHeader>
        <Filter>
          <CheckboxField
            disabled={disabled}
            name="category_filter"
            options={VALID_CATEGORIES.map(category => ({
              ...category,
              checked: fields['category'][category.value],
            }))}
            onChange={handleCheckboxSelect('category')}
          />
        </Filter>
      </CategoryFilterWrapper>

      <DateRangeFilterWrapper>
        <FilterHeader>Date Range</FilterHeader>
        <DateRangeFilter
          disabled={disabled}
          start={start}
          end={end}
          onChange={handleDateRangeChange}
        />
      </DateRangeFilterWrapper>
    </IdeasFilterWrapper>
  )
}
export default IdeaFilters
