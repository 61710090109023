export const actionTypes = {
  GET_EDIT_IDEA: 'GET_EDIT_IDEA',
  GET_EDIT_IDEA_SUCCESS: 'GET_EDIT_IDEA_SUCCESS',
  GET_EDIT_IDEA_FAILURE: 'GET_EDIT_IDEA_FAILURE',
  SAVE_EDIT_IDEA_ID: 'SAVE_EDIT_IDEA_ID',
  REMOVE_EDIT_IDEA_ID: 'REMOVE_EDIT_IDEA_ID',
}

const {
  GET_EDIT_IDEA,
  GET_EDIT_IDEA_SUCCESS,
  GET_EDIT_IDEA_FAILURE,
  SAVE_EDIT_IDEA_ID,
  REMOVE_EDIT_IDEA_ID,
} = actionTypes

export const getEditIdea = () => ({
  type: GET_EDIT_IDEA,
})

export const getEditIdeaSuccess = () => ({
  type: GET_EDIT_IDEA_SUCCESS,
})

export const getEditIdeaFailure = errorMessage => ({
  type: GET_EDIT_IDEA_FAILURE,
  payload: {
    errorMessage,
  },
})

export const saveEditIdeaId = id => ({
  type: SAVE_EDIT_IDEA_ID,
  payload: {
    id,
  },
})

export const removeEditIdeaId = () => ({
  type: REMOVE_EDIT_IDEA_ID,
})
