import { api, get, patch } from '../../httpClient/httpClient'
import {
  getEditIdea,
  getEditIdeaSuccess,
  getEditIdeaFailure,
  saveEditIdeaId,
  removeEditIdeaId,
} from '../actions/editIdea'
import {
  setFormValues,
  sendIdea,
  sendIdeaFailure,
  sendIdeaSuccess,
} from '../actions/newIdea'
import { getAuthToken } from '../selectors/auth'
import errorHandler from './errorHandler'
import { history } from '../../createHistory'
import routes from '../../routes'
import { getFormValues } from '../selectors/newIdea'
import { getValidFields } from '../../util/editIdeaValidation'
import { getEditIdeaId } from '../selectors/editIdea'

const doFetchIdea = id => async (dispatch, getState) => {
  dispatch(getEditIdea(id))
  try {
    const token = getAuthToken(getState())
    const data = await get(api(`/ideas/${id}`), token)
    dispatch(setFormValues(getValidFields(data)))
    dispatch(saveEditIdeaId(data.id))
    dispatch(getEditIdeaSuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, getEditIdeaFailure)
  }
}

const doSubmitForm = () => () => {
  history.push(`${routes.editIdeaConfirm}`)
}

const doSendIdeaFailure = errorMessage => dispatch => {
  dispatch(sendIdeaFailure(errorMessage))
  history.push(`${routes.editIdeaError}`)
}

const doPostIdea = () => async (dispatch, getState) => {
  try {
    dispatch(sendIdea())
    const state = getState()
    const formValues = getFormValues(state)
    const token = getAuthToken(state)
    const id = getEditIdeaId(state)

    const { contact_email, ref } = await patch(
      api(`/ideas/${id}`),
      token,
      formValues
    )
    console.log('TCL: doPostIdea -> data', { contact_email, ref })
    await history.replace(`${routes.editIdeaSuccess}`)

    dispatch(sendIdeaSuccess({ contact_email, ref }))
    dispatch(removeEditIdeaId())
  } catch (error) {
    errorHandler(dispatch, error, doSendIdeaFailure)
  }
}

export { doFetchIdea, doSubmitForm, doPostIdea }
