export enum dataStoreActionTypes {
  GET_DATA = 'GET_DATA',
  GET_DATA_SUCCESS = 'GET_DATA_SUCCESS',
  GET_DATA_FAILURE = 'GET_DATA_FAILURE',
}

export interface Data {
  [key: string]: object
}

export interface Payload {
  errorMessage: string
  data: object
  section: string
}

export interface DataStoreAction {
  data: Data
  type: string
  payload: Payload
}
