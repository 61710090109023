import React from 'react'
import Layout from '../layouts/Layout/Layout'

import EditIdeaErrorContainer from '../components/EditIdea/EditIdeaErrorContainer'

const EditIdeaError = () => {
  return (
    <Layout pageId="page-edit-idea-error">
      <EditIdeaErrorContainer />
    </Layout>
  )
}

export default EditIdeaError
