import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Wrapper, Img, Card, Title, Description, Button } from './styles'

const SideCard = ({
  history,
  img,
  title,
  description,
  button,
  link,
  reverse,
}) => {
  return (
    <Wrapper reverse={reverse}>
      <Img src={img} reverse={reverse} />
      <Card>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {link && button && (
          <Button onClick={() => history.push(link)}>{button}</Button>
        )}
      </Card>
    </Wrapper>
  )
}

SideCard.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.string,
  link: PropTypes.string,
  reverse: PropTypes.bool,
}

export { SideCard }

export default withRouter(SideCard)
