import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleOpen } from '../../redux/actions/menu'
import { getMenu } from '../../redux/selectors/menu'
import { doLogout } from '../../redux/dispatchers/auth'
import Header from './Header'

const mapStateToProps = state => ({
  menu: getMenu(state),
  user: state.auth.user,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleOpen,
      doLogout,
    },
    dispatch
  )

const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header)

export default HeaderContainer
