import React from 'react'
import Layout from '../layouts/Layout/Layout'
import { PageHeader } from '../components/PageHeader'
import { Content, Container } from '../styles'
import styled from 'styled-components'

const Heading = styled.p`
  font-size: 20px;
  font-weight: bold;
  font-family: 'MaryAnn';
`

const PrivacyPolicy = () => {
  return (
    <Layout pageId="page-privacy-policy">
      <PageHeader title="Colleague Privacy Policy" />
      <Content>
        <Container.Padded>
          <Heading>
            We understand that your privacy and the security of your personal
            information is extremely important. This notice sets out what we do
            with your personal information, how we keep it secure, from where
            and how we collect it, as well as your rights in relation to the
            personal information we hold about you.
          </Heading>
          <Heading>Contents</Heading>
          <ul>
            <li>Who are we?</li>
            <li>What sorts of personal information do we hold? </li>
            <li>Our legal basis for processing your personal information</li>
            <li>How do we use your personal information?</li>
            <li>Who might we share your personal information with?</li>
            <li>International transfers of personal information</li>
            <li>
              Keeping you informed about the business and asking for your
              opinions
            </li>
            <li>Your rights</li>
            <li>How long will we keep your personal information for?</li>
            <li>Security</li>
            <li>Contact us</li>
            <li>Policy change</li>
          </ul>
          <Heading>Who are we?</Heading>
          <p>
            When we say ‘we’ or ‘us’ in this policy, we’re referring to the
            separate and distinct legal entities that make up the Sainsbury’s
            Group from time to time. Which of the Sainsbury’s Group companies
            controls your personal information depends on the company you work
            for.
          </p>
          <p>
            The companies which make up the Sainsbury’s Group currently are:
          </p>
          <ul>
            <li>
              Sainsbury’s Supermarkets Ltd (this is the company that sells our
              Tu Clothing range) (registered office: 33 Holborn, London, EC1N
              2HT)
            </li>
            <li>
              Sainsbury’s Bank Plc (registered office: 33 Holborn, London, EC1N
              2HT)
            </li>
            <li>
              Argos Limited (registered office: 489–499 Avebury Boulevard,
              Milton Keynes MK9 2NW)
            </li>
            <li>
              Habitat Retail Limited (registered office: 489–499 Avebury
              Boulevard, Milton Keynes MK9 2NW)
            </li>
            <li>
              Argos financial services (which includes Home Retail Group Card
              Services Limited, ARG Personal Loans Limited and Home Retail Group
              Insurance Services Limited) (registered office: 489–499 Avebury
              Boulevard, Milton Keynes MK9 2NW)
            </li>
            <li>
              Nectar Loyalty Limited (registered office: 6th Floor 80 Strand,
              London, WC2R 0NN)
            </li>
            <li>
              Insight 2 Communication LLP (registered office: 6th Floor 80
              Strand, London, WC2R 0NN)
            </li>
            <li>
              Argos Business Solutions Limited (registered office: 489–499
              Avebury Boulevard, Milton Keynes MK9 2NW).
            </li>
          </ul>
          <p>
            We may add further companies to the Sainsbury’s Group in the future.
            When we do so, we will update this notice.
          </p>
          <Heading>What sorts of information do we hold?</Heading>
          <ul>
            <li>
              Information that you provide to us when you apply for a job or
              start working for us such as your name, address, date of birth,
              telephone number, email address, bank account details.
            </li>
            <li>
              Information from other sources such as from references or
              pre-employment checks.
            </li>
            <li>Information about you that is in the public domain</li>
            <li>
              Information about your performance, such as records of 121s,
              performance reviews, investigations, disciplinaries or information
              that is automatically produced as a result of your role.
            </li>
            <li>
              Records of our interactions (for example, communications between
              us such as letters and call recordings).
            </li>
            <li>
              Financial transactions such as records of what you were paid,
              loans you may have or products that you buy with your colleague
              discount card.
            </li>
            <li>
              Your Sainsbury’s account details, including your user names and
              chosen passwords
            </li>
            <li>
              Electronic communications you receive from us or send from your
              company email accounts
            </li>
            <li>
              Information about your involvement in company schemes such as
              share plans, pensions, staff associations, the cycle to work
              scheme, the childcare voucher scheme etc.
            </li>
            <li>Records of your time and attendance.</li>
            <li>Records of the training and tests that you undertake.</li>
            <li>CCTV images.</li>
            <li>Diversity and inclusion information.</li>
          </ul>
          <Heading>Our legal basis for processing your information</Heading>
          <p>
            Whenever we process your personal information we have to have
            something called a “legal basis” for what we do. The different legal
            bases we rely on are:
          </p>
          <ul>
            <li>
              <b>Consent: </b>You have told us you are happy for us to process
              your personal information for a specific purpose
            </li>

            <li>
              <b>Legitimate interests: </b>The processing is necessary for us to
              conduct our business, but not where our interests are overridden
              by your interests or rights.
            </li>

            <li>
              <b>Performance of a contract: </b>We must process your personal
              information in order to be able to perform contractual obligations
            </li>

            <li>
              <b>Prevention of fraud: </b>Where we are required to process your
              data in order to protect us and our customers from fraud or money
              laundering
            </li>

            <li>
              <b>Vital interests: </b>The processing of your personal
              information is necessary to protect you or someone else’s life
            </li>

            <li>
              <b>Public information: </b>Where we process personal information
              which you have already made public
            </li>

            <li>
              <b>Legal claims: </b>The processing of your personal information
              is necessary for the establishment, exercise or defence of legal
              rights or whenever courts are acting in their judicial capacity;
            </li>

            <li>
              <b>Legal obligation: </b>We are required to process your personal
              information by law
            </li>

            <li>
              <b>Employment law: </b>Where it is necessary for the purposes of
              carrying out the obligations and exercising our or your specific
              rights in the field of employment and social security and social
              protection law
            </li>

            <li>
              <b>Preventive or occupational medicine: </b>If the processing is
              necessary for the purposes of preventive or occupational medicine,
              for the assessment of the working capacity of the employee,
              medical diagnosis, the provision of health or social care etc.
            </li>
          </ul>
          <Heading>How might we use your information?</Heading>
          <p>
            We always use the information we hold about you to administer your
            employment and for other legitimate interests of our business. Set
            out below are some examples:
          </p>
          <ol>
            <li>
              Before you start working for us we may use your information to:
              <ul>
                <li>Assess you as a candidate, including at interview;</li>
                <li>
                  Understand if you have a right to work and to carry out
                  disclosure checks;
                </li>
                <li>Set you up as an employee ahead of your start date;</li>
                <li>
                  Contact you about your interview or your future employment if
                  successful
                </li>
              </ul>
            </li>
            <li>
              When you work for us we use your information to enable your
              employment to run smoothly and this involves things like:
              <ul>
                <li>
                  Paying you correctly (including making additions like extra
                  hours worked or deductions such as Trades Union membership
                  fees and pension contributions);
                </li>
                <li>Keeping your contract up to date;</li>
                <li>
                  Administering your maternity/paternity/adoption/additional
                  paternity leave and your holidays;
                </li>
                <li>
                  Reviewing and keeping a record of your performance to help
                  manage your career;
                </li>
                <li>
                  Highlighting talent so we can succession plan and understand
                  our future needs;
                </li>
                <li>
                  Keeping a record of the training you complete, your
                  achievements and successes so we understand your capabilities
                  (this may include the results of psychometric tests you have
                  undertaken);
                </li>
                <li>
                  Keeping records of individuals who fulfil particular roles and
                  sometimes making that information public. For example store
                  managers and personal licence holders;
                </li>
                <li>
                  Understanding how and why accidents happened to help prevent
                  them happening in the future;
                </li>
                <li>
                  Monitoring absence and the reasons for it to improve
                  attendance;
                </li>
                <li>For our grievance and disciplinary process;</li>
                <li>
                  To provide Occupational Health or other support services;
                </li>
                <li>
                  To provide your total reward package including bonuses and
                  colleague discount cards;
                </li>
                <li>
                  Keeping a record of time and attendance so we can pay you for
                  the hours you work;
                </li>
                <li>
                  Help answer your questions and solve any issues you have;
                </li>
                <li>
                  Undertake restructures or reorganisations of the business;
                </li>
                <li>
                  Provide IT support and for business continuity purposes; and;
                </li>
                <li>
                  To understand any conflicts of interest and your relationships
                  at work.
                </li>
              </ul>
            </li>
            <li>
              When you stop working for us, for any reason, we use your
              information to:
              <ul>
                <li>Manage your exit from the business</li>
                <li>
                  Retain a record of your employment for an appropriate period
                  of time
                </li>
                <li>
                  Pay any benefits like outstanding salary, pensions or death in
                  service benefits
                </li>
                <li>To reclaim any monies owed such as outstanding loans</li>
              </ul>
            </li>
            <li>
              To help us understand how we are performing as a business and our
              future needs we also use your information to produce reports for
              management, conduct analysis, undertake analytics, carry out
              profiling and for colleague insights.
              <p />
            </li>
            <li>
              We may also use your information for the security and safety of
              our colleagues, customers and business. This may involve the:
              <ul>
                <li>
                  Monitoring communications in accordance with our policies (see
                  section 6)
                </li>
                <li>Granting and removing access to systems.</li>
                <li>
                  Use of CCTV (see CCTV section) footage and in exceptional
                  circumstances covert monitoring
                </li>
              </ul>
            </li>
            <li>
              In certain instances we also conduct monitoring. For example:
              <ul>
                <li>
                  We use data loss prevention tools to protect against data
                  leaks, whether accidental or intentional. This involves
                  scanning outbound emails for personal data in particular
                  volumes;
                </li>
                <li>
                  We use third party ‘recovery audit’ suppliers to review
                  communications with our suppliers to ensure that all trading
                  agreements are adhered to; and
                </li>
                <li>
                  In accordance with our Use of IT Systems and BYOD policy.
                </li>
              </ul>
            </li>
            <li>
              We operate CCTV in our stores, depots and store support centres.
              This is generally for the safety and security of our colleagues,
              customers and businesses but on occasion can be used:
              <ul>
                <li>For investigations and other disciplinary purposes;</li>
                <li>
                  To provide to individuals or organisations seeking to exercise
                  their legal rights; and
                </li>
                <li>
                  For analytical purposes such as understanding how people move
                  around our premises.
                </li>
              </ul>
            </li>
          </ol>
          <Heading>Who we might share your information with?</Heading>
          <p>
            <b>The Sainsbury’s Group</b> – where appropriate we will share your
            personal information with the other companies within the Sainsbury’s
            Group.
          </p>
          <p>
            <b>Our service providers</b> - we work with partners, suppliers and
            agencies so that they can help us administer your employment and run
            our businesses. These third parties process your personal
            information on our behalf and are required to meet our standards of
            security before doing so. We only share information that allows them
            to provide their services to us or to facilitate them providing
            their services to you. These third parties include:
          </p>
          <ul>
            <li>
              Benefit and reward companies such as those providing childcare
              vouchers;
            </li>
            <li>
              Payroll providers so that you can be paid correctly and in a
              timely manner
            </li>
            <li>
              Market research partners, who help us to analyse colleague
              feedback;
            </li>
            <li>
              Third party vendors who help us to manage and maintain the Group
              IT infrastructure;
            </li>
            <li>
              Where relevant, our professional advisors, such as lawyers and
              consultants; and
            </li>
            <li>Pensions providers and our share scheme administrators.</li>
          </ul>
          <p>
            <b>Other organisations and individuals</b> - we may share your
            personal information in certain scenarios. For example:
          </p>
          <ul>
            <li>
              If we’re discussing selling or transferring part or all of a
              Sainsbury’s Group business, we may share information about you to
              prospective purchasers and their advisers - but only so they can
              evaluate the relevant business; or
            </li>
            <li>
              If we are reorganised or sold to another organisation, we may
              transfer information we hold about you to them so they can
              continue to provide the Services to you.
            </li>
            <li>
              If we are required to by law, under any code of practice by which
              we are bound or where we are asked to do so by a public or
              regulatory authority such as the police or the Department for Work
              and Pensions;
            </li>
            <li>To assist with Trades Union enquiries;</li>
            <li>
              If we need to do so in order to exercise our legal rights or
              protect our business, customers and colleagues;
            </li>
            <li>
              In response to requests from individuals (or their
              representatives) seeking to protect their rights or the rights of
              others. We will only share your personal information in response
              to requests which do not override your privacy interests. For
              example, we will not share your personal information with
              individuals who are merely curious about you, but we will share
              your personal information to e.g. insurers, solicitors, employers
              etc. which have a legitimate interest in your personal
              information.
            </li>
            <li>
              As part of the Individual Accountability Regime which applies to
              Sainsbury’s Bank we may need to transfer your personal information
              to the regulator as part of getting regulatory approval or if a
              colleague has failed to comply with the conduct rules
            </li>
          </ul>
          <Heading>The public</Heading>
          <p>
            In some circumstances your role may mean that we share your name and
            business contact details with the general public. For example if you
            manage a store your name will be on the store finder or if you are a
            personal licence holder your name will be displayed in store.
          </p>
          <Heading>International transfers of personal information</Heading>
          <p>
            From time to time we transfer your personal information to our Group
            companies, suppliers or service providers based outside of the EEA
            for the purposes described in this privacy policy (please see the
            “Who might we share your personal information with?” section above
            for further details). Currently this includes transfers to the USA
            and India (for a full list of countries please contact us using the
            details in the “Contact Us” section below). When we do this, your
            personal information will continue to be subject to one or more
            appropriate safeguards set out in the law. These might be the use of
            model contracts in a form approved by regulators, or having our
            suppliers sign up to an independent privacy scheme approved by
            regulators (like the US ‘Privacy Shield’ scheme).
          </p>
          <Heading>
            Keeping you informed about the business and asking for your opinions
          </Heading>
          <p>
            We will occasionally contact you with important information about
            the business, for example in an emergency or with information about
            your employment such as sending you your pay slip, your P60, a
            password. This could be by mail, telephone, SMS or email.
          </p>
          <p>
            You may also choose to provide us with your opinions through
            surveys, feedback, on Yammer or online more generally. We use this
            information to understand how we are performing as a business or for
            other legitimate purposes.
          </p>
          <Heading>Your rights</Heading>
          <p>
            You have a number of rights under data protection legislation which,
            in certain circumstances, you may be able to exercise in relation to
            the personal information we process about you. These include:
          </p>
          <ul>
            <li>
              the right to access a copy of the personal information we hold
              about you;
            </li>
            <li>
              the right to correction of inaccurate personal information we hold
              about you;
            </li>
            <li>the right to restrict our use of your personal information;</li>
            <li>the right to be forgotten;</li>
            <li>the right of data portability; and</li>
            <li>
              the right to object to our use of your personal information.
            </li>
          </ul>
          <p>
            Where we rely on consent as the legal basis on which we process your
            personal information, you may also withdraw that consent at any
            time.
          </p>
          <p>
            If you are seeking to exercise any of these rights, please contact
            us using the details in the “Contact Us” section below. Please note
            that we will need to verify your identity before we can fulfil any
            of your rights under data protection law. This helps us to protect
            the personal information belonging to our customer against
            fraudulent requests.
          </p>
          <Heading>Automated decision making and profiling</Heading>
          <p>
            We use automated decision making, including profiling, in certain
            circumstances, such as when it is in our legitimate interests to do
            so, or where we have a right to do so because it is necessary for us
            to enter into, and perform, a contract with you.
          </p>
          <p>
            In certain circumstances you have the right not to be subject to a
            decision based solely on automated processing, including profiling,
            which has legal effects for you or affects you in any other
            significant way.
          </p>
          <p>
            If you are seeking to exercise this right, please contact us using
            the details in the “Contact Us” section below.
          </p>
          <Heading>
            How long will we keep your personal information for?
          </Heading>
          <p>
            We will keep your personal information for the purposes set out in
            this privacy policy and in accordance with the law and relevant
            regulations. We will never retain your personal information for
            longer than is necessary. In most cases, our retention period will
            come to an end 7 years after the end of your relationship with us.
            However, in some instances we are required to hold your personal
            information for up to 12 years following the end of your
            relationship with us (e.g. for data relating to Sainsbury’s Bank
            mortgage products).
          </p>
          <Heading>Security</Heading>
          <p>
            We take protecting your personal information seriously and are
            continuously developing our security systems and processes. Some of
            the controls we have in place are:
          </p>
          <ul>
            <li>
              We limit physical access to our buildings and user access to our
              systems to only those that we believe are entitled to be there;
            </li>
            <li>
              We use technology controls for our information systems, such as
              firewalls, user verification, strong data encryption, and
              separation of roles, systems & data;
            </li>
            <li>
              Systems are proactively monitored through a “detect and respond”
              information security function;
            </li>
            <li>
              We utilize industry “good practice” standards to support the
              maintenance of a robust information security management system;
              and
            </li>
            <li>
              We enforce a “need to know” policy, for access to any data or
              systems.
            </li>
          </ul>
          <Heading>Contact us</Heading>
          <p>
            If you would like to exercise one of your rights as set out in the
            “Your rights” or “Automated decision making and profiling” sections
            above, or you have a question or a complaint about this policy, or
            the way your personal information is processed, please contact us by
            one of the following means:
          </p>
          <p>
            If your enquiry relates to Sainsbury’s Supermarkets, Argos, Habitat
            or Tu:
          </p>
          <ul>
            <li>
              By email:{' '}
              <a href="privacy@sainsburys.co.uk">privacy@sainsburys.co.uk</a>
            </li>
            <li>
              By post: The Data Protection Officer at the Privacy Team,
              Sainsbury’s Supermarkets Ltd, 16th Floor, Arndale House,
              Manchester, M4 3AL
            </li>
          </ul>
          <p>
            Or if your enquiry relates to Sainsbury’s Bank or Argos financial
            services:
          </p>
          <ul>
            <li>
              By email:{' '}
              <a href="mailto:Privacy.Bank@sainsburysbank.co.uk">
                Privacy.Bank@sainsburysbank.co.uk
              </a>
            </li>
            <li>
              By post: Data Protection Officer, Sainsbury’s Bank, 3 Lochside
              Avenue, Edinburgh Park, Edinburgh EH12 9DJ
            </li>
          </ul>
          <p>
            You also have the right to lodge a complaint with the UK regulator,
            the Information Commissioner. Go to{' '}
            <a href="https://ico.org.uk/concerns">ico.org.uk/concerns</a> to
            find out more.
          </p>
          <Heading>Policy change</Heading>
          <p>
            This privacy policy was most recently updated in May 2018. If we
            make changes to it, then we will take appropriate steps to bring
            those changes to your attention.
          </p>
        </Container.Padded>
      </Content>
    </Layout>
  )
}

export default PrivacyPolicy
