import { get, getAPIEndpoint } from '../httpClient/httpClient'
import { getToken } from './TokenUtil'

export async function fetchFromCMS(request: string) {
  const userToken: string | null = getToken() || ''
  const requestURI = `${getAPIEndpoint()}/content/${request}`
  const response = await get(requestURI, userToken)
  return response
}

export async function fetchAdminContentFromCMS(request: string) {
  const adminToken: string | null = getToken() || ''
  const requestURI = `${getAPIEndpoint()}/admin/${request}`
  const response = await get(requestURI, adminToken)
  console.log('response', response)
  return response
}
