import React, { useState } from 'react'
import { Button } from './styles'
import FeedbackModal from './FeedbackModal'

function FeedbackButton() {
  const [modal, toggleModal] = useState(false)

  const toggle = () => toggleModal(!modal)
  return (
    <>
      <Button id="feedback-button" onClick={() => toggle()}>
        <span>Feedback</span>
      </Button>
      {modal && <FeedbackModal doClose={() => toggle()} />}
    </>
  )
}

export default FeedbackButton
