import styled from 'styled-components'

const Content = styled.div.attrs<{ centered?: boolean }>(({ centered }) => ({
  className: `ln-u-soft-ends-lg${centered ? ' ln-u-text-align-center' : ''}`,
}))``

const Form = styled.form.attrs(() => ({
  className: 'ln-u-soft-ends',
}))``

export { Content, Form }
