import React from 'react'
import Layout from '../layouts/Layout/Layout'
import About150Container from '../components/About150/About150Container'

class About150 extends React.Component {
  render() {
    return (
      <Layout pageId="page-about-150">
        <About150Container />
      </Layout>
    )
  }
}

export default About150
