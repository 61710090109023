import { connect } from 'react-redux'
import AdminOnly from './AdminOnly'

const mapStateToProps = state => ({
  user: state.auth.user,
})

const AdminOnlyContainer = connect(mapStateToProps)(AdminOnly)

export default AdminOnlyContainer
