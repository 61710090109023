import React from 'react'
import { CaseStudy, Container, Divider } from '../../styles'
const StudyHeader = ({ name, type, number, description, logo = false }) => {
  return (
    <div>
      <Container.FalseBleed white>
        <CaseStudy.Wrapper padded nomargin>
          <CaseStudy.Flex>
            <CaseStudy.HeaderContainer>
              <CaseStudy.TitleCaseStudy data-id="case-study-title">
                {name}
              </CaseStudy.TitleCaseStudy>
              <CaseStudy.Meta>
                {type && (
                  <CaseStudy.MetaItem row>
                    <CaseStudy.MetaItemLabel>
                      Type of CaseStudyanisation:
                    </CaseStudy.MetaItemLabel>
                    <CaseStudy.MetaItemValue>{type}</CaseStudy.MetaItemValue>
                  </CaseStudy.MetaItem>
                )}
                {number && (
                  <CaseStudy.MetaItem row>
                    <CaseStudy.MetaItemLabel>
                      Number of opportunities:
                    </CaseStudy.MetaItemLabel>
                    <CaseStudy.MetaItemValue>{number}</CaseStudy.MetaItemValue>
                  </CaseStudy.MetaItem>
                )}
              </CaseStudy.Meta>
            </CaseStudy.HeaderContainer>
            {logo && <CaseStudy.Logo src={logo} />}
          </CaseStudy.Flex>
          <Divider margin={`0 0 -20px ${logo ? '100px' : '0'}`} />
          {description && (
            <CaseStudy.Description>{description}</CaseStudy.Description>
          )}
        </CaseStudy.Wrapper>
      </Container.FalseBleed>
    </div>
  )
}

export { StudyHeader }
