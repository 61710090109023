import { createSelector } from 'reselect'
import { distanceOptions } from '../../constants'

const searchReducer = state => state.opportunitiesReducer.search

const getPaginatedRecordCount = createSelector(
  searchReducer,
  reducer => reducer.paginateRecordCount
)

// TODO: take out selectors? - Do we need Distance, SearchTerm, TeamSize
const getDistance = createSelector(
  searchReducer,
  reducer =>
    distanceOptions.find(({ value }) => value === reducer.distance) || {}
)

const getSearchTerm = createSelector(
  searchReducer,
  reducer => reducer.searchTerm
)

const getTeamSize = createSelector(searchReducer, reducer => reducer.teamSize)

const getIsSearching = createSelector(
  searchReducer,
  reducer => reducer.isSearching
)

const getErrorMessage = createSelector(
  searchReducer,
  reducer => reducer.errorMessage
)

const getAllResults = createSelector(
  searchReducer,
  reducer => reducer.opportunities
)

const getResults = createSelector(
  [getAllResults, getPaginatedRecordCount],
  (results, number) =>
    results && results.length > 0 ? results.slice(0, number) : []
)

const getUnseenOpportunitiesCount = createSelector(
  [getAllResults, getPaginatedRecordCount],
  (results, display) => results.length - display
)

const getHasMoreResults = createSelector(
  getUnseenOpportunitiesCount,
  unseenOpportunities => unseenOpportunities > 0
)

export {
  getSearchTerm,
  getDistance,
  getTeamSize,
  getIsSearching,
  getErrorMessage,
  getPaginatedRecordCount,
  getAllResults,
  getResults,
  getUnseenOpportunitiesCount,
  getHasMoreResults,
}
