import { api, get } from '../../httpClient/httpClient'
import {
  getOpportunities,
  getOpportunitiesSuccess,
  getOpportunitiesFailure,
} from '../actions/opportunitiesList'
import { getAuthToken } from '../selectors/auth'
import errorHandler from './errorHandler'

const doFetchOpportunities = () => async (dispatch, getState) => {
  dispatch(getOpportunities())
  try {
    const token = getAuthToken(getState())
    const data = await get(api('/content/opportunities'), token)
    dispatch(getOpportunitiesSuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, getOpportunitiesFailure)
  }
}

export { doFetchOpportunities }
