import React from 'react'
import { CheckboxField, SelectField, TextAreaField } from '@jsluna/form'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'
import { Heading4, Body2 } from '@jsluna/typography'
import { FilledButton } from '@jsluna/button'
import { FormSection, FormHeading, FormLabel } from '../../styles'
import { IdeaType } from '../../types'
import { useIdeaFeedbackForm } from './hooks'

interface IdeaFeedbackFormProps {
  idea: IdeaType
}

const IdeaFeedbackForm: React.FC<IdeaFeedbackFormProps> = ({ idea }) => {
  const { id, campaign } = idea
  const {
    feedback,
    feedback_time,
    agree,
    isValid,
    isLoading,
    errorMessage,
    onSubmit,
  } = useIdeaFeedbackForm(id, campaign)

  return (
    <div className="ln-u-soft-ends">
      <Heading4>Volunteering feedback</Heading4>

      {(() => {
        if (isLoading) {
          return (
            <ProgressIndicator
              className="ln-u-justify-content-center"
              loading
              preventFocus
            >
              <ProgressSpinner />
            </ProgressIndicator>
          )
        }

        if (errorMessage) {
          return <Body2 className="ln-u-color-red">{errorMessage}</Body2>
        }

        return (
          <form onSubmit={onSubmit} data-id="idea-feedback-form">
            <FormSection>
              <FormHeading>
                Submit your feedback <span className="ln-u-color-red">*</span>
              </FormHeading>
              <FormLabel>
                Please give as much detail as possible about your team’s
                volunteering project.
              </FormLabel>
              <TextAreaField
                name="feedback"
                maxLength={4000}
                value={feedback.value}
                onChange={feedback.onChange}
                error={feedback.error}
              />
            </FormSection>
            <FormSection>
              <FormHeading>
                Time <span className="ln-u-color-red">*</span>
              </FormHeading>
              <FormLabel>
                On average how much time have you spent on this colunteering
                opportunity
              </FormLabel>
              <SelectField
                name="feedback_time"
                options={[
                  { label: 'Please select', value: '' },
                  { label: '< 0.5 days', value: '< 0.5 days' },
                  { label: '1 day', value: '1 day' },
                  { label: '1.5 days', value: '1.5 days' },
                  { label: '2 days', value: '2 days' },
                  { label: '2.5 days', value: '2.5 days' },
                  { label: '3 days', value: '3 days' },
                  { label: '3.5 days', value: '3.5 days' },
                  { label: '4 days', value: '4 days' },
                  { label: '4.5 days', value: '4.5 days' },
                  { label: '5 days', value: '5 days' },
                  { label: '> 5.5 days', value: '> 5.5 days' },
                ]}
                value={feedback_time.value}
                onChange={feedback_time.onChange}
                error={feedback_time.error}
              />
            </FormSection>
            <CheckboxField
              name="agree"
              onChange={agree.onChange}
              error={agree.error}
              options={[
                {
                  value: 'Yes',
                  label:
                    'I agree to this information and or images being used on a public website and future marketing materials.',
                  defaultChecked: agree.value === 'Yes',
                },
              ]}
            />
            <FilledButton
              disabled={!isValid}
              type="submit"
              style={{ minWidth: '12rem' }}
            >
              Submit feedback
            </FilledButton>
          </form>
        )
      })()}
    </div>
  )
}

export default IdeaFeedbackForm
