import { createSelector } from 'reselect'
import orderBy from 'lodash/orderBy'

const getIdeasState = state => state.ideasReducer

const getLoading = createSelector(
  [getIdeasState],
  ideasReducer => ideasReducer.loading || ideasReducer.loadingStats
)

const getErrorMessage = createSelector(
  [getIdeasState],
  ideasReducer => ideasReducer.errorMessage
)

const getIdeas = createSelector(
  [getIdeasState],
  ideasReducer => ideasReducer.ideas
)

const getStats = createSelector(
  [getIdeas],
  ideas =>
    ideas &&
    ideas.reduce(
      (stats, { idea_status }) => {
        stats[idea_status.toLowerCase()]++
        stats.total++

        return stats
      },
      {
        total: 0,
        bookmarked: 0,
        updated: 0,
        pending: 0,
        accepted: 0,
        declined: 0,
      }
    )
)

const getIdeasSort = createSelector(
  [getIdeasState],
  ideasReducer => ideasReducer.sort
)

const getIdeasSortBy = createSelector([getIdeasSort], sort => sort.by)

const getIdeasSortDirection = createSelector(
  [getIdeasSort],
  sort => sort.direction
)

const getSortedIdeas = createSelector(
  [getIdeas, getIdeasSortBy, getIdeasSortDirection],
  (ideas, by, direction) => {
    return orderBy(
      ideas,
      [
        idea => {
          if (idea[by]) {
            return idea[by].toString().toLowerCase()
          }
          return null
        },
      ],
      [direction]
    )
  }
)

export { getSortedIdeas, getLoading, getErrorMessage, getStats }
