import React from 'react'
import { LoadingIcon } from 'luna-react'
import Alert from '../Dialogs/Alert'

const IdeaDialog = ({
  action,
  ideaRef,
  isLoading,
  onCancel,
  cancelLabel = 'Cancel',
  confirmLabel = 'Yes',
  onConfirm,
}) => (
  <Alert
    title={`You're about to ${action} idea ${ideaRef}`}
    onCancel={onCancel}
    cancelLabel={cancelLabel}
    confirmLabel={isLoading ? <LoadingIcon inverted /> : confirmLabel}
    onConfirm={onConfirm}
  />
)

export default IdeaDialog
