import { api, get, patch, del } from '../../httpClient/httpClient'
import { idea_statuses } from '../../constants'
import {
  getIdea,
  getIdeaSuccess,
  getIdeaFailure,
  getIdeaHistory,
  getIdeaHistorySuccess,
  getIdeaHistoryFailure,
  updateIdea,
  updateIdeaSuccess,
  updateIdeaFailure,
  updateIdeaStatusSuccess,
  hideDeclineForm,
} from '../actions/idea'
import { getAuthToken } from '../selectors/auth'
import errorHandler from './errorHandler'
import campaigns from '../../data/campaigns'

const noOp = () => null

const getIdeaEndpoint = (campaign, isAdmin) => {
  const campaignConfig = campaigns[campaign] || null

  if (campaignConfig) {
    const { adminEndpoint, ideasEndpoint } = campaignConfig

    return isAdmin ? adminEndpoint : ideasEndpoint
  }

  return isAdmin ? '/admin/ideas' : '/ideas'
}

const doFetchIdea = (idea_id, campaign) => {
  return async (dispatch, getState) => {
    dispatch(getIdea(idea_id))
    try {
      const endpoint = getIdeaEndpoint(campaign)
      const token = getAuthToken(getState())
      const data = await get(api(`${endpoint}/${idea_id}`), token)
      dispatch(getIdeaSuccess(data, campaign))
    } catch (error) {
      errorHandler(dispatch, error, getIdeaFailure)
    }
  }
}

const doFetchAdminIdea = (idea_id, campaign) => {
  return async (dispatch, getState) => {
    dispatch(getIdea(idea_id))
    try {
      const endpoint = getIdeaEndpoint(campaign, true)
      const token = getAuthToken(getState())
      const data = await get(api(`${endpoint}/${idea_id}`), token)
      dispatch(getIdeaSuccess(data, campaign))
    } catch (error) {
      errorHandler(dispatch, error, getIdeaFailure)
    }
  }
}

const doFetchIdeaHistory = (idea_id, campaign) => async (
  dispatch,
  getState
) => {
  dispatch(getIdeaHistory(idea_id))
  try {
    const endpoint = getIdeaEndpoint(campaign)
    const token = getAuthToken(getState())
    const data = await get(api(`${endpoint}/${idea_id}/history`), token)
    dispatch(getIdeaHistorySuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, getIdeaHistoryFailure)
  }
}

const doFetchIdeaWithHistory = (idea_id, campaign) => async dispatch => {
  await dispatch(doFetchIdea(idea_id, campaign))
  await dispatch(doFetchIdeaHistory(idea_id, campaign))
}

const doUpdateIdea = ({
  idea,
  campaign,
  idea_status,
  onSuccess = () => null,
  onError = () => null,
}) => async (dispatch, getState) => {
  dispatch(updateIdea(idea_status))
  try {
    const token = getAuthToken(getState())
    await patch(api(`/admin/ideas/${idea.id}`), token, {
      idea_status,
    })
    dispatch(updateIdeaSuccess())
    dispatch(doFetchIdeaWithHistory(idea.id, campaign))
    onSuccess.apply && onSuccess()
  } catch (error) {
    onError.apply && onError()
    errorHandler(dispatch, error, updateIdeaFailure, idea.idea_status)
  }
}

const doDeleteIdea = ({ id, onSuccess = noOp, onError = noOp }) => async (
  dispatch,
  getState
) => {
  try {
    const token = getAuthToken(getState())
    await del(api(`/admin/ideas/${id}`), token)
    onSuccess()
  } catch (error) {
    onError()
  }
}

const doDeclineIdea = (campaign, idea_id, declined_reason) => async (
  dispatch,
  getState
) => {
  dispatch(updateIdea())
  dispatch(hideDeclineForm())
  try {
    const token = getAuthToken(getState())
    await patch(api(`/admin/ideas/${idea_id}`), token, {
      idea_status: idea_statuses.DECLINED,
      declined_reason,
    })
    dispatch(updateIdeaSuccess())
    dispatch(doFetchIdeaWithHistory(idea_id))
  } catch (error) {
    errorHandler(dispatch, error, updateIdeaFailure, idea_statuses.DECLINED)
  }
}

const doUpdateIdeaHistory = (id, campaign) => {
  return async (dispatch, getState) => {
    const endpoint = getIdeaEndpoint(campaign)
    const token = getAuthToken(getState())
    await get(api(`${endpoint}/${id}/history`), token).then(data => {
      dispatch(getIdeaHistorySuccess(data))
    })
  }
}

const doUpdateIdeaStatus = (id, campaign, status, payload = {}) => {
  return async (dispatch, getState) => {
    const endpoint = getIdeaEndpoint(campaign)
    const token = getAuthToken(getState())

    await patch(api(`${endpoint}/${id}`), token, {
      idea_status: status,
      ...payload,
    }).then(() => {
      dispatch(updateIdeaStatusSuccess(status))
      dispatch(doUpdateIdeaHistory(id, campaign))
    })
  }
}

const doDeleteIdeaNew = (id, campaign) => {
  return async (dispatch, getState) => {
    const endpoint = getIdeaEndpoint(campaign, true)
    const token = getAuthToken(getState())

    await del(api(`${endpoint}/${id}`), token)
  }
}

export {
  doDeleteIdea,
  doDeleteIdeaNew,
  doFetchIdea,
  doFetchAdminIdea,
  doUpdateIdea,
  doUpdateIdeaStatus,
  doDeclineIdea,
  doUpdateIdeaHistory,
  doFetchIdeaWithHistory,
  doFetchIdeaHistory,
}
