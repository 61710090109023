import { Dispatch, AnyAction } from 'redux'
import { State } from '../store'
import errorHandler from '../dispatchers/errorHandler'
import {
  dataFailureAction,
  dataSuccessAction,
  getDataAction,
} from './dataStore'
import { getAuthToken } from '../selectors/auth'
import { api, get } from '../../httpClient/httpClient'
import {
  AdminIdeasStatusTypes,
  AdminIdeaActionTypes,
  AdminIdeaData150Days,
  AdminIdeaData150DaysType,
  AdminIdeaDataHeroes,
  AdminIdeaDataHeroesType,
} from '../../types'

export const adminIdeaSetSourceAction = (source: string) => {
  return {
    type: AdminIdeaActionTypes.ADMIN_IDEA_SET_SOURCE,
    payload: { source: source },
  }
}

export const map150DaysDataDetail = (
  data: AdminIdeaData150Days[],
  ideaId: string
): AdminIdeaData150DaysType => {
  const dataFiltered = data.filter(
    (item: AdminIdeaData150Days) => item.id === ideaId
  )
  const result = dataFiltered[0]

  return {
    business: result.business || [],
    category: result.category,
    colleagueName: result.colleague_name,
    contactEmail: result.contact_email,
    createdDate: result.created_date,
    date: result.date,
    id: result.id,
    ideaStatus: AdminIdeasStatusTypes[result.idea_status.toUpperCase()] || '',
    lastModified: result.last_modified,
    organisationName: result.organisation_name,
    organisationWebsite: result.organisation_website,
    ref: result.ref,
    region: result.region,
    stardustMoments: result.stardust_moments,
    store: result.store,
    storeNumber: result.store_number,
    submittedBy: result.submitted_by,
    teamSize: result.team_size,
    volunteeringIdea: result.volunteering_idea,
  }
}

export const fetch150DaysDetailActionCreator = (ideaId: string) => async (
  dispatch: Dispatch<AnyAction, State>,
  getState: () => State
) => {
  try {
    dispatch(getDataAction())
    const endPoint: string = `/admin/150Days/${ideaId}`
    const token: string = getAuthToken(getState())
    const data = await get<AdminIdeaData150Days[]>(api('/admin/ideas'), token)
    const result: AdminIdeaData150DaysType = map150DaysDataDetail(data, ideaId)
    dispatch(adminIdeaSetSourceAction(endPoint))
    dispatch(dataSuccessAction(result, endPoint))
  } catch (error) {
    errorHandler(dispatch, error, dataFailureAction)
  }
}

export const mapLocalHerosDataDetail = (
  data: AdminIdeaDataHeroes[],
  ideaId: string
): AdminIdeaDataHeroesType => {
  const dataFiltered = data.filter(
    (item: AdminIdeaDataHeroes) => item.id === ideaId
  )
  const result = dataFiltered[0]
  return {
    business: result.business,
    charityAccountNumber: result.charity_account_number,
    charityAddress: result.charity_address,
    charityCategory: result.charity_category || [],
    charityContact: result.charity_contact,
    charityEmailAddress: result.charity_email_address,
    charityName: result.charity_name,
    charityNumber: result.charity_number,
    charityPhoneNumber: result.charity_phone_number,
    charitySortCode: result.charity_sort_code,
    colleagueEmail: result.colleague_email,
    colleagueName: result.colleague_name,
    createdDate: result.created_date,
    employeeNumber: result.employee_number,
    gender: result.gender,
    hoursClaimed: result.hours_claimed,
    id: result.id,
    lastModified: result.last_modified,
    ref: result.ref,
    storeName: result.store_name,
    submittedBy: result.submitted_by,
    title: result.title,
    volunteeringDetails: result.volunteering_details,
  }
}

export const fetchLocalHerosDetailActionCreator = (ideaId: string) => async (
  dispatch: Dispatch<AnyAction, State>,
  getState: () => State
) => {
  try {
    dispatch(getDataAction())
    const endPoint: string = `/admin/localHeroes/${ideaId}`
    const token: string = getAuthToken(getState())
    const data = await get<AdminIdeaDataHeroes[]>(
      api('/admin/localHeroes'),
      token
    )
    const result: AdminIdeaDataHeroesType = await mapLocalHerosDataDetail(
      data,
      ideaId
    )
    dispatch(adminIdeaSetSourceAction(endPoint))
    dispatch(dataSuccessAction(result, endPoint))
  } catch (error) {
    errorHandler(dispatch, error, dataFailureAction)
  }
}
