import { CheckboxField } from 'luna-react'
import React from 'react'

const FormItemCheckbox = props => {
  const { name, value = [], options, ...rest } = props
  options.map(option =>
    value.includes(option.value)
      ? (option.checked = true)
      : (option.checked = false)
  )

  const onChange = evt => {
    const { checked, value: currentValue } = evt.target
    let values
    if (checked) {
      values = [...value, currentValue]
    } else {
      values = value.filter(value => value !== currentValue)
    }
    props.onChange({ target: { name, value: values } })
  }

  const onBlur = evt => {
    const focusedElement = evt.relatedTarget

    if (focusedElement && focusedElement.name !== name) {
      props.onBlur({ target: { name, value: [...value] } })
    }
  }

  return (
    <CheckboxField
      {...rest}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
    />
  )
}

export default FormItemCheckbox
