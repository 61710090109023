import React from 'react'
import Modal from '../Modal/Modal'

import styled from 'styled-components'
import { Divider } from '../../styles'
import { PrimaryButton, OutlinedButton } from 'luna-react'

const AlertWrapper = styled.div`
  background-color: #fff;
  padding: 16px;
  width: 80%;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    width: 464px;
  }
`

const AlertTitle = styled.h4`
  margin: 6px 0;
  font-size: 1.3rem;
`

const AlertBody = styled.div`
  margin: 6px 0 24px;
`
const AlertButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Dismiss = styled(OutlinedButton)`
  margin-right: 15px;
`
const noOp = () => null
const Alert = ({
  confirmLabel,
  cancelLabel,
  onConfirm = noOp,
  onCancel = noOp,
  title = 'Alert',
  children,
}) => {
  return (
    <Modal>
      <AlertWrapper>
        <AlertTitle>{title}</AlertTitle>
        <Divider />
        <AlertBody>{children || 'Are you sure you want to do this?'}</AlertBody>
        <AlertButtons>
          {cancelLabel ? (
            <Dismiss data-id="alert-cancel" onClick={onCancel}>
              {cancelLabel}
            </Dismiss>
          ) : null}
          {confirmLabel ? (
            <PrimaryButton data-id="alert-confirm" onClick={onConfirm}>
              {confirmLabel}
            </PrimaryButton>
          ) : null}
        </AlertButtons>
      </AlertWrapper>
    </Modal>
  )
}

export default Alert
