import React, { ReactText } from 'react'
import moment from 'moment'
import { Body1 } from '@jsluna/typography'
import { IdeaValueType } from '../../types'

interface IdeaReviewItem {
  label: string
  value: IdeaValueType
}

interface IdeaReviewProps {
  items: IdeaReviewItem[]
}

const IdeaReview: React.FC<IdeaReviewProps> = ({ items }) => {
  if (!items || !items.length) {
    return null
  }

  // TODO: Change to unordered list
  return (
    <>
      {items.map(({ label, value }: IdeaReviewItem, index: number) => {
        if (value) {
          let valueFormatted: ReactText = ''

          if (moment(value, 'YYYY-MM-DD', true).isValid()) {
            valueFormatted = moment(value).format('DD/MM/YYYY')
          } else {
            valueFormatted = Array.isArray(value) ? value.join(', ') : value
          }

          return (
            <div key={index}>
              <Body1>{label}</Body1>
              <Body1 element="p">
                <strong>{valueFormatted}</strong>
              </Body1>
            </div>
          )
        }

        return null
      })}
    </>
  )
}

export default IdeaReview
