import React from 'react'
import { Delete } from 'luna-icons'
import { Wrapper, WrapperLabel } from './styles'
import styled from 'styled-components'

const DeleteWrapper = styled(Wrapper)`
  color: ${({ theme }) => theme.color.red};
`
const DeleteLabel = styled(WrapperLabel)`
  padding-left: 8px;
`

const noOp = () => null

const DeleteButton = ({ label = 'Delete', onClick = noOp, ...rest }) => {
  return (
    <DeleteWrapper {...rest} onClick={onClick}>
      <Delete />
      <DeleteLabel>{label}</DeleteLabel>
    </DeleteWrapper>
  )
}

export default DeleteButton
