import { Dispatch, AnyAction } from 'redux'
import { connect } from 'react-redux'
import { State } from '../../redux/store'
import {
  selectPageActive,
  selectPageLast,
} from '../../redux/selectors/adminIdeas'
import AdminIdeasPagination from './AdminIdeasPagination.component'
import { paginationChangePageViewActionCreator } from '../../redux/actions/adminIdeas'

const mapStateToProps = (state: State) => ({
  pageActive: selectPageActive(state),
  pageLast: selectPageLast(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction, State>) => ({
  changePageView: (page: number) => {
    dispatch(paginationChangePageViewActionCreator(page))
  },
})

export const AdminIdeasPaginationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminIdeasPagination)

AdminIdeasPaginationContainer.displayName = 'AdminIdeasPaginationContainer'
