import React from 'react'
import { PropTypes } from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { LoadingIndicator } from 'luna-react'
import styled from 'styled-components'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import StatusIcon from '../Status/StatusIcon'
import IdeaHistory from '../IdeaHistory/IdeaHistory'
import RefIdeaHeader from '../../components/RefIdeaHeader'
import IdeaFeedbackForm from '../../components/IdeaFeedbackForm'
import IdeaReview from '../IdeaReview'
import { idea_statuses, ideaLabels } from '../../constants'
import campaigns from '../../data/campaigns'
import { Container, Content, Divider, IdeaTitle, IdeaBody } from '../../styles'

const IdeaWrapper = styled.div`
  padding: 20px 0;
`

const FloatedStatusIcon = styled.div`
  float: right;
`

const Button = styled(Link)``

class UserIdea extends React.Component {
  componentDidMount() {
    this.props.doFetchIdeaWithHistory(
      this.props.match.params.idea_id,
      this.props.campaign
    )
  }

  render() {
    const { idea, loading, errorMessage, campaign } = this.props
    const campaignConfig = campaigns[campaign]
    const { fieldLabels, editRoute, ideasRoute } = campaignConfig

    return (
      <>
        <RefIdeaHeader to={ideasRoute} />
        <Content>
          <Container.Padded>
            {loading ? (
              <LoadingIndicator message="Loading idea…" />
            ) : errorMessage ? (
              <ErrorMessage errorMessage={errorMessage} />
            ) : idea ? (
              <IdeaWrapper data-id="idea">
                <FloatedStatusIcon>
                  <StatusIcon big={true} status={idea.idea_status} />
                </FloatedStatusIcon>
                {fieldLabels && (
                  <IdeaReview
                    items={Object.keys(fieldLabels)
                      .filter(key => !!idea[key])
                      .map(key => ({
                        label: fieldLabels[key],
                        value: idea[key],
                      }))}
                  />
                )}
                <Divider margin={'30px 0'} />
                <div id="idea_status">
                  <IdeaTitle>Status</IdeaTitle>
                  <IdeaBody>{ideaLabels[idea.idea_status]}</IdeaBody>
                  {idea.history && idea.history.length > 0 && (
                    <IdeaHistory history={idea.history} />
                  )}
                </div>
                {idea.idea_status === idea_statuses.ACCEPTED && (
                  <IdeaFeedbackForm idea={idea} />
                )}
                {idea.idea_status === idea_statuses.DECLINED && (
                  <Button
                    to={`${editRoute}${idea.id}`}
                    className="ln-c-button ln-c-button--outlined"
                    data-id={`edit-${idea.ref}`}
                  >
                    Edit idea
                  </Button>
                )}
              </IdeaWrapper>
            ) : null}
          </Container.Padded>
        </Content>
      </>
    )
  }
}

UserIdea.propTypes = {
  doFetchIdeaWithHistory: PropTypes.func.isRequired,
  ideas: PropTypes.object,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export { UserIdea }

export default withRouter(UserIdea)
