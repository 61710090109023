import { State } from '../store'
import { createSelector } from 'reselect'
import { responseStatus } from '../../types'

const programmesState = (state: State) => state.programmes
const dataStoreState = (state: State) => state.dataStore

const selectTitle = createSelector(
  programmesState,
  programme => programme.title
)

const selectSubHeader = createSelector(
  programmesState,
  programme => programme.subHeader
)

const selectDataLoadingState = createSelector(
  dataStoreState,
  dataStore => dataStore.responseStatus === responseStatus.PENDING
)

const selectDataErrorState = createSelector(
  dataStoreState,
  dataStore => dataStore.responseStatus === responseStatus.FAILURE
)

const selectProgrammeTiles = (state: State, endpoint: string) =>
  createSelector(
    dataStoreState,
    dataStore => dataStore && dataStore.data && dataStore.data[endpoint]
  )(state)

const selectTabs = () => {
  return [
    { label: 'All', value: 'all' },
    { label: 'National', value: 'national' },
    { label: 'Local', value: 'local' },
    { label: 'Personal', value: 'personal' },
    { label: 'Resources', value: 'resource' },
  ]
}

export {
  selectTabs,
  selectTitle,
  selectSubHeader,
  selectDataLoadingState,
  selectDataErrorState,
  selectProgrammeTiles,
}
