import Layout from '../layouts/Layout/Layout'
// import OpportunityContainer from 'components/Opportunity/OpportunityContainer'
import PropTypes from 'prop-types'
import React from 'react'
import {
  BackArrow,
  BackArrowLeft,
  BackButton,
  BackButtonLink,
  FauxHeader,
  PulledContent,
  RelativeContainer,
} from '../styles/Opportunity'

const Organisation = () => (
  <Layout pageId="page-opportunity">
    <FauxHeader />
    <PulledContent>
      <RelativeContainer>
        <BackButton>
          <BackButtonLink role="link" onClick={() => window.history.back()}>
            <BackArrow>
              <BackArrowLeft />
            </BackArrow>
            Back
          </BackButtonLink>
        </BackButton>
        {/*<OpportunityContainer*/}
        {/*  id={match.params.opportunity_id}*/}
        {/*  organisationSlug={match.params.organisation_slug}*/}
        {/*/>*/}
      </RelativeContainer>
    </PulledContent>
  </Layout>
)

Organisation.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Organisation

Organisation.defaultName = 'Organisation'
