import React from 'react'
import heroSmall from '../../assets/hero.png'
import heroMedium from '../../assets/hero@2x.png'
import heroLarge from '../../assets/hero@3x.png'

const Hero = () => {
  return (
    <img
      style={{ display: 'block' }}
      srcSet={`${heroSmall} 749w, ${heroMedium} 1498w, ${heroLarge} 2247w`}
      src={`${heroLarge}`}
      alt="Connected Communities Hub"
    />
  )
}
export default Hero
