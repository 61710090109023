import PropTypes from 'prop-types'
import React from 'react'
import { Container } from '../../styles'
import { Blurb, Title, Wrapper } from './styles'
import Head from '../Head/Head'

const PageHeader = ({ blurb = '', title }) => (
  <Wrapper>
    <Head title={title} />
    <Container.FullBleed>
      <Title small>{title}</Title>
      {blurb && <Blurb>{blurb}</Blurb>}
    </Container.FullBleed>
  </Wrapper>
)

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  blurb: PropTypes.string,
}
export { PageHeader }
