export const FiltersHeroes = {
  searchMaxShortTextLength: 0,
  searchMaxLongTextLength: 0,
  dateRangeVisible: false,
  searchOptions: [
    {
      label: 'Email address',
      value: 'email',
      placeholder: 'e.g. sam.smith@sainsburys.co.uk',
    },
    {
      label: 'Employee number',
      value: 'employee_number',
      placeholder: 'e.g. 1234567890',
    },
    { label: 'Name', value: 'colleague_name', placeholder: 'e.g. smith' },
    { label: 'Charity name', value: 'charity_name', placeholder: 'e.g. RSPCA' },
  ],
  filterOptions: [
    {
      heading: 'Associated store or team',
      key: 'business',
      values: [
        { label: 'Sainsbury’s Asia', value: 'Sainsbury’s Asia' },
        { label: 'Sainsbury’s Bank', value: 'Sainsbury’s Bank' },
        { label: 'Sainsbury’s SSC', value: 'Sainsbury’s SSC' },
        { label: 'Sainsbury’s Store', value: 'Sainsbury’s Store' },
        { label: 'Sainsbury’s Logistics', value: 'Sainsbury’s Logistics' },
        { label: 'Sainsbury’s Argos', value: 'Sainsbury’s Argos' },
        { label: 'Argos Store', value: 'Argos Store' },
        { label: 'Argos Logistics', value: 'Argos Logistics' },
        {
          label: 'Sainsbury’s and Argos CMC',
          value: 'Sainsbury’s and Argos CMC',
        },
        { label: 'Habitat', value: 'Habitat' },
        { label: 'Nectar', value: 'Nectar' },
        { label: 'AFS', value: 'AFS' },
        { label: 'Other', value: 'Other' },
      ],
    },
    {
      heading: 'Category',
      key: 'charity_category',
      values: [
        { label: 'Poverty', value: 'Poverty' },
        { label: 'Homelessness', value: 'Homelessness' },
        { label: 'Disability', value: 'Disability' },
        { label: 'Health', value: 'Health' },
        { label: 'Mental health', value: 'Mental health' },
        { label: 'Schools/Young people', value: 'Schools/Young people' },
        { label: 'Older people', value: 'Older people' },
        { label: 'Community care', value: 'Community care' },
        { label: 'Environment', value: 'Environment' },
        { label: 'Other', value: 'Other' },
      ],
    },
  ],
}
