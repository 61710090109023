import React from 'react'
import { Heading5 } from 'luna-react'

import {
  ContentHeader,
  ContentHeaderTitle,
  ContentHeaderBlurb,
  Content,
  Container,
} from '../../styles'

const NewIdeaError = () => {
  return (
    <>
      <ContentHeader grey>
        <Container.FullBleed>
          <ContentHeaderTitle>Sorry</ContentHeaderTitle>
          <ContentHeaderBlurb>
            Something has gone wrong with your submission.
          </ContentHeaderBlurb>
        </Container.FullBleed>
      </ContentHeader>

      <Content data-id="error">
        <Container.Padded>
          <Heading5>
            An error occurred while trying to save your idea. The team has been
            notified. Please try again.
          </Heading5>
        </Container.Padded>
      </Content>
    </>
  )
}

export default NewIdeaError
