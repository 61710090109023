import { AnyAction } from 'redux'
import { actionTypes } from '../actions/auth'
import { extractTokenData } from '../../util/TokenUtil'
import { AuthState } from '../store'

const initialState = {
  token: null,
  user: null,
}

const addTokenAndUser = (token: string) => {
  return {
    token,
    user: extractTokenData(token),
  }
}

const addToken = (token: string) => {
  return {
    token,
  }
}

const authReducer = (
  state: AuthState = initialState,
  action: AnyAction
): any => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        ...addTokenAndUser(action.payload.token),
      }
    case actionTypes.LOGOUT:
      return {
        ...state,
        token: null,
        user: null,
      }
    case actionTypes.REFRESH:
      return {
        ...state,
        ...addToken(action.payload.token),
      }
    default:
      return state
  }
}

export { initialState, authReducer }
