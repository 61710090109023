import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchAdminContentFromCMS } from '../../util/contentfulUtil'
import { State, store, RootState } from '../store'

const AdminCharityList: any = {
  selectors: {
    adminCharityListSelector: (state: RootState) => state.adminCharityList,
  },
  slice: createSlice({
    name: 'adminCharityList',
    initialState: [],
    reducers: {
      getContentPending: state => {
        return state
      },
      getContentFailure: (state, action: PayloadAction<State>) => {
        return {
          ...state,
          ...action.payload,
        }
      },
      // TODO Finalize type of payload
      getContentSuccess: (state, action: { payload: any }) => {
        return action.payload
      },
    },
  }),
  thunk: {
    fetchAdminCharityList: async function(customEndpoint?: string) {
      const endpoint = customEndpoint || 'charities'
      const dispatch = store.dispatch
      dispatch(getContentPending())
      try {
        const content = await fetchAdminContentFromCMS(endpoint)
        dispatch(getContentSuccess(content))
      } catch (error) {
        dispatch(getContentFailure(error))
      }
    },
  },
}

export const {
  getContentPending,
  getContentFailure,
  getContentSuccess,
} = AdminCharityList.slice.actions
export const adminCharityListReducer = AdminCharityList.slice.reducer
export const { adminCharityListSelector } = AdminCharityList.selectors
export const { fetchAdminCharityList } = AdminCharityList.thunk
