import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Container as LunaContainer } from '@jsluna/grid'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'
import {
  OutlinedButton,
  FilledButton,
  ButtonGroupWrapper,
  ButtonGroupSecondary,
} from '@jsluna/button'
import { doFetchFormValues } from '../../redux/dispatchers/ideaForm'
import {
  selectFormIsValid,
  selectFormIsLoading,
  selectFormErrorMessage,
} from '../../redux/selectors/ideaForm'
import { PageHeader } from '../../components/PageHeader'
import FormSection from '../../components/Form/FormSection'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import { ideaFormRoutes } from '../../data/ideaFormRoutes'
import { name, path, fields } from '../../data/volunteeringFormData'
import { Container } from '../../styles'

interface MatchProps {
  id: string
}

const VolunteeringEdit: React.FC<RouteComponentProps<MatchProps>> = ({
  match,
  history,
}) => {
  const id = match.params.id
  const confirmPath = `${path}${ideaFormRoutes.confirm}/${id}`
  const dispatch = useDispatch()
  const isValid = useSelector(selectFormIsValid(name))
  const isLoading = useSelector(selectFormIsLoading(name))
  const errorMessage = useSelector(selectFormErrorMessage(name))
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (isValid) {
      history.push(confirmPath)
    }
  }

  useEffect(() => {
    if (id) {
      dispatch(doFetchFormValues(name, id))
    }
  }, [id, dispatch])

  return (
    <>
      <PageHeader
        title="Volunteering Project Submission Page"
        blurb="This page is for colleagues to get involved in the Volunteering project."
      />
      <Container.Wrapper>
        <LunaContainer soft>
          <div className="ln-u-soft-ends">
            {(() => {
              if (isLoading) {
                return (
                  <ProgressIndicator
                    className="ln-u-justify-content-center"
                    loading
                    preventFocus
                  >
                    <ProgressSpinner />
                  </ProgressIndicator>
                )
              }

              if (errorMessage) {
                return <ErrorMessage errorMessage={errorMessage} />
              }

              return (
                <form onSubmit={onSubmit}>
                  {fields.map((section: object, index: number) => (
                    <FormSection
                      formType={name}
                      key={index}
                      section={section}
                    />
                  ))}
                  <ButtonGroupWrapper actionBar>
                    <ButtonGroupSecondary>
                      <FilledButton
                        type="submit"
                        disabled={!isValid}
                        style={{ minWidth: '12rem' }}
                      >
                        Review details
                      </FilledButton>
                      <OutlinedButton
                        style={{ minWidth: '8rem' }}
                        onClick={() => {
                          history.goBack()
                        }}
                      >
                        Cancel
                      </OutlinedButton>
                    </ButtonGroupSecondary>
                  </ButtonGroupWrapper>
                </form>
              )
            })()}
          </div>
        </LunaContainer>
      </Container.Wrapper>
    </>
  )
}

export default withRouter(VolunteeringEdit)
