import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  setSearchTerm,
  setDistance,
  setTeamSize,
} from '../../redux/actions/opportunitiesSearch'
import {
  doSearchOpportunities,
  doResetSearch,
} from '../../redux/dispatchers/opportunitiesSearch'

import OpportunitiesSearch from './OpportunitiesSearch'
import {
  getIsSearching,
  getErrorMessage,
} from '../../redux/selectors/opportunitiesSearch'

const mapStateToProps = state => {
  return {
    searching: getIsSearching(state),
    errorMessage: getErrorMessage(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSearchTerm,
      setDistance,
      setTeamSize,
      doResetSearch,
      doSearchOpportunities,
    },
    dispatch
  )

const OpportunitiesSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OpportunitiesSearch)

export default OpportunitiesSearchContainer
