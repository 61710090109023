import React from 'react'
import Layout from '../layouts/Layout/Layout'

import NewIdeaSuccess from '../components/NewIdea/NewIdeaSuccessContainer'

const NewIdeaSuccessContainer = () => {
  return (
    <Layout pageId="page-new-idea-success">
      <NewIdeaSuccess />
    </Layout>
  )
}

export default NewIdeaSuccessContainer
