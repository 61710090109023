import { ROLE } from '../constants'

const isAnyAdmin = user => {
  if (
    user &&
    (user.role === ROLE.ADMIN ||
      user.role === ROLE.SUPER_ADMIN ||
      user.role === ROLE.EXTERNAL_ADMIN)
  ) {
    return true
  }
  return false
}

const isExternalAdmin = user => {
  if (user && user.role === ROLE.EXTERNAL_ADMIN) {
    return true
  }
  return false
}

export { isAnyAdmin, isExternalAdmin }
