import { api, get } from '../../httpClient/httpClient'
import { getAuthToken } from '../selectors/auth'
import errorHandler from './errorHandler'
import {
  getDataAction,
  dataSuccessAction,
  dataFailureAction,
} from '../actions/dataStore'
import { State } from '../store'
import { Dispatch, AnyAction } from 'redux'

const doFetchDataStoreData = (apiEndpoint: string) => async (
  dispatch: Dispatch<AnyAction, State>,
  getState: () => State
) => {
  dispatch(getDataAction())
  try {
    const token = getAuthToken(getState())
    const data = await get(api(apiEndpoint), token)
    dispatch(dataSuccessAction(data, apiEndpoint))
  } catch (error) {
    errorHandler(dispatch, error, dataFailureAction)
  }
}

export { doFetchDataStoreData }
