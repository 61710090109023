export const OPTIONS = [
  { label: 'Filter by Ref ID', value: 'ref', placeholder: 'e.g. A1B2C3' },
  {
    label: 'Filter by email',
    value: 'submitted_by',
    placeholder: 'e.g. sam.smith@sainsburys.co.uk',
  },
  {
    label: 'Filter by organisation',
    value: 'organisation_name',
    placeholder: 'e.g. The Woodland Trust',
  },
]

const filterUnselectedFields = obj => {
  Object.keys(obj).forEach(key => {
    if (!obj[key]) delete obj[key]
  })

  return obj
}

export const serialise = (fields = {}) =>
  Object.keys(fields).reduce((acc, field) => {
    const filteredFields = filterUnselectedFields(fields[field])

    const fieldArray = Object.keys(filteredFields)
    if (fieldArray.length) {
      acc[field] = fieldArray.join(',')
    }
    return acc
  }, {})

export const deserialise = (urlParams = {}) =>
  Object.keys(urlParams).reduce((accumulator, param) => {
    accumulator[param] = urlParams[param]
      .split(',')
      .reduce((filterCategories, value) => {
        filterCategories[value] = true
        return filterCategories
      }, {})
    return accumulator
  }, {})

export const extractFields = (fields = {}) => {
  const { search, filter } = fields
  const searchCategory = Object.keys(search.value)[0]

  const params = {}
  if (searchCategory && search.value[searchCategory]) {
    params[searchCategory] = search.value[searchCategory]
  }
  return {
    ...params,
    ...serialise(filter.value),
  }
}

export const parseDateRange = (dateMap = {}) => {
  const dateRange = Object.keys(dateMap)
    .filter(date => dateMap[date])
    .sort()

  if (!dateRange.length) {
    return {}
  }

  const [start, end] = dateRange

  return { start, end }
}
