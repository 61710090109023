import moment from 'moment'
import { createFormReducer } from './ideaFormCreator'
import { getEmailFromToken } from '../../util/TokenUtil'
import { name, path, endpoint, fields } from '../../data/volunteeringFormData'

const reducer = createFormReducer(name, path, endpoint, fields, {
  author_email: getEmailFromToken(),
  inbox: 'Yes',
  volunteering_unavoidable_costs: 'No',
  volunteering_date: moment().format('YYYY-MM-DD'),
})

export { reducer }
