import React, { useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import routes from '../../routes'
import { isLocalCharity, isVolunteering } from '../../features'

interface TabConfig {
  id: string
  label: string
  path: string
}

const AdminIdeasNavigation: React.FC<RouteComponentProps> = ({
  location,
  history,
}) => {
  const tabsConfig: TabConfig[] = [
    {
      id: 'localheroes',
      label: 'Local heroes',
      path: routes.adminLocalHeroes,
    },
    {
      id: '150days',
      label: '150 Days',
      path: routes.admin150Days,
    },
  ]

  if (isLocalCharity()) {
    tabsConfig.push({
      id: 'charities',
      label: 'Local charities',
      path: routes.adminCharities,
    })
  }

  if (isVolunteering()) {
    tabsConfig.unshift({
      id: 'volunteering',
      label: 'Volunteering',
      path: routes.adminVolunteering,
    })
  }

  let currentTab = tabsConfig[0].id
  tabsConfig.forEach((tab: TabConfig) => {
    if (tab.path === location.pathname) {
      currentTab = tab.id
    }
  })

  const [activeTab, setActiveTab] = useState<string>(currentTab)
  const tabs = tabsConfig.map((tab: TabConfig) => ({
    id: tab.id,
    label: tab.label,
    onClick: () => {
      history.push(tab.path)
      setActiveTab(tab.id)
    },
  }))

  return (
    <div className="ln-u-soft-bottom">
      <TabGroup tabs={tabs} activeTab={activeTab} />
    </div>
  )
}

export default withRouter(AdminIdeasNavigation)
