import { store } from '../store'
import { fetchFromCMS } from '../../util/contentfulUtil'

// 1. Action constants, these are the actions available
export const CONTENTFUL_OPPORTUNITY_DETAIL_GET_REQUEST =
  'contentful/opportunity-detail/get/request'
export const CONTENTFUL_OPPORTUNITY_DETAIL_GET_SUCCESS =
  'contentful/opportunity-detail/get/success'
export const CONTENTFUL_OPPORTUNITY_DETAIL_GET_FAILURE =
  'contentful/opportunity-detail/get/failure'

// 2, Action types, these should match the consts above
export type OpportunityDetailActionTypes =
  | { type: typeof CONTENTFUL_OPPORTUNITY_DETAIL_GET_SUCCESS; payload?: any }
  | { type: typeof CONTENTFUL_OPPORTUNITY_DETAIL_GET_FAILURE; payload?: any }
  | { type: typeof CONTENTFUL_OPPORTUNITY_DETAIL_GET_REQUEST; payload?: any }

// 3. Action creators
export function getOpportunityDetailRequest(
  payload: {} | null
): OpportunityDetailActionTypes {
  return {
    type: CONTENTFUL_OPPORTUNITY_DETAIL_GET_REQUEST,
    payload,
  }
}
export function getOpportunityDetailSuccess(
  payload: {} | null
): OpportunityDetailActionTypes {
  return {
    type: CONTENTFUL_OPPORTUNITY_DETAIL_GET_SUCCESS,
    payload,
  }
}
export function getOpportunityDetailFailure(
  payload: {} | null
): OpportunityDetailActionTypes {
  return {
    type: CONTENTFUL_OPPORTUNITY_DETAIL_GET_FAILURE,
    payload,
  }
}

// 4. Thunks
export async function fetchOpportunityDetailFromCMS(request: string) {
  store.dispatch(getOpportunityDetailRequest(null))
  try {
    // Success
    const opportunity = await fetchFromCMS(request)
    return store.dispatch(getOpportunityDetailSuccess(opportunity))
  } catch (error) {
    // Error
    return store.dispatch(getOpportunityDetailFailure(error))
  }
}
// TODO - Typecheck the payload
