import styled from 'styled-components'
import { FilledButton, OutlinedButton } from 'luna-react'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    flex-direction: row;
  }
`
const Block = styled.div`
  background: ${({ theme }) => theme.color.lighterGrey};
  padding: 40px;
  text-align: center;
  max-width: 60%;
  @media (max-width: ${({ theme }) => theme.mq.sm}) {
    max-width: 90%;
  }
`
const Heading = styled.h3`
  color: ${({ theme }) => theme.color.orange};
  font-size: 26px;
  line-height: 32px;
`
const P = styled.p`
  font-size: 18px;
`

const Button = styled(FilledButton)`
  margin: 10px;
  width: 80%;
`

const ButtonAlt = styled(OutlinedButton)`
  margin: 10px;
  width: 80%;
`

const Divider = styled.div`
  margin: 20px;
  width: 100%;
  max-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    min-width: auto;
  }
`

const InnerDivider = styled.span`
  font-family: MaryAnn, 'Trebuchet MS', sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  position: relative;
  padding: 10px;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: ${({ theme }) => theme.color.lightGrey};
    width: 200%;
    height: 1px;
    top: 50%;
    @media (min-width: ${({ theme }) => theme.mq.sm}) {
      top: auto;
      width: 1px;
      height: 200%;
      left: 50%;
    }
  }
  &:before {
    right: 100%;
    @media (min-width: ${({ theme }) => theme.mq.sm}) {
      right: auto;
      bottom: 100%;
    }
  }
  &:after {
    left: 100%;
    @media (min-width: ${({ theme }) => theme.mq.sm}) {
      left: 50%;
      top: 100%;
    }
  }
`

export { Wrapper, Block, Heading, P, Divider, InnerDivider, Button, ButtonAlt }
