import { PropTypes } from 'prop-types'
import React from 'react'
import { Container, Content } from '../../styles'
import FormSection from '../Form/FormSection/legacy/FormSection'
import { PageHeader } from '../PageHeader'
import { Form, FormSubmitButton, BoldP } from '../Form/FormFields/styles'

class LocalHeroesForm extends React.Component {
  componentDidMount = () => {
    const { doResetForm, isEditIdea } = this.props
    if (isEditIdea) {
      doResetForm()
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.props.isValid) {
      this.props.doSubmitForm()
    }
  }

  render() {
    const { isValid, formSections } = this.props
    return (
      <>
        <PageHeader
          title="Local Heroes &mdash; application form"
          blurb="Are you a Local Hero?"
        />

        <Content>
          <Container.Padded white>
            <BoldP>
              To apply for our Local Heroes funding scheme, please complete and
              submit the form below.
            </BoldP>

            <p>
              Once approved, £5 per hour will be donated to the charity or
              community group that you have volunteered with (up to £200 per
              year). If there are any issues with your application, then the
              Community Affairs team will be in touch.{' '}
            </p>

            <p>
              <strong>Please note:</strong> This form is for colleagues
              currently volunteering in their own time only, and is not
              connected to the 150 Days of Community volunteering project.
            </p>
            <Form onSubmit={this.handleSubmit} data-id="newLocalHeroesForm">
              {formSections.map((section, index) => (
                <FormSection
                  formType="local-heroes"
                  key={index}
                  section={section}
                />
              ))}
              <FormSubmitButton
                disabled={!isValid}
                type="submit"
                data-id="submit"
              >
                Review details
              </FormSubmitButton>
            </Form>
          </Container.Padded>
        </Content>
      </>
    )
  }
}

LocalHeroesForm.propTypes = {
  formSections: PropTypes.array.isRequired,
  isValid: PropTypes.bool,
  doSubmitForm: PropTypes.func.isRequired,
}

export default LocalHeroesForm
