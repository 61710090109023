import React from 'react'
import { Pagination } from 'luna-react'
import styled from 'styled-components'

interface AdminIdeasPaginationProps {
  pageActive: number
  pageLast: number
  changePageView: (pageNumber: number) => void
}

export const PaginationControl = styled(Pagination)`
  margin: 0 auto;
  justify-content: center;
  display: flex;
`
PaginationControl.displayName = 'PaginationControl'

const AdminIdeasPagination: React.FC<AdminIdeasPaginationProps> = ({
  changePageView,
  pageActive,
  pageLast,
}) => {
  const handleChange = (
    page: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault()
    changePageView(page)
  }
  return (
    <>
      <PaginationControl
        showFirstAndLast
        showPages
        onChange={handleChange}
        total={pageLast}
        current={pageActive}
      />
    </>
  )
}

export default AdminIdeasPagination
