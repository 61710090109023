import { createSelector } from 'reselect'
import { State } from '../store'

const adminIdeaReducer = (state: State) => state.adminIdea

const selectAdminIdeaSource = createSelector(adminIdeaReducer, reducer =>
  reducer && reducer.source ? reducer.source : ''
)

export { selectAdminIdeaSource }
