import { connect } from 'react-redux'
import PrivateRoute from './PrivateRoute'

const mapStateToProps = state => ({
  user: state.auth.user,
})

const PrivateRouteContainer = connect(mapStateToProps)(PrivateRoute)

export default PrivateRouteContainer
