import React from 'react'
import PropTypes from 'prop-types'
import { StatisticsIcon, StatisticsWrapper } from './styles'

const Statistics = ({ stats }) => (
  <StatisticsWrapper>
    <StatisticsIcon status="pending">{stats.pending}</StatisticsIcon>
    <StatisticsIcon status="accepted">{stats.accepted}</StatisticsIcon>
    <StatisticsIcon status="declined">{stats.declined}</StatisticsIcon>
    <StatisticsIcon status="updated">{stats.updated}</StatisticsIcon>
    <StatisticsIcon status="bookmarked">{stats.bookmarked}</StatisticsIcon>
    <StatisticsIcon status="total">{stats.total}</StatisticsIcon>
  </StatisticsWrapper>
)

Statistics.propTypes = {
  stats: PropTypes.shape({
    pending: PropTypes.number.isRequired,
    accepted: PropTypes.number.isRequired,
    declined: PropTypes.number.isRequired,
    updated: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
}

export { Statistics }
