import { fetch } from 'whatwg-fetch'
import { HttpErrors, HttpMethods } from './constants'
import download from 'downloadjs'
import { reportError } from '../util/errorReporting'

let API_URL: any
if (window && (window as any).ENV && (window as any).ENV.API_URL) {
  API_URL = (window as any).ENV.API_URL
}

const api = (uri: any, queryParams?: any) => {
  const url = `${API_URL}${uri}`
  return queryParams ? `${url}?${queryParams}` : url
}

const unauthorize = (status: any) => {
  throw new Error(HttpErrors.UNAUTHORIZED)
}

const handleAPIErrorResponse = (error: any) => {
  reportError(new Error(`API Response Error: ${error}`))
}

function checkResponseCodes<T>(response: any, responseBody: any) {
  switch (response.status) {
    case 200:
    case 201:
    case 202:
    case 204:
      return responseBody
    case 400:
      handleAPIErrorResponse(responseBody.error)
      throw new Error(responseBody.error)
    case 401:
    case 403:
      responseBody && handleAPIErrorResponse(responseBody.error)
      unauthorize(response.status)
      break
    case 404:
      throw new Error(HttpErrors.NOT_FOUND)
    case 422:
      throw new Error(HttpErrors.UNPROCESSABLE_ENTITY)
    case 500:
      throw new Error(HttpErrors.SERVER_ERROR)
    default:
      throw new Error(HttpErrors.UNKNOWN)
  }
  return null
}

async function getResponseBody(response: any) {
  try {
    return await response.json()
  } catch (error) {
    return null
  }
}

async function post(
  url: string,
  userToken = null,
  body = null,
  credentials = null
) {
  let response

  const headers: any = {
    'Content-Type': 'application/json; charset=utf-8',
  }
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`
  }
  const options: any = {
    headers,
    method: HttpMethods.POST,
  }
  if (body) {
    options.body = JSON.stringify(body)
  }
  if (credentials) {
    options.credentials = credentials
  }

  try {
    response = await fetch(url, options)
  } catch (error) {
    throw new Error(HttpErrors.CONNECTION_ERROR)
  }

  return checkResponseCodes(response, await getResponseBody(response))
}

async function patch(
  url: string,
  userToken = null,
  body = null,
  credentials = null
) {
  let response

  const headers: any = {
    'Content-Type': 'application/json; charset=utf-8',
  }
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`
  }
  const options: any = {
    headers,
    method: HttpMethods.PATCH,
  }
  if (body) {
    options.body = JSON.stringify(body)
  }
  if (credentials) {
    options.credentials = credentials
  }

  try {
    response = await fetch(url, options)
  } catch (error) {
    throw new Error(HttpErrors.CONNECTION_ERROR)
  }

  return checkResponseCodes(response, await getResponseBody(response))
}

const get = async <T>(url: string, userToken?: string) => {
  let response

  const headers: any = {}
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`
  }

  try {
    response = await fetch(url, {
      headers,
      method: HttpMethods.GET,
    })
  } catch (error) {
    throw new Error(HttpErrors.CONNECTION_ERROR)
  }

  return checkResponseCodes<T>(response, await getResponseBody(response))
}

async function getFile(url: string, userToken?: string) {
  const headers: any = {
    'Content-Type': 'text/csv',
  }

  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`
  }

  fetch(url, {
    headers,
    method: HttpMethods.GET,
  }).then((response: any) => {
    const contentDispositionValue = response.headers.get('content-disposition')

    response.blob().then((valueBlob: any) => {
      const startIndex = contentDispositionValue.indexOf('"') + 1
      const endIndex = contentDispositionValue.length - 1
      const fileName = contentDispositionValue.substring(startIndex, endIndex)

      download(valueBlob, fileName)
    })
  })
}

async function del(url: string, userToken?: string) {
  let response

  const headers: any = {}
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`
  }

  try {
    response = await fetch(url, {
      headers,
      method: HttpMethods.DELETE,
    })
  } catch (error) {
    throw new Error(HttpErrors.CONNECTION_ERROR)
  }

  return checkResponseCodes(response, await getResponseBody(response))
}

function getAPIEndpoint() {
  return API_URL
}

export { get, getFile, post, del, api, patch, getAPIEndpoint }
