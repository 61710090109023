import { actions } from '../actions/ideaFilter'
import { deserialise, OPTIONS } from '../../util/ideaFilterUtils'
import { parseUrl } from 'query-string'

const getFilterIndex = type => OPTIONS.findIndex(({ value }) => value === type)

const getInitialState = () => {
  const urlParams = parseUrl(window.location.search).query

  const defaultSearchKey =
    (urlParams.ref && 'ref') ||
    (urlParams.submitted_by && 'submitted_by') ||
    (urlParams.organisation_name && 'organisation_name')
  const defaultSearchTerm = urlParams[defaultSearchKey] || ''
  const filters = deserialise(urlParams)

  return {
    search: {
      value: defaultSearchKey ? { [defaultSearchKey]: defaultSearchTerm } : {},
    },
    filter: {
      value: {
        category: filters.category || {},
        business: filters.business || {},
        idea_status: filters.idea_status || {},
        created_date: filters.created_date || {},
      },
    },
    type: defaultSearchKey && getFilterIndex(defaultSearchKey),
  }
}

export const ideaFilterReducer = (state = getInitialState(), action = {}) => {
  const { filter, search } = state
  const { payload = {} } = action
  switch (action.type) {
    case actions.UPDATE_FILTER_FIELD:
      return {
        ...state,
        filter: {
          ...filter,
          value: {
            ...filter.value,
            [payload.key]: {
              ...filter.value[payload.key],
              [payload.value]: payload.selected,
            },
          },
        },
      }
    case actions.UPDATE_DATE_RANGE:
      return {
        ...state,
        filter: {
          ...filter,
          value: { ...filter.value, created_date: payload },
        },
      }
    case actions.UPDATE_SEARCH_FIELD:
      const { key, value = '' } = payload
      return {
        ...state,
        search: { ...search, value: { [key]: value } },
      }
    case actions.SEARCH_TYPE_CHANGE:
      return {
        ...state,
        search: {
          value: {},
        },
        type: payload.value,
      }
    case actions.RESET_FILTERS:
      return {
        ...state,
        filter: {
          value: {
            category: {},
            business: {},
            idea_status: {},
            created_date: {},
          },
        },
      }
    default:
      return { ...state }
  }
}
