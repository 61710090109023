import React from 'react'
import { withRouter } from 'react-router-dom'
import { registerPageView } from '../../util/analytics'
import { history } from '../../createHistory'
class Analytics extends React.Component {
  componentDidMount() {
    this.unsubscribe = history.listen(() => registerPageView({}))
  }
  componentWillUnmount() {
    this.unsubscribe()
  }
  render() {
    return null
  }
}

export default withRouter(Analytics)
