const actionTypes = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  REFRESH: 'REFRESH',
}

const login = token => ({
  type: actionTypes.LOGIN,
  payload: {
    token,
  },
})

const logout = () => ({
  type: actionTypes.LOGOUT,
})

const refresh = token => ({
  type: actionTypes.REFRESH,
  payload: {
    token,
  },
})

export { login, logout, refresh, actionTypes }
