import { PropTypes } from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Container, Content } from '../../styles'
import FormSection from '../Form/FormSection/legacy/FormSection'
import { PageHeader } from '../PageHeader'
import { Form, FormSubmitButton, FormCancelButton } from './styles'
import { LoadingIndicator } from 'luna-react'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import routes from '../../routes'

const Body = ({ children }) => (
  <>
    <PageHeader title="Edit Idea" />
    <Content>
      <Container.Padded white>{children}</Container.Padded>
    </Content>
  </>
)

class EditIdeaForm extends React.Component {
  componentDidMount() {
    const { doFetchIdea, id, match } = this.props
    if (id && id === match.params.id) {
      return
    } else {
      doFetchIdea(match.params.id)
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.props.isValid) {
      this.props.doSubmitForm()
    }
  }

  render() {
    const { loading, errorMessage, isValid, formSections, history } = this.props

    if (loading) {
      return (
        <Body>
          <LoadingIndicator message="Loading idea…" />
        </Body>
      )
    }

    if (errorMessage) {
      return (
        <Body>
          <ErrorMessage errorMessage={errorMessage} />
        </Body>
      )
    }

    return (
      <Body>
        <Form onSubmit={this.handleSubmit} data-id="EditIdeaForm">
          {formSections.map((section, index) => (
            <FormSection key={index} section={section} formType="new-idea" />
          ))}
          <FormCancelButton
            onClick={() => {
              history.push(routes.userIdeas)
            }}
          >
            Cancel
          </FormCancelButton>
          <FormSubmitButton disabled={!isValid} type="submit" data-id="submit">
            Review details
          </FormSubmitButton>
        </Form>
      </Body>
    )
  }
}

EditIdeaForm.propTypes = {
  formSections: PropTypes.array.isRequired,
  isValid: PropTypes.bool,
  doSubmitForm: PropTypes.func.isRequired,
}

export { EditIdeaForm }

export default withRouter(EditIdeaForm)
