import React from 'react'
import routes from '../routes'
import Layout from '../layouts/Layout/Layout'
import { BackHeader } from '../components/PageHeader'
import Opportunity from '../sections/Opportunity/Opportunity'

function OpportunityPage() {
  return (
    <Layout pageId="page-opportunity">
      <BackHeader
        title=""
        textTo="Opportunities"
        to={`${routes.opportunities}`}
      />
      <Opportunity />
    </Layout>
  )
}

export default OpportunityPage
