import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getLoading, getErrorMessage } from '../../redux/selectors/studies'
import { doFetchStudies } from '../../redux/dispatchers/studies'

import Study from './Study'
import { getStudy } from '../../redux/selectors/study'

const mapStateToProps = (state, ownProps) => {
  const { titleSlug } = ownProps
  return {
    study: getStudy(state, titleSlug),
    loading: getLoading(state),
    errorMessage: getErrorMessage(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doFetchStudies,
    },
    dispatch
  )
const StudyContainer = connect(mapStateToProps, mapDispatchToProps)(Study)

export default StudyContainer
