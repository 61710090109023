import { api, get } from '../../httpClient/httpClient'
import {
  getContentAbout,
  getContentAboutSuccess,
  getContentAboutFailure,
} from '../actions/about'
import { getAuthToken } from '../selectors/auth'
import errorHandler from './errorHandler'

const doFetchAbout = () => async (dispatch, getState) => {
  dispatch(getContentAbout())
  try {
    const token = getAuthToken(getState())

    const data = await get(api('/content/about'), token)

    dispatch(getContentAboutSuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, getContentAboutFailure)
  }
}

export { doFetchAbout }
