import styled from 'styled-components'
import theme from '../../styles/theme'

const StatisticsColours = Object.freeze({
  pending: theme.color.yellow,
  accepted: theme.color.green,
  declined: theme.color.red,
  updated: theme.color.aqua,
  bookmarked: theme.color.black,
  total: theme.color.grey,
})

const StatisticsWord = styled.div`
  background-color: #fff;
  font-size: 12px;
  display: flex;
  border-radius: 8px;
  padding-right: 8px;
  margin-bottom: 10px;
  margin-right: 10px;
`

StatisticsWord.displayName = 'StatisticsWord'

const StatisticsIcon = styled.div<{ status: string }>`
  color: ${props => (props.status === 'pending' ? 'black' : 'white')};
  height: 18px;
  width: 36px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 4px;
  text-align: center;
  background: ${props => StatisticsColours[props.status]};
`

StatisticsIcon.displayName = 'StatisticsIcon'

const StatisticsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  flex-wrap: wrap;
`

StatisticsWrapper.displayName = 'StatisticsWrapper'

export { StatisticsIcon, StatisticsWrapper, StatisticsWord }
