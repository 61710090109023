import styled from 'styled-components'
import { Tabs } from 'luna-react'
import { Link, NavLink } from 'react-router-dom'

const ProgrammesContainer = styled.div`
  min-height: 500px;
  padding: 0 10%;
  @media (max-width: ${({ theme }) => theme.mq.md}) {
    padding: 0 5%;
  }
  @media (max-width: ${({ theme }) => theme.mq.sm}) {
    padding: 0 2%;
  }
  @media (max-width: ${({ theme }) => theme.mq.xs}) {
    padding: 0;
  }
`

const ProgrammeTileStyle = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
  @media (max-width: ${({ theme }) => theme.mq.sm}) {
    padding-bottom: 16px;
  }
`

ProgrammeTileStyle.displayName = 'ProgrammeTileStyle'

const ProgrammeTileStyleHeading = styled.h3`
  align-self: flex-start;
  margin-bottom: 14px;
  font-size: 20px;
  text-decoration: none !important;
  p {
    text-decoration: none;
  }
`

const NoProgrammeMessage = styled.h3`
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 66px;
`
const ProgrammeImageContainer = styled.div`
  position: relative;
  height: 124px;
  width: 124px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.lighterGrey};
`

const ProgrammeTileStyleImage = styled.img`
  margin: 0 5px 28px 0;
  background-color: black;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 100%;
  width: auto;
  max-width: none;
`

const ProgrammeTileStyleInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 129px);
  padding-left: 14px;
`

ProgrammeTileStyleInfo.displayName = 'ProgrammeTileStyleInfo'

const TabsLinkStyled = styled(NavLink).attrs({
  className: 'ln-c-tabs__link',
})`
  border-right-width: 0;
  text-decoration: none !important;
  &.is-active::before {
    opacity: 0;
  }
`

TabsLinkStyled.displayName = 'TabsLinkStyled'

const TabsStyled = styled(Tabs)`
  &:not(.ln-c-tabs--pill) .ln-c-tabs__link:not(:last-child),
  &:not(.ln-c-tabs--pill):not(.ln-c-tabs--fill) .ln-c-tabs__link.is-active {
    border-right-width: 0;
  }
  @media (min-width: 960px) {
    .ln-c-tabs__link,
    .ln-c-tabs__list-wrap {
      flex-grow: 1;
      height: 48px;
    }
    .ln-c-tabs__link.is-active {
      border-bottom-color: #f06c00;
      border-bottom-width: 2px;
    }
    .ln-c-tabs__list {
      display: flex;
      height: 48px;
    }
  }
  @media (max-width: 959px) {
    .ln-c-tabs__link.is-active {
      border-bottom-color: #f06c00;
      border-bottom-width: 2px;
    }
  }
`

TabsStyled.displayName = 'TabsStyled'

const TabProgrammeStyled = styled(Link)`
  text-decoration: none;
  border-bottom: ${({ theme }) => theme.color.orange} 4px solid;
  :hover {
    text-decoration: underline;
  }
`
TabProgrammeStyled.displayName = 'TabProgrammeStyled'

const P = styled.p`
  margin-bottom: 0;
`

export {
  ProgrammesContainer,
  ProgrammeTileStyleHeading,
  ProgrammeImageContainer,
  ProgrammeTileStyleImage,
  ProgrammeTileStyleInfo,
  ProgrammeTileStyle,
  TabsLinkStyled,
  TabsStyled,
  NoProgrammeMessage,
  TabProgrammeStyled,
  P,
}
