import React from 'react'
import routes from '../../routes'
import FormattedDate from '../FormattedDate/FormattedDate'
import { TableCard } from '../../styles'

export interface IdeasDataRowProps {
  idea: any
  currentIndex: number
}

const IdeasDataRow150Days: React.FC<IdeasDataRowProps> = ({
  idea,
  currentIndex,
}) => {
  const {
    id,
    stores,
    colleagueName,
    contactEmail,
    ideaStatus = '',
    createdDate,
    ref,
    category,
  } = idea
  return (
    <TableCard.Wrapper
      to={`${routes.admin150Days}/${id}`}
      data-id={`idea-${currentIndex}`}
      data-ref={`${ref}`}
      data-status={`${ideaStatus}`}
      status={ideaStatus}
    >
      <TableCard.Block>
        <TableCard.Legend>Reference</TableCard.Legend>
        <TableCard.Label>{ref}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend> Date Submitted</TableCard.Legend>
        <TableCard.Label>
          <FormattedDate date={createdDate} />
        </TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Team Leader</TableCard.Legend>
        <TableCard.Label> {colleagueName}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Email</TableCard.Legend>
        <TableCard.Label> {contactEmail}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Store/team</TableCard.Legend>
        <TableCard.Label>
          {Array.isArray(stores) ? stores.join(', ') : stores}
        </TableCard.Label>
      </TableCard.Block>
      <TableCard.Block>
        <TableCard.Legend>Category</TableCard.Legend>
        <TableCard.Label> {category}</TableCard.Label>
      </TableCard.Block>
      <TableCard.Block data-id="ideas_status">
        <TableCard.Legend>Status</TableCard.Legend>
        <TableCard.Label status={ideaStatus}>
          {ideaStatus.toLowerCase()}
        </TableCard.Label>
      </TableCard.Block>
    </TableCard.Wrapper>
  )
}

export default IdeasDataRow150Days
