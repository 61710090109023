const routes = {
  home: '/',
  about: '/about',
  about150: '/about150',
  aboutLocalHeroes: '/aboutLocalHeroes',
  newIdea: '/new-idea',
  newIdeaConfirm: 'confirm',
  newIdeaSuccess: 'success',
  newIdeaError: 'error',
  opportunities: '/opportunities',
  organisation: 'organisation',
  caseStudies: '/case-studies',
  faqs: '/faqs',
  termsAndConditions: '/terms-and-conditions',
  admin: '/admin',
  adminLocalHeroes: '/admin/localheroes',
  adminCharities: '/admin/charities',
  adminVolunteering: '/admin/volunteering',
  admin150Days: '/admin/150days',
  adminIdeas: '/admin/ideas',
  userIdeas: '/ideas',
  editIdea: '/edit-idea',
  editIdeaConfirm: '/edit-idea-confirm',
  editIdeaError: '/edit-idea-error',
  editIdeaSuccess: '/edit-idea-success',
  privacyPolicy: '/privacy-policy',
  localCharity: '/local-charity',
  localHeroes: '/local-heroes',
  localHeroesConfirm: 'confirm',
  localHeroesSuccess: 'success',
  localHeroesError: 'error',
  adminIdea: '/admin/idea',
  programmes: '/programmes',
  programmesAll: '/programmes/all',
  programmesNational: '/programmes/national',
  programmesLocal: '/programmes/local',
  programmesPersonal: '/programmes/personal',
  programmesResources: '/programmes/resources',
  volunteeringProject: '/volunteering',
}

export default routes
