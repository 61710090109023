import React from 'react'
import { Container } from '../../styles'
import {
  PageTitleContainer,
  PageTitleWrapper,
  PageTitleH1,
} from './PageTitle.styles'

interface PageTitleProps {
  title: string
}

const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  return (
    <PageTitleContainer>
      <Container.FalseBleed>
        <PageTitleWrapper>
          <PageTitleH1>{title}</PageTitleH1>
        </PageTitleWrapper>
      </Container.FalseBleed>
    </PageTitleContainer>
  )
}

export default PageTitle
