import React, { useEffect } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { Container, Panel, CenteredLoadingIndicator } from '../../styles'
import routes from '../../routes'
import { Wrapper, ContainerStyled, StudyStyle, StudyLink } from './styles'
import { CaseStudiesHeading } from './styles.js'

const Studies = ({ studies, loading, errorMessage, doFetchStudies }) => {
  useEffect(() => {
    if (studies.length === 0) {
      doFetchStudies()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (loading)
    return <CenteredLoadingIndicator message="Loading case studies..." />
  if (errorMessage) return <div>{errorMessage}</div>
  return (
    <>
      <Wrapper>
        <Container.Padded>
          <CaseStudiesHeading size={26} accented>
            Latest Case studies
          </CaseStudiesHeading>
        </Container.Padded>
        {studies.map(study => (
          <ContainerStyled key={study.id}>
            <StudyStyle>
              <StudyLink to={`${routes.caseStudies}/${study.titleSlug}`}>
                <Panel.Head bg={study.image.fields.file.url} small />
                <Container.Padded>
                  <Panel.Container noPaddingBottom white>
                    <Panel.Title medium dark>
                      {study.title}
                    </Panel.Title>
                  </Panel.Container>
                </Container.Padded>
                <Container.Padded>
                  <Panel.Container white>
                    {documentToReactComponents(study.detail)[0]}
                  </Panel.Container>
                </Container.Padded>
              </StudyLink>
            </StudyStyle>
          </ContainerStyled>
        ))}
      </Wrapper>
    </>
  )
}

export default Studies

Studies.defaultName = 'Studies'
