export const truncate = (text, num) => {
  if (!text) {
    return ''
  }
  if (!num || text.length <= num) {
    return text
  }
  const truncatedText = text.slice(0, num)
  return truncatedText.substr(0, truncatedText.lastIndexOf(' ')) + ' ...'
}
