import React from 'react'
import { withRouter } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { State } from '../../redux/store'
// Store
import { fetchOpportunityDetailFromCMS } from '../../redux/actions'
import { connect } from 'react-redux'
// Components
import { Article } from '@jsluna/section'
import { Container, Content } from '../../styles'
import { Text } from '@jsluna/typography'
import PrimaryHeader from '../../components/PrimaryHeader/PrimaryHeader'
// Styles
import OpportunitySidebar from '../../components/Opportunity/OpportunitySidebar'
import CaptionListText from '../../components/CaptionListText/CaptionListText'
import { CenteredLoadingIndicator } from '../../styles'
// Theme
import theme from '../../styles/theme'

// TODO move atomic components & animations to their own directory
export const fadeInAnim = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const LoadingAnimationWrapper = styled.div`
  animation-name: ${fadeInAnim};
  animation-duration: 1.5s;
  animation-delay: 0s;
  animation-iteration-count: 1;
`
const FlexBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: ${props => props['justifyContent'] || 'space-between'};
  flex: 1;
`
interface FlexColumn {
  flex: string | number
}
const FlexColumn = styled.div<FlexColumn>`
  flex-direction: column;
  flex-basis: 100%;
  /* flex: 1; */
  @media (min-width: ${theme.mq.ss}) {
    flex-direction: row;
    flex: ${({ flex }) => flex || 1};
  }
`
const MainContent = styled(FlexBox)`
  padding: 24px;
  @media (max-width: ${theme.mq.ss}) {
    padding: 8px;
  }
`

interface PageParamsInterface {
  path: string
  url: string
  isExact: boolean
  params: {
    title: string
  }
}

interface OpportunityInterface {
  organisation_name?: string | null
  org_description?: string | null
  website?: string | null
  project_title?: string | null
  project_description?: string | null
  projects_skills?: string | null
  organisation_type?: string | null
  training?: string | null
  minimum_commitment?: string | null
  date_available_start?: string | null
  date_available_end?: string | null
  address_line_1?: string | null
  address_line_2?: string | null
  address_line_3?: string | null
  postal_code?: string | null
  contact_email?: string | null
  contact_number?: string | null
  volunteers_required_max?: string | null
  volunteers_required_min?: string | null
  geolocation?: {} | null
}
interface OpportunityProps {
  match: PageParamsInterface
  opportunityDetail: {}
}

function OpportunityComponent(props: OpportunityProps) {
  const { match }: { match: PageParamsInterface } = props
  const { opportunityDetail } = props
  const {
    organisation_name,
    website,
    project_title,
    project_description,
    projects_skills,
    training,
    minimum_commitment,
    date_available_start,
    date_available_end,
    contact_email,
    contact_number,
    volunteers_required_max,
  }: OpportunityInterface = opportunityDetail

  const opportunityTitle: string = match.params.title
  const opportunityRequest = `opportunities?title=${opportunityTitle}`

  React.useEffect(() => {
    fetchOpportunityDetailFromCMS(opportunityRequest) // thunk
  }, [opportunityRequest])

  // CONTENT IS LOADING
  let loadedContent = (
    <>
      <LoadingAnimationWrapper>
        <PrimaryHeader title={''} image={null} imageAlt={''} loading={true}>
          <CenteredLoadingIndicator />
        </PrimaryHeader>
      </LoadingAnimationWrapper>
      <Content>
        <Container.FalseBleed>
          <MainContent>
            <Article />
          </MainContent>
        </Container.FalseBleed>
      </Content>
    </>
  )
  // CONTENT IS READY
  if (project_title && organisation_name) {
    loadedContent = (
      <>
        <PrimaryHeader
          title={project_title}
          image={null}
          imageAlt={organisation_name || ''}
        >
          <div>
            <CaptionListText
              columns={true}
              marginBottom="0px"
              items={[
                {
                  title: 'Organisation: \b',
                  text: organisation_name,
                },
              ]}
            />
          </div>
        </PrimaryHeader>
        <Content>
          <Container.FalseBleed>
            <MainContent>
              <FlexColumn flex={3}>
                <Article>
                  <Text element="p" typeStyle="body-2">
                    {project_description}
                  </Text>
                </Article>
              </FlexColumn>
              <FlexColumn flex={1}>
                <OpportunitySidebar
                  availableFrom={date_available_start || ''}
                  availableUntil={date_available_end || ''}
                  email={contact_email || ''}
                  minimumCommitment={minimum_commitment || ''}
                  organisation_name={organisation_name}
                  phone={contact_number || ''}
                  skills={projects_skills || ''}
                  teamSize={volunteers_required_max || ''}
                  training={training || ''}
                  website={website || ''}
                />
              </FlexColumn>
            </MainContent>
          </Container.FalseBleed>
        </Content>
      </>
    )
  }

  return loadedContent
}

// Export connected
const mapStateToProps = (state: State) => ({
  opportunityDetail: state.opportunityDetail,
})
const Opportunity = connect(mapStateToProps, null)(OpportunityComponent)
export default withRouter(Opportunity)
