import React from 'react'
import Layout from '../layouts/Layout/Layout'

import EditIdeaSuccess from '../components/EditIdea/EditIdeaSuccess'

const EditIdeaSuccessSection = () => {
  return (
    <Layout pageId="page-edit-idea-success">
      <EditIdeaSuccess />
    </Layout>
  )
}

export default EditIdeaSuccessSection
