export type PaginationControlsType = {
  pageActive?: number
  pageLast?: number
}

export type StatisticsType = {
  pending: number
  accepted: number
  declined: number
  updated: number
  bookmarked: number
  total: number
}

export enum PaginationActionTypes {
  PAGINATION_SET_SOURCE = 'PAGINATION_SET_SOURCE',
  PAGINATION_SET_PAGE_DATA = 'PAGINATION_SET_PAGE_DATA',
  PAGINATION_CHANGE_PAGE = 'PAGINATION_CHANGE_PAGE',
}

export const paginatedRecordCount = 20

export enum AdminIdeasStatusTypes {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  COMPLETED = 'COMPLETED',
  EDITED = 'EDITED',
  DECLINED = 'DECLINED',
  BOOKMARKED = 'BOOKMARKED',
  UPDATED = 'UPDATED',
}

// Domain types
export type AdminIdeasDataVolunteeringType = {
  id: string
  ref: string
  authorEmail: string
  createdDate: string
  storeName: string
  organisationName: string
  volunteeringDate: string
  ideaStatus: AdminIdeasStatusTypes
}

export type AdminIdeasDataHeroesType = {
  colleagueName: string
  createdDate: string
  employeeNumber: string
  charityCategory: string[]
  id: string
  storeName: string
  ref: string
}

export type AdminIdeasData150DaysType = {
  colleagueName: string
  contactEmail: string
  organisationName: string
  createdDate: string
  ideaStatus: AdminIdeasStatusTypes
  category: string
  id: string
  stores: string[]
  ref: string
}

// Service types
export interface AdminIdeasData150Days {
  colleague_name: string
  contact_email: string
  organisation_name: string
  created_date: string
  idea_status: string
  category: string
  id: string
  business: string[]
  ref: string
}

export interface AdminIdeasDataHeroes {
  colleague_name: string
  created_date: string
  employee_number: string
  charity_category: string[]
  id: string
  store_name: string
  ref: string
}

export interface AdminIdeasDataVolunteering {
  id: string
  ref: string
  author_email: string
  created_date: string
  store_name: string
  organisation_name: string
  volunteering_date: string
  idea_status: string
}
