import React, { Component } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import Lightbox from 'react-image-lightbox'

import { Container, Panel, CenteredLoadingIndicator } from '../../styles'
import { StudyHeader } from '../StudyHeader/StudyHeader'
import { BackHeader } from '../PageHeader'
import styled from 'styled-components'
import routes from '../../routes'

const Paragraph = (node, children) => <Panel.B>{children}</Panel.B>
const Quote = (node, children) => (
  <Panel.Blockquote>{children}</Panel.Blockquote>
)

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: Paragraph,
    [BLOCKS.QUOTE]: Quote,
  },
}

const Wrapper = styled.div`
  padding: 300px 50px;
`

const MainContent = styled(Container.Padded)`
  padding: 40px 160px;
`

const StudyGalleryTitle = styled.h2`
  font-size: ${({ size }) => (size ? `${size}px` : '22px')};
  text-align: ${({ align }) => align || 'left'};
  color: ${({ theme, accented }) =>
    accented ? theme.color.orange : theme.color.darkGrey};
  margin: ${({ margin }) => (margin ? `${margin}px 0` : '20px 0')};
`

const GalleryImage = styled.img`
  display: block;
  cursor: pointer;
`

const GalleryLayout = styled.div`
  column-count: 1;
  column-gap: 0;

  @media (min-width: ${({ theme }) => theme.mq.sm}) and (max-width: ${({
      theme,
    }) => theme.mq.md}) {
    column-count: 2;
  }
  @media (min-width: ${({ theme }) => theme.mq.md}) {
    column-count: 3;
  }
`

const GalleryPanel = styled.div`
  break-inside: avoid;
  padding: 0px;
`

const GalleryContent = styled.div`
  padding: 5px;
`

class Study extends Component {
  state = {
    photoIndex: 0,
    isOpen: false,
  }

  componentDidMount() {
    if (!this.props.study) {
      this.props.doFetchStudies()
    }
  }

  render() {
    const { study, loading, errorMessage } = this.props
    const { photoIndex, isOpen } = this.state

    if (errorMessage) return <Wrapper>{errorMessage}</Wrapper>
    if (loading)
      return (
        <Wrapper>
          <CenteredLoadingIndicator message="Loading case study..." />
        </Wrapper>
      )

    if (!study) return null
    const { title, logo, detail, images = [] } = study

    return (
      <>
        <BackHeader title="" textTo="Case studies" to={routes.caseStudies} />
        <StudyHeader name={title} logo={logo && logo.fields.file.url} />
        <MainContent>
          <Container.Padded>
            {documentToReactComponents(detail, options)}
          </Container.Padded>
          {images.length > 0 && (
            <Container.Padded>
              <StudyGalleryTitle size={28} accented>
                Gallery
              </StudyGalleryTitle>
              <GalleryLayout>
                {images.map(
                  ({ fields }, index) =>
                    fields &&
                    fields.file &&
                    fields.file.url && (
                      <GalleryPanel>
                        <GalleryContent>
                          <GalleryImage
                            src={fields.file.url}
                            alt=" "
                            onClick={() =>
                              this.setState({ isOpen: true, photoIndex: index })
                            }
                          />
                        </GalleryContent>
                      </GalleryPanel>
                    )
                )}
              </GalleryLayout>
              {isOpen && (
                <Lightbox
                  mainSrc={
                    images[photoIndex].fields &&
                    images[photoIndex].fields.file.url
                  }
                  nextSrc={
                    images.length > 1 &&
                    images[(photoIndex + 1) % images.length].fields &&
                    images[(photoIndex + 1) % images.length].fields.file.url
                  }
                  prevSrc={
                    images.length > 1 &&
                    images[(photoIndex + images.length - 1) % images.length]
                      .fields &&
                    images[(photoIndex + images.length - 1) % images.length]
                      .fields.file.url
                  }
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex:
                        (photoIndex + images.length - 1) % images.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % images.length,
                    })
                  }
                />
              )}
            </Container.Padded>
          )}
        </MainContent>
      </>
    )
  }
}

export default Study
