import styled from 'styled-components'

const Content = styled.div<{
  grey?: boolean
  smallpadding?: boolean
  notoppadding?: boolean
}>`
  background: ${props =>
    props.grey ? props.theme.color.lighterGrey : props.theme.color.white};
  padding: ${props =>
    props.smallpadding
      ? props.notoppadding
        ? '0 0 10px 0'
        : '10px 0'
      : props.notoppadding
      ? '0 0 40px 0'
      : '40px 0'};
`

const ContentBottom = styled.div<{ grey: boolean }>`
  background: ${props =>
    props.grey ? props.theme.color.lighterGrey : props.theme.color.white};
  margin-bottom: 100px;
  padding-bottom: 40px;
`

const ContentInner = styled.div`
  background: ${({ theme }) => theme.color.lighterGrey};
  padding-top: 40px;
`

const ContentHeader = styled(Content)<{
  nopaddingbottom?: boolean
  spaced?: boolean
  bg?: string
}>`
  padding-bottom: ${props => (props.nopaddingbottom ? '0' : '20px')};
  padding-top: calc(
    ${props => (props.spaced ? '400px' : '40px')} +
      ${props => props.theme.header.height}
  );
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: top center;
`

const ContentHeaderTitle = styled.h1`
  align-items: center;
  display: flex;
  margin-bottom: 0.2rem;
  padding: 0 15px;
`

const ContentHeaderBlurb = styled.p`
  font-family: ${props => props.theme.fontFamily};
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  padding: 0 15px;
`

export {
  Content,
  ContentHeader,
  ContentHeaderTitle,
  ContentHeaderBlurb,
  ContentInner,
  ContentBottom,
}
