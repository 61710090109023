export const actionTypes = {
  GET_IDEA: 'GET_IDEA',
  GET_IDEA_SUCCESS: 'GET_IDEA_SUCCESS',
  GET_IDEA_FAILURE: 'GET_IDEA_FAILURE',
  GET_IDEA_HISTORY: 'GET_IDEA_HISTORY',
  GET_IDEA_HISTORY_SUCCESS: 'GET_IDEA_HISTORY_SUCCESS',
  GET_IDEA_HISTORY_FAILURE: 'GET_IDEA_HISTORY_FAILURE',
  UPDATE_IDEA: 'UPDATE_IDEA',
  UPDATE_IDEA_SUCCESS: 'UPDATE_IDEA_SUCCESS',
  UPDATE_IDEA_FAILURE: 'UPDATE_IDEA_FAILURE',
  UPDATE_IDEA_STATUS_SUCCESS: 'UPDATE_IDEA_STATUS_SUCCESS',
  SHOW_DECLINE_FORM: 'SHOW_DECLINE_FORM',
  HIDE_DECLINE_FORM: 'HIDE_DECLINE_FORM',
}

const {
  GET_IDEA,
  GET_IDEA_SUCCESS,
  GET_IDEA_FAILURE,
  GET_IDEA_HISTORY,
  GET_IDEA_HISTORY_SUCCESS,
  GET_IDEA_HISTORY_FAILURE,
  UPDATE_IDEA,
  UPDATE_IDEA_SUCCESS,
  UPDATE_IDEA_FAILURE,
  UPDATE_IDEA_STATUS_SUCCESS,
  SHOW_DECLINE_FORM,
  HIDE_DECLINE_FORM,
} = actionTypes

export const getIdea = idea_id => ({
  type: GET_IDEA,
  payload: {
    idea_id,
  },
})

export const getIdeaSuccess = (idea, campaign) => ({
  type: GET_IDEA_SUCCESS,
  payload: {
    idea,
    campaign,
  },
})

export const getIdeaFailure = errorMessage => ({
  type: GET_IDEA_FAILURE,
  payload: {
    errorMessage,
  },
})

export const getIdeaHistory = idea_id => ({
  type: GET_IDEA_HISTORY,
  payload: {
    idea_id,
  },
})

export const getIdeaHistorySuccess = ideaHistory => ({
  type: GET_IDEA_HISTORY_SUCCESS,
  payload: {
    ideaHistory,
  },
})

export const getIdeaHistoryFailure = errorMessage => ({
  type: GET_IDEA_HISTORY_FAILURE,
  payload: {
    errorMessage,
  },
})

export const updateIdea = idea_status => ({
  type: UPDATE_IDEA,
  payload: {
    idea_status,
  },
})

export const updateIdeaSuccess = idea => ({
  type: UPDATE_IDEA_SUCCESS,
  payload: {
    idea,
  },
})

export const updateIdeaFailure = (errorMessage, idea_status) => ({
  type: UPDATE_IDEA_FAILURE,
  payload: {
    errorMessage,
    idea_status,
  },
})

export const updateIdeaStatusSuccess = status => ({
  type: UPDATE_IDEA_STATUS_SUCCESS,
  status,
})

export const showDeclineForm = () => ({
  type: SHOW_DECLINE_FORM,
})

export const hideDeclineForm = () => ({
  type: HIDE_DECLINE_FORM,
})
