import React from 'react'
import {
  StatisticsIcon,
  StatisticsWrapper,
  StatisticsWord,
} from './AdminIdeasStatistics.styles'
import { StatisticsType } from '../../types'

interface StatisticsProps {
  stats: StatisticsType
}

const Statistics: React.FC<StatisticsProps> = ({ stats }) => (
  <StatisticsWrapper>
    <StatisticsWord>
      <StatisticsIcon status="total">{stats.total}</StatisticsIcon>
      TOTAL
    </StatisticsWord>
    <StatisticsWord>
      <StatisticsIcon status="pending">{stats.pending}</StatisticsIcon>
      PENDING
    </StatisticsWord>
    <StatisticsWord>
      <StatisticsIcon status="accepted">{stats.accepted}</StatisticsIcon>
      ACCEPTED
    </StatisticsWord>
    <StatisticsWord>
      <StatisticsIcon status="updated">{stats.updated}</StatisticsIcon>
      UPDATED
    </StatisticsWord>
    <StatisticsWord>
      <StatisticsIcon status="declined">{stats.declined}</StatisticsIcon>
      DECLINED
    </StatisticsWord>
  </StatisticsWrapper>
)

export { Statistics }
