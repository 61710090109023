import React from 'react'
import { withRouter } from 'react-router-dom'
import { Heading, Card, FilledButton } from 'luna-react'
import routes from '../routes'
import Layout from '../layouts/Layout/Layout'
import { Callout, Content, Container } from '../styles'

import { FullBleed } from '../styles/FullBleed'
import { BorderedCardWrapper } from '../styles/BorderedCard'
import { HomeHeader } from '../components/PageHeader'
import HardCodedImageBackground from '../components/ImageBackground/HardCodedImageBackground'
import FeedbackModal from '../components/FeedbackModal/FeedbackModal'
import { history } from '../createHistory'
import UserFeedback from '../components/UserFeedback/'
import HomeCaseStudies from '../components/Studies/HomeCaseStudiesContainer'

import {
  HomepageSection,
  HomepageHeading,
  HomepageText,
  GreyContainer,
} from '../styles/Home'

class Home extends React.Component {
  state = { isModalVisible: false }
  showModal = () => {
    this.setState({ isModalVisible: true })
  }
  hideModal = () => {
    this.setState({ isModalVisible: false })
  }

  render() {
    const { isModalVisible } = this.state
    return (
      <Layout pageId="page-home">
        {isModalVisible ? <FeedbackModal onDismiss={this.hideModal} /> : null}
        <HomeHeader title="Our Community" />
        <>
          <Content>
            <Container.Padded>
              <Callout.Wrapper>
                <HomepageHeading accented>
                  Help us measure your impact!
                </HomepageHeading>
                <Callout.Body>
                  Please complete this survey to help us measure the wider
                  impact of the 150 Days of Community initiative, for both the
                  beneficiaries and the Sainsbury’s colleagues who took part.
                </Callout.Body>
                <Callout.Button
                  data-id="survey-modal-button"
                  onClick={this.showModal}
                >
                  Submit your volunteer feedback
                </Callout.Button>
              </Callout.Wrapper>
            </Container.Padded>
          </Content>
          <FullBleed>
            <HardCodedImageBackground padding={{ md: '76px' }} image="150_days">
              <Container.Padded style={{ overflow: 'auto' }}>
                <HomepageSection>
                  <Card>
                    <Container.Padded padding={{ md: '15px' }}>
                      <Heading level={5}>
                        With 150 Days of Community now over, we are in the
                        process of updating OurCommunity. In the coming weeks we
                        will be updating this site with full details of our
                        current Community Affairs programmes, as well as sharing
                        some exciting new updates. Stay tuned!
                      </Heading>
                    </Container.Padded>
                  </Card>
                </HomepageSection>
              </Container.Padded>
            </HardCodedImageBackground>
          </FullBleed>
          <GreyContainer>
            <Container.Padded>
              <HomepageSection>
                <Container.Padded padding={{ md: '250px' }}>
                  <HomepageHeading size={26} accented>
                    Case studies
                  </HomepageHeading>
                  <HomepageText margin={20}>
                    Here are some of the community activities our colleagues
                    have supported in the past.
                  </HomepageText>
                </Container.Padded>
                <BorderedCardWrapper>
                  <HomeCaseStudies />
                </BorderedCardWrapper>
                <FilledButton
                  style={{ display: 'block', margin: '70px  auto' }}
                  onClick={() => history.push(routes.caseStudies)}
                >
                  See all case studies
                </FilledButton>
              </HomepageSection>
            </Container.Padded>
          </GreyContainer>
          <Content>
            <UserFeedback />
          </Content>
        </>
      </Layout>
    )
  }
}

export { Home }
export default withRouter(Home)
