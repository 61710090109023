export const ProgrammeAction = {
  REQUEST: 'programme/request',
  SUCCESS: 'programme/success',
  FAILURE: 'programme/failure',
}

export interface ProgrammeRequestActionType {
  type: typeof ProgrammeAction.REQUEST
  programmeKey: string
}

export interface ProgrammeSuccessActionType {
  type: typeof ProgrammeAction.SUCCESS
  payload: any
}

export interface ProgrammeFailureActionType {
  type: typeof ProgrammeAction.SUCCESS
  errorMessage: string
}

export function getProgrammeRequest(
  programmeKey: string
): ProgrammeRequestActionType {
  return {
    type: ProgrammeAction.REQUEST,
    programmeKey,
  }
}

export function getProgrammeSuccess(payload: any): ProgrammeSuccessActionType {
  return {
    type: ProgrammeAction.SUCCESS,
    payload,
  }
}

export function getProgrammeFailure(
  errorMessage: string
): ProgrammeFailureActionType {
  return {
    type: ProgrammeAction.FAILURE,
    errorMessage,
  }
}
