import React from 'react'
import {
  SearchLabel,
  SearchSelect,
  SearchTextInput,
  SearchWrapper,
} from './Filters.styles'
import { SearchOptionType } from '../../redux/actions/filters'

interface FiltersSearchProps {
  configData: any
  onChange: (key: string, value: string) => {}
  onSearchTypeChange: (searchOptions: SearchOptionType[], value: string) => {}
  searchValue: string
  disabled: boolean
  searchType: string
}

const getPlaceholder = (searchOptions: object, index: string) => {
  const option = searchOptions[index] || {}
  return option.placeholder || ''
}

const FiltersSearch: React.FC<FiltersSearchProps> = ({
  configData,
  onSearchTypeChange,
  onChange,
  searchType,
  searchValue,
  disabled,
}) => {
  if (configData && configData.searchOptions) {
    const searchOptions = configData.searchOptions
    return (
      <SearchWrapper>
        <SearchLabel>Search</SearchLabel>
        <SearchSelect
          name="idea_search_select"
          options={searchOptions.map(
            (currentOption: object, index: number) => ({
              ...currentOption,
              value: index,
            })
          )}
          value={searchType}
          onChange={(event: React.ChangeEvent<HTMLFormElement>) =>
            onSearchTypeChange(searchOptions, event.target.value)
          }
        />
        <SearchTextInput
          disabled={disabled}
          name="idea_search_input"
          onChange={(event: React.ChangeEvent<HTMLFormElement>) =>
            onChange(
              searchOptions[searchType] && searchOptions[searchType].value,
              event.target.value
            )
          }
          value={searchValue}
          placeholder={getPlaceholder(searchOptions, searchType)}
        />
      </SearchWrapper>
    )
  }
  return <></>
}

export default FiltersSearch
