import React from 'react'

const Address = ({ address }) => {
  if (address.address_line_1 === '') {
    delete address['address_line_1']
  }
  if (address.address_line_2 === '') {
    delete address['address_line_2']
  }
  if (address.address_line_3 === '') {
    delete address['address_line_3']
  }
  if (address.postal_code === '') {
    delete address['postal_code']
  }
  const addressString = Object.values(address)
    .filter(Boolean)
    .join(', ')
  return <span className="address">{addressString}</span>
}

export default Address
