import { connect } from 'react-redux'

import OpportunitiesAnywhere from './OpportunitiesAnywhere'
import { getOpportunitiesAvailableAnywhere } from '../../redux/selectors/opportunitiesList'

const mapStateToProps = state => {
  return {
    opportunities: getOpportunitiesAvailableAnywhere(state),
  }
}

const OpportunitiesAnywhereContainer = connect(mapStateToProps)(
  OpportunitiesAnywhere
)

export default OpportunitiesAnywhereContainer
