import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

const Footer = styled.footer`
  background: ${({ theme }) => theme.color.darkGrey};
  color: ${({ theme }) => theme.color.white};
`

const Wrapper = styled.div`
  width: 100%;
  padding: 40px 15px;
`

const SubWrapper = styled(Wrapper)`
  background: #3a3a3a;
  display: flex;
  justify-content: center;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: ${props => props.theme.mq.md}) {
    flex-direction: row;
    width: auto;
    justify-content: center;
  }
`

const ListItem = styled.li`
  display: inherit;
  width: 100%;
  text-align: center;
  @media (min-width: ${props => props.theme.mq.md}) {
    width: auto;
  }
`

const footerLink = css`
  text-decoration: none;
  padding: 16px;
  width: 100%;
  color: ${props => props.theme.color.white};

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: ${props => props.theme.color.white};
  }

  @media (min-width: ${props => props.theme.mq.md}) {
    width: auto;
  }
`

const ListLink = styled.a`
  ${footerLink}
`

const ListLinkInternal = styled(Link)`
  ${footerLink}
`

const P = styled.p`
  text-align: center;
  padding: 0 16px;
  margin-bottom: 8px;
`

export {
  Footer,
  Wrapper,
  SubWrapper,
  List,
  ListItem,
  ListLink,
  ListLinkInternal,
  P,
}
