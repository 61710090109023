export const actionTypes = {
  GET_IDEAS: 'GET_IDEAS',
  GET_IDEAS_SUCCESS: 'GET_IDEAS_SUCCESS',
  GET_IDEAS_FAILURE: 'GET_IDEAS_FAILURE',
  UPDATE_SORT_BY: 'UPDATE_SORT_BY',
  CLEAR_IDEAS: 'CLEAR_IDEAS',
}

const {
  GET_IDEAS,
  GET_IDEAS_SUCCESS,
  GET_IDEAS_FAILURE,
  UPDATE_SORT_BY,
  CLEAR_IDEAS,
} = actionTypes

export const getIdeas = () => ({
  type: GET_IDEAS,
})

export const getIdeasSuccess = ideas => ({
  type: GET_IDEAS_SUCCESS,
  payload: {
    ideas,
  },
})

export const getIdeasFailure = errorMessage => ({
  type: GET_IDEAS_FAILURE,
  payload: {
    errorMessage,
  },
})

export const updateSortBy = by => ({
  type: UPDATE_SORT_BY,
  payload: {
    by,
  },
})

export const clearIdeas = () => ({
  type: CLEAR_IDEAS,
})
