import React from 'react'
import { AdminIdeaDataHeroesType } from '../../types'
import { IdeaBody, IdeaTitle } from '../../styles'

interface AdminIdeaDetailHeroesProps {
  data: AdminIdeaDataHeroesType
}

const AdminIdeaDetailHeroes: React.FC<AdminIdeaDetailHeroesProps> = ({
  data,
}) => {
  const {
    business,
    charityAccountNumber,
    charityAddress,
    charityCategory,
    charityContact,
    charityEmailAddress,
    charityName,
    charityNumber,
    charityPhoneNumber,
    charitySortCode,
    colleagueEmail,
    colleagueName,
    employeeNumber,
    gender,
    hoursClaimed,
    storeName,
    title,
    volunteeringDetails,
  } = data
  return (
    <>
      <div id="idea_colleague_name">
        <IdeaTitle>Colleague name</IdeaTitle>
        <IdeaBody>{colleagueName}</IdeaBody>
      </div>
      <div id="idea_colleague_email">
        <IdeaTitle>Colleague or store email address</IdeaTitle>
        <IdeaBody>{colleagueEmail}</IdeaBody>
      </div>
      <div id="idea_gender">
        <IdeaTitle>Gender</IdeaTitle>
        <IdeaBody>{gender}</IdeaBody>
      </div>
      <div id="idea_employee_number">
        <IdeaTitle>Employee number</IdeaTitle>
        <IdeaBody>{employeeNumber}</IdeaBody>
      </div>
      <div id="idea_store_team">
        <IdeaTitle>Associated store or team</IdeaTitle>
        <IdeaBody>
          {Array.isArray(business) ? business.join(', ') : business}
        </IdeaBody>
      </div>
      <div id="idea_store_number_name">
        <IdeaTitle>
          Store name and number, depot name or divisional team name
        </IdeaTitle>
        <IdeaBody>{storeName}</IdeaBody>
      </div>
      <div id="idea_charity_name">
        <IdeaTitle>Name of charity / community group</IdeaTitle>
        <IdeaBody>{charityName}</IdeaBody>
      </div>
      <div id="idea_charity_number">
        <IdeaTitle>Registered charity number</IdeaTitle>
        <IdeaBody>{charityNumber}</IdeaBody>
      </div>
      <div id="idea_charity_contact">
        <IdeaTitle>Contact name at charity / community group</IdeaTitle>
        <IdeaBody>{charityContact}</IdeaBody>
      </div>
      <div id="idea_charity_address">
        <IdeaTitle>Charity / community group address</IdeaTitle>
        <IdeaBody>{charityAddress}</IdeaBody>
      </div>
      <div id="idea_charity_email">
        <IdeaTitle>Charity / community group contact email address</IdeaTitle>
        <IdeaBody>{charityEmailAddress}</IdeaBody>
      </div>
      <div id="idea_charity_phone_number">
        <IdeaTitle>Charity / community group telephone number</IdeaTitle>
        <IdeaBody>{charityPhoneNumber}</IdeaBody>
      </div>
      <div id="idea_charity_account_number">
        <IdeaTitle>Charity / community group bank account number</IdeaTitle>
        <IdeaBody>{charityAccountNumber}</IdeaBody>
      </div>
      <div id="idea_charity_sort_code">
        <IdeaTitle>Charity / community group sort code</IdeaTitle>
        <IdeaBody>{charitySortCode}</IdeaBody>
      </div>
      <div id="idea_charity_hours">
        <IdeaTitle>How many hours to claim</IdeaTitle>
        <IdeaBody>{hoursClaimed}</IdeaBody>
      </div>
      <div id="idea_charity_volunteer">
        <IdeaTitle>What did you do to volunteer?</IdeaTitle>
        <IdeaBody>{volunteeringDetails}</IdeaBody>
      </div>
      <div id="idea_charity_categories">
        <IdeaTitle>
          Categories that best describe the work of the charity/community group
          you are supporting
        </IdeaTitle>
        <IdeaBody>
          {Array.isArray(charityCategory)
            ? charityCategory.join(', ')
            : charityCategory}
        </IdeaBody>
      </div>
      <div id="idea_title">
        <IdeaTitle>Title</IdeaTitle>
        <IdeaBody>{title}</IdeaBody>
      </div>
    </>
  )
}

export default AdminIdeaDetailHeroes
