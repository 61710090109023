import React from 'react'
import PropTypes from 'prop-types'

import OpportunitySummary from '../../components/OpportunitySummary/OpportunitySummary'
import { ContentInner } from '../../styles'
import { TwoColContainer } from './styles'

const renderResultsList = results => {
  const resultsList = results.map((result, index) => {
    return (
      <OpportunitySummary
        key={`${index}_${result.id}`}
        data={result}
        organisationSlug={result.organisation_slug}
        anywhere
        isPadded={true}
        nomargin={false}
      />
    )
  })
  return (
    <TwoColContainer data-id="opportunities-anywhere-list">
      {resultsList}
    </TwoColContainer>
  )
}

const OpportunitiesAnywhere = ({ opportunities }) => {
  if (opportunities && opportunities.length && opportunities.length > 0) {
    return (
      <>
        <ContentInner>{renderResultsList(opportunities)}</ContentInner>
      </>
    )
  }
  return null
}

OpportunitiesAnywhere.propTypes = {
  opportunities: PropTypes.array,
}

export default OpportunitiesAnywhere
