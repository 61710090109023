import React, { useEffect } from 'react'
import { Container, GridWrapper, GridItem, FilledButton } from 'luna-react'
import { scroller, Element } from 'react-scroll'
import PageTitle from '../../components/PageTitle/PageTitle'
import CCHAccordion from '../../components/CCHAccordion/CCHAccordion'
import Resources from '../../components/Resources/Resources'
import ContentfulText from '../../components/ContentfulText/ContentfulText'
import ContentfulImage from '../../components/ContentfulImage/ContentfulImage'
import Head from '../../components/Head/Head'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import ProgrammeLayout from './Programme.layout'
import {
  ProgrammePadding,
  ProgrammeLoaderPadding,
  FaqsTitle,
  FaqsContainer,
  HideMobile,
} from './Programme.styles'
import {
  Container as Wrapper,
  Content,
  CenteredLoadingIndicator,
} from '../../styles'

interface ProgrammeProps {
  programmeKey: string
  programmeData: any
  getProgrammeData: (programmeKey: string) => void
  match: any
  loading: boolean
  errorMessage: string
}

const Programme: React.FC<ProgrammeProps> = ({
  programmeKey,
  programmeData,
  getProgrammeData,
  match,
  loading,
  errorMessage,
}) => {
  const programmeKeyCurrent = match.params.programmeKey

  useEffect(() => {
    if (programmeKeyCurrent !== programmeKey) {
      getProgrammeData(programmeKeyCurrent)
    }
  }, [programmeKey, programmeKeyCurrent, getProgrammeData])

  if (loading) {
    return (
      <ProgrammeLayout>
        <ProgrammeLoaderPadding>
          <CenteredLoadingIndicator message="Fetching programme..." />
        </ProgrammeLoaderPadding>
      </ProgrammeLayout>
    )
  }

  if (errorMessage) {
    return (
      <ProgrammeLayout>
        <Container>
          <Wrapper.FalseBleed>
            <ProgrammePadding>
              <ErrorMessage errorMessage={errorMessage} />
            </ProgrammePadding>
          </Wrapper.FalseBleed>
        </Container>
      </ProgrammeLayout>
    )
  }

  if (!programmeData) {
    return null
  }

  const {
    programmeTitle,
    programmeText,
    programmeText2,
    image,
    resourceItems,
    formItems,
    faqItems,
  } = programmeData

  return (
    <ProgrammeLayout>
      <Head title={programmeTitle} description={programmeTitle} />
      <PageTitle title={programmeTitle} />
      <Container>
        <Wrapper.FalseBleed>
          <ProgrammePadding>
            <GridWrapper element="div">
              <GridItem size={{ md: '1/4', lg: '2/12' }} element="div">
                {/* TODO: Add menu component */}
                <div />
              </GridItem>
              <GridItem size={{ md: '3/4', lg: '7/12' }} element="div">
                {programmeText && <ContentfulText content={programmeText} />}
                {image && <ContentfulImage content={image} />}
                {programmeText2 && <ContentfulText content={programmeText2} />}
              </GridItem>
              <GridItem size={{ lg: '1/4' }} element="div">
                {resourceItems && (
                  <Resources withIcon title="Resources" items={resourceItems} />
                )}
                {formItems && <Resources title="Forms" items={formItems} />}
                {faqItems && (
                  <HideMobile>
                    <FilledButton
                      fullWidth
                      onClick={() => {
                        scroller.scrollTo('faqs-accordion', {
                          smooth: true,
                          duration: 1000,
                        })
                      }}
                    >
                      FAQs
                    </FilledButton>
                  </HideMobile>
                )}
              </GridItem>
            </GridWrapper>
          </ProgrammePadding>
        </Wrapper.FalseBleed>
      </Container>
      {faqItems && (
        <Content grey>
          <Wrapper.FalseBleed>
            <Element name="faqs-accordion">
              <FaqsContainer>
                <FaqsTitle>Related FAQs</FaqsTitle>
                <CCHAccordion items={faqItems} />
              </FaqsContainer>
            </Element>
          </Wrapper.FalseBleed>
        </Content>
      )}
    </ProgrammeLayout>
  )
}

export default Programme
