import React from 'react'
import Layout from '../layouts/Layout/Layout'

import { PageHeader } from '../components/PageHeader'
import {
  ContentBottom,
  Content,
  Container,
  OpportunitiesSearch,
} from '../styles'
import OpportunitiesControls from '../components/OpportunitiesControls/OpportunitiesControls'
import OpportunitiesSearchContainer from '../components/OpportunitiesSearch/OpportunitiesSearchContainer'
import OpportunitiesSearchResultsContainer from '../components/OpportunitiesSearchResults/OpportunitiesSearchResultsContainer'
import { getParamValueFromLocation } from '../util/getParamValue'

import OpportunitiesAnywhereContainer from '../components/OpportunitiesAnywhere/OpportunitiesAnywhereContainer'
import styled from 'styled-components'

const AnywhereLabel = styled.span`
  color: ${props => props.theme.color.orange};
`

const OpportunitiesView = ({ location }) => {
  return (
    <Layout pageId="page-opportunities">
      <PageHeader
        title="Volunteering Opportunities"
        blurb="We know that sometimes the hardest part about setting up
              volunteering activity, is simply knowing where to look."
      />
      <Content>
        <Container.FalseBleed padded>
          <OpportunitiesControls selectOpp />
          <OpportunitiesSearchContainer
            searchTerm={getParamValueFromLocation(location, 'address')}
            distance={getParamValueFromLocation(location, 'distance')}
            teamSize={getParamValueFromLocation(location, 'team_size')}
          />
          <OpportunitiesSearchResultsContainer />
        </Container.FalseBleed>
      </Content>

      <Content smallpadding>
        <Container.FalseBleed padded>
          <OpportunitiesSearch.Heading hard>
            Volunteering Opportunities based{' '}
            <AnywhereLabel>UK Wide</AnywhereLabel>
          </OpportunitiesSearch.Heading>
        </Container.FalseBleed>
      </Content>

      <ContentBottom notoppadding grey>
        <Container.FalseBleed padded>
          <OpportunitiesAnywhereContainer />
        </Container.FalseBleed>
      </ContentBottom>
    </Layout>
  )
}

export default OpportunitiesView

OpportunitiesView.displayName = 'OpportunitiesSearch'
