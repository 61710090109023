import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Container } from '../../styles'
import Hero from '../Hero/Hero'
import { Wrapper } from './styles'
import Head from '../Head/Head'
import { Heading } from 'luna-react'
import ourCommunityImage from '../../assets/our-community.svg'
import { homeGradient } from '../PageHeader/styles'

const OurCommunityImage = styled.img`
  margin-bottom: 30px;
`

const HomepageHeaderWrapper = styled(Wrapper)`
  ${Container.FullBleed} {
    @media (min-width: ${({ theme }) => theme.mq.sm}) {
      display: flex;
    }
  }
`
const HomepageHeroWrapper = styled.div`
  img {
    width: 100%;
  }
`
const HomepageHeaderTextWrapper = styled.div`
  padding: 0 20px;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    flex: 1 0 40%;
  }
`

const SectionDivider = styled.div`
  background-color: ${({ theme }) => theme.color.orange};
  height: 10px;
  width: 100%;
  position: relative;
`

const HomeHeader = () => (
  <HomepageHeaderWrapper background={homeGradient} nopaddingbottom>
    <Head title="Welcome to our community" />
    <Container.FullBleed>
      <HomepageHeaderTextWrapper level={4}>
        <OurCommunityImage src={ourCommunityImage} />
        <Heading level={4}>
          The place to be for all Sainsbury’s Group community based activity.
        </Heading>
        <Heading level={6}>
          Making a positive difference in our communities is a core Sainsbury’s
          value - this site is here to help make this easier and even better
          than before!
        </Heading>
      </HomepageHeaderTextWrapper>
      <HomepageHeroWrapper>
        <Hero />
      </HomepageHeroWrapper>
    </Container.FullBleed>
    <SectionDivider />
  </HomepageHeaderWrapper>
)

HomeHeader.propTypes = {
  title: PropTypes.string.isRequired,
}
export { HomeHeader }
