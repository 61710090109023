import styled from 'styled-components'

/*eslint no-unexpected-multiline:0*/
const Wrapper = styled.div<{
  background?: string
  nopaddingbottom?: boolean
  minHeight?: string
}>`
  background: ${({ background, theme }) =>
    background || theme.color.lighterGrey};
  padding-bottom: ${({ nopaddingbottom }) => (nopaddingbottom ? '0' : '60px')};
  padding-top: calc(40px + ${({ theme }) => theme.header.height});
  min-height: ${({ minHeight }) => (minHeight ? minHeight : 'auto')};
`

const Title = styled.h1<{ hard?: boolean; small?: boolean }>`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  padding: 0 ${({ hard }) => (hard ? 0 : '15px')};
  ${({ small }) => (small ? 'font-size: 1.625rem;' : null)}
`

const SubTitle = styled.p`
  font-family: ${props => props.theme.fontFamily};
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  padding: 0 15px;
`

SubTitle.displayName = 'SubTitle'

export { Wrapper, Title, SubTitle }
