import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { doFetchIdeaWithHistory } from '../../redux/dispatchers/idea'
import UserIdea from './UserIdea'

const mapStateToProps = (state, ownProps) => {
  return {
    idea_id: ownProps.idea_id,
    idea: state.ideaReducer.idea,
    loading: state.ideaReducer.loading,
    errorMessage: state.ideaReducer.errorMessage,
    campaign: ownProps.match.params.campaign,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doFetchIdeaWithHistory,
    },
    dispatch
  )

const IdeaContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserIdea)
)

export default IdeaContainer
