import styled from 'styled-components'
import { Body1, Body2, Text as LunaText, Heading6 } from '@jsluna/typography'

const Text = styled(LunaText).attrs<{ orange?: boolean }>(({ orange }) => {
  if (orange) {
    return {
      className: 'ln-u-color-orange',
    }
  }

  return null
})``

const Paragraph = styled(Body1).attrs(() => ({
  element: 'p',
}))``

const Paragraph1 = Paragraph

const Paragraph2 = styled(Body2).attrs(() => ({
  element: 'p',
}))``

const HeadingSmall = styled(Heading6).attrs(() => ({
  element: 'p',
  className: 'ln-u-font-size-body-2 ln-u-margin-bottom*1',
}))``

export { Text, Paragraph, Paragraph1, Paragraph2, HeadingSmall }
