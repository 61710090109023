import { connect } from 'react-redux'

import { getErrorMessage } from '../../redux/selectors/newIdea'

import NewIdeaError from './NewIdeaError'

const mapStateToProps = state => {
  return {
    errorMessage: getErrorMessage(state),
  }
}

export default connect(mapStateToProps)(NewIdeaError)
