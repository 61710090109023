import { api, post } from '../../httpClient/httpClient'
import routes from '../../routes'
import { getAuthToken } from '../selectors/auth'
import errorHandler from './errorHandler'
import { validationMap } from '../../util/newIdeaValidations'
import {
  sendIdea,
  sendIdeaFailure,
  sendIdeaSuccess,
  setFormError,
  updateForm,
  resetForm,
} from '../actions/newIdea'
import { getFormValues } from '../selectors/newIdea'
import { history } from '../../createHistory'
import { removeEditIdeaId } from '../actions/editIdea'

const doUpdateForm = (key, value) => async dispatch => {
  try {
    await validationMap[key].validate(value)
    dispatch(setFormError(key, undefined))
  } catch (error) {
    dispatch(setFormError(key, error.message))
  } finally {
    dispatch(updateForm(key, value))
  }
}

const doSubmitForm = () => () => {
  history.push(`${routes.newIdea}/${routes.newIdeaConfirm}`)
}

const doResetForm = () => dispatch => {
  dispatch(resetForm())
  dispatch(removeEditIdeaId())
}

const doSendIdeaFailure = errorMessage => dispatch => {
  dispatch(sendIdeaFailure(errorMessage))
  history.push(`${routes.newIdea}/${routes.newIdeaError}`)
}

const doPostIdea = () => async (dispatch, getState) => {
  try {
    dispatch(sendIdea())
    const state = getState()
    const formValues = getFormValues(state)
    const token = getAuthToken(state)
    const data = await post(api('/ideas'), token, formValues)
    const { contact_email, ref } = data
    dispatch(sendIdeaSuccess({ contact_email, ref }))
    history.replace(`${routes.newIdea}/${routes.newIdeaSuccess}`)
  } catch (error) {
    errorHandler(dispatch, error, doSendIdeaFailure)
  }
}

export {
  doSubmitForm,
  doPostIdea,
  doUpdateForm,
  doSendIdeaFailure,
  doResetForm,
}
