import {
  OrganisationActionTypes,
  OpportunityActionTypes,
} from '../actions/opportunitiesList'
import { normalizeOpportunities } from '../../util/opportunitiesUtils'

const initialState = {
  selectedOrganisation: '',
  loading: false,
  errorMessage: null,
  opportunities: [],
  opportunitiesById: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OrganisationActionTypes.SET_ORGANISATION:
      return {
        ...state,
        selectedOrganisation: action.payload.selectedOrganisation,
      }
    case OpportunityActionTypes.GET_OPPORTUNITIES:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      }
    case OpportunityActionTypes.GET_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        opportunities: action.payload.opportunities,
        opportunitiesById: normalizeOpportunities(action.payload.opportunities),
        loading: false,
      }
    case OpportunityActionTypes.GET_OPPORTUNITIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      }

    default:
      return state
  }
}

export { initialState, reducer, normalizeOpportunities }
