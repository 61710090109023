import { createSelector } from 'reselect'
import { State } from '../store'

const selectIdeaReducer = (state: State) => state.ideaReducer

const selectIdeaData = createSelector(
  selectIdeaReducer,
  ideaReducer => ideaReducer.idea
)

const selectIsLoading = createSelector(
  selectIdeaReducer,
  ideaReducer => ideaReducer.loading
)

const selectErrorMessage = createSelector(
  selectIdeaReducer,
  ideaReducer => ideaReducer.errorMessage
)

const selectIsDeclineFormVisible = createSelector(
  selectIdeaReducer,
  ideaReducer => ideaReducer.isDeclineFormVisible
)

export {
  selectIdeaData,
  selectIsLoading,
  selectErrorMessage,
  selectIsDeclineFormVisible,
}
