import { OutlinedButton } from 'luna-react'
import styled from 'styled-components'

export const FeedbackWrapper = styled.div`
  text-align: left;
  max-height: 100%;
  overflow-y: scroll;
  width: 80%;
  background-color: #fff;
  padding: 40px;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    width: 768px;
  }
`

export const FeedbackStepWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    justify-content: space-between;
    display: flex;
  }
`

export const FeedbackStep = styled.div`
background-color: ${({ theme }) => theme.color.lighterGrey}
text-align: center;
padding: 20px;
margin: 20px 0 20px;
@media (min-width: ${({ theme }) => theme.mq.sm}) {
flex: 1 1 45%;
margin: 10px;
&:first-of-type {
  margin-left: 0
}

&:last-of-type {
  margin-right: 0
}
}
`

export const CloseButton = styled(OutlinedButton)`
  float: right;
  margin: 30px 0;
`

export const FeedbackDisclaimer = styled.p`
  text-align: center;
  font-size: 14px;
`
