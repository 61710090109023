import React from 'react'
import Layout from '../layouts/Layout/Layout'

import LocalHeroesFormContainer from '../components/LocalHeroes/LocalHeroesFormContainer'

const LocalHeroesForm = () => {
  return (
    <Layout pageId="page-new-lh-form">
      <LocalHeroesFormContainer />
    </Layout>
  )
}

export default LocalHeroesForm
