import React from 'react'
import styled from 'styled-components'
const responsifyImage = image => {
  return `
  @media
    (min-resolution: 96dpi),
    (min-resolution: 1dppx),
    (-webkit-min-device-pixel-ratio: 1),
    (min--moz-device-pixel-ratio: 1) {
      background: transparent url(${image}?w=1440) center center / cover no-repeat;
  }
  @media
      (min-resolution: 192dpi),
      (min-resolution: 2dppx),
      (-webkit-min-device-pixel-ratio: 2),
      (min--moz-device-pixel-ratio: 2) {
        background: transparent url(${image}) center center / cover no-repeat;
  }`
}
const getPadding = (padding, breakpoint) => {
  if (typeof padding === 'object' && padding !== null) {
    return padding[breakpoint]
  }
  return padding
}

const Background = styled.div`
  padding: ${({ padding }) => (padding ? getPadding(padding, 'md') : '15px')} 0;
  overflow: auto;
  ${({ image }) => responsifyImage(image)}
`

const ImageBackground = ({ image, children, padding }) => (
  <Background padding={padding} image={image}>
    {children}
  </Background>
)
export default ImageBackground
