import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import routes from '../../routes'
import { isAnyAdmin } from '../../util/roles'

const PrivateRoute = ({
  component,
  user,
  redirectTo = routes.home,
  ...rest
}) => {
  return isAnyAdmin(user) ? (
    <Route component={component} {...rest} />
  ) : (
    <Redirect to={redirectTo} />
  )
}

PrivateRoute.propTypes = {
  user: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export default PrivateRoute
