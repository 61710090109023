import React from 'react'
import styled from 'styled-components'
import bookmarkOutline from '../../assets/bookmark-outline.svg'

const BookmarkLabel = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.color.darkGrey};
  line-height: 2.5;
  background: transparent url(${bookmarkOutline}) 0 center / contain no-repeat;
  padding-left: 20px;
`
const BookmarkIcon = () => (
  <div>
    <BookmarkLabel>Bookmark</BookmarkLabel>
  </div>
)

export default BookmarkIcon
