import { createSelector } from 'reselect'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'

export const listReducer = state => state.opportunitiesReducer.list
export const searchReducer = state => state.opportunitiesReducer.search

export const getOpportunities = createSelector(
  listReducer,
  reducer => reducer.opportunities
)

export const getOpportunitiesById = createSelector(
  searchReducer,
  listReducer,
  (searchReducer, listReducer) => ({
    ...searchReducer.opportunitiesById,
    ...listReducer.opportunitiesById,
  })
)

export const getSelectedOrganisation = createSelector(
  listReducer,
  reducer => reducer.selectedOrganisation
)

export const getLoading = createSelector(
  listReducer,
  reducer => reducer.loading
)

export const getErrorMessage = createSelector(
  listReducer,
  reducer => reducer.getErrorMessage
)

export const getOpportunitiesGroupedByOrganisation = createSelector(
  getOpportunities,
  opportunities => {
    const orgs = groupBy(opportunities, opp => {
      return opp.organisation_slug
    })
    return orgs
  }
)

export const getOpportunityCount = state => slug => {
  return createSelector(getOpportunities, opportunities => {
    const result = opportunities.filter(item => item.organisation_slug === slug)
    return result && result.length ? result.length : 0
  })(state)
}

export const getOrganisations = createSelector(
  getOpportunitiesGroupedByOrganisation,
  organisations => {
    return Object.keys(organisations).sort()
  }
)

export const getOpportunitiesForOrganisation = createSelector(
  getOpportunities,
  getSelectedOrganisation,
  (opportunities, organisation) => {
    const opps = groupBy(opportunities, opp => {
      return opp.organisation_slug
    })
    return opps[organisation] || []
  }
)

export const getSortedOpportunitiesForOrganisation = createSelector(
  getOpportunitiesForOrganisation,
  opps => sortBy(opps, 'project_title')
)

export const getNumberOfOpportunitiesForOrganisation = createSelector(
  getOpportunitiesForOrganisation,
  opportunities => {
    return opportunities && Object.values(opportunities).length
  }
)

export const getOpportunitiesAvailableAnywhere = createSelector(
  getOpportunities,
  opportunities => {
    return opportunities.sort().filter(item => {
      return item.geolocation.anywhere
    })
  }
)

export const getCategoryList = createSelector(listReducer, reducer => {
  let result = []
  if (reducer.opportunities) {
    result = [
      ...new Set(reducer.opportunities.map(item => item.organisation_type)),
    ].map(item => {
      return {
        label: item,
        value: item,
      }
    })
  }
  return result
})
