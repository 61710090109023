import { PropTypes } from 'prop-types'
import React from 'react'
import { Container, Content } from '../../styles'
import FormSection from '../Form/FormSection/legacy/FormSection'
import { PageHeader } from '../PageHeader'
import { Form, FormSubmitButton, BoldP } from '../Form/FormFields/styles'
import Alert from '../Dialogs/Alert'
import { history } from '../../createHistory'

class NewIdeaForm extends React.Component {
  componentDidMount = () => {
    const { doResetForm, isEditIdea } = this.props
    if (isEditIdea) {
      doResetForm()
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.props.isValid) {
      this.props.doSubmitForm()
    }
  }

  render() {
    const { isValid, formSections, canSubmitIdeaForm } = this.props

    return (
      <>
        <PageHeader
          title="Submit your Idea"
          blurb="This page is for designated colleagues to submit your team's 150 Days of Community volunteering idea."
        />

        <Content>
          <Container.Padded white>
            <BoldP>
              You can only submit an idea if you have a personal Sainsbury’s
              Group email inbox. If you are a Volunteering Coordinator, and do
              not have a personal inbox, please ask your Store or Senior manager
              to submit the idea on your behalf.
            </BoldP>
            <p>
              By this point, you should have decided with your team on the
              volunteering idea that you wish to pursue during 150 Days of
              Community.
            </p>
            <p>
              Fill out the below form, providing as much detail as possible, and
              then submit your idea for review.
            </p>
            <p>
              Once your idea has been submitted, look out for the approval email
              which we’ll send to the address submitted in the form below, which
              will include further instructions on next steps.
            </p>

            <Form onSubmit={this.handleSubmit} data-id="newIdeaForm">
              {formSections.map((section, index) => (
                <FormSection
                  formType="new-idea"
                  key={index}
                  section={section}
                />
              ))}
              <FormSubmitButton
                disabled={!isValid}
                type="submit"
                data-id="submit"
              >
                Review details
              </FormSubmitButton>
            </Form>
          </Container.Padded>
          {canSubmitIdeaForm ? null : (
            <Alert
              title="We're sorry"
              cancelLabel="Close"
              onCancel={history.goBack}
            >
              <p>
                Unfortunately you can’t submit an idea because you do not have
                mailbox.
              </p>
              <p>
                Please contact your REP or store manager and ask them to submit
                on your behalf.
              </p>
            </Alert>
          )}
        </Content>
      </>
    )
  }
}

NewIdeaForm.propTypes = {
  formSections: PropTypes.array.isRequired,
  isValid: PropTypes.bool,
  doSubmitForm: PropTypes.func.isRequired,
}

export default NewIdeaForm
