import styled from 'styled-components'
import { StickyContainer } from 'react-sticky'

export const AccordionSectionWrapper = styled.div`
  margin-top: 40px;
  font-family: ${({ theme }) => theme.fontFamily};
`

export const AccordionSectionHeader = styled.h3`
  color: ${({ theme }) => theme.color.orange};
  font-size: 26px;
`

export const BackToTop = styled.div`
  color: ${({ theme }) => theme.color.orange};
  margin: 30px 10px;
  text-align: right;
  font-size: 16px;
  cursor: pointer;
`

export const FlexSidebarWrapper = styled(StickyContainer)`
  display: none;
  @media (min-width: ${({ theme }) => theme.mq.md}) {
    display: block;
    flex: 0 0 25%;
  }
`

export const FaqSectionLink = styled.div`
  display: block;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 18px;
  padding: 5px 5px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.orange};
  }
`

export const FaqWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.mq.md}) {
    display: flex;
  }
`

export const FaqAccordionWrapper = styled.div``
