import React from 'react'
import Layout from '../layouts/Layout/Layout'
import UserIdea from '../components/UserIdea'

const UserIdeaPage = () => {
  return (
    <Layout pageId="page-my-idea">
      <UserIdea />
    </Layout>
  )
}

export default UserIdeaPage
