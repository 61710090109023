import React from 'react'
import {
  Block,
  StyledLink,
  Divider,
  InnerDivider,
  Wrapper,
  TextInfoWrapper,
  TextInfoHeader,
} from './styles'

const OpportunitiesControls = ({ selectOpp = false, selectOrg = false }) => {
  return (
    <>
      <Wrapper>
        <Block selected={selectOpp}>
          <StyledLink to={`/opportunities/`} data-id="search-opportunities">
            Search for Volunteering Opportunities
          </StyledLink>
        </Block>
        <Divider>
          <InnerDivider>OR</InnerDivider>
        </Divider>
        <Block selected={selectOrg}>
          <StyledLink to={`/opportunities/org/`} data-id="search-organisations">
            View a list of organisations
          </StyledLink>
        </Block>
      </Wrapper>
      <TextInfoWrapper>
        <TextInfoHeader>
          View a list of organisations for whom you’re able to volunteer
        </TextInfoHeader>
        <p>Use your postcode, full or partial address to search</p>
      </TextInfoWrapper>
    </>
  )
}

export default OpportunitiesControls
