const HttpErrors = {
  BAD_REQUEST: 'bad_request',
  CONNECTION_ERROR: 'connection_error',
  UNAUTHORIZED: 'unauthorized',
  FORBIDDEN: 'forbidden',
  NOT_FOUND: 'not_found',
  UNPROCESSABLE_ENTITY: 'unprocessable_entity',
  SERVER_ERROR: 'server_error',
  UNKNOWN: 'unknown',
}

const HttpMethods = {
  POST: 'POST',
  GET: 'GET',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
}

export { HttpErrors, HttpMethods }
