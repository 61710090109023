import React from 'react'
import Layout from '../layouts/Layout/Layout'
import { PageHeader } from '../components/PageHeader'
import { Container, Content } from '../styles'
import { WithChildren } from '../types'

const AdminIdeasLayout = ({ children }: WithChildren) => {
  return (
    <Layout pageId="page-admin">
      <PageHeader title="Ideas list" />
      <Content grey>
        <Container.FalseBleed>{children}</Container.FalseBleed>
      </Content>
    </Layout>
  )
}

export default AdminIdeasLayout
