import { Dispatch, AnyAction } from 'redux'
import { State } from '../store'
import {
  dataFailureAction,
  dataSuccessAction,
  getDataAction,
} from './dataStore'
import { getAuthToken } from '../selectors/auth'
import { api, get } from '../../httpClient/httpClient'
import errorHandler from '../dispatchers/errorHandler'

export enum ProgrammesActionTypes {
  PROGRAMME_HEADER_DETAIL_SET = 'PROGRAMME_HEADER_DETAIL_SET',
  PROGRAMME_TILES_SET = 'PROGRAMME_TILES_SET',
}

export type ProgrammesHeaderDetailType = {
  title: string
  subHeader: string
}

export type ProgrammeType = {
  imageDescription: string
  imageTitle: string
  imageUrl: string
  text: string
  title: string
}

export type ProgrammesHeaderDetailDataType = {
  title: string
  subheader: string
}

const mapProgrammesHeaderDetail = (
  data: ProgrammesHeaderDetailType
): ProgrammesHeaderDetailType => {
  return {
    title: data[0].title || '',
    subHeader: data[0].subheader || '',
  }
}

const mapProgrammeTiles = (data: any): ProgrammeType[] => {
  return data.length > 0
    ? data.map((item: any) => {
        return mapProgrammeTile(item)
      })
    : []
}

const mapProgrammeTile = (data: any): ProgrammeType => {
  return {
    title: data.programmeTitle || '',
    text: data.programmeText
      ? data.programmeText.content[0].content[0].value
      : '',
    imageUrl: data.image ? data.image.fields.file.url : '',
    imageDescription:
      data.image && data.image.fields.description
        ? data.image.fields.description
        : '',
    imageTitle: data.image ? data.image.fields.title : '',
  }
}

const programmeHeaderDetailAction = (title: string, subHeader: string) => {
  return {
    type: ProgrammesActionTypes.PROGRAMME_HEADER_DETAIL_SET,
    payload: {
      title: title,
      subHeader: subHeader,
    },
  }
}

const programmeTilesAction = (tiles: ProgrammeType[]) => {
  return {
    type: ProgrammesActionTypes.PROGRAMME_TILES_SET,
    payload: {
      tiles,
    },
  }
}

const fetchProgrammeHeaderDetail = () => {
  return async (
    dispatch: Dispatch<AnyAction, State>,
    getState: () => State
  ) => {
    const isDetailExist = getState().programmes.title.length > 1
    if (!isDetailExist) {
      try {
        dispatch(getDataAction())
        const endPoint = '/content/programmeLanding'
        const token = getAuthToken(getState())
        const data = await get<ProgrammesHeaderDetailDataType>(
          api(endPoint),
          token
        )
        const result: ProgrammesHeaderDetailType = await mapProgrammesHeaderDetail(
          data
        )
        dispatch(dataSuccessAction(result, endPoint))
        dispatch(programmeHeaderDetailAction(result.title, result.subHeader))
      } catch (error) {
        errorHandler(dispatch, error, dataFailureAction)
      }
    }
  }
}

const fetchProgrammeTiles = (key: string) => {
  const endPoint =
    key === 'all' ? '/content/programme' : `/content/programme?type=${key}`
  return async (
    dispatch: Dispatch<AnyAction, State>,
    getState: () => State
  ) => {
    const state = getState()
    if (
      state.dataStore &&
      state.dataStore.data &&
      state.dataStore.data[endPoint]
    ) {
      return
    } else {
      try {
        dispatch(getDataAction())
        const token = getAuthToken(getState())
        const data = await get<any>(api(endPoint), token)
        const result: ProgrammeType[] = await mapProgrammeTiles(data)
        dispatch(dataSuccessAction(result, endPoint))
      } catch (error) {
        errorHandler(dispatch, error, dataFailureAction)
      }
    }
  }
}

export {
  fetchProgrammeHeaderDetail,
  fetchProgrammeTiles,
  mapProgrammesHeaderDetail,
  mapProgrammeTiles,
  mapProgrammeTile,
  programmeHeaderDetailAction,
  programmeTilesAction,
}
