import React from 'react'
import PropTypes from 'prop-types'
import { LoadingIcon } from 'luna-react'

import { Section } from '../Form/FormSection/styles'
import {
  FormItem,
  SeperatorText,
  StyledButton,
  StyledSelectField,
  StyledTextInputField,
} from './styles.js'

class OrganisationsSearch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchTerm: props.searchTerm || '',
      categorySelectedName: props.categorySelectedName || '',
      categorySelectedIndex: 0,
      categoryList: props.categoryList || [],
    }

    props.doResetSearch()
  }

  componentDidMount() {
    if (
      this.state.searchTerm !== '' ||
      this.state.categorySelectedIndex !== 0
    ) {
      this.props.doSearchOrganisations(
        this.state.searchTerm,
        this.state.categorySelectedName
      )
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    if (
      this.state.searchTerm !== '' ||
      this.state.categorySelectedIndex !== 0
    ) {
      this.props.doSearchOrganisations(
        this.state.searchTerm,
        this.state.categorySelectedName
      )
    }
  }

  handleOrganisationNameChange = e => {
    e.preventDefault()
    this.setState({
      searchTerm: e.target.value,
    })
  }

  handleCategoryChange = e => {
    e.preventDefault()
    this.setState({
      categorySelectedIndex: e.target.selectedIndex,
      categorySelectedName: e.target.value,
    })
  }

  isSubmitButtonDisabled = (searchTerm, categoryIndex) =>
    !(searchTerm !== '' || categoryIndex !== 0)

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Section>
          <FormItem>
            <StyledTextInputField
              label="Search by organisation name"
              onChange={this.handleOrganisationNameChange}
              defaultValue={this.props.organisationName}
              maxLength={20}
              name="orgname"
              data-id="organisation-name-search-input"
            />
            <SeperatorText>OR</SeperatorText>
            <StyledSelectField
              label="Filter by category"
              onChange={this.handleCategoryChange}
              data-id="organisation-category-select"
              options={this.props.categoryList}
              defaultValue={String(this.props.categorySelectedName)}
              name="category"
              disabled={this.state.searchTerm !== ''}
            />
            <StyledButton
              data-id="opportunity-search-button"
              type="submit"
              disabled={this.isSubmitButtonDisabled(
                this.state.searchTerm,
                this.state.categorySelectedIndex
              )}
            >
              {this.props.isSearching ? (
                <LoadingIcon style={{ display: 'inline-block' }} inverted />
              ) : (
                <span>Submit</span>
              )}
            </StyledButton>
          </FormItem>
        </Section>
      </form>
    )
  }
}

OrganisationsSearch.propTypes = {
  isSearching: PropTypes.bool,
  errorMessage: PropTypes.string,
  categoryList: PropTypes.array,
  setSearchTerm: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  doResetSearch: PropTypes.func.isRequired,
  doSearchOrganisations: PropTypes.func.isRequired,
}

export default OrganisationsSearch

OrganisationsSearch.defaultName = 'OrganisationsSearch'
