import React from 'react'
import { Router as ReactRouter, Route, Switch } from 'react-router-dom'
import { ScrollManager, WindowScroller } from 'react-scroll-manager'

import routes from './routes'
import PrivateRoute from './components/PrivateRoute'

import NewHomepage from './pages/Home'
import About from './pages/About'
import About150 from './pages/About150'
import AboutLocalHeroes from './pages/LocalHeroes'

import NewIdeaForm from './pages/NewIdeaForm'
import NewIdeaConfirm from './pages/NewIdeaConfirm'
import NewIdeaSuccess from './pages/NewIdeaSuccess'
import NewIdeaError from './pages/NewIdeaError'

import LocalHeroesForm from './pages/LocalHeroesForm'
import LocalHeroesSuccess from './pages/LocalHeroesSuccess'
import LocalHeroesConfirm from './pages/LocalHeroesConfirm'
import LocalHeroesError from './pages/LocalHeroesError'

// TODO: Move opportunity sub-routes to main router
import Opportunities from './sections/opportunities/RouterContainer'
import Opportunity from './pages/Opportunity'

import CaseStudies from './pages/CaseStudies'
import FAQs from './pages/FAQs'
import NotFound from './pages/NotFound'
import CaseStudy from './pages/CaseStudy'
import Analytics from './components/Analytics/Analytics'
import UserIdeas from './pages/UserIdeas'
import UserIdea from './pages/UserIdea'
import EditIdeaForm from './pages/EditIdeaForm'
import EditIdeaConfirm from './pages/EditIdeaConfirm'
import EditIdeaError from './pages/EditIdeaError'
import EditIdeaSuccess from './pages/EditIdeaSuccess'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/Terms'
import { AdminRouterContainer } from './sections/AdminIdeas'
import ProgrammesLanding from './pages/ProgrammesLanding'
import ProgrammesDetail from './pages/ProgrammesDetail'
import Volunteering from './pages/Volunteering'

class Router extends React.Component {
  render() {
    return (
      <ScrollManager history={this.props.history}>
        <ReactRouter history={this.props.history}>
          <WindowScroller>
            <Analytics />
            <Switch>
              <Route exact path={routes.home} component={NewHomepage} />

              <Route exact path={routes.about} component={About} />

              <Route exact path={routes.about150} component={About150} />

              <Route
                exact
                path={routes.aboutLocalHeroes}
                component={AboutLocalHeroes}
              />
              <Route exact path={routes.newIdea} component={NewIdeaForm} />

              <Route
                exact
                path={`${routes.newIdea}/${routes.newIdeaConfirm}`}
                component={NewIdeaConfirm}
              />
              <Route
                exact
                path={`${routes.newIdea}/${routes.newIdeaSuccess}`}
                component={NewIdeaSuccess}
              />
              <Route
                exact
                path={`${routes.newIdea}/${routes.newIdeaError}`}
                component={NewIdeaError}
              />
              <Route
                exact
                path={`${routes.privacyPolicy}`}
                component={PrivacyPolicy}
              />
              <Route
                exact
                path={`${routes.opportunities}/opportunity/:title`}
                component={Opportunity}
              />
              <Route
                path={routes.opportunities}
                render={() => <Opportunities />}
              />
              <Route exact component={CaseStudies} path={routes.caseStudies} />
              <Route
                exact
                component={CaseStudy}
                path={`${routes.caseStudies}/:study_id`}
              />

              <Route exact component={UserIdeas} path={`${routes.userIdeas}`} />

              <Route
                exact
                component={UserIdeas}
                path={[routes.userIdeas, `${routes.userIdeas}/:campaign`]}
              />
              <Route
                exact
                component={UserIdea}
                path={`${routes.userIdeas}/:campaign/:idea_id`}
              />

              <Route
                exact
                component={EditIdeaForm}
                path={`${routes.editIdea}/:id`}
              />

              <Route
                exact
                component={EditIdeaConfirm}
                path={`${routes.editIdeaConfirm}`}
              />

              <Route
                exact
                component={EditIdeaError}
                path={`${routes.editIdeaError}`}
              />

              <Route
                exact
                component={EditIdeaSuccess}
                path={`${routes.editIdeaSuccess}`}
              />

              <Route exact component={FAQs} path={routes.faqs} />
              <Route
                exact
                component={TermsAndConditions}
                path={routes.termsAndConditions}
              />

              <PrivateRoute
                path={routes.admin}
                component={() => <AdminRouterContainer />}
              />

              <Route
                exact
                path={routes.programmes}
                component={ProgrammesLanding}
              />

              <Route
                path={`${routes.programmes}/:programmeKey`}
                component={ProgrammesDetail}
              />

              <Route
                exact
                component={LocalHeroesForm}
                path={`${routes.localHeroes}`}
              />

              <Route
                exact
                component={LocalHeroesConfirm}
                path={`${routes.localHeroes}/${routes.localHeroesConfirm}`}
              />

              <Route
                exact
                component={LocalHeroesSuccess}
                path={`${routes.localHeroes}/${routes.localHeroesSuccess}`}
              />

              <Route
                exact
                component={LocalHeroesError}
                path={`${routes.localHeroes}/${routes.localHeroesError}`}
              />

              <Route
                component={Volunteering}
                path={routes.volunteeringProject}
              />

              <Route component={NotFound} default />
            </Switch>
          </WindowScroller>
        </ReactRouter>
      </ScrollManager>
    )
  }
}

export default Router
