import { connect } from 'react-redux'

import {
  getColleagueEmail,
  getRef,
} from '../../redux/selectors/localHeroesForm'
import LocalHeroesSuccess from './LocalHeroesSuccess'

const mapStateToProps = state => {
  return {
    local_heroes_ref: getRef(state),
    colleague_email: getColleagueEmail(state),
  }
}

export default connect(mapStateToProps, null)(LocalHeroesSuccess)
