import { ArrowLeft } from 'luna-icons'
import styled from 'styled-components'
import { Container, Content } from './index'

export const FauxHeader = styled(Content)`
  background: ${({ theme }) => theme.color.lighterGrey};
  height: 320px;

  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    left: 10px;
    width: calc(100% - 20px);
  }
`

export const PulledContent = styled(Content)`
  background-color: transparent;
  margin-top: -120px;
  padding-top: 0;
  position: relative;
`

export const RelativeContainer = styled(Container.FalseBleed)`
  position: relative;
`

export const BackButton = styled.div`
  position: absolute;
  height: 60px;
  left: 0;
  top: -60px;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    left: 10px;
    width: calc(100% - 20px);
  }
`

export const BackButtonLink = styled.a`
  color: ${({ theme }) => theme.color.orange};
  font-family: ${props => props.theme.fontFamily};
  font-size: 22px;
  font-weight: bold;
  line-height: 60px;
  padding-left: 40px;
  text-decoration: none;
  cursor: pointer;
`

export const BackArrow = styled.div`
  content: '<';
  display: block;
  height: 60px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 20px;
`

export const BackArrowLeft = styled(ArrowLeft)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -5px;
`
