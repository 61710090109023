const MAX_SHORT_TEXT_LENGTH = 255
const MAX_LONG_TEXT_LENGTH = 4000

const BUSINESS_AREAS = [
  'Sainsbury’s Asia',
  'Sainsbury’s Bank',
  'Sainsbury’s SSC',
  'Sainsbury’s Store',
  'Sainsbury’s Logistics',
  'Sainsbury’s Argos',
  'Argos Store',
  'Argos Logistics',
  'Sainsbury’s and Argos CMC',
  'Habitat',
  'Nectar',
  'AFS',
  'Other',
]

const CATEGORIES = [
  'Carbon',
  'Water',
  'Food waste',
  'Biodiversity',
  'Sustainable diets',
  'Recycling',
  'Plastic',
]

const ACTIVITIES = [
  'Litter picking',
  'Beach cleans',
  'Tree planting',
  'Creation of green spaces / new habitats',
  'Restoration of green spaces / habitats',
  'Redistribution of surplus food',
  'Educational projects on Net Zero topic(s)',
  'Awareness raising projects on Net Zero topic(s)',
  'Offsetting projects',
  'Other',
]

const VOLUNTEERING_COLLEAGUES = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6-10', value: '6-10' },
  { label: '11-15', value: '11-15' },
  { label: '> 15', value: '> 15' },
]

const VOLUNTEERING_HOURS = [
  { label: '0.5', value: '0.5' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6-8', value: '6-8' },
  { label: '9-12', value: '9-12' },
  { label: '12-24', value: '12-24' },
  { label: '> 24', value: '> 24' },
]

function mapOptions(options: string[]) {
  return options.map((option: string) => ({
    label: option,
    value: option,
  }))
}

export {
  MAX_SHORT_TEXT_LENGTH,
  MAX_LONG_TEXT_LENGTH,
  BUSINESS_AREAS,
  CATEGORIES,
  ACTIVITIES,
  VOLUNTEERING_COLLEAGUES,
  VOLUNTEERING_HOURS,
  mapOptions,
}
