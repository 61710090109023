import React, { useEffect, useState } from 'react'
import { Card, GridItem, GridWrapper, TabPanel } from 'luna-react'
import {
  ProgrammeTileStyle,
  ProgrammeTileStyleHeading,
  ProgrammeImageContainer,
  ProgrammeTileStyleImage,
  ProgrammeTileStyleInfo,
  TabProgrammeStyled,
  P,
} from './Programmes.styles'
import imageReplacement from '../../assets/programmes/together-we-are.jpg'
import { ProgrammeType } from '../../redux/actions/programmeList'
import { truncate } from '../../util/truncate'

interface ProgrammesTabPanelProps {
  tiles: ProgrammeType[]
  getLinkTo: (title: string) => void
}

const ProgrammesTabPanel: React.FC<ProgrammesTabPanelProps> = ({
  tiles,
  getLinkTo,
}) => {
  const [activeTabData, setActiveTabData] = useState<ProgrammeType[]>([])

  useEffect(() => {
    setActiveTabData(tiles)
  }, [tiles, setActiveTabData])

  return (
    <TabPanel>
      {activeTabData && (
        <Card>
          <GridWrapper matrix equalHeight element="ul">
            {activeTabData &&
              activeTabData.map((item: ProgrammeType, index: number) => (
                <GridItem size={{ sm: '1/2' }} element="li" key={index}>
                  <TabProgrammeStyled
                    to={`${getLinkTo(item.title)}`}
                    data-id={`tab-programme-${index}`}
                  >
                    <ProgrammeTileStyle>
                      <ProgrammeImageContainer>
                        <ProgrammeTileStyleImage
                          src={
                            item.imageUrl.length > 1
                              ? item.imageUrl
                              : imageReplacement
                          }
                          alt={item.imageDescription}
                        />
                      </ProgrammeImageContainer>
                      <ProgrammeTileStyleInfo>
                        <ProgrammeTileStyleHeading>
                          {item.title}
                        </ProgrammeTileStyleHeading>
                        <P>{truncate(item.text, 120)}</P>
                      </ProgrammeTileStyleInfo>
                    </ProgrammeTileStyle>
                  </TabProgrammeStyled>
                </GridItem>
              ))}
          </GridWrapper>
        </Card>
      )}
    </TabPanel>
  )
}

export default ProgrammesTabPanel
