import React from 'react'
import PropTypes from 'prop-types'
import { LoadingIndicator, FilledButton } from 'luna-react'

import ErrorMessage from '../ErrorMessage/ErrorMessage'
import OrganisationSummary from '../../components/OrganisationSummary/OrganisationSummary'
import { OpportunitiesSearch } from './../../styles'
import { TwoColContainer } from './styles.js'

class OrganisationsSearchResults extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.isSearching !== nextProps.isSearching ||
      this.props.errorMessage !== nextProps.errorMessage ||
      this.props.results !== nextProps.results
    )
  }

  renderResultsList(results) {
    const resultsList = results.map(result => {
      return (
        <OrganisationSummary
          key={result.id}
          data={result}
          numberOfOpportunities={this.props.getOpportunityCount(
            result.organisation_slug
          )}
          organisationName={result.organisation_slug}
        />
      )
    })
    return (
      <TwoColContainer data-id="opportunities-results-list">
        {resultsList}
      </TwoColContainer>
    )
  }

  render() {
    const {
      searchTerm,
      isSearching,
      errorMessage,
      showMoreRecords,
      results,
      hasMore,
      unseenOpportunitiesCount,
    } = this.props

    if (isSearching) {
      return <LoadingIndicator message="Searching for organisations…" />
    }
    if (errorMessage) {
      return <ErrorMessage errorMessage={errorMessage} />
    }
    if (!isSearching && searchTerm && results && results.length === 0) {
      return (
        <>
          <OpportunitiesSearch.Heading hard>
            No organisations found
          </OpportunitiesSearch.Heading>
          <p>Please refine your search criteria</p>
        </>
      )
    }
    if (results && results.length > 0) {
      return (
        <>
          {this.renderResultsList(results)}
          {hasMore && (
            <FilledButton
              onClick={e => {
                e.preventDefault()
                showMoreRecords(6)
              }}
              style={{ margin: '0 auto', display: 'block' }}
              data-id="show-more-button"
            >
              Show {Math.min(6, unseenOpportunitiesCount)} more{' '}
              {unseenOpportunitiesCount === 1
                ? 'Organisation'
                : 'Organisations'}
            </FilledButton>
          )}
        </>
      )
    }
    return null
  }
}

OrganisationsSearchResults.propTypes = {
  searchTerm: PropTypes.string,
  isSearching: PropTypes.bool,
  errorMessage: PropTypes.string,
  showMoreRecords: PropTypes.func,
  results: PropTypes.array,
  hasMore: PropTypes.bool,
  unseenOpportunitiesCount: PropTypes.number,
  getOpportunityCount: PropTypes.func,
}

export default OrganisationsSearchResults
