import * as Yup from 'yup'

const MAX_SHORT_TEXT_LENGTH = 255
const MAX_LONG_TEXT_LENGTH = 4000

const VALID_GENDERS = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Transgender male', value: 'Transgender male' },
  { label: 'Transgender female', value: 'Transgender female' },
  { label: 'Non binary', value: 'Non binary' },
  { label: 'Gender fluid', value: 'Gender fluid' },
  { label: 'Other', value: 'Other' },
  { label: 'Rather not say', value: 'Rather not say' },
]

const VALID_BUSINESSES = [
  { label: 'Sainsbury’s Asia', value: 'Sainsbury’s Asia' },
  { label: 'Sainsbury’s Bank', value: 'Sainsbury’s Bank' },
  { label: 'Sainsbury’s SSC', value: 'Sainsbury’s SSC' },
  { label: 'Sainsbury’s Store', value: 'Sainsbury’s Store' },
  { label: 'Sainsbury’s Logistics', value: 'Sainsbury’s Logistics' },
  { label: 'Sainsbury’s Argos', value: 'Sainsbury’s Argos' },
  { label: 'Argos Store', value: 'Argos Store' },
  { label: 'Argos Logistics', value: 'Argos Logistics' },
  { label: 'Sainsbury’s and Argos CMC', value: 'Sainsbury’s and Argos CMC' },
  { label: 'Habitat', value: 'Habitat' },
  { label: 'Nectar', value: 'Nectar' },
  { label: 'AFS', value: 'AFS' },
  { label: 'Other', value: 'Other' },
]

const VALID_CATEGORIES = [
  { label: 'Poverty', value: 'Poverty' },
  { label: 'Homelessness', value: 'Homelessness' },
  { label: 'Disability', value: 'Disability' },
  { label: 'Health', value: 'Health' },
  { label: 'Mental health', value: 'Mental health' },
  { label: 'Schools/Young people', value: 'Schools/Young people' },
  { label: 'Older people', value: 'Older people' },
  { label: 'Community care', value: 'Community care' },
  { label: 'Environment', value: 'Environment' },
  { label: 'Other', value: 'Other' },
]

export default [
  {
    header: 'Colleague name',
    required: true,
    items: [
      {
        label: 'Enter a name or email address',
        placeholder: 'e.g. Sam Wood',
        type: 'text',
        required: true,
        name: 'colleague_name',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string()
          .max(MAX_SHORT_TEXT_LENGTH, 'Name is too long')
          .required('Colleague name is required'),
      },
    ],
  },
  {
    header: 'Colleague or store email address',
    required: true,
    items: [
      {
        label:
          'Correspondence will be sent to this email address and necessary for communications with CAF',
        placeholder: 'sam.ward@argos.co.uk',
        type: 'email',
        name: 'colleague_email',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string()
          .email('Please provide a valid email address')
          .max(MAX_SHORT_TEXT_LENGTH, 'Email address is too long')
          .required('Colleague or store email address is required'),
      },
    ],
  },
  {
    header: 'Gender',
    required: true,
    items: [
      {
        type: 'radio',
        name: 'gender',
        options: VALID_GENDERS,
        validation: Yup.string()
          .required('You need to select a gender')
          .oneOf(VALID_GENDERS.map(({ value }) => value)),
      },
    ],
  },
  {
    header: 'Employee number',
    required: true,
    items: [
      {
        label: '',
        placeholder: 'e.g. 123 456 789',
        type: 'text',
        name: 'employee_number',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string()
          .max(MAX_SHORT_TEXT_LENGTH, 'Employee number is too long')
          .required('Employee number is required'),
      },
    ],
  },
  {
    header: 'Associated store or team',
    required: true,
    items: [
      {
        type: 'radio',
        name: 'business',
        options: VALID_BUSINESSES,
        validation: Yup.string()
          .oneOf(VALID_BUSINESSES.map(({ value }) => value))
          .required('You need to select at least one store or team'),
      },
    ],
  },
  {
    header: 'Store name and number, depot name or divisional team name',
    required: true,
    items: [
      {
        label: '',
        placeholder: '',
        type: 'text',
        name: 'store_name',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string()
          .max(MAX_SHORT_TEXT_LENGTH, 'Store name is too long')
          .required('Store name is required'),
      },
    ],
  },
  {
    header: 'Name of charity / community group',
    required: true,
    items: [
      {
        label: '',
        placeholder: '',
        type: 'text',
        name: 'charity_name',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string()
          .max(MAX_SHORT_TEXT_LENGTH, 'Charity name is too long')
          .required('Name of charity is required'),
      },
    ],
  },
  {
    header: 'Registered charity number',
    items: [
      {
        label: 'If applicable',
        placeholder: '',
        type: 'text',
        name: 'charity_number',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string().max(
          MAX_SHORT_TEXT_LENGTH,
          'Registered charity number is too long'
        ),
      },
    ],
  },
  {
    header: 'Contact name at charity / community group',
    required: true,
    items: [
      {
        label: '',
        placeholder: '',
        type: 'text',
        name: 'charity_contact',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string()
          .max(MAX_SHORT_TEXT_LENGTH, 'Contact name is too long')
          .required('Contact name at charity is required'),
      },
    ],
  },
  {
    header: 'Charity / community group address',
    required: true,
    items: [
      {
        label: 'Please include postcode',
        placeholder: '',
        type: 'textarea',
        name: 'charity_address',
        maxLength: MAX_LONG_TEXT_LENGTH,
        validation: Yup.string()
          .max(MAX_LONG_TEXT_LENGTH, 'Charity address is too long')
          .required('Charity address is required'),
      },
    ],
  },
  {
    header: 'Charity / community group contact email address',
    required: true,
    items: [
      {
        label: '',
        placeholder: '',
        type: 'email',
        name: 'charity_email_address',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string()
          .email('Please provide a valid email address')
          .required('Charity contact email is required'),
      },
    ],
  },
  {
    header: 'Charity / community group telephone number',
    items: [
      {
        label: '',
        placeholder: '',
        type: 'text',
        name: 'charity_phone_number',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string(),
      },
    ],
  },
  {
    header: 'Charity / community group bank account number',
    required: true,
    items: [
      {
        label: '',
        placeholder: '',
        type: 'text',
        name: 'charity_account_number',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string()
          .max(MAX_SHORT_TEXT_LENGTH, 'Charity account number is too long')
          .required('Charity account number is required'),
      },
    ],
  },
  {
    header: 'Charity / community group sort code',
    required: true,
    items: [
      {
        label: '',
        placeholder: '',
        type: 'number',
        name: 'charity_sort_code',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string()
          .max(MAX_SHORT_TEXT_LENGTH, 'Charity sort code is too long')
          .required('Charity sort code is required'),
      },
    ],
  },
  {
    header: 'How many hours do you wish to claim for?',
    required: true,
    items: [
      {
        label: 'This is necessary for CAF, you can claim a maximum of 40 hours',
        placeholder: '',
        type: 'number',
        name: 'hours_claimed',
        max: 40,
        min: 0,
        validation: Yup.number()
          .typeError('Enter a valid number')
          .positive('Enter a valid number')
          .required('Enter the number of hours you wish to claim for'),
      },
    ],
  },
  {
    header: 'What did you do to volunteer?',
    required: true,
    items: [
      {
        label: 'Please provide as much detail as possible',
        placeholder: '',
        type: 'textarea',
        name: 'volunteering_details',
        validation: Yup.string().required(
          'Don’t forget to tell us what you did to volunteer'
        ),
      },
    ],
  },
  {
    header:
      'Which of the categories best describe the work of the charity/community group you are supporting',
    required: true,
    items: [
      {
        type: 'checkbox',
        name: 'charity_category',
        options: VALID_CATEGORIES,
        validation: Yup.array()
          .min(1, 'You need to select at least one category')
          .max(VALID_CATEGORIES.length, 'You have selected too many categories')
          .required(),
      },
    ],
  },
  {
    header: 'Title',
    required: true,
    items: [
      {
        label:
          'Remember, the title is how this item is shown in Search Results. This can be different to the name',
        placeholder: '',
        type: 'text',
        name: 'title',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string().required('Title is required'),
      },
    ],
  },
]
