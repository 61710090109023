import React from 'react'
import PropTypes from 'prop-types'

class Token extends React.Component {
  componentDidMount() {
    const { doRefresh } = this.props
    const TOKEN = (window && window.ENV && window.ENV.TOKEN) || {}
    const { expireTime = 600000, refreshBefore = 30000 } = TOKEN

    // 1. refresh called to update local storage token, if it exists.
    doRefresh()
    // 2. this timeout will be called just before the expiry time
    setTimeout(() => {
      doRefresh()
      // 3. this setInterval will be called repreatedly eith the same delta
      this.refreshTimeout = window.setInterval(doRefresh, expireTime)
    }, expireTime - refreshBefore)
  }

  componentWillUnmount = () => {
    clearInterval(this.refreshTimeout)
  }

  render() {
    return this.props.children
  }
}

Token.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Token
