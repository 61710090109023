import { actionTypes } from '../actions/faqs'

const {
  GET_CONTENT_FAQS,
  GET_CONTENT_FAQS_SUCCESS,
  GET_CONTENT_FAQS_FAILURE,
} = actionTypes

const initialState = {
  faqs: [],
  loading: true,
  errorMessage: null,
}

const faqsReducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case GET_CONTENT_FAQS:
      return {
        ...state,
        errorMessage: null,
        loading: true,
      }
    case GET_CONTENT_FAQS_SUCCESS:
      return {
        ...state,
        faqs: payload.faqs,
        loading: false,
      }
    case GET_CONTENT_FAQS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
      }
    default:
      return state
  }
}

export { initialState, faqsReducer }
