import React from 'react'
import Layout from '../layouts/Layout/Layout'
import EditIdeaFormContainer from '../components/EditIdea/EditIdeaFormContainer'

const EditIdeaForm = () => {
  return (
    <Layout pageId="page-edit-idea">
      <EditIdeaFormContainer />
    </Layout>
  )
}

export default EditIdeaForm
