import {
  OpportunityActionTypes,
  OrganisationActionTypes,
  ActionTypes,
} from './opportunitiesList'

const setDistance = distance => ({
  type: OpportunityActionTypes.SET_DISTANCE,
  payload: { distance },
})

const setTeamSize = teamSize => ({
  type: OpportunityActionTypes.SET_TEAM_SIZE,
  payload: { teamSize },
})

const setSearchTerm = searchTerm => ({
  type: OpportunityActionTypes.SET_OPPORTUNITY_SEARCH_TERM,
  payload: { searchTerm: searchTerm.toUpperCase() },
})

const setCategory = category => ({
  type: OrganisationActionTypes.SET_CATEGORY,
  payload: { category },
})

const searchOpportunities = () => ({
  type: OpportunityActionTypes.SEARCH_OPPORTUNITIES,
})

const searchOpportunitiesSuccess = results => ({
  type: OpportunityActionTypes.SEARCH_OPPORTUNITIES_SUCCESS,
  payload: { results },
})

const searchOpportunitiesFailure = errorMessage => ({
  type: OpportunityActionTypes.SEARCH_OPPORTUNITIES_FAILURE,
  payload: { errorMessage },
})

const showMoreRecords = number => ({
  type: ActionTypes.SHOW_MORE_RECORDS,
  payload: { number },
})

const resetSearchState = () => ({
  type: OpportunityActionTypes.DELETE_SEARCH_STATE,
})

export {
  setDistance,
  setSearchTerm,
  setTeamSize,
  searchOpportunities,
  searchOpportunitiesSuccess,
  searchOpportunitiesFailure,
  showMoreRecords,
  resetSearchState,
  setCategory,
}
