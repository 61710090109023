import React from 'react'
import {
  DateRangeFilterWrapper,
  Filter,
  FilterHeader,
  IdeasFilterWrapper,
  FilterWrapper,
} from './Filters.styles'
import { CheckboxField } from 'luna-react'
import DateRangeFilter from '../DateRangeFilter/DateRangeFilter'
import { FilterValuesType } from '../../redux/actions/filters'
import { parseDateRange } from '../../util/filtersUtils'

interface FiltersControlsProps {
  configData: any
  onChange: (key: string, value: string, selected: boolean) => void
  onRangeChange: (dateRange: object) => void
  fields: any
  disabled: boolean
}

const FiltersControls: React.FC<FiltersControlsProps> = ({
  configData,
  onChange,
  onRangeChange,
  disabled,
  fields,
}) => {
  const handleCheckboxSelect = (key: string) => (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    const e = event.target
    onChange(key, e.value, e.checked)
  }

  const handleDateRangeChange = ({ start, end }: any) => {
    onRangeChange(
      start && end
        ? {
            [start]: true,
            [end]: true,
          }
        : {}
    )
  }
  if (configData && configData.filterOptions) {
    const isCheckboxSelected = (key: string, value: string) =>
      fields[key] && fields[key][value] ? fields[key][value] : false

    const { start = null, end = null } = fields['created_date']
      ? parseDateRange(fields['created_date'])
      : {}

    const filterConfigData = configData.filterOptions
    const dateRangeVisible = configData.dateRangeVisible
    return (
      <IdeasFilterWrapper>
        {filterConfigData.map((filterItem: any, index: number) => (
          <FilterWrapper key={index}>
            <FilterHeader>{filterItem.heading}</FilterHeader>
            <Filter>
              <CheckboxField
                disabled={disabled}
                name={`filter_${filterItem.key}`}
                options={filterItem.values.map(
                  (filterValue: FilterValuesType) => ({
                    ...filterValue,
                    checked: isCheckboxSelected(
                      filterItem.key,
                      filterValue.value
                    ),
                  })
                )}
                onChange={handleCheckboxSelect(filterItem.key)}
              />
            </Filter>
          </FilterWrapper>
        ))}
        {dateRangeVisible && (
          <DateRangeFilterWrapper>
            <FilterHeader>Date Range</FilterHeader>
            <DateRangeFilter
              disabled={disabled}
              start={start}
              end={end}
              onChange={handleDateRangeChange}
            />
          </DateRangeFilterWrapper>
        )}
      </IdeasFilterWrapper>
    )
  }
  return <>{''}</>
}

export default FiltersControls
