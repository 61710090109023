import styled from 'styled-components'
import { theme } from '../../styles'

const IconColours = Object.freeze({
  PENDING: theme.color.yellow,
  ACCEPTED: theme.color.green,
  COMPLETED: theme.color.lightGrey,
  DECLINED: theme.color.red,
  UPDATED: theme.color.aqua,
  BOOKMARKED: theme.color.grey,
})

const IconWrapper = styled.div`
  color: white;
  height: ${({ big }) => (big ? '32px' : '23px')};
  width: ${({ big }) => (big ? '32px' : '23px')};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ big }) => (big ? '20px' : '12px')};
  margin: ${({ center }) => (center ? '0 auto' : '0')};
  background: ${({ status }) => IconColours[status]};
`

/* StatusButton */
const Wrapper = styled.button`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: ${({ disabled }) => (disabled ? '0.35' : '1')};
  border: 2px solid transparent;
`

const WrapperRed = styled(Wrapper)`
  color: ${({ theme }) => theme.color.red};
  border-color: ${({ theme }) => theme.color.red};
  background-color: ${({ theme }) => theme.color.white};
`

const WrapperGreen = styled(Wrapper)`
  color: ${({ theme }) => theme.color.green};
  border-color: ${({ theme }) => theme.color.green};
  background-color: ${({ theme }) => theme.color.white};
`

const WrapperLabel = styled.span`
  font-weight: bold;
  margin-right: 12px;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fontFamily};
`
/* End StatusButton */

export { IconWrapper, Wrapper, WrapperRed, WrapperGreen, WrapperLabel }
