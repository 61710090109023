import { AnyAction } from 'redux'
import { DataStoreState } from '../store'
import {
  DataStoreAction,
  dataStoreActionTypes,
  responseStatus,
} from '../../types'

const dataStoreInitialState = {
  data: undefined,
  responseStatus: responseStatus.UNKNOWN,
  errorMessage: null,
}

const dataStoreReducer = (
  state: DataStoreState = dataStoreInitialState,
  action: AnyAction
): DataStoreState => {
  const { type, payload } = action as DataStoreAction
  switch (type) {
    case dataStoreActionTypes.GET_DATA: {
      return {
        ...state,
        errorMessage: null,
        responseStatus: responseStatus.PENDING,
      }
    }
    case dataStoreActionTypes.GET_DATA_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.section]: payload.data,
        },
        responseStatus: responseStatus.SUCCESS,
      }
    }
    case dataStoreActionTypes.GET_DATA_FAILURE: {
      return {
        ...state,
        responseStatus: responseStatus.FAILURE,
        errorMessage: payload.errorMessage,
      }
    }
    default:
      return state
  }
}

export { dataStoreInitialState, dataStoreReducer }
