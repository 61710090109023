import { connect } from 'react-redux'
import IdeaSearch from './IdeaSearch'
import { updateSearch, changeSearchType } from '../../redux/actions/ideaFilter'
import { isSearchDisabled } from '../../redux/selectors/ideaFilter'

const mapStateToProps = state => {
  const { value } = state.ideaFilter.search
  let searchValue = ''
  if (Object.keys(value).length) {
    searchValue = Object.entries(value)[0][1]
  }
  return {
    searchValue,
    disabled: isSearchDisabled(state),
    filterType: `${state.ideaFilter.type}`,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onChange: (key, value) => dispatch(updateSearch(key, value)),
    onSearchTypeChange: value => dispatch(changeSearchType(value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdeaSearch)
