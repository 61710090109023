import { connect } from 'react-redux'
import UserFeedback from './UserFeedback'

const mapStateToProps = state => ({
  email: state.auth.user.email,
})

const UserFeedbackContainer = connect(mapStateToProps)(UserFeedback)

export default UserFeedbackContainer
