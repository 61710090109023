import styled from 'styled-components'
import StatusIcon from '../Status/StatusIcon'

const IdeaHistoryStatus = styled(StatusIcon)`
  width: 16px;
  height: 16px;
  font-size: 9px;
`

const IdeaHistoryItemWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  font-size: 13px;
`

const IdeaStatusWrapper = styled.div`
  padding: 2px 10px 7px 0;
`

const VerticalLine = styled.div`
  border-left: 1px solid #8e8e8e;
  height: 100%;
  margin: 6px 0 0 8px;
`

const IdeaHistoryDeclinedReason = styled.div`
  background-color: #f6f6f6;
  padding: 14px;
  margin-top: 8px;
`

export {
  IdeaHistoryItemWrapper,
  IdeaStatusWrapper,
  IdeaHistoryStatus,
  VerticalLine,
  IdeaHistoryDeclinedReason,
}
