import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { Container as LunaContainer } from '@jsluna/grid'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'
import { doFetchIdeaWithHistory } from '../../redux/dispatchers/idea'
import {
  selectIdeaData,
  selectIsLoading,
  selectErrorMessage,
} from '../../redux/selectors/idea'
import Head from '../../components/Head/Head'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import { BackHeader } from '../../components/PageHeader/BackHeader'
import { Label, Reference } from '../../components/RefIdeaHeader/styles'
import IdeaHistory from '../../components/IdeaHistory/IdeaHistory'
import StatusIcon from '../../components/Status/StatusIcon'
import IdeaActionsBar from '../../components/IdeaActions'
import IdeaReview from '../../components/IdeaReview'
import { Divider, Container, Content, IdeaTitle, IdeaBody } from '../../styles'
import campaigns from '../../data/campaigns'
import { ideaLabels } from '../../constants'

const FloatRight = styled.div`
  float: right;
`

interface MatchParams {
  idea_id: string
}

interface AdminIdeaProps extends RouteComponentProps<MatchParams> {
  campaign: string
}

const AdminIdea: React.FC<AdminIdeaProps> = ({ campaign, match }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsLoading)
  const errorMessage = useSelector(selectErrorMessage)
  const idea = useSelector(selectIdeaData)
  const id = match.params.idea_id
  const campaignConfig = campaigns[campaign]
  const { adminRoute, fieldLabels } = campaignConfig

  useEffect(() => {
    dispatch(doFetchIdeaWithHistory(id, campaign))
  }, [dispatch, id, campaign])

  return (
    <>
      <Head
        title={isLoading ? 'Loading...' : idea && idea.title ? idea.title : ''}
        description={''}
      />
      <BackHeader
        title={
          <>
            <Label>Ref:&nbsp;</Label>
            <Reference>
              {isLoading ? '' : idea && idea.ref ? idea.ref : ''}
            </Reference>
          </>
        }
        to={adminRoute}
        textTo=""
      />
      <Container.Wrapper>
        <LunaContainer soft>
          <Content>
            {(() => {
              if (isLoading) {
                return (
                  <ProgressIndicator
                    className="ln-u-justify-content-center"
                    loading
                    preventFocus
                  >
                    <ProgressSpinner />
                  </ProgressIndicator>
                )
              }

              if (errorMessage) {
                return <ErrorMessage errorMessage={errorMessage} />
              }

              if (idea) {
                return (
                  <>
                    <FloatRight>
                      <StatusIcon big={true} status={idea.idea_status} />
                    </FloatRight>
                    <IdeaReview
                      items={Object.keys(fieldLabels)
                        .filter(key => !!idea[key])
                        .map((key: string) => {
                          return {
                            label: fieldLabels[key],
                            value: idea[key],
                          }
                        })}
                    />
                    <Divider margin="30px 0" />
                    <div id="idea_status">
                      <IdeaTitle>Status</IdeaTitle>
                      <IdeaBody>{ideaLabels[idea.idea_status]}</IdeaBody>
                      {idea.history && idea.history.length > 0 && (
                        <IdeaHistory history={idea.history} />
                      )}
                    </div>
                    <IdeaActionsBar idea={idea} />
                  </>
                )
              }

              return null
            })()}
          </Content>
        </LunaContainer>
      </Container.Wrapper>
    </>
  )
}

export default withRouter(AdminIdea)
