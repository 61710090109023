export * from './global'
export * from './idea'
export * from './ideaForm'
export * from './dataStore'
export * from './adminIdea'
export * from './adminIdeas'

// TODO: Replace / remove
export enum responseStatus {
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  UNKNOWN = 'UNKNOWN',
}

// TODO: Replace / remove
export type WithChildren = {
  children?: any
}
