import styled from 'styled-components'

export const ProgrammePadding = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (max-width: ${({ theme }) => theme.mq.md}) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
  @media (max-width: ${({ theme }) => theme.mq.xs}) {
    padding-left: 0;
    padding-right: 0;
  }
`

export const ProgrammeLoaderPadding = styled.div`
  padding-top: 8rem;
  padding-bottom: 8rem;
`

export const FaqsContainer = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  @media (max-width: ${({ theme }) => theme.mq.md}) {
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
  @media (max-width: ${({ theme }) => theme.mq.xs}) {
    padding: 0;
  }
`

export const FaqsTitle = styled.h3`
  font-size: 26px;
  color: ${({ theme }) => theme.color.orange};
  margin-bottom: 24px;
`

export const HideMobile = styled.div`
  @media (max-width: ${({ theme }) => theme.mq.md}) {
    display: none;
  }
`
