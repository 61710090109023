export const FiltersVolunteering = {
  searchMaxShortTextLength: 0,
  searchMaxLongTextLength: 0,
  dateRangeVisible: false,
  searchOptions: [
    {
      label: 'Filter by ref ID',
      value: 'ref',
      placeholder: 'e.g. A1B2C3',
      enableSearchOnly: true,
    },
    {
      label: 'Filter by email address',
      value: 'author_email',
      placeholder: 'e.g. sam.smith@sainsburys.co.uk',
    },
    {
      label: 'Filter by organisation',
      value: 'organisation_name',
      placeholder: 'e.g. Amber Communities Trust',
    },
  ],
  filterOptions: [
    {
      heading: 'Business area',
      key: 'business',
      values: [
        { label: 'Sainsbury’s Asia', value: 'Sainsbury’s Asia' },
        { label: 'Sainsbury’s Bank', value: 'Sainsbury’s Bank' },
        { label: 'Sainsbury’s SSC', value: 'Sainsbury’s SSC' },
        { label: 'Sainsbury’s Store', value: 'Sainsbury’s Store' },
        { label: 'Sainsbury’s Logistics', value: 'Sainsbury’s Logistics' },
        { label: 'Sainsbury’s Argos', value: 'Sainsbury’s Argos' },
        { label: 'Argos Store', value: 'Argos Store' },
        { label: 'Argos Logistics', value: 'Argos Logistics' },
        {
          label: 'Sainsbury’s and Argos CMC',
          value: 'Sainsbury’s and Argos CMC',
        },
        { label: 'Habitat', value: 'Habitat' },
        { label: 'Nectar', value: 'Nectar' },
        { label: 'AFS', value: 'AFS' },
        { label: 'Other', value: 'Other' },
      ],
    },
    {
      heading: 'Category',
      key: 'category',
      values: [
        { label: 'Carbon', value: 'Carbon' },
        { label: 'Water', value: 'Water' },
        { label: 'Food waste', value: 'Food waste' },
        { label: 'Biodiversity', value: 'Biodiversity' },
        { label: 'Sustainable diets', value: 'Sustainable diets' },
        { label: 'Recycling', value: 'Recycling' },
        { label: 'Plastic', value: 'Plastic' },
      ],
    },
  ],
}
