import jwt from 'jsonwebtoken'

import { ACCESS_TOKEN } from '../constants'

const extractTokenData = token => {
  try {
    const { sub, role, inactive } = jwt.decode(token)
    return {
      email: sub,
      role,
      inactive,
    }
  } catch (error) {
    return {}
  }
}

const setTokenInLocalStorage = token =>
  localStorage.setItem(ACCESS_TOKEN, token)

const unsetTokenInLocalStorage = () => localStorage.removeItem(ACCESS_TOKEN)

const getToken = () => {
  const hash = window.location.hash
  return hash ? hash.substr(1) : localStorage.getItem(ACCESS_TOKEN)
}

const getEmailFromToken = () => (extractTokenData(getToken()) || {}).email

export {
  extractTokenData,
  setTokenInLocalStorage,
  unsetTokenInLocalStorage,
  getToken,
  getEmailFromToken,
}
