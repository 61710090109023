import routes from '../routes'
import { isProgrammePage } from '../features'

export const basicMenu = {
  about: {
    id: 'about',
    title: 'About',
    path: routes.about,
  },
  ideas: {
    id: 'opportunities',
    title: 'Opportunities',
    path: routes.opportunities,
  },
  caseStudies: {
    id: 'caseStudies',
    title: 'Case studies',
    path: routes.caseStudies,
  },
}

export const withProgrameMenu = {
  programs: {
    id: 'programmes',
    title: 'Programmes',
    path: routes.programmes,
  },
  ...basicMenu,
}

export default () => {
  return isProgrammePage() ? withProgrameMenu : basicMenu
}
