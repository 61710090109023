import { api, get, post } from '../../httpClient/httpClient'
import routes from '../../routes'
import { getAuthToken } from '../selectors/auth'
import errorHandler from './errorHandler'
import { validationMap } from '../../util/localHeroesFormValidations'
import {
  sendLocalHeroes,
  sendLocalHeroesFailure,
  sendLocalHeroesSuccess,
  setFormError,
  updateForm,
  resetForm,
  getLocalHeroes,
  getLocalHeroesSuccess,
  getLocalHeroesFailure,
} from '../actions/localHeroes'
import { getFormValues } from '../selectors/localHeroesForm'
import { history } from '../../createHistory'

const doFetchLocalHeroes = () => async (dispatch, getState) => {
  dispatch(getLocalHeroes())
  try {
    const token = getAuthToken(getState())
    const data = await get(api('/content/about/localHeroes'), token)
    dispatch(getLocalHeroesSuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, getLocalHeroesFailure)
  }
}

const doUpdateForm = (key, value) => async dispatch => {
  try {
    await validationMap[key].validate(value)
    dispatch(setFormError(key, undefined))
  } catch (error) {
    dispatch(setFormError(key, error.message))
  } finally {
    dispatch(updateForm(key, value))
  }
}

const doSubmitForm = () => () => {
  history.push(`${routes.localHeroes}/${routes.localHeroesConfirm}`)
}

const doResetForm = () => dispatch => {
  dispatch(resetForm())
}

const doPostLocalHeroesFailure = errorMessage => dispatch => {
  dispatch(sendLocalHeroesFailure(errorMessage))
  history.push(`${routes.localHeroes}/${routes.localHeroesError}`)
}

const doPostLocalHeroes = () => async (dispatch, getState) => {
  try {
    dispatch(sendLocalHeroes())
    const state = getState()
    const formValues = getFormValues(state)
    const token = getAuthToken(state)
    const data = await post(api('/heroes'), token, formValues)
    const { colleague_email, ref } = data
    await history.replace(`${routes.localHeroes}/${routes.localHeroesSuccess}`)
    dispatch(sendLocalHeroesSuccess({ colleague_email, ref }))
  } catch (error) {
    errorHandler(dispatch, error, doPostLocalHeroesFailure)
  }
}

export {
  doSubmitForm,
  doPostLocalHeroes,
  doUpdateForm,
  doPostLocalHeroesFailure,
  doResetForm,
  doFetchLocalHeroes,
}
