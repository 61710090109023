import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Studies from './Studies'
import {
  getStudies,
  getLoading,
  getErrorMessage,
} from '../../redux/selectors/studies'
import { doFetchStudies } from '../../redux/dispatchers/studies'

const mapStateToProps = state => {
  return {
    studies: getStudies(state),
    loading: getLoading(state),
    errorMessage: getErrorMessage(state),
    // selectedOrganisation: getSelectedOrganisation(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doFetchStudies,
    },
    dispatch
  )
const StudiesContainer = connect(mapStateToProps, mapDispatchToProps)(Studies)

export default StudiesContainer
