import { actionTypes } from '../actions/idea'

const {
  GET_IDEA,
  GET_IDEA_SUCCESS,
  GET_IDEA_FAILURE,
  GET_IDEA_HISTORY,
  GET_IDEA_HISTORY_SUCCESS,
  GET_IDEA_HISTORY_FAILURE,
  UPDATE_IDEA,
  UPDATE_IDEA_FAILURE,
  UPDATE_IDEA_STATUS_SUCCESS,
  SHOW_DECLINE_FORM,
  HIDE_DECLINE_FORM,
} = actionTypes

const initialState = {
  idea: null,
  loading: false,
  errorMessage: null,
  isDeclineFormVisible: false,
}

const ideaReducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case GET_IDEA:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      }
    case GET_IDEA_SUCCESS:
      return {
        ...state,
        idea: {
          ...payload.idea,
          campaign: payload.campaign,
        },
        loading: false,
      }
    case GET_IDEA_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
      }
    case GET_IDEA_HISTORY:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      }
    case GET_IDEA_HISTORY_SUCCESS:
      return {
        ...state,
        idea: {
          ...state.idea,
          history: payload.ideaHistory,
        },
        loading: false,
      }
    case GET_IDEA_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
      }
    case UPDATE_IDEA:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      }
    case UPDATE_IDEA_STATUS_SUCCESS:
      return {
        ...state,
        idea: {
          ...state.idea,
          idea_status: action.status,
        },
      }
    case UPDATE_IDEA_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case SHOW_DECLINE_FORM:
      return {
        ...state,
        isDeclineFormVisible: true,
      }
    case HIDE_DECLINE_FORM:
      return {
        ...state,
        isDeclineFormVisible: false,
      }
    default:
      return state
  }
}

export { initialState, ideaReducer }
