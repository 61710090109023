import { Dispatch, AnyAction } from 'redux'
import { connect } from 'react-redux'
import { State } from '../../redux/store'
import Programmes from './Programmes.component'
import {
  fetchProgrammeHeaderDetail,
  fetchProgrammeTiles,
} from '../../redux/actions/programmeList'
import {
  selectTabs,
  selectSubHeader,
  selectTitle,
  selectDataLoadingState,
  selectDataErrorState,
  selectProgrammeTiles,
} from '../../redux/selectors/programmeList'
import { withRouter } from 'react-router'

const mapStateToProps = (state: State, ownProps: any) => {
  const tabParam =
    new URLSearchParams(ownProps.location.search).get('tab') || 'all'

  const endPoint =
    tabParam === 'all'
      ? '/content/programme'
      : `/content/programme?type=${tabParam}`

  return {
    subHeader: selectSubHeader(state),
    title: selectTitle(state),
    tabs: selectTabs(),
    programmeName: tabParam,
    tiles: selectProgrammeTiles(state, endPoint),
    loading: selectDataLoadingState(state),
    error: selectDataErrorState(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction, State>) => {
  return {
    fetchProgrammeHeaderDetail: () => dispatch(fetchProgrammeHeaderDetail()),
    fetchProgrammeTiles: (tab: string) => dispatch(fetchProgrammeTiles(tab)),
  }
}

const ProgrammesContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Programmes)
)

export default ProgrammesContainer
