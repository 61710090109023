import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  doFetchIdeaWithHistory,
  doDeleteIdea,
  doUpdateIdea,
} from '../../redux/dispatchers/idea'
import { showDeclineForm, hideDeclineForm } from '../../redux/actions/idea'
import Idea from './Idea'

const mapStateToProps = (state, ownProps) => {
  return {
    idea_id: ownProps.idea_id,
    idea: state.ideaReducer.idea,
    loading: state.ideaReducer.loading,
    errorMessage: state.ideaReducer.errorMessage,
    isDeclineFormVisible: state.ideaReducer.isDeclineFormVisible,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doFetchIdeaWithHistory,
      doUpdateIdea,
      doDeleteIdea,
      showDeclineForm,
      hideDeclineForm,
    },
    dispatch
  )

const IdeaContainer = connect(mapStateToProps, mapDispatchToProps)(Idea)

export default IdeaContainer
