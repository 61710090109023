import { doLogout } from './auth'

const errorHandler = (dispatch, error, errorAction, ...rest) => {
  if (error.message === 'unauthorized') {
    dispatch(doLogout())
  } else {
    dispatch(errorAction(error.message, ...rest))
  }
}

export default errorHandler
