import { TOGGLE_VIEW, RESET_VIEW } from '../actions/mapList'
import { VIEW } from '../../constants'

const initialState = {
  VIEW: VIEW.LIST,
}

const mapListReducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case TOGGLE_VIEW:
      const { VIEW } = payload
      return {
        ...state,
        VIEW,
      }
    case RESET_VIEW:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}

export { initialState, mapListReducer }
