import styled from 'styled-components'
import { Link } from 'react-router-dom'

const CaseStudiesHeading = styled.h2`
  font-size: ${({ size }) => (size ? `${size}px` : '22px')};
  text-align: ${({ align }) => align || 'center'};
  color: ${({ theme, accented }) =>
    accented ? theme.color.orange : theme.color.darkGrey};
  margin: ${({ margin }) => (margin ? `${margin}px 0` : '20px 0')};
`

const Wrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  @media (min-width: ${({ theme }) => theme.mq.sm}) and (max-width: ${({
      theme,
    }) => theme.mq.md}) {
    max-width: 100%;
  }
`

const ContainerStyled = styled.div`
  max-width: 100%;
  padding-bottom: 40px;

  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    max-width: calc(50% - 40px);
    width: 100%;
    margin: 0;
    padding: 0 15px 40px 15px;
  }
  @media (min-width: ${({ theme }) => theme.mq.md}) {
    max-width: calc(50% - 100px);
    width: 100%;
    margin: 0;
    padding-bottom: 40px;
  }
`

const StudyStyle = styled.div`
  border-bottom: 5px solid ${({ theme }) => theme.color.orange};
  height: 100%;
`

const StudyLink = styled(Link)`
  text-decoration: none;
`

export { CaseStudiesHeading, Wrapper, ContainerStyled, StudyStyle, StudyLink }
