import React from 'react'
import { Accordion, AccordionItem } from 'luna-react'

interface CCHAccordionProps {
  items: any[]
}

const CCHAccordion: React.FC<CCHAccordionProps> = ({ items }) => {
  if (!items || !items.length) {
    return null
  }

  return (
    <Accordion multipleOpen titleElement="h4">
      {items.map((item: any, index: number) => {
        const key = `accordion-item-${index + 1}`

        return (
          <AccordionItem key={key} id={key} title={item.fields.accordionHeader}>
            <p>{item.fields.accordionText}</p>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

export default CCHAccordion
