import { withRouter } from 'react-router'
import { Dispatch, AnyAction } from 'redux'
import { connect } from 'react-redux'
import { State } from '../../redux/store'
import {
  selectAdminIdeasData,
  selectAdminIdeasStats,
} from '../../redux/selectors/adminIdeas'
import { selectDataIsLoading } from '../../redux/selectors/dataStore'
import {
  downloadVolunteering,
  fetchVolunteeringActionCreator,
} from '../../redux/actions/adminIdeas'
import AdminIdeasListVolunteering from './AdminIdeasListVolunteering.component'

const mapStateToProps = (state: State) => {
  return {
    stats: selectAdminIdeasStats(state),
    data: selectAdminIdeasData(state),
    loading: selectDataIsLoading(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction, State>) => {
  return {
    downloadVolunteering: () => {
      dispatch(downloadVolunteering())
    },
    fetchData: (paramsQuery: string) => {
      dispatch(fetchVolunteeringActionCreator(paramsQuery))
    },
  }
}

const AdminIdeasListVolunteeringContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminIdeasListVolunteering)
)

export default AdminIdeasListVolunteeringContainer
