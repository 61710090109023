import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FullBleed } from './FullBleed'
import * as Panel from './Panel'
import { BorderedCard } from '.'
import { Heading } from 'luna-react'

export const HomepageSection = styled(Panel.Wrapper)`
  margin: 60px 0;
`
export const HomepageHeading = styled.h2`
  font-size: ${({ size }) => (size ? `${size}px` : '22px')};
  text-align: ${({ align }) => align || 'center'};
  color: ${({ theme, accented }) =>
    accented ? theme.color.orange : theme.color.darkGrey};
  margin: ${({ margin }) => (margin ? `${margin}px 0` : '20px 0')};
`

export const HomepageText = styled.div`
  margin: ${({ margin }) => (margin ? `${margin}px 0` : '10px 0')};
  font-size: ${({ size }) => (size ? `${size}px` : '18px')};
  text-align: ${({ align }) => align || 'center'};
`

export const GreyContainer = styled(FullBleed)`
  background-color: ${({ theme }) => theme.color.lighterGrey};
  overflow: auto;
`

export const AtAGlanceBody = styled.div`
  padding: 0 10px 10px 0;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    padding: 10px 10px 10px 0;
    flex: 1 1 auto;
    border-right: 1px solid ${({ theme }) => theme.color.lighterGrey};
  }
`
export const AtAGlanceBlock = styled.div`
  margin: 0;
  flex: 1 0 20%;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    &:first-of-type {
      padding-left: 0;
      margin-left: 0;
    }
    &:last-of-type {
      ${AtAGlanceBody} {
        border: none;
      }
    }
  }
`

export const AtAGlanceHeading = styled(HomepageHeading)`
  margin: 0;
`
export const AtAGlanceTitle = styled(HomepageHeading)`
  margin-bottom: 0;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    margin-bottom: 10px;
  }
`

export const AtAGlanceWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    display: flex;
  }
`

export const CaseStudyLink = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  &:hover {
    color: inherit;
  }
`
export const CaseStudyTitle = styled(Heading)`
  padding: 20px;
  margin-top: auto;
`

export const CaseStudyText = styled(HomepageText)`
  padding: 20px;
`
export const CaseStudyCard = styled(BorderedCard)`
  margin-top: 30px;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    display: flex;
  }
`
