import {
  SelectField,
  TextAreaField as LunaTextAreaField,
  TextInputField,
  RadioButtonField,
} from '@jsluna/form'
import FormItemCheckbox from './legacy/FormItemCheckbox'
import DatePicker from '../DatePicker'

const FormFields = {
  text: TextInputField,
  radio: RadioButtonField,
  textarea: LunaTextAreaField,
  email: TextInputField,
  date: DatePicker,
  number: TextInputField,
  select: SelectField,
  checkbox: FormItemCheckbox,
}

export { FormFields }
