import { api, get } from '../../httpClient/httpClient'
import {
  getContentStudies,
  getContentStudiesSuccess,
  getContentStudiesFailure,
} from '../actions/studies'
import { getAuthToken } from '../selectors/auth'
import errorHandler from './errorHandler'

const doFetchStudies = () => async (dispatch, getState) => {
  dispatch(getContentStudies())
  try {
    const token = getAuthToken(getState())

    const data = await get(api('/content/caseStudies'), token)

    dispatch(getContentStudiesSuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, getContentStudiesFailure)
  }
}

export { doFetchStudies }
