import styled from 'styled-components'

const IdeaTitle = styled.div`
  color: ${({ theme }) => theme.color.grey};
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
`

const IdeaBody = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 24px;
  display: block;
  text-decoration: none;
`

export { IdeaTitle, IdeaBody }
