import React from 'react'
import { PropTypes } from 'prop-types'
import { LoadingIcon } from 'luna-react'
import { Redirect } from 'react-router-dom'
import routes from '../../routes'
import { Container, Content } from '../../styles'
import LocalHeroesContent from '../LocalHeroesContent/LocalHeroesContent'
import { PageHeader } from '../PageHeader'
import {
  ConfirmButton,
  EditButton,
  FormButtons,
} from '../Form/FormFields/styles'
import { history } from '../../createHistory'

class LocalHeroes extends React.Component {
  constructor() {
    super()
    this.confirmIdea = this.confirmIdea.bind(this)
    this.backToForm = this.backToForm.bind(this)
  }

  confirmIdea = e => {
    e.preventDefault()
    if (!this.props.sending) {
      this.props.doPostLocalHeroes()
    }
  }

  backToForm = e => {
    e.preventDefault()
    history.goBack()
  }

  render() {
    const { formValues, sending, isValid } = this.props

    if (!isValid) {
      return <Redirect to={routes.localHeroes} noThrow />
    }
    return (
      <>
        <PageHeader
          title="Review form content"
          blurb="Please confirm the information entered is correct."
        />

        <Content data-id="confirmation">
          <Container.Padded>
            {formValues ? <LocalHeroesContent content={formValues} /> : null}
            <FormButtons>
              <EditButton data-id="edit" onClick={this.backToForm}>
                Edit
              </EditButton>
              <ConfirmButton data-id="confirm" onClick={this.confirmIdea}>
                {sending ? (
                  <LoadingIcon style={{ display: 'inline-block' }} inverted />
                ) : (
                  <span>Submit</span>
                )}
              </ConfirmButton>
            </FormButtons>
          </Container.Padded>
        </Content>
      </>
    )
  }
}

LocalHeroes.propTypes = {
  formValues: PropTypes.object.isRequired,
  isValid: PropTypes.bool,
  sending: PropTypes.bool,
}

export default LocalHeroes
