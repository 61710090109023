import { withRouter } from 'react-router'
import { Dispatch, AnyAction } from 'redux'
import { connect } from 'react-redux'
import { State } from '../../redux/store'
import {
  selectAdminIdeasData,
  selectAdminIdeasStats,
} from '../../redux/selectors/adminIdeas'
import { selectDataIsLoading } from '../../redux/selectors/dataStore'
import {
  downloadLocalHeroes,
  fetchLocalHerosActionCreator,
} from '../../redux/actions/adminIdeas'
import AdminIdeasListHeroes from './AdminIdeasListHeroes.component'

const mapStateToProps = (state: State) => {
  return {
    stats: selectAdminIdeasStats(state),
    data: selectAdminIdeasData(state),
    loading: selectDataIsLoading(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction, State>) => {
  return {
    downloadLocalHeroes: () => {
      dispatch(downloadLocalHeroes())
    },
    fetchData: (paramsQuery: string) => {
      dispatch(fetchLocalHerosActionCreator(paramsQuery))
    },
  }
}

const AdminIdeasListHeroesContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminIdeasListHeroes)
)

export default AdminIdeasListHeroesContainer
