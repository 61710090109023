import React from 'react'
import { PropTypes } from 'prop-types'

import { UserInfo } from './styles'
import { isExternalAdmin } from '../../util/roles'

const User = ({ user, doLogout }) => {
  const logoutHandler = e => {
    e.preventDefault()
    doLogout()
  }
  return (
    <UserInfo>
      <span data-id="user-email">{user.email}</span>
      {isExternalAdmin(user) && (
        <>
          {' - '}
          <a onClick={logoutHandler} data-id="logout-button" href="/">
            Logout
          </a>
        </>
      )}
    </UserInfo>
  )
}

User.propTypes = {
  doLogout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
}
export default User
