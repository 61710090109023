import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { theme } from '../styles'

const statusColours = {
  PENDING: theme.color.yellow,
  ACCEPTED: theme.color.green,
  COMPLETED: theme.color.lightGrey,
  DECLINED: theme.color.red,
  UPDATED: theme.color.aqua,
  EDITED: theme.color.aqua,
  BOOKMARKED: theme.color.darkGrey,
}

const Wrapper = styled(Link)<{ status: string }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 12px;
  text-decoration: none;
  margin: 7px 0;
  padding: 0 15px;
  width: 100%;
  background-color: ${props => props.theme.color.white};
  box-shadow: 0px 2px 2px 0 ${props => props.theme.color.lightestGrey};
  border-left: 4px solid ${props => statusColours[props.status]};
`

const Block = styled.div<{ size?: number }>`
  display: flex;
  flex: ${props => (props.size ? props.size : 1)};
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  padding: 15px;
  min-width: 165px;
  &[data-id='ideas_status'] {
    text-transform: capitalize;
  }
`

const Legend = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.grey};
  display: block;
`

const Label = styled.span<{ status?: string }>`
  font-size: 15px;
  font-family: ${props => props.theme.fontFamily};
  display: block;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${props => (props.status ? statusColours[props.status] : 'inherit')};
`

export { Legend, Label, Wrapper, Block }
