import { stringify } from 'query-string'

import { api, get } from '../../httpClient/httpClient'
import { getAuthToken } from '../selectors/auth'
import errorHandler from './errorHandler'
import { history } from '../../createHistory'

import {
  searchOpportunities,
  searchOpportunitiesSuccess,
  searchOpportunitiesFailure,
  resetSearchState,
} from '../actions/opportunitiesSearch'
import {
  getSearchTerm,
  getDistance,
  getTeamSize,
} from '../selectors/opportunitiesSearch'
import { isValidTeamSize } from '../../util/teamSize'
import { resetViewState } from '../../redux/actions/mapList'

const doResetSearch = () => async dispatch => {
  dispatch(resetSearchState())
  dispatch(resetViewState())
}

const doSearchOrganisations = (searchTerm, categoryName) => async (
  dispatch,
  getState
) => {
  dispatch(searchOpportunities())
  const state = getState()
  const token = getAuthToken(state)
  let requestParams = {}
  let apiParams = {}

  if (searchTerm) {
    requestParams = { orgname: searchTerm, ...requestParams }
    apiParams = { organisationName: searchTerm, ...apiParams }
  } else {
    if (categoryName) {
      requestParams = { category: categoryName, ...requestParams }
      apiParams = { organisationType: categoryName, ...apiParams }
    }
  }

  const userQueryString = stringify(requestParams, { encode: false })

  // TODO: use 'push' from connected-react-router?
  history.push(`/opportunities/org/search?${userQueryString}`)

  const apiQueryString = stringify(apiParams, { encode: false })

  try {
    const data = await get(
      api(`/content/opportunities?${apiQueryString}`),
      token
    )
    dispatch(searchOpportunitiesSuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, searchOpportunitiesFailure)
  }
}

const doSearchOpportunities = () => async (dispatch, getState) => {
  dispatch(searchOpportunities())
  const state = getState()
  const token = getAuthToken(state)
  let requestParams = {}
  let apiParams = {}

  const searchTerm = encodeURIComponent(getSearchTerm(state)).toUpperCase()

  if (searchTerm) {
    requestParams = { address: searchTerm, ...requestParams }
    apiParams = { address: searchTerm, ...apiParams }
  }

  const teamSize = Number.parseInt(getTeamSize(state), 10)
  if (isValidTeamSize(teamSize)) {
    requestParams = { team_size: teamSize, ...requestParams }
    apiParams = { team_size: teamSize, ...apiParams }
  }

  const { value, distance } = getDistance(state)
  if (value && distance) {
    requestParams = { distance: value, ...requestParams }
    apiParams = { radius: distance, ...apiParams }
  }

  const userQueryString = stringify(requestParams, { encode: false })

  // TODO: use 'push' from connected-react-router?
  history.push(`/opportunities/search?${userQueryString}`)

  const apiQueryString = stringify(apiParams, { encode: false })

  try {
    const data = await get(
      api(`/content/opportunities?${apiQueryString}`),
      token
    )
    dispatch(searchOpportunitiesSuccess(data))
  } catch (error) {
    errorHandler(dispatch, error, searchOpportunitiesFailure)
  }
}

export { doSearchOpportunities, doSearchOrganisations, doResetSearch }
