import { withRouter } from 'react-router'
import { Dispatch, AnyAction } from 'redux'
import { connect } from 'react-redux'
import { State } from '../../redux/store'
import AdminIdeasList150Days from './AdminIdeasList150Days.component'
import {
  selectAdminIdeasData,
  selectAdminIdeasStats,
} from '../../redux/selectors/adminIdeas'
import { selectDataIsLoading } from '../../redux/selectors/dataStore'
import {
  downloadIdeas,
  fetch150DaysActionCreator,
} from '../../redux/actions/adminIdeas'

const mapStateToProps = (state: State) => {
  return {
    stats: selectAdminIdeasStats(state),
    data: selectAdminIdeasData(state),
    loading: selectDataIsLoading(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction, State>) => {
  return {
    downloadIdeas: () => {
      dispatch(downloadIdeas())
    },
    fetchData: (paramsQuery: string) => {
      dispatch(fetch150DaysActionCreator(paramsQuery))
    },
  }
}

const AdminIdeasList150DaysContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminIdeasList150Days)
)

export default AdminIdeasList150DaysContainer
