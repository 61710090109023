import styled from 'styled-components'

const Image = styled.img`
  width: 100%;
  display: block;
  border: none;
`

const ImageContainer = styled.div`
  margin-bottom: 1.5rem;
`

export { Image, ImageContainer }
