import styled from 'styled-components'

const Wrapper = styled.span`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`

const Label = styled.span`
  vertical-align: middle;
  font-size: 26px;
  color: ${({ theme }) => theme.color.darkGrey};
`
const Reference = styled(Label)`
  color: ${({ theme }) => theme.color.orange};
`

export { Label, Reference, Wrapper }
