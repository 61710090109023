import React, { useState } from 'react'
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
} from '@react-google-maps/api'
import { Link } from 'react-router-dom'
import { OutlinedButton, LoadingIcon } from 'luna-react'
import {
  InfoWrapper,
  InfoHeader,
  InfoTitle,
  InfoDistance,
  InfoTag,
  InfoTagBold,
} from './styles'

const GOOGLE_MAPS_KEY = window && window.ENV && window.ENV.GOOGLE_MAPS_KEY

const Info = ({ result }) => {
  return (
    <InfoWrapper>
      <InfoHeader>
        <InfoTitle>{result.organisation_name}</InfoTitle>
        <InfoDistance>{result.geolocation.distance} mi</InfoDistance>
      </InfoHeader>
      <p>
        <InfoTag>
          Project title: <InfoTagBold>{result.project_title}</InfoTagBold>
        </InfoTag>
        <InfoTag>
          Project title: <InfoTagBold>{result.address_line_1}</InfoTagBold>
        </InfoTag>
        <InfoTag>
          Team size:{' '}
          <InfoTagBold>
            {result.volunteers_required_min} - {result.volunteers_required_max}
          </InfoTagBold>
        </InfoTag>
      </p>
      <Link
        to={`/opportunities/${result.organisation_slug}/${result.id}`}
        style={{ minWidth: '100px' }}
      >
        <OutlinedButton>{result.organisation_name}</OutlinedButton>
      </Link>
    </InfoWrapper>
  )
}

const MapMarker = ({ result }) => {
  const [isActive, setActive] = useState(false)
  const { location } = result.geolocation
  return (
    <Marker
      key={result.id}
      onClick={() => {
        setActive(true)
      }}
      position={location}
    >
      {!!isActive && (
        <InfoWindow onCloseClick={() => setActive(false)} position={location}>
          <Info result={result} />
        </InfoWindow>
      )}
    </Marker>
  )
}

const OppsMap = ({ results }) => {
  // Initial position of the first result OR defaults to London
  const initialPos = results[0].geolocation.location || {
    lat: 51.5074,
    lng: 0.1278,
  }
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_KEY,
  })
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? (
    <GoogleMap
      id="example-map"
      zoom={12}
      center={initialPos}
      mapContainerStyle={{
        height: '500px',
        width: '100%',
      }}
    >
      {results.map(result => (
        <MapMarker key={result.id} result={result} />
      ))}
    </GoogleMap>
  ) : (
    <LoadingIcon style={{ display: 'inline-block' }} inverted />
  )
}

export default OppsMap
export { MapMarker, Info }
