import styled from 'styled-components'
import { Card } from 'luna-react'

const BorderedCard = styled(Card)`
  margin: 10px auto;
  border-bottom: 5px solid ${props => props.theme.color.orange};
`

const BorderedCardWrapper = styled.div`
  display: block;
  padding: 0;
  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    display: flex;
    ${BorderedCard} {
      margin: 0 10px;
      width: 50%;
    }
    ${BorderedCard}:first-of-type {
      margin-left: 0;
    }
    ${BorderedCard}:last-of-type {
      margin-right: 0;
    }
  }
`
export { BorderedCard, BorderedCardWrapper }
