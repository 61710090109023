import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
} from './styles'
import IdeaAccept from './IdeaAccept'
import IdeaDelete from './IdeaDelete'
import IdeaBookmark from './IdeaBookmark'
import IdeaDecline from './IdeaDecline'
import IdeaActionButton from './IdeaActionButton'
import { doUpdateIdeaStatus } from '../../redux/dispatchers/idea'
import { idea_statuses } from '../../constants'
import { IdeaType } from '../../types'

interface IdeaActionBarProps {
  idea: IdeaType
}

const IdeaActionBar: React.FC<IdeaActionBarProps> = ({ idea }) => {
  const dispatch = useDispatch()
  const [declineOpen, setDeclineOpen] = useState(false)
  const doDeclineIdea = (declined_reason: string) => {
    const { id, campaign } = idea
    dispatch(
      doUpdateIdeaStatus(id, campaign, idea_statuses.DECLINED, {
        declined_reason,
      })
    ).finally(() => {
      setDeclineOpen(false)
    })
  }

  if (idea.idea_status === idea_statuses.COMPLETED) {
    return null
  }

  return (
    <>
      <ButtonGroupWrapper>
        <ButtonGroupPrimary>
          <IdeaAccept idea={idea} />
          <IdeaActionButton
            status={idea_statuses.DECLINED}
            disabled={idea.idea_status === idea_statuses.DECLINED}
            onClick={() => {
              setDeclineOpen(true)
            }}
            data-id="idea-decline"
            title="Decline idea"
            label="Decline idea"
          />
        </ButtonGroupPrimary>
        <ButtonGroupSecondary>
          <IdeaDelete idea={idea} />
          <IdeaBookmark idea={idea} />
        </ButtonGroupSecondary>
      </ButtonGroupWrapper>
      <IdeaDecline
        idea_id={idea.id}
        submitted_by={idea.submitted_by}
        isDeclineFormVisible={declineOpen}
        hideDeclineForm={() => {
          setDeclineOpen(false)
        }}
        doDeclineIdea={doDeclineIdea}
      />
    </>
  )
}

export default IdeaActionBar
