import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import { ProgressSpinner } from '@jsluna/progress'
import UserIdeaSummary from '../IdeaSummary/UserIdeaSummary'
import ErrorMessage from '../ErrorMessage/ErrorMessage'

class UserIdeas extends React.Component {
  fetchIdeas() {
    this.props.doFetchUserIdeas(this.props.campaign)
  }

  componentDidMount() {
    this.fetchIdeas()
  }

  componentDidUpdate(prevProps) {
    if (this.props.campaign !== prevProps.campaign) {
      this.fetchIdeas()
    }
  }

  render() {
    return (
      <div className="ln-u-soft-ends">
        {(({ ideas, loading, errorMessage, campaign }) => {
          if (loading) {
            return <ProgressSpinner />
          }

          if (errorMessage) {
            return <ErrorMessage errorMessage={errorMessage} />
          }

          if (ideas && ideas.length < 1) {
            const errorContent = {
              volunteering: {
                title: 'You have not submitted a Volunteering Project idea!',
                prefix: 'Please submit a ',
                link: {
                  path: '/volunteering',
                  copy: 'Volunteering Project idea',
                },
              },
              default: {
                title: 'You have not submitted an idea!',
                prefix: 'Please ',
                link: {
                  path: '/new-idea',
                  copy: 'submit an idea',
                },
              },
            }
            const content = errorContent[campaign] || errorContent.default
            const { title, prefix, link } = content

            return (
              <ErrorMessage icon title={title}>
                <p>
                  <span>{prefix}</span>
                  <Link to={link.path}>{link.copy}</Link>
                  <span> before visiting this page.</span>
                </p>
              </ErrorMessage>
            )
          }

          if (ideas && ideas.length > 0) {
            return (
              <div data-id="my-ideas-table">
                {ideas
                  .map(idea => ({
                    ...idea,
                    volunteering_idea:
                      idea.volunteering_idea ||
                      idea.volunteering_project_detail,
                  }))
                  .map((idea, index) => (
                    <UserIdeaSummary
                      idea={idea}
                      key={idea.id}
                      count={index}
                      campaign={campaign}
                    />
                  ))}
              </div>
            )
          }

          return null
        })(this.props)}
      </div>
    )
  }
}

UserIdeas.propTypes = {
  doFetchUserIdeas: PropTypes.func.isRequired,
  doUpdateUserSort: PropTypes.func.isRequired,
  ideas: PropTypes.array,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
  campaign: PropTypes.string.isRequired,
}

export default UserIdeas
