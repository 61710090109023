import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Landing from '../../pages/Landing'
import Sunrise from '../../pages/Sunrise'
import Router from '../../Router'
import TokenContainer from '../Token/TokenContainer'
import { history } from '../../createHistory'

class Auth extends Component {
  componentDidMount() {
    this.props.doLogin()
  }
  render() {
    const { user } = this.props
    if (user && user.role) {
      if (user.inactive) {
        return <Sunrise />
      }
      return (
        <TokenContainer>
          <Router history={history} />
        </TokenContainer>
      )
    }
    return <Landing />
  }
}

Auth.propTypes = {
  user: PropTypes.object,
  doLogin: PropTypes.func.isRequired,
}

export default Auth
