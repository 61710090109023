import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import formSections from '../../data/ideasFormData'
import EditIdeaForm from './EditIdeaForm'
import {
  getLoading,
  getErrorMessage,
  getEditIdeaId,
} from '../../redux/selectors/editIdea'
import { doFetchIdea, doSubmitForm } from '../../redux/dispatchers/editIdea'
import { getIsValid } from '../../redux/selectors/newIdea'

const mapStateToProps = state => {
  return {
    loading: getLoading(state),
    errorMessage: getErrorMessage(state),
    isValid: getIsValid(state),
    id: getEditIdeaId(state),
    formSections,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doSubmitForm,
      doFetchIdea,
    },
    dispatch
  )

const EditIdeaFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditIdeaForm)

export { EditIdeaFormContainer }

export default EditIdeaFormContainer
