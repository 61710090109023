import React from 'react'
import { PropTypes } from 'prop-types'
import { Container } from '../../styles'

// Internal components
import routes from '../../routes'
import Menu from '../Menu/Menu'
import logo from '../../assets/logo.svg'
import { Desktop } from '../../util/MediaQuery'
import { MenuToggle } from './MenuToggle'
import { MobileMenu } from './MobileMenu'
import { OuterWrapper, InnerWrapper, StyledLink, Logo } from './styles'
import User from './User'

const Header = ({ siteTitle, menu, toggleOpen, user, doLogout }) => {
  const { isOpen } = menu
  return (
    <OuterWrapper>
      <Container.FullBleed>
        <InnerWrapper>
          <StyledLink to={routes.home}>
            <Logo src={logo} alt={siteTitle} />
          </StyledLink>
          <Desktop>
            <Menu />
          </Desktop>
          <MenuToggle isOpen={isOpen} toggleOpen={toggleOpen} />
        </InnerWrapper>
        <User user={user} doLogout={doLogout} />
      </Container.FullBleed>
      <MobileMenu isOpen={isOpen} toggleOpen={toggleOpen} />
    </OuterWrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  doLogout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
}

export default Header
