import * as Yup from 'yup'
import formSections from '../data/localHerosFormData'

const validationMap = formSections
  .reduce((accumulator, { items }) => [...accumulator, ...items], [])
  .reduce((accumulator, formItem) => {
    const { name, validation } = formItem
    accumulator[name] = validation
    return accumulator
  }, {})

const validationSchema = Yup.object().shape({
  ...validationMap,
})

const isFormValid = formValues => {
  try {
    validationSchema.validateSync(formValues)
    return true
  } catch {
    return false
  }
}

export { validationMap, validationSchema, isFormValid }
