import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Token from './Token'
import { doRefresh } from '../../redux/dispatchers/auth'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doRefresh,
    },
    dispatch
  )

const TokenContainer = connect(undefined, mapDispatchToProps)(Token)

export default TokenContainer
