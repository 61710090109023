import styled from 'styled-components'

const PageTitleContainer = styled.div`
  display: block;
  width: 100%;
  background-color: ${({ theme }) => theme.color.lighterGrey};
`

const PageTitleWrapper = styled.div`
  display: block;
  width: 100%;
  background-color: white;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.color.lighterGrey};
`

const PageTitleH1 = styled.h1`
  font-size: 40px;
  color: ${({ theme }) => theme.color.darkGrey};
  margin-bottom: 0;
`

export { PageTitleContainer, PageTitleWrapper, PageTitleH1 }
