import React, { useEffect } from 'react'
import { FeedbackWrapper } from './styles'
import Modal from '../Modal/Modal'
import FeedbackForm from './FeedbackForm'

const FeedbackModal = ({ doClose }) => {
  const ref = React.useRef(null)
  useEffect(() => {
    ref.current.focus()
  }, [])

  return (
    <Modal>
      <FeedbackWrapper
        innerRef={ref}
        role="dialog"
        tabIndex="0"
        aria-modal="true"
      >
        <FeedbackForm doClose={doClose} />
      </FeedbackWrapper>
    </Modal>
  )
}

export default FeedbackModal
