import styled from 'styled-components'
import { Heading, FilledButton } from 'luna-react'

const Header = styled(Heading)`
  margin-bottom: 10px;
  color: ${props => props.theme.color.orange};
  text-align: ${props => (props.align ? props.align : 'center')};
`
const Body = styled.div`
  text-align: center;
  margin-bottom: 20px;
  padding: 0 ${props => (props.hard ? 0 : '15%')};
  font-size: 18px;
  width: 100%;
`

const Wrapper = styled.div`
  background-color: ${({ theme, white }) =>
    white ? theme.color.white : theme.color.lighterGrey};
  padding: 40px ${props => (props.hard ? 0 : '10px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props =>
    isNaN(props.verticalSpacing) ? '20px' : `${props.verticalSpacing * 5}px`};
  margin-top: ${props =>
    isNaN(props.verticalSpacing) ? '20px' : `${props.verticalSpacing * 5}px`};
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${({ theme }) => theme.mq.sm}) {
    padding: 40px 10px;
  }

  @media (min-width: ${({ theme }) => theme.mq.md}) {
    padding: 40px;
  }
`
const Button = styled(FilledButton)``

export { Wrapper, Header, Body, Button }
