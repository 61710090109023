import React from 'react'
import { PropTypes } from 'prop-types'
import { Accordion, AccordionItem, Card, Heading } from 'luna-react'
import styled from 'styled-components'
import routes from '../../routes'
import {
  Callout,
  CenteredLoadingIndicator,
  Container,
  Content,
  Panel,
} from '../../styles'
import ImageBackground from '../ImageBackground/ImageBackground'
import { BackHeader } from '../PageHeader'
import { history } from '../../createHistory'
import ErrorMessage from '../ErrorMessage/ErrorMessage'

class About150 extends React.Component {
  componentDidMount() {
    this.props.doFetchDataStoreData()
  }

  renderContent = () => {
    const {
      accordionItems,
      title,
      subtitle,
      quoteText,
      image,
      buttonText,
      paragraphItems,
      disclaimerHeader,
      disclaimerText,
    } = this.props.about150

    const [firstParagraph, secondParagraph] = [...paragraphItems]
    return (
      <Content>
        <BackHeader
          title={title}
          blurb={subtitle}
          textTo="Our Community Hub"
          to={routes.about}
        />
        <Content>
          <Container.FullBleed>
            {firstParagraph && (
              <Panel.Wrapper>
                <Panel.Head bg={image.file.url}>
                  <Container.Padded>
                    <Panel.Container white>
                      <Panel.Title>
                        {firstParagraph.paragraphHeader}
                      </Panel.Title>
                      <Panel.Blurb>
                        {firstParagraph.paragraphSubheader}
                      </Panel.Blurb>
                    </Panel.Container>
                  </Container.Padded>
                </Panel.Head>
                <Container.Padded>
                  <Panel.Container noPaddingTop white>
                    <Panel.ContainerWithQuote white>
                      <div style={{ flex: '1 1 0px' }}>
                        {firstParagraph.paragraph.content.map((p, index) => (
                          <Panel.P key={index}>{p.content[0].value}</Panel.P>
                        ))}
                      </div>
                      <Panel.Quote>{quoteText}</Panel.Quote>
                    </Panel.ContainerWithQuote>
                  </Panel.Container>
                </Container.Padded>
              </Panel.Wrapper>
            )}
          </Container.FullBleed>
        </Content>
        <ImageBackground padding={{ md: '76px' }} image="about150bg">
          <Container.Padded style={{ overflow: 'auto' }}>
            {secondParagraph && (
              <Card>
                <Heading level={5}>{secondParagraph.paragraphHeader}</Heading>
                {secondParagraph.paragraph.content.map((p, index) => (
                  <p style={{ margin: 0 }} key={index}>
                    {p.content[0].value}
                  </p>
                ))}
              </Card>
            )}
          </Container.Padded>
        </ImageBackground>
        <Content grey>
          <Container.Padded>
            <Accordion multipleOpen titleElement="h3">
              {accordionItems.map((item, index) => (
                <AccordionItem title={item.accordionHeader} key={index}>
                  {item.accordionText}
                </AccordionItem>
              ))}
            </Accordion>
          </Container.Padded>
        </Content>
        <Content>
          <Callout.Wrapper white>
            <Callout.Header level={3}>{disclaimerHeader}</Callout.Header>
            <Callout.Body>{disclaimerText}</Callout.Body>
            <Callout.Button onClick={() => history.push(routes.newIdea)}>
              {buttonText}
            </Callout.Button>
          </Callout.Wrapper>
        </Content>
      </Content>
    )
  }

  render() {
    const Wrapper = styled.div`
      padding: 300px 50px;
    `
    const { loading, about150, errorMessage } = this.props
    return (
      <>
        {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        {loading && (
          <Wrapper>
            <Container.Padded style={{ overflow: 'auto' }}>
              <CenteredLoadingIndicator message="Fetching content..." />
            </Container.Padded>
          </Wrapper>
        )}
        {about150 && this.renderContent()}
      </>
    )
  }
}

About150.propTypes = {
  doFetchDataStoreData: PropTypes.func,
  about150: PropTypes.object,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default About150
