import React from 'react'
import Layout from '../layouts/Layout/Layout'

import NewLocalHeroConfirmationContainer from '../components/LocalHeroes/LocalHeroesConfirmationContainer'

const NewLocalHeroConfirmation = () => {
  return (
    <Layout pageId="page-new-local-hero-confirm">
      <NewLocalHeroConfirmationContainer />
    </Layout>
  )
}

export default NewLocalHeroConfirmation
