import Cookie from 'js-cookie'

const TRACE_ID_KEY = 'trace_id'

const initialiseTraceId = () => {
  const traceId =
    Math.random()
      .toString(36)
      .substring(2, 5) +
    Math.random()
      .toString(36)
      .substring(2, 5)

  Cookie.set(TRACE_ID_KEY, traceId, {
    expires: 7,
    path: '/',
    domain: window.location.hostname,
  })
}

const getTraceId = () => Cookie.get(TRACE_ID_KEY) || ''

export { initialiseTraceId, getTraceId }
