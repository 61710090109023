import routes from '../routes'

const date = new Date()
const year = date.getFullYear()

const footer = {
  links: [
    {
      id: '0',
      title: 'Privacy Policy',
      href: routes.privacyPolicy,
      internal: true,
      dataId: 'privacyPolicy',
    },
    {
      id: '2',
      title: 'Accessibility',
      href: 'https://help.sainsburys.co.uk/help/website/our-commitment',
    },
    {
      id: '3',
      title: 'Terms & Conditions',
      href: routes.termsAndConditions,
      internal: true,
      dataId: 'terms-and-conditions',
    },
  ],
  disclaimer: 'Sainsbury’s, Live Well For Less',
  copyright: `© J Sainsbury plc ${year}`,
}

export default footer
