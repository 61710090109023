import React from 'react'
import PropTypes from 'prop-types'
import {
  IdeaHistoryItemWrapper,
  IdeaStatusWrapper,
  IdeaHistoryStatus,
  VerticalLine,
  IdeaHistoryDeclinedReason,
} from './styles'
import moment from 'moment'

const getDay = date => moment(date).format('DD MMM YYYY')

const getTime = date => moment(date).format('HH:mm')

const IdeaHistoryItem = ({
  item: { idea_status, updated_date, updated_by, declined_reason },
}) => (
  <IdeaHistoryItemWrapper>
    <IdeaStatusWrapper>
      <IdeaHistoryStatus status={idea_status} center={true} />
      <VerticalLine />
    </IdeaStatusWrapper>
    <div>
      <div id="updated_date">
        <b>Date:</b> {getDay(updated_date)}
      </div>
      <div id="updated_time">
        <b>Time:</b> {getTime(updated_date)}
      </div>
      <div>
        <b>User:</b> {updated_by}
      </div>
      {declined_reason && (
        <IdeaHistoryDeclinedReason>{declined_reason}</IdeaHistoryDeclinedReason>
      )}
    </div>
  </IdeaHistoryItemWrapper>
)

IdeaHistoryItem.propTypes = {
  item: PropTypes.shape({
    idea_status: PropTypes.string.isRequired,
    updated_date: PropTypes.string.isRequired,
    updated_by: PropTypes.string.isRequired,
    declined_reason: PropTypes.string,
  }),
}

export default IdeaHistoryItem
