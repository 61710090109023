import * as Yup from 'yup'

import {
  VALID_TEAM_SIZE,
  VALID_BUSINESSES,
  VALID_CATEGORIES,
  MAX_LONG_TEXT_LENGTH,
  MAX_SHORT_TEXT_LENGTH,
} from './ideasDomain'

export default [
  {
    header: 'Name of person completing this form',
    required: true,
    items: [
      {
        label:
          'You must have a personal Sainsbury’s Group email address to complete this form',
        placeholder: 'e.g. Sam Wood',
        type: 'text',
        required: true,
        name: 'colleague_name',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string()
          .max(MAX_SHORT_TEXT_LENGTH, 'Name is too long')
          .required('Colleague name is required'),
      },
    ],
  },
  {
    header: 'Email address',
    items: [
      {
        label: 'Correspondence will be sent to this email address',
        placeholder: 'e.g. sam.wood@sainsburys.co.uk',
        type: 'email',
        name: 'contact_email',
        defaultValue: '',
        disabled: true,
        maxLength: MAX_SHORT_TEXT_LENGTH,
      },
    ],
  },
  {
    header: 'Associated store or team',
    required: true,
    items: [
      {
        type: 'checkbox',
        name: 'business',
        options: VALID_BUSINESSES,
        validation: Yup.array()
          .min(1, 'You need to select one business at least')
          .max(VALID_BUSINESSES.length, 'Too many businesses selected')
          .required(),
      },
      {
        label: 'Store name, depot name, SSC team name, or other',
        placeholder: 'e.g. Stratford Superstore',
        type: 'text',
        name: 'store',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string()
          .max(MAX_SHORT_TEXT_LENGTH, 'Store details is too long')
          .required('The store/team name is required'),
      },
      {
        label: 'Store number (for store colleagues only, optional)',
        placeholder: 'e.g. 0002',
        type: 'text',
        name: 'store_number',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string().max(
          MAX_SHORT_TEXT_LENGTH,
          'Store number is too long'
        ),
      },
      {
        label: 'Region (for store colleagues only, optional)',
        placeholder: 'e.g. C04',
        type: 'text',
        name: 'region',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string().max(
          MAX_SHORT_TEXT_LENGTH,
          'Region details is too long'
        ),
      },
      {
        label: 'Number of members in your team',
        type: 'select',
        name: 'team_size',
        options: VALID_TEAM_SIZE,
        validation: Yup.string().required('Tell us your team size'),
      },
    ],
  },
  {
    header: 'Organisation',
    required: true,
    items: [
      {
        label: 'Organisation name',
        placeholder: 'e.g. Woodland Trust',
        type: 'text',
        name: 'organisation_name',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string()
          .max(MAX_SHORT_TEXT_LENGTH, 'The organisation name is too long')
          .required('The organisation name is required'),
      },
      {
        label: 'Category',
        type: 'select',
        name: 'category',
        options: VALID_CATEGORIES,
        validation: Yup.string().required('Category is required'),
      },
      {
        label: 'Organisation website (optional)',
        placeholder: 'e.g. http://www.woodlandtrust.org.uk',
        type: 'text',
        name: 'organisation_website',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string().max(
          MAX_LONG_TEXT_LENGTH,
          'The organisation website is too long'
        ),
      },
    ],
  },
  {
    header: 'Volunteering idea',
    required: true,
    items: [
      {
        label:
          'Please tell us as much as possible about the sort of thing you’d like to do',
        type: 'textarea',
        name: 'volunteering_idea',
        maxLength: MAX_LONG_TEXT_LENGTH,
        validation: Yup.string()
          .max(MAX_LONG_TEXT_LENGTH, 'Your idea description is too long')
          .required("Don't forget to tell us your idea"),
      },
    ],
  },
  {
    header: 'Stardust moments',
    items: [
      {
        label: 'How could we support to make this extra special?',
        type: 'textarea',
        name: 'stardust_moments',
        maxLength: MAX_LONG_TEXT_LENGTH,
        validation: Yup.string().max(
          MAX_LONG_TEXT_LENGTH,
          'Stardust moments is too long'
        ),
      },
    ],
  },
  {
    header: 'Do you have a date in mind for your volunteering day?',
    items: [
      {
        label:
          'If you have an exact date in mind please use this format DD/MM/YYYY',
        placeholder: 'e.g. 2nd week in July or 10/07/2019',
        type: 'text',
        name: 'date',
        maxLength: MAX_SHORT_TEXT_LENGTH,
        validation: Yup.string().max(
          MAX_SHORT_TEXT_LENGTH,
          'Date is not valid'
        ),
      },
    ],
  },
]
