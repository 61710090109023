import React from 'react'
import { PropTypes } from 'prop-types'

import { IdeaTitle, IdeaBody } from '../../styles'

const IdeaContent = ({ idea }) => {
  const {
    business,
    colleague_name,
    contact_email,
    date,
    organisation_name,
    organisation_website,
    category,
    stardust_moments,
    store,
    store_number,
    region,
    volunteering_idea,
    team_size,
  } = idea
  return (
    <>
      <div id="idea_colleague_name">
        <IdeaTitle>Team Leader</IdeaTitle>
        <IdeaBody>{colleague_name}</IdeaBody>
      </div>
      <div id="idea_contact_email">
        <IdeaTitle>Email address</IdeaTitle>
        <IdeaBody>{contact_email}</IdeaBody>
      </div>
      <div id="idea_business">
        <IdeaTitle>Store/Team</IdeaTitle>
        <IdeaBody>
          {Array.isArray(business) ? business.join(', ') : business}
        </IdeaBody>
      </div>
      <div id="idea_store">
        <IdeaTitle>Store name, or store-support-centre team name</IdeaTitle>
        <IdeaBody>{store}</IdeaBody>
      </div>
      {store_number && (
        <div id="idea_store_number">
          <IdeaTitle>
            Store number, or store-support-centre team number
          </IdeaTitle>
          <IdeaBody>{store_number}</IdeaBody>
        </div>
      )}
      {region && (
        <div id="idea_region">
          <IdeaTitle>Region</IdeaTitle>
          <IdeaBody>{region}</IdeaBody>
        </div>
      )}
      <div id="idea_team_size">
        <IdeaTitle>Team Size</IdeaTitle>
        <IdeaBody>{team_size}</IdeaBody>
      </div>
      <div id="idea_organisation_name">
        <IdeaTitle>Organisation name</IdeaTitle>
        <IdeaBody>{organisation_name}</IdeaBody>
      </div>
      {organisation_website && (
        <div id="idea_organisation_website">
          <IdeaTitle>Organisation website</IdeaTitle>
          <IdeaBody>{organisation_website}</IdeaBody>
        </div>
      )}
      <div id="idea_category">
        <IdeaTitle>Category</IdeaTitle>
        <IdeaBody>{category}</IdeaBody>
      </div>
      <div id="idea_volunteering_idea">
        <IdeaTitle>Volunteering idea</IdeaTitle>
        <IdeaBody>{volunteering_idea}</IdeaBody>
      </div>
      {stardust_moments && (
        <div id="idea_stardust_moments">
          <IdeaTitle>Stardust moment:</IdeaTitle>
          <IdeaBody>{stardust_moments}</IdeaBody>
        </div>
      )}
      {date && (
        <div id="idea_date">
          <IdeaTitle>Volunteering date</IdeaTitle>
          <IdeaBody>{date}</IdeaBody>
        </div>
      )}
    </>
  )
}

IdeaContent.propTypes = {
  idea: PropTypes.shape({
    business: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    colleague_name: PropTypes.string.isRequired,
    contact_email: PropTypes.string.isRequired,
    date: PropTypes.string,
    organisation_name: PropTypes.string.isRequired,
    organisation_website: PropTypes.string,
    stardust_moments: PropTypes.string,
    store: PropTypes.string.isRequired,
    volunteering_idea: PropTypes.string.isRequired,
    team_size: PropTypes.string.isRequired,
  }),
}

export default IdeaContent
