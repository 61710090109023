export const actionTypes = {
  GET_CONTENT_STUDIES: 'GET_CONTENT_STUDIES',
  GET_CONTENT_STUDIES_SUCCESS: 'GET_CONTENT_STUDIES_SUCCESS',
  GET_CONTENT_STUDIES_FAILURE: 'GET_CONTENT_STUDIES_FAILURE',
}

const {
  GET_CONTENT_STUDIES,
  GET_CONTENT_STUDIES_SUCCESS,
  GET_CONTENT_STUDIES_FAILURE,
} = actionTypes

export const getContentStudies = () => ({
  type: GET_CONTENT_STUDIES,
})

export const getContentStudiesSuccess = studies => ({
  type: GET_CONTENT_STUDIES_SUCCESS,
  payload: { studies },
})

export const getContentStudiesFailure = errorMessage => ({
  type: GET_CONTENT_STUDIES_FAILURE,
  payload: {
    errorMessage,
  },
})
