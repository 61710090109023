import React from 'react'
import { PropTypes } from 'prop-types'
import { idea_statuses } from '../../constants'
import StatusIcon from './StatusIcon'
import { WrapperRed, WrapperGreen, WrapperLabel } from './styles'
const StatusButton = props => {
  switch (props.status) {
    case idea_statuses.ACCEPTED:
      return (
        <WrapperGreen {...props}>
          <WrapperLabel>{props.label}</WrapperLabel>
          <StatusIcon status={props.status} />
        </WrapperGreen>
      )
    case idea_statuses.DECLINED:
      return (
        <WrapperRed {...props}>
          <WrapperLabel>{props.label}</WrapperLabel>
          <StatusIcon status={props.status} />
        </WrapperRed>
      )
    default:
      return null
  }
}

StatusButton.propTypes = {
  status: PropTypes.oneOf([idea_statuses.ACCEPTED, idea_statuses.DECLINED]),
  label: PropTypes.string,
  title: PropTypes.string,
  'data-id': PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default StatusButton
