import React from 'react'
import { PropTypes } from 'prop-types'
import { withRouter } from 'react-router-dom'
import { LoadingIndicator } from 'luna-react'
import styled from 'styled-components'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import StatusIcon from '../Status/StatusIcon'
import { idea_statuses, ideaLabels } from '../../constants'
import StatusButton from '../Status/StatusButton'
import IdeaContent from '../IdeaContent/IdeaContent'
import { Divider, IdeaTitle, IdeaBody } from '../../styles'
import DeclineIdeaFormContainer from '../DeclineIdeaForm/DeclineIdeaFormContainer'
import IdeaHistory from '../IdeaHistory/IdeaHistory'
import DeleteButton from '../Status/DeleteButton'
import BookmarkIcon from '../Status/BookmarkIcon'
import { history } from '../../createHistory'
import routes from '../../routes'
import { canBeBookmarked } from '../../data/ideasDomain'
import IdeaDialog from './IdeaDialog'

const IdeaWrapper = styled.div`
  padding: 20px 0;
`

const DeleteIdeaWrapper = styled.div`
  margin-left: auto;
  color: ${({ theme }) => theme.color.red};
  font-size: 18px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily};
`

const Buttons = styled.div`
  display: flex;
  margin: 20px 0 0;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`
const FloatedStatusIcon = styled.div`
  float: right;
`

const BookmarkIconWrapper = styled.div`
  cursor: pointer;
  margin-left: auto;
`

class Idea extends React.Component {
  constructor() {
    super()
    this.state = { modal: {} }
    this.clickDeclineHandler = this.clickDeclineHandler.bind(this)
  }

  componentDidMount() {
    this.props.doFetchIdeaWithHistory(this.props.match.params.idea_id)
  }

  modalActions = {
    DELETE: {
      action: 'delete',
      onConfirm: () => {
        this.handleIdeaDelete()
      },
    },
    BOOKMARK: {
      action: 'bookmark',
      onConfirm: () => {
        this.handleIdeaUpdate(idea_statuses.BOOKMARKED)
      },
    },
  }

  handleIdeaUpdate = ideaStatus => {
    if (!idea_statuses[ideaStatus]) {
      return
    }
    if (this.props.idea.idea_status === idea_statuses[ideaStatus]) {
      return
    }
    const { doUpdateIdea, idea } = this.props
    this.setModalBusy()
    doUpdateIdea({
      idea,
      idea_status: idea_statuses[ideaStatus],
      onSuccess: () => {
        this.hideModal()
      },
      onError: () => {
        this.hideModal()
      },
    })
  }

  hideModal = () => {
    this.setState({
      modal: {
        ...this.state.modal,
        visible: false,
        busy: false,
      },
    })
  }
  setModalBusy = () => {
    this.setState({
      modal: {
        ...this.state.modal,
        busy: true,
      },
    })
  }

  showModal = type => {
    this.setState({
      modal: {
        ...this.state.modal,
        visible: true,
        busy: false,
        type,
      },
    })
  }

  showDeleteButton = () => {
    const { idea = {} } = this.props
    const { idea_status } = idea
    return (
      idea_status === idea_statuses.DECLINED && !this.state.deleteActionMessage
    )
  }

  handleIdeaDelete = () => {
    const { doDeleteIdea, idea } = this.props
    const { id } = idea
    this.setModalBusy()
    doDeleteIdea({
      id,
      onSuccess: () => {
        this.setDeleteActionMessage('Deleted')
        this.hideModal()
        history.replace(routes.adminIdeas)
      },
      onError: () => {
        this.hideModal()
        this.setDeleteActionMessage('An error occurred')
      },
    })
  }

  setDeleteActionMessage = message => {
    this.setState({ deleteActionMessage: message })
  }

  clickDeclineHandler(e) {
    e.preventDefault()
    if (this.props.idea.idea_status === idea_statuses.DECLINED) return null
    this.props.showDeclineForm()
  }

  clickAcceptHandler = e => {
    e.preventDefault()
    if (this.props.idea.idea_status === idea_statuses.ACCEPTED) return null
    if (
      window.confirm(
        `Are you sure that you want to accept the idea? An email will be sent to ${this.props.idea.submitted_by}.`
      )
    ) {
      this.props.doUpdateIdea({
        idea: this.props.idea,
        idea_status: idea_statuses.ACCEPTED,
      })
    }
  }

  render() {
    const { idea, loading, errorMessage } = this.props
    const { modal = {} } = this.state

    if (loading) {
      return <LoadingIndicator message="Loading idea…" />
    }
    if (errorMessage) {
      return <ErrorMessage errorMessage={errorMessage} />
    }
    if (idea) {
      const { idea_status, ref } = idea
      return (
        <IdeaWrapper data-id="idea">
          <FloatedStatusIcon>
            <StatusIcon big={true} status={idea_status} />
          </FloatedStatusIcon>
          <IdeaContent idea={idea} />
          <Divider margin={'30px 0'} />
          <div style={{ display: 'flex' }}>
            <div id="idea_status">
              <IdeaTitle>Status</IdeaTitle>
              <IdeaBody>{ideaLabels[idea_status]}</IdeaBody>
              {idea.history && idea.history.length > 0 && (
                <IdeaHistory history={idea.history} />
              )}
            </div>
          </div>
          <Buttons>
            <StatusButton
              status={idea_statuses.ACCEPTED}
              disabled={idea_status === idea_statuses.ACCEPTED}
              onClick={this.clickAcceptHandler}
              data-id="idea-accept"
              title="Accept idea"
              label="Accept idea"
            />
            <StatusButton
              status={idea_statuses.DECLINED}
              disabled={idea_status === idea_statuses.DECLINED}
              onClick={this.clickDeclineHandler}
              data-id="idea-decline"
              title="Decline idea"
              label="Decline idea"
            />
            <DeleteIdeaWrapper>
              {this.showDeleteButton() ? (
                <DeleteButton
                  data-id="idea-delete"
                  label="Delete this idea"
                  onClick={() => this.showModal('DELETE')}
                />
              ) : null}
              {this.state.deleteActionMessage ? (
                <span data-id="delete-message">
                  {this.state.deleteActionMessage}
                </span>
              ) : null}
            </DeleteIdeaWrapper>
            {canBeBookmarked(idea) ? (
              <BookmarkIconWrapper
                data-id="idea-bookmark"
                label="Bookmark this idea"
                onClick={() => {
                  this.showModal('BOOKMARK')
                }}
              >
                <BookmarkIcon />
              </BookmarkIconWrapper>
            ) : null}
          </Buttons>
          <DeclineIdeaFormContainer
            idea_id={this.props.match.params.idea_id}
            submitted_by={idea.submitted_by}
          />
          {modal.visible && (
            <IdeaDialog
              {...this.modalActions[modal.type]}
              onCancel={this.hideModal}
              ideaRef={ref}
              isLoading={this.state.modal.busy}
            />
          )}
        </IdeaWrapper>
      )
    }
    return null
  }
}

Idea.propTypes = {
  doFetchIdeaWithHistory: PropTypes.func.isRequired,
  doUpdateIdea: PropTypes.func.isRequired,
  showDeclineForm: PropTypes.func.isRequired,
  hideDeclineForm: PropTypes.func.isRequired,
  ideas: PropTypes.object,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export { Idea }

export default withRouter(Idea)
