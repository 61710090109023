import React from 'react'
import { IconWrapper } from './styles'
import Icon from './Icon'

const DEFAULT_COMPONENT = () => <span />

const StatusIcon = ({ status, big = false, ...rest }) => {
  const Component = Icon[status] || DEFAULT_COMPONENT
  return (
    <IconWrapper status={status} big={big} {...rest}>
      <Component />
    </IconWrapper>
  )
}

export default StatusIcon
