import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Org } from '../../styles'
import { Text, Heading } from '@jsluna/typography'
import styled from 'styled-components'

const FlexRow = styled.div`
  flex-direction: column;
  flex-basis: 100%;
  flex-wrap: wrap;
`

export const OrganisationSummary = ({ data, numberOfOpportunities }) => {
  const { organisation_name, organisation_type } = data

  return (
    <Org.Wrapper>
      <Org.Header>{organisation_name}</Org.Header>
      <Org.Meta>
        <Org.MetaItem row>
          <FlexRow>
            <Text element="p" typeStyle="body-2">
              <Org.MetaItemLabel>Type of organisation: </Org.MetaItemLabel>
              <Org.MetaItemValue>{organisation_type}</Org.MetaItemValue>
            </Text>
            <Text element="p" typeStyle="body-2">
              <Org.MetaItemLabel>Number of opportunities:</Org.MetaItemLabel>
              <Org.MetaItemValue>{numberOfOpportunities}</Org.MetaItemValue>
            </Text>
          </FlexRow>
        </Org.MetaItem>
      </Org.Meta>
      <Heading level={4}>
        {numberOfOpportunities} opportunit
        {numberOfOpportunities > 1 ? 'ies' : 'y'}
      </Heading>
    </Org.Wrapper>
  )
}

OrganisationSummary.propTypes = {
  data: PropTypes.object,
  numberOfOpportunities: PropTypes.number,
  organisationName: PropTypes.string,
}

export default withRouter(OrganisationSummary)
