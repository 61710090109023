import React from 'react'
import Layout from '../layouts/Layout/Layout'

import NewIdeaConfirmationContainer from '../components/NewIdea/NewIdeaConfirmationContainer'

const NewIdeaConfirmation = () => {
  return (
    <Layout pageId="page-new-idea-confirm">
      <NewIdeaConfirmationContainer />
    </Layout>
  )
}

export default NewIdeaConfirmation
