import React from 'react'
import { PropTypes } from 'prop-types'
import IdeaHistoryItem from '../IdeaHistoryItem/IdeaHistoryItem'
import { IdeaTitle } from '../../styles'
import { IdeaHistoryWrapper } from './styles'

const IdeaHistory = ({ history }) => {
  return (
    <IdeaHistoryWrapper>
      <IdeaTitle>History</IdeaTitle>
      <>
        {history.map(item => (
          <IdeaHistoryItem key={item.updated_date} item={item} />
        ))}
      </>
    </IdeaHistoryWrapper>
  )
}

IdeaHistory.propTypes = {
  history: PropTypes.array.isRequired,
}

export default IdeaHistory
