import { createSelector } from 'reselect'

const getFaqsState = state => state.faqsReducer

const getLoading = createSelector(
  [getFaqsState],
  faqsReducer => faqsReducer.loading
)

const getErrorMessage = createSelector(
  [getFaqsState],
  faqsReducer => faqsReducer.errorMessage
)

const getFaqs = createSelector([getFaqsState], faqsReducer => faqsReducer.faqs)

export { getFaqs, getLoading, getErrorMessage }
