import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { doUpdateIdeaStatus } from '../../redux/dispatchers/idea'
import { idea_statuses } from '../../constants'
import { ErrorMessageType } from '../../types'

interface UseFieldStateType {
  name: string
  value: any
  error: ErrorMessageType
  isValid: boolean
}

interface UseFieldType {
  value: any
  error: ErrorMessageType
  isValid: boolean
  onChange: (event: any) => void
}

const useField = (
  name: string,
  value: any,
  validation: Function
): UseFieldType => {
  const [field, setField] = useState<UseFieldStateType>({
    name,
    value,
    error: null,
    isValid: false,
  })

  const onChange = (event: React.ChangeEvent<HTMLFormElement>): void => {
    const { value, type, checked } = event.target
    const valueNew =
      type === 'checkbox'
        ? checked
          ? [...field.value, value]
          : field.value.filter((item: string) => item !== value)
        : value
    const error = validation(valueNew)

    setField({
      ...field,
      value: valueNew,
      error,
      isValid: !error,
    })
  }

  return {
    value: field.value,
    error: field.error,
    isValid: field.isValid,
    onChange,
  }
}

interface UseIdeaFeedbackFormType {
  feedback: UseFieldType
  feedback_time: UseFieldType
  agree: UseFieldType
  isValid: boolean
  isLoading: boolean
  errorMessage: ErrorMessageType
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

const useIdeaFeedbackForm = (
  id: string,
  campaign: string
): UseIdeaFeedbackFormType => {
  const dispatch = useDispatch()
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>(null)
  const feedback = useField('feedback', '', (value: string) =>
    !value ? 'Feedback is required' : null
  )
  const feedback_time = useField('feedback_time', '', (value: string) =>
    !value ? 'Time is required' : null
  )
  const agree = useField('agree', ['Yes'], (value: string[]) =>
    !value.includes('Yes') ? 'This is required' : null
  )
  const isValid = feedback.isValid && feedback_time.isValid && agree.isValid
  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    setErrorMessage(null)
    setisLoading(true)
    dispatch(
      doUpdateIdeaStatus(id, campaign, idea_statuses.COMPLETED, {
        feedback: feedback.value,
        feedback_time: feedback_time.value,
      })
    )
      .then(() => {
        setisLoading(false)
      })
      .catch((error: Error) => {
        setisLoading(false)
        setErrorMessage(error.message)
      })
  }

  return {
    feedback,
    feedback_time,
    agree,
    isValid,
    isLoading,
    errorMessage,
    onSubmit,
  }
}

export { useField, useIdeaFeedbackForm }
