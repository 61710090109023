import { createStore, applyMiddleware, compose } from 'redux'
import { combineReducers } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { ENABLE_DEV_TOOLS } from '../config'
import {
  aboutReducer,
  authReducer,
  authInitialState,
  adminIdeaInitialState,
  adminIdeaReducer,
  adminIdeasReducer,
  adminIdeasInitialState,
  dataStoreReducer,
  editIdeaReducer,
  faqsReducer,
  formReducer,
  ideaReducer,
  ideaInitialState,
  ideaFilterReducer,
  ideasReducer,
  localHeroesReducer,
  localHeroesFormReducer,
  mapListReducer,
  menuReducer,
  newIdeaReducer,
  opportunitiesReducer,
  opportunityDetailReducer,
  programmeInitialState,
  programmeReducer,
  programmesInitialState,
  programmesReducer,
  studiesReducer,
  userIdeasReducer,
} from './reducers'
import { adminCharityListReducer } from './ducks/adminCharityList'
import {
  responseStatus,
  AdminIdeasData150DaysType,
  AdminIdeasDataHeroesType,
  PaginationControlsType,
} from '../types'
import { FiltersType } from './actions/filters'
import { ProgrammeState } from './reducers/programmeDetail'

export interface AuthState {
  token: string | null
  user: string | null
}

export type AdminIdeasDataType =
  | AdminIdeasDataHeroesType[]
  | AdminIdeasData150DaysType[]

export interface AdminIdeasState {
  data: AdminIdeasDataType
  source: string
  controls?: PaginationControlsType
  filters: FiltersType
}

export interface AdminIdeaState {
  source: string | null
}

export interface DataStoreState {
  data: object | undefined
  responseStatus: responseStatus
  errorMessage: string | null
}

export interface ProgrammesState {
  title: string
  subHeader: string
  tiles?: object[]
}

export interface IdeaState {
  idea: any
  loading: boolean
  errorMessage: string | null
  isDeclineFormVisible: boolean
}

export interface State {
  auth: AuthState
  adminIdeas: AdminIdeasState
  adminIdea: AdminIdeaState
  dataStore: DataStoreState
  programme: ProgrammeState
  programmes: ProgrammesState
  ideaReducer: IdeaState
  forms: {}
  opportunityDetail: {}
}

export const getInitialState = (): Partial<State> => ({
  auth: authInitialState,
  adminIdeas: adminIdeasInitialState,
  adminIdea: adminIdeaInitialState,
  programmes: programmesInitialState,
  programme: programmeInitialState,
  ideaReducer: ideaInitialState,
})

const rootReducer = combineReducers({
  auth: authReducer,
  adminIdeas: adminIdeasReducer,
  adminIdea: adminIdeaReducer,
  adminCharityList: adminCharityListReducer,
  newIdea: newIdeaReducer,
  ideaReducer,
  ideasReducer,
  userIdeasReducer,
  opportunitiesReducer,
  opportunityDetail: opportunityDetailReducer,
  studiesReducer,
  localHeroesReducer,
  editIdeaReducer,
  localHeroes: localHeroesFormReducer,
  forms: formReducer,
  ui: combineReducers({ menu: menuReducer, listView: mapListReducer }),
  ideaFilter: ideaFilterReducer,
  faqsReducer,
  aboutReducer,
  dataStore: dataStoreReducer,
  programme: programmeReducer,
  programmes: programmesReducer,
})

const middlewares = [thunk]
const state: State = getInitialState() as State

let composeEnhancers = compose

if (ENABLE_DEV_TOOLS) {
  composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

const store = createStore(
  rootReducer,
  state,
  composeEnhancers(applyMiddleware(...middlewares))
)

export { store }
export type RootState = ReturnType<typeof rootReducer>
