import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  setSearchTerm,
  setCategory,
} from '../../redux/actions/opportunitiesSearch'
import {
  doSearchOrganisations,
  doResetSearch,
} from '../../redux/dispatchers/opportunitiesSearch'

import OrganisationsSearch from './OrganisationsSearch'
import {
  getIsSearching,
  getErrorMessage,
} from '../../redux/selectors/opportunitiesSearch'

import { getCategoryList } from '../../redux/selectors/opportunitiesList'

const mapStateToProps = state => {
  return {
    isSearching: getIsSearching(state),
    errorMessage: getErrorMessage(state),
    categoryList: getCategoryList(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSearchTerm,
      setCategory,
      doResetSearch,
      doSearchOrganisations,
    },
    dispatch
  )

const OrganisationsSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationsSearch)

export default OrganisationsSearchContainer
