export const actionTypes = {
  SAVE_FORM_VALUES: 'LH_SAVE_FORM_VALUES',
  RESET_FORM: 'LH_RESET_FORM',
  SEND_IDEA: 'LH_SEND_IDEA',
  SEND_IDEA_SUCCESS: 'LH_SEND_IDEA_SUCCESS',
  SEND_IDEA_FAILURE: 'LH_SEND_IDEA_FAILURE',
  UPDATE_FORM: 'LH_UPDATE_FORM',
  SET_FIELD_ERROR: 'LH_SET_FIELD_ERROR',
  GET_LOCAL_HEROES: 'GET_LOCAL_HEROES',
  GET_LOCAL_HEROES_SUCCESS: 'GET_LOCAL_HEROES_SUCCESS',
  GET_LOCAL_HEROES_FAILURE: 'GET_LOCAL_HEROES_FAILURE',
}

const {
  SAVE_FORM_VALUES,
  UPDATE_FORM,
  SET_FIELD_ERROR,
  RESET_FORM,
  SEND_IDEA,
  SEND_IDEA_SUCCESS,
  SEND_IDEA_FAILURE,
  GET_LOCAL_HEROES,
  GET_LOCAL_HEROES_SUCCESS,
  GET_LOCAL_HEROES_FAILURE,
} = actionTypes

export const setFormValues = formValues => ({
  type: SAVE_FORM_VALUES,
  payload: {
    formValues,
  },
})

export const updateForm = (property, value) => ({
  type: UPDATE_FORM,
  payload: {
    key: property,
    value,
  },
})

export const setFormError = (property, errorMessage) => ({
  type: SET_FIELD_ERROR,
  payload: {
    key: property,
    errorMessage,
  },
})

export const resetForm = () => ({
  type: RESET_FORM,
})

export const sendLocalHeroes = () => ({
  type: SEND_IDEA,
})

export const sendLocalHeroesSuccess = ({ colleague_email, ref }) => ({
  type: SEND_IDEA_SUCCESS,
  payload: {
    colleague_email,
    ref,
  },
})

export const sendLocalHeroesFailure = errorMessage => ({
  type: SEND_IDEA_FAILURE,
  payload: {
    errorMessage,
  },
})

export const getLocalHeroes = () => ({
  type: GET_LOCAL_HEROES,
})

export const getLocalHeroesSuccess = localHeroes => ({
  type: GET_LOCAL_HEROES_SUCCESS,
  payload: { localHeroes },
})

export const getLocalHeroesFailure = errorMessage => ({
  type: GET_LOCAL_HEROES_FAILURE,
  payload: {
    errorMessage,
  },
})
