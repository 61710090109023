import React from 'react'
import { PropTypes } from 'prop-types'
import { LoadingIcon } from 'luna-react'
import { Redirect } from 'react-router-dom'
import routes from '../../routes'
import { Container, Content } from '../../styles'
import IdeaContent from '../IdeaContent/IdeaContent'
import { PageHeader } from '../PageHeader'
import { ConfirmButton, EditButton, FormButtons } from './styles'
import { history } from '../../createHistory'

class NewIdeaConfirmation extends React.Component {
  confirmIdea = e => {
    e.preventDefault()
    if (!this.props.sending) {
      this.props.doPostIdea()
    }
  }

  backToForm = e => {
    e.preventDefault()
    history.goBack()
  }

  render() {
    const { formValues, sending, isValid } = this.props

    if (!isValid) {
      return <Redirect to={routes.userIdeas} noThrow />
    }
    return (
      <>
        <PageHeader
          title="Review form content"
          blurb="Please confirm the information entered is correct."
        />

        <Content data-id="confirmation">
          <Container.Padded>
            {formValues ? <IdeaContent idea={formValues} /> : null}
            <FormButtons>
              <EditButton data-id="edit" onClick={this.backToForm}>
                Edit
              </EditButton>
              <ConfirmButton data-id="confirm" onClick={this.confirmIdea}>
                {sending ? (
                  <LoadingIcon style={{ display: 'inline-block' }} inverted />
                ) : (
                  <>Submit</>
                )}
              </ConfirmButton>
            </FormButtons>
          </Container.Padded>
        </Content>
      </>
    )
  }
}

NewIdeaConfirmation.propTypes = {
  formValues: PropTypes.object.isRequired,
  isValid: PropTypes.bool,
  sending: PropTypes.bool,
}

export default NewIdeaConfirmation
