import { createSelector } from 'reselect'
import orderBy from 'lodash/orderBy'

const getIdeasState = state => state.userIdeasReducer

const getLoading = createSelector(
  [getIdeasState],
  ideasReducer => ideasReducer.loading
)

const getErrorMessage = createSelector(
  [getIdeasState],
  ideasReducer => ideasReducer.errorMessage
)

const getIdeas = createSelector(
  [getIdeasState],
  ideasReducer => ideasReducer.ideas
)

const getIdeasSort = createSelector(
  [getIdeasState],
  ideasReducer => ideasReducer.sort
)

const getIdeasSortBy = createSelector([getIdeasSort], sort => sort.by)

const getIdeasSortDirection = createSelector(
  [getIdeasSort],
  sort => sort.direction
)

const getSortedIdeas = createSelector(
  [getIdeas, getIdeasSortBy, getIdeasSortDirection],
  (ideas, by, direction) => {
    return orderBy(
      ideas,
      [
        idea => {
          if (idea[by]) {
            return idea[by].toString().toLowerCase()
          }
          return null
        },
      ],
      [direction]
    )
  }
)

export { getSortedIdeas, getLoading, getErrorMessage }
