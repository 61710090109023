import { OPTIONS } from '../../util/ideaFilterUtils'

export const actions = {
  UPDATE_SEARCH_FIELD: 'UPDATE_SEARCH_FIELD',
  UPDATE_FILTER_FIELD: 'UPDATE_FILTER_FIELD',
  UPDATE_DATE_RANGE: 'UPDATE_DATE_RANGE',
  RESET_IDEA_SEARCH_FORM: 'RESET_IDEA_SEARCH_FORM',
  SEARCH_TYPE_CHANGE: 'SEARCH_TYPE_CHANGE',
  RESET_FILTERS: 'RESET_FILTERS',
}

export const isIdTypeSelected = type =>
  OPTIONS.findIndex(({ value }) => value === 'ref') === parseInt(type)

export const updateSearch = (key, value) => ({
  type: actions.UPDATE_SEARCH_FIELD,
  payload: {
    key,
    value,
  },
})

export const updateDateRange = value => ({
  type: actions.UPDATE_DATE_RANGE,
  payload: value,
})

export const updateFilters = (key, filter) => {
  return {
    type: actions.UPDATE_FILTER_FIELD,
    payload: {
      key,
      value: filter.value,
      selected: filter.selected,
    },
  }
}

export const resetFilters = () => dispatch => {
  dispatch({
    type: actions.RESET_FILTERS,
  })
}

export const changeSearchType = value => dispatch => {
  if (isIdTypeSelected(value)) {
    dispatch({
      type: actions.RESET_FILTERS,
    })
  }
  dispatch({
    type: actions.SEARCH_TYPE_CHANGE,
    payload: {
      value,
    },
  })
}
