import { withRouter } from 'react-router'
import { Dispatch, AnyAction } from 'redux'
import { connect } from 'react-redux'
import { State } from '../../redux/store'
import FiltersControls from './FiltersControls.component'
import {
  selectConfigData,
  selectFilterValues,
  selectIsFilterDisabled,
} from '../../redux/selectors/filters'
import { updateFilters, updateDateRange } from '../../redux/actions/filters'

const mapStateToProps = (state: State, ownProps: any): any => {
  const pathname = ownProps.location.pathname
  return {
    configData: selectConfigData(pathname),
    fields: selectFilterValues(state),
    disabled: selectIsFilterDisabled(state, pathname),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction, State>) => {
  return {
    onChange: (key: string, value: string, selected: boolean) => {
      dispatch(updateFilters(key, value, selected))
    },
    onRangeChange: (dateRange: object) => {
      dispatch(updateDateRange(dateRange))
    },
  }
}

const FiltersControlsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FiltersControls)
)

export default FiltersControlsContainer

FiltersControlsContainer.displayName = 'FiltersControlsContainer'
