import styled from 'styled-components'
import { Text } from 'luna-react'

const FormLabel = styled(Text)`
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
`

export { FormLabel }
