import styled from 'styled-components'
import { OutlinedButton, FilledButton, Text } from 'luna-react'

const Button = styled(FilledButton)`
  display: flex;
  position: fixed;
  right: 0;
  top: 50%;
  padding: 20px 10px;
  transform: translateY(-50%) rotate(-90deg);
  transform-origin: bottom right;
`
const FeedbackWrapper = styled.div`
  max-width: 620px;
  width: 100%;
  max-height: 100%;
  background: ${({ theme }) => theme.color.white};
  padding: 30px;
  overflow-y: scroll;
`

const Heading = styled.h1`
  font-size: 26px;
  line-height: 26px;
`
const P = styled.p`
  font-weight: bold;
  font-family: 'MaryAnn';

  @media (min-width: ${({ theme }) => theme.mq.md}) {
    font-size: 22px;
    line-height: 28px;
  }
`

const SubmitButton = styled(FilledButton)``

const CloseButton = styled(OutlinedButton)`
  margin-right: 10px;
`

const FormSection = styled.div`
  background-color: ${props => props.theme.color.lighterGrey};
  margin-bottom: 20px;
  padding: 1.5rem;
  padding-bottom: 1px;
`

const FormLabel = styled(Text)`
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: bold;
  font-family: 'MaryAnn';
`

const ErrorMessage = styled.p`
  color: ${props => props.theme.color.red};
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
`

export {
  Button,
  FeedbackWrapper,
  Heading,
  P,
  SubmitButton,
  CloseButton,
  FormSection,
  FormLabel,
  ErrorMessage,
}
